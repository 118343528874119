import {
  Accordion,
  Alert,
  BasicLink,
  PagePadding,
  TextLink,
  AccordionNodeItem,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";
import TransfusionTriggerChecklist from "../../components/Checklists/TransfusionTriggerChecklist";
import { getInternalLinkForPage } from "../../util/pageHandlingHelpers";
import OpHome from "../op";

export function CiaPbm() {
  // const linkTransfusionTriggerPage = getInternalLinkForPage(CiaPbm, "de", "Mehr zu Transfusionstriggern");
  const linkOpHome = getInternalLinkForPage(
    OpHome,
    "de",
    "Mehr zu Prä- und postoperativem Anämiemanagement"
  );

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Patient Blood Management</h1>
        <ul>
          <li>
            Interdisziplinäres Diagnose- und Therapiekonzept zur Steigerung der
            Patientensicherheit
          </li>
        </ul>

        <Alert
          content="Ziel: Optimierung der Erythrozytenzahl, Minimierung des Blutverlustes und Evaluierung der physiologischen Anämietoleranz."
          type="info"
        />

        <h2>
          Die drei Säulen des Patient Blood Managements{" "}
          <TextReference references={["ref1", "ref9", "ref10", "ref11"]} />
        </h2>

        <Accordion
          items={[
            {
              title: "Anämiediagnostik",
              body: {
                content: (
                  <AccordionNodeItem>
                    <p>
                      Frühe Detektion und Behandlung einer Anämie entsprechend
                      der WHO-Definition vor elektiven Eingriffen mit hoher
                      Transfusionswahrscheinlichkeit (&gt; 10 %)
                    </p>
                    <p>
                      Standardisierte Anämiediagnostik und bei Bedarf Therapie
                      der Anämie vor OP
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Blutverlust minimieren",
              body: {
                content: (
                  <AccordionNodeItem>
                    <p>
                      Maßnahmen zur Minimierung des Blutverlustes und die
                      vermehrte Nutzung fremdblutsparender Maßnahmen{" "}
                      <TextReference references={["ref15"]} />
                    </p>
                    <ul>
                      <li>
                        Blutverlust während und nach der OP minimieren (z. B.
                        restriktive Blutentnahmen)
                        <ul>
                          <li>Z.b. Verwendung kleinerer Blutentnahmegefäße</li>
                          <li>Reduzieren der Häufigkeit von Blutentnahmen</li>
                          <li>Verwendung mimimal invasiver Techniken</li>
                        </ul>
                      </li>
                      <li>
                        Präoperative Blutverlustvorsorge
                        <ul>
                          <li>
                            Z.B: Rechtzeitiges, kontrolliertes Absetzen von
                            Antikoagulatoren oder Thrombozytenaggregationshemmer
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Leitliniengerechte Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <p>
                      Rationaler und leitliniengerechter Einsatz von
                      Bluttransfusionen
                    </p>
                    <ul>
                      <li>
                        Einhaltung der Leitlinien:
                        <ul>
                          <li>
                            S3-Leitlinie Supportivtherapie
                            <br />
                            <BasicLink
                              enableLinkDecoration
                              link={{
                                isExternal: true,
                                url: "https://www.leitlinienprogramm-onkologie.de/fileadmin/user_upload/Downloads/Leitlinien/Supportivtherapie/LL_Supportiv_Langversion_1.3.pdf",
                                title: "Zum Leitlinienprogramm Onkologie",
                                label: "Zum Leitlinienprogramm Onkologie",
                              }}
                            />
                          </li>
                          <li>
                            Querschnittsleitlinie der Bundesärztekammer
                            <br />
                            <BasicLink
                              enableLinkDecoration
                              link={{
                                isExternal: true,
                                url: "https://www.bundesaerztekammer.de/aerzte/medizin-und-ethik/wissenschaftlicher-beirat/veroeffentlichungen/haemotherapietransfusionsmedizin/querschnitt-leitlinie/",
                                title: "Zur Bundesärztekammer",
                                label: "Zur Bundesärztekammer",
                              }}
                            />
                            <br />
                            Hinweis: Links nur mit Internetzugang nutzbar
                          </li>
                          <li>
                            Transfusionsgrund hinterfragen (siehe Checkliste)
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />

        <p>
          Die Gestaltung und Einführung des <Abbreviation>pbm</Abbreviation>{" "}
          erfolgt am besten durch interdisziplinäre Teams, die zunächst
          Versorgungsstrukturen analysieren und optimieren müssen, um Ressourcen
          der Patient:innen zu schonen und zu stärken. Weitere Infos finden Sie
          in diesem Video.
        </p>

        <p>
          <BasicLink
            enableLinkDecoration
            link={{
              label: "Zum Video",
              url: "https://www.patientbloodmanagement.de/",
              title: "Zum Video",
              isExternal: true,
            }}
          />
        </p>
        <p>Hinweis: Link nur mit Internetzugang nutzbar</p>
        <p>
          Mit freundlicher Genehmigung des <Abbreviation>pbm</Abbreviation>
          -Netzwerkes
        </p>

        <TextLink link={linkOpHome} />

        <h2>Transfusionstrigger-Checkliste</h2>

        <h3>
          Indikationsstellung von <Abbreviation>ek</Abbreviation>s{" "}
          <TextReference references={["ref9"]} />
        </h3>
        <p>
          Die Entscheidungsfindung zu einer EK-Gabe darf nicht alleine anhand
          des Hb-Werts erfolgen, sondern muss weitere Faktoren, wie die
          individuelle Anämietoleranz, den akuten klinischen Zustand der
          Patient:innen sowie physiologische Transfusionstrigger beachten.
          Mithilfe dieser Checkliste können Sie anklicken, welche
          Transfusionstrigger neben dem bestimmten Hb-Wert bestehen.
        </p>

        <TransfusionTriggerChecklist />
      </PagePadding>
    </>
  );
}

export default CiaPbm;
