const references = {
  fn: {
    ref1: 'Fieber unbekannter Genese (FUO) bei neutropenischen Patienten. Empfehlungen der Arbeitsgemeinschaft Infektionen (AGIHO) der DGHO (Deutsche Gesellschaft für Hämatologie und Medizinische Onkologie), <a href="https://www.onkopedia.com/de/onkopedia/guidelines/fieber-unbekannter-genese-fuo-bei-neutropenischen-patienten/@@guideline/html/index.html" target="_blank" rel="noopener noreferrer">https://www.onkopedia.com/de/onkopedia/guidelines/fieber-unbekannter-genese-fuo-bei-neutropenischen-patienten/@@guideline/html/index.html</a> (Letzter Zugriff 04.05.2022).',
    ref2: 'Leitlinienprogramm Onkologie (Deutsche Krebsgesellschaft, Deutsche Krebshilfe, AWMF): Supportive Therapie bei onkologischen PatientInnen – Langversion 1.3, 2020, AWMF Registernummer: 032/054OL, <a href="https://www.leitlinienprogrammonkologie.de/leitlinien/supportive-therapie/" target="_blank" rel="noopener noreferrer">https://www.leitlinienprogrammonkologie.de/leitlinien/supportive-therapie/</a> (Letzter Zugriff 04.05.2022).',
    ref3: "Common Terminology Criteria for Adverse Events (CTCAE); Version 5.0. Published: November 27, 2017.",
    ref4: "Klastersky J. et al. The Multinational Association for Supportive Care in Cancer risk index: A multinational scoring system for identifying low-risk febrile neutropenic cancer patients. J Clin Oncol. 2000 Aug;18(16):3038–51.",
    ref5: "Aapro et al. 2010 update of EORTC guidelines for the use of granulocyte-colony stimulating factor to reduce the incidence of chemotherapy-induced febrile neutropenia in adult patients with lymphoproliferative disorders and solid tumours. Eur J Cancer 2011; 47 (1): 8–32.",
    ref6: "Link H. Supportivtherapien: Essenziell für das Wohl des Patienten. Dtsch Ärztebl 2018; 115(7):[26].",
    ref7: "Klastersky J. et al. Ann Oncol. Management of febrile neutropenia: ESMO Clinical Practice Guidelines. 2016 Sep;27(suppl 5):v111– v118.",
    ref8: "Fachinformation Filgrastim HEXAL®, Stand Juli 2022.",
    ref9: "Fachinformation Ziextenzo®, Stand Juli 2021.",
    ref10:
      "Aapro et al. 2010 update of EORTC guidelines for the use of granulocyte-colony stimulating factor to reduce the incidence of chemotherapy-induced febrile neutropenia in adult patients with lymphoproliferative disorders and solid tumours. Eur J Cancer. 2011 Jan;47(1):8–32.",
    ref11:
      "Leitlinienprogramm Prophylaxe infektiöser Komplikationen durch Granulozyten-Koloniestimulierende Faktoren (G-CSF, Pegfilgrastim, Biosimilars) für die Arbeitsgemeinschaft Infektionen (AGIHO) der DGHO. Stand August 2014.",
    ref12:
      "Weycker et al. Risk of febrile neutropenia in patients receiving emerging chemotherapy regimens. Support Care Cancer. 2014 Dec;22(12):3275–85.",
    ref13:
      "Möbus et al. Ten-year results of intense dose-dense chemotherapy show superior survival compared with a conventional schedule in high-risk primary breast cancer: final results of AGO phase III iddEPC trial. Ann Oncol. 2018 Jan 1;29(1):178–185.",
    ref14:
      'Sepsis bei neutropenischen Patienten. Empfehlungen der Arbeitsgemeinschaft Infektionen (AGIHO) der DGHO und der Arbeitsgruppe iCHOP von DGHO und DGIIN. Online verfügbar unter <a href="https://www.onkopedia.com/de/onkopedia/guidelines/sepsis-bei-neutropenischen-patienten/@@guideline/html/index.html" target="_blank" rel="noopener noreferrer">https://www.onkopedia.com/de/onkopedia/guidelines/sepsis-bei-neutropenischen-patienten/@@guideline/html/index.html</a> (Letzter Zugriff 04.05.2022).',
    ref15:
      'Febrile Neutropenie mit Lungeninfiltraten nach intensiver Chemotherapie (Fieber in Neutropenie). Empfehlungen der Arbeitsgemeinschaft Infektionen (AGIHO) der DGHO (Deutsche Gesellschaft für Hämatologie und Medizinische Onkologie). Online verfügbar unter <a href="https://www.onkopedia.com/de/onkopedia/guidelines/febrile-neutropenie-mit-lungeninfiltraten-nach-intensiver-chemotherapie-fieber-in-neutropenie/@@guideline/html/index.html" target="_blank" rel="noopener noreferrer">https://www.onkopedia.com/de/onkopedia/guidelines/febrile-neutropenie-mit-lungeninfiltraten-nach-intensiver-chemotherapie-fieber-in-neutropenie/@@guideline/html/index.html</a> (Letzter Zugriff 04.05.2022).',
    ref16:
      "Diagnostik und Therapie früher und fortgeschrittener Mammakarzinome. Herausgegeben von der Kommission Mamma der AGO e. V., 2020 v1.",
    ref17:
      "Ishikawa et al. Feasibility of AC/EC Followed by Weekly Paclitaxel in Node-positive Breast Cancer in Japan. Anticancer Res. 2009; 29(5): 1515–1520.",
    ref18:
      "Shimizu et al. A Phase II Study of Epirubicin and Cyclophosphamide Followed by Weekly Paclitaxel with or without Trastuzumab as Primary Systemic Therapy in Locally Advanced Breast Cancer. Anticancer Research 2010; 30:4665–4672.",
    ref19:
      "Mirzaei HR et al. Dose-Dense Epirubicin and Cyclophosphamide Followed by Weekly Paclitaxel in Node-Positive Breast Cancer. Chemother Res Pract. 2014:259312.",
    ref20:
      'Pro Biosimilars. So profitiert unser Gesundheitssystem von Biosimilars! Online verfügbar unter <a href="https://probiosimilars.de/themen/die-bedeutung-von-biosimilars/bedeutung-fuer-das-gesundheitssystem/" target="_blank" rel="noopener noreferrer">https://probiosimilars.de/themen/die-bedeutung-von-biosimilars/bedeutung-fuer-das-gesundheitssystem/</a> (Letzter Zugriff 16.02.2022).',
    ref21:
      "Cerchione et al. Pegfilgrastim in primary prophylaxis of febrile neutropenia following frontline bendamustine plus rituximab treatment in patients with indolent non-Hodgkin lymphoma: a single center, real-life experience. Support Care Cancer. 2017 Mar; 25(3):839–845.",
    ref22:
      "Barr et al. R-CHOP, radioimmunotherapy, and maintenance rituximab in untreated follicular lymphoma (SWOG S0801): a single-arm, phase 2, multicentre study. Lancet Haematol. 2018 Mar;5(3):e102–e108.",
    ref23:
      "Reynolds et al. A Phase III trial of fludarabine, cyclophosphamide, and rituximab vs. pentostatin, cyclophosphamide, and rituximab in B-cell chronic lymphocytic leukemia. Invest New Drugs. 2012 Jun; 30(3):1232–40.",
    ref24:
      "Goede et al. Obinutuzumab plus Chlorambucil in Patients with CLL and Coexisting Conditions. N Engl J Med 2014; 370:1101–10.",
    ref25:
      "Pettengell et al. Impact of febrile neutropenia on R-CHOP chemotherapy delivery and hospitalizations among patients with diffuse large B-cell lymphoma. Support Care Cancer. 2012 Mar; 20(3):647–52.",
    ref26:
      "Casasnovas et al. PET-adapted treatment for newly diagnosed advanced Hodgkin lymphoma (AHL2011): a randomised, multicentre, non-inferiority, phase 3 study. Lancet Oncol 2019; 20: 202–15.",
    ref27:
      "Connors et al. Brentuximab Vedotin with Chemotherapy for Stage III or IV Hodgkin’s Lymphoma. N Engl J Med. 2018 Jan 25; 378(4):331–344.",
    ref28:
      "Younus et al. Febrile neutropenia rates with adjuvant docetaxel and cyclophosphamide chemotherapy in early breast cancer: discrepancy between published reports and community practice—an updated analysis. Curr Oncol. 2012 Dec; 19(6): 332–334.",
    ref29:
      "Yerushalmi et al. Adjuvant Docetaxel and Cyclophosphamide (DC) with Prophylactic Granulocyte Colony-Stimulating Factor (GCSF) on Days 8 &12 in Breast Cancer Patients: A Retrospective Analysis. PLoS One 2014; 9(10):e107273.",
    ref30: "Martin et al. Proc Am Soc Clin Oncol 2004; 22:14S (abstract 620).",
    ref31:
      "Truong et al. Interpreting febrile neutropenia rates from randomized, controlled trials for consideration of primary prophylaxis in the real world: a systematic review and meta-analysis. Annals of Oncology 2016;27(4):608–618.",
    ref32:
      "Gilbar et al. High incidence of febrile neutropenia following adjuvant breast chemotherapy with docetaxel, carboplatin and trastuzumab. Breast Cancer Manage 2014; 3(4):327-333.",
    ref33:
      "Valero et al. Multicenter phase III randomized trial comparing docetaxel and trastuzumab with docetaxel, carboplatin, and trastuzumab as first-line chemotherapy for patients with HER2-gene-amplified metastatic breast cancer (BCIRG 007 study): two highly active therapeutic regimens. J Clin Oncol. 2011; 29:149–156.",
    ref34:
      "Rocque et al. Adjuvant Therapy for HER2+ Breast Cancer: Practice, Perception and Toxicity. Breast Cancer Res. Treat. 2012; 131:713–721.",
    ref35:
      "Citron et al. Randomized Trial of Dose-Dense Versus Conventionally Scheduled and Sequential Versus Concurrent Combination Chemotherapy as Postoperative Adjuvant Treatment of Node-Positive Primary Breast Cancer: First Report of Intergroup Trial C9741/Cancer and Leukemia Group B Trial 9741. J Clin Oncol. 2003; 21(8):1431–1439.",
    ref36:
      "Mirzaei et al. Dose-Dense Epirubicin and Cyclophosphamide Followed by Docetaxel as Adjuvant Chemotherapy in Node-Positive Breast Cancer. Int J Breast Cancer 2013; 13:404396.",
    ref37: "Sikov WM et al. J Clin Oncol. 2015;33(1): 13–21.",
    ref38:
      "Nabholtz et al. Docetaxel and Doxorubicin Compared With Doxorubicin and Cyclophosphamide as First-Line Chemotherapy for Metastatic Breast Cancer: Results of a Randomized, Multicenter, Phase III Trial. J Clin Oncol. 2003; 21(6):968–975.",
    ref39:
      "Blohmer et al. Epirubicin and cyclophosphamide versus epirubicin and docetaxel as first-line therapy for women with metastatic breast cancer: final results of a randomised phase III trial. Annals of Oncology 2010; 21:1430–1435.",
    ref40:
      "Furlanetto, J. et al. Efficacy and safety of nab-paclitaxel 125 mg/m2 and nab-paclitaxel 150 mg/m2 compared to paclitaxel in early high-risk breast cancer. Results from the neoadjuvant randomized GeparSepto study (GBG 69). Breast Cancer Res Treat 2017;163: 495–506.",
    ref41:
      "Kuderer NM et al. Mortality, morbidity, and cost associated with febrile neutropenia in adult cancer patients. Cancer. 2006;106(10):2258-2266.",
    ref42:
      'Arbeitsverein Gynäkologische Onkologie e.V. (AGO). Diagnostik und Therapie früher und fortgeschrittener Mammakrzinome. Stand: 2022. Online verfügbar unter: <a href="https://www.ago-online.de/fileadmin/ago-online/downloads/_leitlinien/kommission_mamma/2022/AGO_2022D_Gesamtdatei.pdf" target="_blank" rel="noopener noreferrer">https://www.ago-online.de/fileadmin/ago-online/downloads/_leitlinien/kommission_mamma/2022/AGO_2022D_Gesamtdatei.pdf</a>. Zuletzt aufgerufen: 23.012023.',
    ref43:
      "Rugo HS et al. Optimizing treatment management of trastuzumab deruxtecan in clinical practice of breast cancer. ESMO Open. 2022;7(4):100553.",
    ref44:
      "Ellis PA et al. Phase III, randomized study of trastuzumab emtansine (T-DM1) ± pertuzumab (P) vs trastuzumab + taxane (HT) for first-line treatment of HER2-positive MBC: Primary results from the MARIANNE study. ASCO Annual Meeting 2015. Abstract Nr. 507.",
    ref45:
      'ClinicalTrials. A Study of Trastuzumab Emtansine (T-DM1) Plus Pertuzumab/Pertuzumab Placebo Versus Trastuzumab [Herceptin] Plus a Taxane in Participants With Metastatic Breast Cancer (MARIANNE). Online verfügbar unter: <a href="https://clinicaltrials.gov/ct2/show/NCT01120184" target="_blank" rel="noopener noreferrer">https://clinicaltrials.gov/ct2/show/NCT01120184</a>. Zuletzt aufgerufen am 02.02.23.',
    ref46:
      "Symposium „Antibody-Drug-Conjugates bei fortgeschrittenem Mammakarzinom“, 30.6.2022 im Rahmen des DGS-Kongresses. Veranstalter: Gilead Sciences.",
  },
  cia: {
    ref1: 'Leitlinienprogramm Onkologie (Deutsche Krebsgesellschaft, Deutsche Krebshilfe, AWMF): Supportive Therapie bei onkologischen PatientInnen - Langversion 1.3, 2020, AWMF Registernummer: 032/054OL, <a href="https://www.leitlinienprogramm-onkologie.de/leitlinien/supportive-therapie/" target="_blank" rel="noopener noreferrer">https://www.leitlinienprogramm-onkologie.de/leitlinien/supportive-therapie/</a> (Letzter Zugriff 30.11.2022).',
    ref2: 'Aapro M et al., Annals of Oncology 2018; 29(Supplement_4):iv96-iv110. Online verfügbar unter: <a href="https://www.sciencedirect.com/science/article/pii/S0923753419316886" target="_blank" rel="noopener noreferrer">https://www.sciencedirect.com/science/article/pii/S0923753419316886</a>. ',
    ref3: "Aapro MS, Link H: September 2007 update on EORTC guidelines and anemia management with erythropoiesis-stimulating agents. Oncologist 2008; 13 (3): 33–6.",
    ref4: "Fachinformation Epoetin alfa HEXAL<sup>®</sup>",
    ref5: 'Bundesärztekammer. Querschnitts-Leitlinien zur Therapie mit Blutkomponenten und Plasmaderivaten. Gesamtnovelle 2020. <a href="https://www.bundesaerztekammer.de/fileadmin/user_upload/_old-files/downloads/pdf-Ordner/MuE/Querschnitts-Leitlinien_BAEK_zur_Therapie_mit_Blutkomponenten_und_Plasmaderivaten-Gesamtnovelle_2020.pdf" target="_blank" rel="noopener noreferrer">https://www.bundesaerztekammer.de/fileadmin/user_upload/_old-files/downloads/pdf-Ordner/MuE/Querschnitts-Leitlinien_BAEK_zur_Therapie_mit_Blutkomponenten_und_Plasmaderivaten-Gesamtnovelle_2020.pdf</a> (Letzter Zugriff 18.01.2023).',
    ref6: "Whitlock EL, Kim H, Auerbach AD. Harms associated with single unit perioperative transfusion: retrospective population based analysis. BMJ. 2015;350:h3037.",
    ref7: "Acheson AG, Brookes MJ, Spahn DR. Effects of Allogeneic Red Blood Cell Transfusions on Clinical Outcomes in Patients Undergoing Colorectal Cancer Surgery: A Systematic Review and Meta-Analysis. Annals of Surgery. 2012;256(2):235-44.",
    ref8: "Horowitz M, Neeman E, Sharon E, Ben-Eliyahu S. Exploiting the critical perioperative period to improve long-term cancer outcomes. Nature Reviews Clinical Oncology. 2015;12(4):213-26.",
    ref9: 'Patient Blood Management. Online verfügbar unter <a href="https://www.patientbloodmanagement.de/" target="_blank" rel="noopener noreferrer">https://www.patientbloodmanagement.de/</a> (Letzter Zugriff 14.06.2022).',
    ref10:
      "Wimble et al. Narrative review of blood management in the cancer patient undergoing surgery. Dig Med Res 2020;3:30.",
    ref11:
      'Hastka J et al. Eisenmangel und Eisenmangelanämie. Online verfügbar unter <a href="https://www.onkopedia.com/de/onkopedia/guidelines/eisenmangel-und-eisenmangelanaemie/@@guideline/html/index.html" target="_blank" rel="noopener noreferrer">https://www.onkopedia.com/de/onkopedia/guidelines/eisenmangel-und-eisenmangelanaemie/@@guideline/html/index.html</a> (Letzter Zugriff 14.06.2022).',
    ref12: "AGO Guideline Breast Version 2018.1D.",
    ref13:
      "Möbus et al. Ten-year results of intense dose-dense chemotherapy show superior survival compared with a conventional schedule in high-risk primary breast cancer: final results of AGO phase III iddEPC trial. Ann Oncol. 2018 Jan 1; 29(1):178-185.",
    ref14:
      "Möbus et al. Adding epoetin alfa to intense dose-dense adjuvant chemotherapy for breast cancer: randomized clinical trial. J Natl Cancer Inst 2013 Jul 17;105(14):1018–26.",
    ref15:
      'Halvorsen S et al. 2022 ESC Guidelines on cardiovascular assessment and management of patients undergoing non-cardiac surgery. Eur Heart J. 2022;43(39):3826-3924. Online verfügbar unter: <a href="https://academic.oup.com/eurheartj/article/43/39/3826/6675076" target="_blank" rel="noopener noreferrer">https://academic.oup.com/eurheartj/article/43/39/3826/6675076</a>. (Letzter Zugriff 06.12.2022).',
    ref16:
      "Ganzoni AM. Eisen-Dextran intravenös: therapeutische und experimentelle Möglichkeiten. Schweiz Med Wochenschr 1970; 100:301–3",
    ref17:
      "Delaney M et al. Transfusion reactions: prevention, diagnosis, and treatment. Lancet. 2016;388(10061):2825-2836.",
    ref18:
      'Khoury JD, et al. The 5th edition of the World Health Organization Classification of Haematolymphoid Tumours: Myeloid and Histiocytic/Dendritic Neoplasms. Leukemia. 2022 Jul;36(7):1703-1719. Online verfügbar unter: <a href="https://www.nature.com/articles/s41375-022-01613-1" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/s41375-022-01613-1</a>. Zuletzt aufgerufen am: 24.02.2023.',
  },
  mds: {
    ref1: 'Myelodysplastische Syndrome (MDS). Empfehlungen der Fachgesellschaft zur Diagnostik und Therapie hämatologischer und onkologischer Erkrankungen, herausgegeben von der DGHO (Deutsche Gesellschaft für Hämatologie und Medizinische Onkologie). Aktueller Stand: Februar 2023. <a href="https://www.onkopedia.com/de/onkopedia/guidelines/myelodysplastische-syndrome-mds/@@guideline/html/index.html" target="_blank" rel="noopener noreferrer">https://www.onkopedia.com/de/onkopedia/guidelines/myelodysplastische-syndrome-mds/@@guideline/html/index.html</a> (Letzter Zugriff 25.02.2023)',
    ref2: "Aapro M et al., Annals of Oncology 2018; 29(Supplement_4):iv96-iv110.",
    ref3: "Fachinformation Epoetin alfa HEXAL<sup>®</sup>",
    ref4: "Fachinformation Lenalidomid HEXAL<sup>®</sup>",
    ref5: "Fachinformation Reblozyl<sup>®</sup>",
    ref6: "Lyons RM et al. Relation between chelation and clinical outcomes in lower-risk patients with myelodysplastic syndromes: Registry analysis at 5 years. Leuk Res 2017;56:88–95.",
    ref7: "Leitch HA et al. Iron overload in myelodysplastic syndromes: Evidence based guidelines from the Canadian consortium on MDS. Leuk Res 2018;74:21–41.",
    ref8: "Langemeijer S et al. Impact of Treatment with Iron Chelators in Lower-Risk MDS Patients Participating in the European Leukemianet MDS (EUMDS) Registry. Blood 2016;128:3186.",
    ref9: "Hao Y et al. Clinical Outcomes Among Patients with Myelodysplastic Syndromes Treated with Iron Chelation Therapy: A Real World Medicare Database Study. Blood 2016;128:2396.",
    ref10: "Hao Y et al. NCCN 2017; Poster AB2017-13.",
    ref11:
      "Gattermann N et al. Iron overload in MDS-pathophysiology, diagnosis, and complications. Ann Haematol 2011;90:1–10.",
    ref12: "Fachinformation Deferasirox HEXAL<sup>®</sup>",
    ref13:
      "Greenberg PL et al. Revised international prognostic scoring system for myelodysplastic syndromes. Blood 2012;120:2454–2465.",
    ref14:
      "Angelucci E et al. Iron Chelation in Transfusion-Dependent Patients With Low- to Intermediate-1–Risk Myelodysplastic Syndromes. Ann Intern Med 2020;172:513–522.",
    ref15:
      'Khoury JD, et al. The 5th edition of the World Health Organization Classification of Haematolymphoid Tumours: Myeloid and Histiocytic/Dendritic Neoplasms. Leukemia. 2022 Jul;36(7):1703-1719. Online verfügbar unter: <a href="https://www.nature.com/articles/s41375-022-01613-1" target="_blank" rel="noopener noreferrer">https://www.nature.com/articles/s41375-022-01613-1</a>. Zuletzt aufgerufen am: 24.02.2023.',
    ref16:
      "Jädersten M, et al. Erythropoietin and granulocyte-colony stimulating factor treatment associated with improved survival in myelodysplastic syndrome. J Clin Oncol. 2008;26(21):3607-3613.",
    ref17:
      "Fenaux P, Giagounidis A, Selleslag D, et al. A randomized phase 3 study of lenalidomide versus placebo in RBC transfusion-dependent patients with Low-/Intermediate-1-risk myelodysplastic syndromes with del5q. Blood. 2011;118(14):3765-3776.",
    ref18:
      "Kuendgen Aet al. Lenalidomide does not increase AML progression risk in RBC transfusion-dependent patients with Low- or Intermediate-1-risk MDS with del(5q): a comparative analysis. Leukemia. 2013;27(5):1072-1079.",
  },
  op: {
    ref1: "Deutsche Gesellschaft für Anästhesiologie und Intensivmedizin (DGAI) in Kooperation mit der Arbeitsgemeinschaft der Wissenschaftlichen Medizinischen Fachgesellschaften (AWMF). S3-Leitlinie Präoperative Anämie. Version 1.0, April 2018.",
    ref2: 'Patient Blood Management. Medizinische Grundlagen. Online verfügbar unter <a href="https://www.patientbloodmanagement.de/pbm-informationen-fuer-aerzte/" target="_blank" rel="noopener noreferrer">https://www.patientbloodmanagement.de/pbm-informationen-fuer-aerzte/</a> (Letzter Zugriff 22.06.2022).',
    ref3: "Meybohm P, Muller MM, Zacharowski K. [Preoperative Preparation: Patient Blood Management – What is Optimal?]. Anästhesiologie, Intensivmedizin, Notfallmedizin und Schmerztherapie. 2017;52(5):326-40.",
    ref4: "Munoz M, Acheson AG, Auerbach M, Besser M, Habler O, Kehlet H, et al. International consensus statement on the peri-operative management of anaemia and iron deficiency. Anaesthesia. 2017;72(2):233-47.",
    ref5: 'National Blood Association Australia. Patient Blood Management Guidelines. Onlive verfügbar unter <a href="https://www.blood.gov.au/pbm-guidelines" target="_blank" rel="noopener noreferrer">https://www.blood.gov.au/pbm-guidelines</a> (Letzter Zugriff 22.06.2022).',
    ref6: 'National Institute for Health and Care Excellence. Blood transfusion – Quality standard 138. 2016. Online verfügbar unter <a href="https://www.nice.org.uk/guidance/qs138" target="_blank" rel="noopener noreferrer">https://www.nice.org.uk/guidance/qs138</a> (letzter Zugriff 22.06.2022).',
    ref7: "Froessler B et al. The Important Role for Intravenous Iron in Perioperative Patient Blood Management in Major Abdominal Surgery: A Randomized Controlled Trial. Annals of Surgery. 2016;264(1):41-6.",
    ref8: "Meybohm P et al. Feasibility and efficiency of a preoperative anaemia walk-in clinic: secondary data from a prospective observational trial. British Journal of Anaesthesia. 2017;118(4):625-6.",
    ref9: 'Halvorsen S et al. 2022 ESC Guidelines on cardiovascular assessment and management of patients undergoing non-cardiac surgery. Eur Heart J. 2022;43(39):3826-3924. Online verfügbar unter: <a href="https://academic.oup.com/eurheartj/article/43/39/3826/6675076" target="_blank" rel="noopener noreferrer">https://academic.oup.com/eurheartj/article/43/39/3826/6675076</a>. (Letzter Zugriff 06.12.2022).',
  },
  home: {},
};

export default references;
