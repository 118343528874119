import {
  BloodDropIcon,
  CrossIcon,
  Highlight,
  IconListItem,
  List,
  PagePadding,
  SearchIcon,
  Table,
  TableHead,
  TableRow,
  ThermometerIcon,
} from "components/ui";
import { ListItem, TableBody } from "@mui/material";
import HexalSeo from "../../components/HexalSeo";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";

export function FnDiagnosticNeutropenia() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik Neutropenie</h1>
        <p>Die Diagnostik erfolgt über ein Differentialblutbild.</p>
        <h2>
          Schweregrade nach <Abbreviation>ctcae</Abbreviation>{" "}
          <TextReference references={["ref3"]} />
        </h2>
        <Table>
          <TableHead
            labels={[
              "Grad",
              "Absolute Zahl der Neutrophilen (x 10<sup>9</sup>/l)",
            ]}
          />
          <TableBody>
            <TableRow entries={["0", "≥ 2 (Normalwerte)"]} />
            <TableRow entries={["1", "1,5 bis < 2"]} />
            <TableRow entries={["2", "1 bis < 1,5"]} />
            <TableRow entries={["3", "0,5 bis < 1"]} />
            <TableRow entries={["4", "< 0,5"]} />
          </TableBody>
        </Table>
        <p>
          Regelmäßige Blutbildkontrolle wird empfohlen (z. B. 2x/Woche, im Nadir
          ggf. häufiger)
        </p>
        <h2>Weiterführende Diagnostik</h2>
        <p>
          <Highlight>Wenn Hinweise auf Infektion gegeben sind:</Highlight>
        </p>
        <List>
          <IconListItem
            icon={<ThermometerIcon />}
            description="Tägliche Temperaturkontrolle und Aufklärung der Patient:innen, sich bei Fieber umgehend beim Arzt/bei der Ärztin vorzustellen."
          />
          <IconListItem icon={<SearchIcon />} description="Symptome beachten" />
          <IconListItem
            icon={<BloodDropIcon />}
            description="2x wöchentlich Differentialblutbild"
          />
          <ListItem className="text-only">
            Bei Fieber oder neu auftretenden Symptomen:
          </ListItem>
          <IconListItem
            icon={<CrossIcon />}
            description={
              <>
                Sofortige Wiedervorstellung{" "}
                <TextReference references={["ref1"]} />
              </>
            }
          />
        </List>
        <h3>Ergänzend</h3>
        <ul>
          <li>
            Screening von asymptomatischen neutropenischen Patient:innen auf
            invasive Pilzinfektionen
          </li>
          <li>
            Bei Hochrisiko-Patient:innen, ohne systemische Antimykotika-Therapie
            gegen Schimmelpilze und mit erwarteter Neutropenie-Dauer &gt; 7
            Tage: mind. 2x/Woche Kontrolle von Aspergillus-Galactomannan im
            Serum
            <TextReference references={["ref1"]} />
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default FnDiagnosticNeutropenia;
