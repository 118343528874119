import { ReactNode } from "react";

/* eslint-disable-next-line */
export interface NavigationListProps {
  children: ReactNode;
}

export function NavigationList(props: NavigationListProps) {
  return <div>{props.children}</div>;
}

export default NavigationList;
