import { Helmet } from "react-helmet";
import { useContext } from "react";
import GlobalContext from "../context/globalContext";
import { getPagePrefixForPageScope } from "../util/pageHandlingHelpers";

const TITLE_PREFIX_DE = "Hexal Therapieguide | ";

export function HexalSeo() {
  const { currentPage } = useContext(GlobalContext);

  // TODO: Implement logic for AT pages via context
  const titlePrefix = TITLE_PREFIX_DE;

  return (
    <Helmet>
      {currentPage && currentPage.title && (
        <title>
          {titlePrefix.concat(
            getPagePrefixForPageScope(currentPage.pageScope, "long"),
            " – ",
            currentPage.title
          )}
        </title>
      )}
      {currentPage && currentPage.description && (
        <meta name="description" content={currentPage.description} />
      )}
      {currentPage && currentPage.keywords && (
        <meta name="keywords" content={currentPage.description} />
      )}
    </Helmet>
  );
}

export default HexalSeo;
