import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import MdsPraxisCasuisticsMdsDel5q from "./praxisCasuisticsMdsDel5q";
import MdsPraxisCasuisticsMdsMld from "./praxisCasuisticsMdsMld";
import MdsPraxisCasuisticsCardio from "./praxisCasuisticsCardio";

export function MdsPraxisCasuistics() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const mdsDel5q = currentPage?.children.find(
    (child) => child.view === MdsPraxisCasuisticsMdsDel5q
  );
  if (mdsDel5q) {
    navigationItems.push({
      link: {
        label: mdsDel5q.title,
        isExternal: false,
        url: mdsDel5q.path,
      },
      description: mdsDel5q.description,
    });
  }

  const mdsmld = currentPage?.children.find(
    (child) => child.view === MdsPraxisCasuisticsMdsMld
  );
  if (mdsmld) {
    navigationItems.push({
      link: {
        label: mdsmld.title,
        isExternal: false,
        url: mdsmld.path,
      },
      description: mdsmld.description,
    });
  }

  const cardio = currentPage?.children.find(
    (child) => child.view === MdsPraxisCasuisticsCardio
  );
  if (cardio) {
    navigationItems.push({
      link: {
        label: cardio.title,
        isExternal: false,
        url: cardio.path,
      },
      description: cardio.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Kasuistiken</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsPraxisCasuistics;
