/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { css } from "@emotion/react";

interface DecisionTreeWrapperProps {
  children: ReactNode;
}

export function DecisionTreeWrapper(props: DecisionTreeWrapperProps) {
  const styles = {
    root: css`
      margin-bottom: 64px;
    `,
  };

  return <div css={styles.root}>{props.children}</div>;
}

export default DecisionTreeWrapper;
