import { Alert, DynamicSpacing, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextInfo from "../../components/TextInfo";
import ProfessionalInformationButton from "../../components/ProfessionalInformationButton";
import { resources } from "../../data/resources";
import EpoetinAlfaDosageDecisionTree from "../../components/DecisionTrees/EpoetinAlfaDosageDecisionTree";

export function CiaTherapyEsaDosage() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Dosierung & Dosisanpassung Epoetin alfa HEXAL
          <sup>®</sup>
        </h1>

        <Alert
          content="Ziel: Hämoglobinkonzentration zwischen 10 g/dl und 12 g/dl (6,2 bis 7,5 mmol/l) halten."
          type="info"
        />

        <ul>
          <li>
            Hämoglobinkonzentration ≥ 12 g/dl
            <TextInfo>7,5 mmol/l</TextInfo> sollte vermieden werden
          </li>
          <li>
            Geeignete Dosisanpassungen können durchgeführt werden, um diesen
            Zielbereich zu treffen
          </li>
          <li>
            Beurteilung des initialen erythroiden Ansprechens 8 bis 12 Wochen
            nach Beginn der Behandlung empfohlen
          </li>
        </ul>

        <p>
          Im folgenden Schema ist dargestellt, wie die Dosierung von Epoetin
          alfa Hexal bei CIA abhängig vom Hb-Wert angepasst werden sollte. Durch
          Antippen erscheinen die jeweiligen Optionen. Weitere Informationen
          finden Sie in der Fachinformation – siehe Link unten.
        </p>

        <EpoetinAlfaDosageDecisionTree />

        <DynamicSpacing spacing={6} />
        <p>
          Link zu Fachinfo Epoetin alpha HEXAL
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.epoetinAlpha.url}
          />
        </p>
      </PagePadding>
    </>
  );
}

export default CiaTherapyEsaDosage;
