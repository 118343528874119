/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { css } from "@emotion/react";

export interface SeparatorWrapperProps {
  children: ReactNode;
  marginTop?: number;
  marginBottom?: number;
}

export function SeparatorWrapper(props: SeparatorWrapperProps) {
  const { marginTop = 15, marginBottom = 15 } = props;
  return (
    <div
      css={css`
        margin-top: ${marginTop}px;
        margin-bottom: ${marginBottom}px;
      `}
    >
      {props.children}
    </div>
  );
}

export default SeparatorWrapper;
