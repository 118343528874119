import { ReactNode } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../../theme";
import AppBar from "../app-bar/app-bar";
import BottomNavigation from "../bottom-navigation/bottom-navigation";
import { Link } from "../ts/link";

export interface LayoutProps {
  children: ReactNode;
  onBackLinkClick?: () => void;
  onFavClick?: () => void;
  favIsActive?: boolean;
  favoritesLink: Link;
  homeButtonLink: Link;
  disableFavorite?: boolean;
  disableBackLink?: boolean;
  appIcon?: ReactNode;
}

export function Layout(props: LayoutProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        onBackLinkClick={props.onBackLinkClick}
        onFavClick={props.onFavClick}
        favIsActive={props.favIsActive}
        disableFavorite={props.disableFavorite}
        disableBackLink={props.disableBackLink}
        appIcon={props.appIcon}
      />
      <main>{props.children}</main>
      <BottomNavigation
        homeButtonLink={props.homeButtonLink}
        favoritesLink={props.favoritesLink}
      />
    </ThemeProvider>
  );
}

export default Layout;
