import { useContext } from "react";
import GlobalContext from "../context/globalContext";

export const useFavorites = () => {
  const { favorites, currentPath, currentPage, setFavorites } =
    useContext(GlobalContext);

  const toggleFavoriteStatusForCurrentPage = () => {
    const newFavorites = [...favorites];

    if (newFavorites.find((favorite) => favorite.fullPath === currentPath)) {
      const indexOfEntry = newFavorites.findIndex(
        (favorite) => favorite.fullPath === currentPath
      );
      if (indexOfEntry > -1) {
        newFavorites.splice(indexOfEntry, 1);
      }
    } else if (currentPath && currentPage) {
      newFavorites.push({
        fullPath: currentPath,
        view: currentPage,
      });
    }
    setFavorites(newFavorites);
  };

  const currentPageIsFavorite = () =>
    favorites.find((favorite) => favorite.fullPath === currentPath) !==
    undefined;

  const removeFavoriteByFullPath = (fullPath: string) => {
    const newFavorites = [...favorites];

    if (newFavorites.find((favorite) => favorite.fullPath === fullPath)) {
      const indexOfEntry = newFavorites.findIndex(
        (favorite) => favorite.fullPath === fullPath
      );
      if (indexOfEntry > -1) {
        newFavorites.splice(indexOfEntry, 1);
      }
    }
    setFavorites(newFavorites);
  };

  return {
    toggleFavoriteStatusForCurrentPage,
    currentPageIsFavorite,
    removeFavoriteByFullPath,
  };
};
