/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import Tooltip from "../Tooltip/Tooltip";

export interface TextWithTooltipProps {
  caption: string;
  text: string;
}

export function TextWithTooltip(props: TextWithTooltipProps) {
  const styles = {
    text: css`
      // text-decoration: underline;
      padding-bottom: 1px;
      border-bottom: 1px solid;
    `,
  };
  return (
    <Tooltip caption={props.caption}>
      <span
        css={styles.text}
        dangerouslySetInnerHTML={{ __html: props.text }}
      />
    </Tooltip>
  );
}

export default TextWithTooltip;
