/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import {
  ArrowSeparator,
  DecisionTreeWrapper,
  ResultBox,
  ScoreButtonGroup,
  SeparatorWrapper,
  VerticalLine,
  ButtonGroup,
} from "components/ui";
import { useState } from "react";

type SelectionState = {
  symptom1: boolean;
  symptom2: boolean;
  baseDiagnostics1: null | string;
  diagnostics2a: boolean;
  intermediateResult1: null | string;
  xRayCatScanResult: null | string;
  intermediateResult2: null | string;
  intermediateResult3: null | string;
};

export function PulmonaryInfiltratesDecisionTree() {
  const initialState: SelectionState = {
    symptom1: false,
    symptom2: false,
    baseDiagnostics1: null,
    diagnostics2a: false,
    xRayCatScanResult: null,
    intermediateResult1: null,
    intermediateResult2: null,
    intermediateResult3: null,
  };

  const [selected, setSelected] = useState<SelectionState>(initialState);
  const initialSelectionDone = () => selected.symptom1 || selected.symptom2;
  const extendedDiagnosticsNecessary = () =>
    selected.baseDiagnostics1 === "diagnostics1";
  const catScanThoraxNecessary = () =>
    selected.xRayCatScanResult === "intermediateResult1b";
  const requestDiagnosticsResult = () =>
    (selected.baseDiagnostics1 !== null &&
      selected.baseDiagnostics1 !== "diagnostics1") ||
    selected.diagnostics2a;
  const showAdvancedDiagnostics = () =>
    selected.intermediateResult1 !== null ||
    selected.xRayCatScanResult === "intermediateResult1a";

  return (
    <DecisionTreeWrapper>
      <Grid container>
        <Grid item xs={12}>
          <ScoreButtonGroup
            onChange={(id) => {
              const resetTree =
                id === "symptom1"
                  ? selected.symptom1 && !selected.symptom2
                  : !selected.symptom1 && selected.symptom2;
              if (resetTree) {
                setSelected(initialState);
              } else {
                setSelected((values) => ({
                  ...values,
                  [id]: !selected[id as "symptom1" | "symptom2"],
                }));
              }
            }}
            outlined
            scores={[
              { id: "symptom1", label: "Persistierendes Fieber" },
              {
                id: "symptom2",
                label: "Symptome einer Infektion der unteren Luftwege",
              },
            ]}
          />
          {initialSelectionDone() && (
            <>
              <VerticalLine columns={1} position={1} />
              <ScoreButtonGroup
                onChange={(id) => {
                  if (
                    selected.baseDiagnostics1 !== null &&
                    selected.baseDiagnostics1 === id
                  ) {
                    setSelected((values) => ({
                      ...values,
                      baseDiagnostics1: null,
                      diagnostics2a: false,
                      xRayCatScanResult: null,
                      intermediateResult1: null,
                      intermediateResult2: null,
                      intermediateResult3: null,
                    }));
                  } else {
                    setSelected((values) => ({
                      ...values,
                      baseDiagnostics1: id,
                      diagnostics2a: false,
                      xRayCatScanResult: null,
                      intermediateResult1: null,
                      intermediateResult2: null,
                      intermediateResult3: null,
                    }));
                  }
                }}
                outlined
                singleSelect
                scores={[
                  { id: "diagnostics1", label: "Röntgen Thorax" },
                  { id: "diagnostics2", label: "CT Thorax" },
                  { id: "diagnostics3", label: "MRT Thorax" },
                  { id: "diagnostics4", label: "Nicht-invasive Diagnostik" },
                ]}
              />
              {extendedDiagnosticsNecessary() && (
                <>
                  <VerticalLine columns={1} position={1} />
                  <ButtonGroup
                    results={[
                      {
                        label: "Pathologisch",
                        color:
                          selected.xRayCatScanResult === "intermediateResult1a"
                            ? "error"
                            : "default",
                        id: "intermediateResult1a",
                      },
                      {
                        label: "Normal/unklar",
                        color:
                          selected.xRayCatScanResult === "intermediateResult1b"
                            ? "success"
                            : "default",
                        id: "intermediateResult1b",
                      },
                    ]}
                    onClick={(id) => {
                      if (
                        selected.xRayCatScanResult !== null &&
                        selected.xRayCatScanResult === id
                      ) {
                        setSelected((values) => ({
                          ...values,
                          xRayCatScanResult: null,
                          diagnostics2a: false,
                          intermediateResult1: null,
                          intermediateResult2: null,
                          intermediateResult3: null,
                        }));
                      } else {
                        setSelected((values) => ({
                          ...values,
                          xRayCatScanResult: id,
                          diagnostics2a: false,
                          intermediateResult1: null,
                          intermediateResult2: null,
                          intermediateResult3: null,
                        }));
                      }
                    }}
                  />
                  {catScanThoraxNecessary() && (
                    <>
                      <VerticalLine columns={1} position={1} />
                      <ButtonGroup
                        results={[
                          {
                            label: "CT Thorax",
                            color: selected.diagnostics2a
                              ? "primary"
                              : "default",
                            id: "diagnostics2a",
                          },
                        ]}
                        onClick={(id) => {
                          if (selected.diagnostics2a) {
                            setSelected((values) => ({
                              ...values,
                              diagnostics2a: false,
                              intermediateResult1: null,
                              intermediateResult2: null,
                              intermediateResult3: null,
                            }));
                          } else {
                            setSelected((values) => ({
                              ...values,
                              [id]: true,
                              intermediateResult1: null,
                              intermediateResult2: null,
                              intermediateResult3: null,
                            }));
                          }
                        }}
                      />
                    </>
                  )}
                </>
              )}
              {requestDiagnosticsResult() && (
                <>
                  <VerticalLine columns={1} position={1} />
                  {selected.baseDiagnostics1 !== "diagnostics4" && (
                    <ButtonGroup
                      results={[
                        {
                          label: "Pathologisch",
                          color:
                            selected.intermediateResult1 ===
                            "intermediateResult1a"
                              ? "error"
                              : "default",
                          id: "intermediateResult1a",
                        },
                        {
                          label: "Normal",
                          color:
                            selected.intermediateResult1 ===
                            "intermediateResult1b"
                              ? "success"
                              : "default",
                          id: "intermediateResult1b",
                        },
                      ]}
                      onClick={(id) => {
                        if (
                          selected.intermediateResult1 !== null &&
                          selected.intermediateResult1 === id
                        ) {
                          setSelected((values) => ({
                            ...values,
                            intermediateResult1: null,
                            intermediateResult2: null,
                            intermediateResult3: null,
                          }));
                        } else {
                          setSelected((values) => ({
                            ...values,
                            intermediateResult1: id,
                            intermediateResult2: null,
                            intermediateResult3: null,
                          }));
                        }
                      }}
                    />
                  )}
                  {selected.baseDiagnostics1 === "diagnostics4" && (
                    <ButtonGroup
                      results={[
                        {
                          label: "Pathologisch",
                          color:
                            selected.intermediateResult1 ===
                            "intermediateResult1a"
                              ? "error"
                              : "default",
                          id: "intermediateResult1a",
                        },
                      ]}
                      onClick={(id) => {
                        if (
                          selected.intermediateResult1 !== null &&
                          selected.intermediateResult1 === id
                        ) {
                          setSelected((values) => ({
                            ...values,
                            intermediateResult1: null,
                            intermediateResult2: null,
                            intermediateResult3: null,
                          }));
                        } else {
                          setSelected((values) => ({
                            ...values,
                            intermediateResult1: id,
                            intermediateResult2: null,
                            intermediateResult3: null,
                          }));
                        }
                      }}
                    />
                  )}
                </>
              )}
              {showAdvancedDiagnostics() && (
                <>
                  <VerticalLine
                    columns={
                      selected.baseDiagnostics1 === "diagnostics4" ? 1 : 2
                    }
                    position={
                      selected.intermediateResult1 === "intermediateResult1a" ||
                      selected.xRayCatScanResult === "intermediateResult1a"
                        ? 1
                        : 2
                    }
                    color={
                      selected.intermediateResult1 === "intermediateResult1a" ||
                      selected.xRayCatScanResult === "intermediateResult1a"
                        ? "error"
                        : "success"
                    }
                  />
                  <ResultBox
                    label={
                      selected.intermediateResult1 === "intermediateResult1a" ||
                      selected.xRayCatScanResult === "intermediateResult1a"
                        ? "Bronchoskopie + BAL"
                        : "kein Lungeninfiltrat"
                    }
                    variant="small"
                    color="primary"
                  />
                  {(selected.intermediateResult1 === "intermediateResult1a" ||
                    selected.xRayCatScanResult === "intermediateResult1a") && (
                    <>
                      <VerticalLine columns={1} position={1} />
                      <ButtonGroup
                        results={[
                          {
                            label: "negativ oder nicht durchführbar",
                            color:
                              selected.intermediateResult2 ===
                              "intermediateResult2a"
                                ? "error"
                                : "default",
                            id: "intermediateResult2a",
                          },
                          {
                            label: "positiv",
                            color:
                              selected.intermediateResult2 ===
                              "intermediateResult2b"
                                ? "success"
                                : "default",
                            id: "intermediateResult2b",
                          },
                        ]}
                        onClick={(id) => {
                          if (
                            selected.intermediateResult2 !== null &&
                            selected.intermediateResult2 === id
                          ) {
                            setSelected((values) => ({
                              ...values,
                              intermediateResult2: null,
                              intermediateResult3: null,
                            }));
                          } else {
                            setSelected((values) => ({
                              ...values,
                              intermediateResult2: id,
                              intermediateResult3: null,
                            }));
                          }
                        }}
                      />
                      {selected.intermediateResult2 !== null && (
                        <>
                          <VerticalLine
                            columns={2}
                            position={
                              selected.intermediateResult2 ===
                              "intermediateResult2a"
                                ? 1
                                : 2
                            }
                          />
                          <ResultBox
                            label={
                              selected.intermediateResult2 ===
                              "intermediateResult2a"
                                ? "präemptive Therapie"
                                : "gezielte Therapie"
                            }
                            color="primary"
                            variant="small"
                          />
                          <VerticalLine columns={1} position={1} />
                          <ButtonGroup
                            results={[
                              {
                                label: "kein Ansprechen",
                                color:
                                  selected.intermediateResult3 ===
                                  "intermediateResult3a"
                                    ? "error"
                                    : "default",
                                id: "intermediateResult3a",
                              },
                              {
                                label: "klinisches Ansprechen",
                                color:
                                  selected.intermediateResult3 ===
                                  "intermediateResult3b"
                                    ? "success"
                                    : "default",
                                id: "intermediateResult3b",
                              },
                            ]}
                            onClick={(id) => {
                              if (
                                selected.intermediateResult3 !== null &&
                                selected.intermediateResult3 === id
                              ) {
                                setSelected((values) => ({
                                  ...values,
                                  intermediateResult3: null,
                                }));
                              } else {
                                setSelected((values) => ({
                                  ...values,
                                  intermediateResult3: id,
                                }));
                              }
                            }}
                          />
                          {selected.intermediateResult3 !== null && (
                            <>
                              <SeparatorWrapper>
                                <ArrowSeparator color="primary" />
                              </SeparatorWrapper>
                              <div
                                css={css`
                                  width: 100%;
                                  text-align: center;
                                `}
                              >
                                <ResultBox
                                  color={
                                    selected.intermediateResult3 ===
                                    "intermediateResult3a"
                                      ? "error"
                                      : "warning"
                                  }
                                  variant="large"
                                  label={
                                    selected.intermediateResult3 ===
                                    "intermediateResult3b"
                                      ? "CT Thorax Kontrolle"
                                      : "invasive Diagnostik"
                                  }
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </DecisionTreeWrapper>
  );
}

export default PulmonaryInfiltratesDecisionTree;
