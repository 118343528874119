import { Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import { MouseEvent } from "react";
import IconButton from "../IconButton/IconButton";
import Modal from "../Modal/Modal";
import DynamicSpacing from "../DynamicSpacing/DynamicSpacing";

export interface ExternalLinkModalProps {
  url: string;
  open: boolean;
  onClose: (event?: MouseEvent) => void;
  title?: string;
}
export function ExternalLinkModal(props: ExternalLinkModalProps) {
  return (
    <Modal open={props.open} onClose={props.onClose}>
      <h2>Achtung, Sie verlassen jetzt unsere Website</h2>
      <p>
        Bitte beachten Sie, dass dieser Link eine Website öffnet, für deren
        Inhalt wir nicht verantwortlich sind und auf die unsere
        Datenschutzbestimmungen keine Anwendung finden.
      </p>
      <DynamicSpacing spacing={2} />
      <Grid container spacing="24px">
        <Grid item xs={6}>
          <a
            onClick={props.onClose}
            title={props.title}
            href={props.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            <IconButton
              label="Fortfahren"
              startIcon={<ArrowForwardIcon />}
              fullWidth
            />
          </a>
        </Grid>
        <Grid item xs={6}>
          <div onClick={props.onClose}>
            <IconButton label="Abbrechen" startIcon={<CloseIcon />} fullWidth />
          </div>
        </Grid>
      </Grid>
    </Modal>
  );
}

export default ExternalLinkModal;
