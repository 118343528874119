import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import MasccScore from "../../components/Scores/MasccScore";

export function FnRiskMascc() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Risikoeinschätzung (MASCC Score)</h1>
        <p>
          Das Infektionsrisiko korreliert mit dem Nadir und der Dauer der
          Neutropenie. Dennoch ist der Verlauf bei individuellen Patient:innen
          schwer vorhersehbar. Zur Einschätzung von Komplikationsrate und
          Mortalität bei <Abbreviation>fn</Abbreviation> kann der{" "}
          <Abbreviation>mascc</Abbreviation> Risiko-Score herangezogen werden.{" "}
          <TextReference references={["ref4"]} />
        </p>
        <p>
          Tippen Sie dazu auf die folgenden Auswahlfelder. Wenn Sie unten
          angekommen sind, wird ihre Auswahl noch einmal zusammengefasst. So
          können Sie ganz einfach die Punktzahl des MASCC Scores zusammenzählen
          und herausfinden, ob ein hohes oder niedriges Risiko besteht.
        </p>
        <MasccScore />
      </PagePadding>
    </>
  );
}

export default FnRiskMascc;
