import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import CiaPraxisTipps from "./praxisTipps";
import CiaPraxisCasuistic from "./praxisCasuistic";

export function CiaPraxis() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const tipps = currentPage?.children.find(
    (child) => child.view === CiaPraxisTipps
  );
  if (tipps) {
    navigationItems.push({
      link: {
        label: tipps.title,
        isExternal: false,
        url: tipps.path,
      },
      description: tipps.description,
    });
  }

  const casuistic = currentPage?.children.find(
    (child) => child.view === CiaPraxisCasuistic
  );
  if (casuistic) {
    navigationItems.push({
      link: {
        label: casuistic.title,
        isExternal: false,
        url: casuistic.path,
      },
      description: casuistic.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Aus der Praxis</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default CiaPraxis;
