import { Table as MuiTable, TableContainer } from "@mui/material";
import { ReactNode } from "react";

export interface TableProps {
  children: ReactNode;
}

export function Table(props: TableProps) {
  return (
    <TableContainer>
      <MuiTable>{props.children}</MuiTable>
    </TableContainer>
  );
}

export default Table;
