/** @jsxImportSource @emotion/react */

import {
  Accordion,
  DynamicSpacing,
  FullWidthCarousel,
  Graph,
  GraphLegend,
  PagePadding,
  Table,
  AccordionNodeItem,
  TableHead,
  TableBody,
  TableRow,
} from "components/ui";
import { css } from "@emotion/react";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import HexalSeo from "../../components/HexalSeo";

import * as dosage1 from "../../assets/img/fn/praxis/kasuistik-mammakarzinom-dosisintensiviert-1.svg";
import * as dosage2 from "../../assets/img/fn/praxis/kasuistik-mammakarzinom-dosisintensiviert-2.svg";
import * as dosage3 from "../../assets/img/fn/praxis/kasuistik-mammakarzinom-dosisintensiviert-3.svg";
import TextInfo from "../../components/TextInfo";

export function FnPraxisCasuisticsMammaCaIntensivied() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Rolle der Supportivtherapie beim Mammakarzinom</h1>
        <ul>
          <li>
            Adjuvante Therapie des Mammakarzinoms{" "}
            <Abbreviation>hrplus</Abbreviation>/
            <Abbreviation>her2</Abbreviation> mit hohem Risiko
          </li>
          <li>Dosisintensivierte Therapie</li>
          <li>43-jährige Patientin, 71 kg</li>
        </ul>

        <Accordion
          items={[
            {
              title: "Symptome & Diagnostik",
              defaultOpen: true,
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Familienanamnese</h3>
                    <ul>
                      <li>
                        Großmutter mütterlicherseits Mammakarzinom mit 80 Jahren
                      </li>
                      <li>
                        des Weiteren keine Mamma- und/oder Ovarialkarzinome in
                        der Familie
                      </li>
                    </ul>

                    <h3>Februar 2018</h3>
                    <p>
                      Vorstellung aufgrund bekannter Fibroadenome zur
                      Zweitmeinung
                    </p>

                    <h3>Diagnostik</h3>
                    <ul>
                      <li>
                        Klinik: Tastbefund links, ca. 1 cm, gut verschieblich
                      </li>
                      <li>
                        Mammasonographie: glatt begrenzter Herd von 10 x 12 mm
                      </li>
                      <li>
                        Mammographie: Nachweis von Mikrokalk auf Position der
                      </li>
                      <li>Raumforderung</li>
                      <li>
                        Beurteilung: <Abbreviation>birads</Abbreviation> IV
                        links
                      </li>
                    </ul>

                    <h3>Stanzbiopsie</h3>
                    <ul>
                      <li>
                        Mammastanzbiopsien (links) mit Infiltration durch ein
                        mäßig differenziertes invasives Mammakarzinom (
                        <Abbreviation>g2</Abbreviation>, 3+2+1=6 nach Elston &
                        Ellis); <Abbreviation>b5b</Abbreviation>
                      </li>
                      <li>
                        Immunhistochemie: Östrogenhormonrezeptor 40 %,
                        Progesteronrezeptor 30 %, Ki67 inhomogen/fokal bis 25 %,{" "}
                        <Abbreviation>her2</Abbreviation>: negativ
                      </li>
                      <li>Staging ohne Anhalt für Fernmetastasen</li>
                      <li>Somit Mammakarzinom links, cT1c cN0 cM0</li>
                    </ul>

                    <h3>Interdisziplinäre Tumorkonferenz</h3>
                    <ul>
                      <li>
                        <Abbreviation>bet</Abbreviation> mit{" "}
                        <Abbreviation>snb</Abbreviation>, ggf. Axilla
                      </li>
                      <li>
                        folgend ggf. Genexpressionstest zur Planung der
                        Systemtherapie
                      </li>
                    </ul>

                    <h3>März 2018:</h3>
                    <ul>
                      <li>
                        BET mit SNB; intraoperativ 3 SNB positiv;
                        Axilladissektion Level I und II
                      </li>
                      <li>
                        Histologie: pT1c (17 mm) pN2 (8/14) L1 V0 Pn0 R0 Ris0
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Medikamentöse Tumor-Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Therapie</h3>
                    <p>
                      <Abbreviation>iddepc</Abbreviation>{" "}
                      <TextReference references={["ref13"]} />
                      <br />
                      Epirubicin (150 mg/m<sup>2</sup> i.v. als Bolus-Infusion)
                      q2w x 3
                      <br />
                      Paclitaxel (225 mg/m<sup>2</sup> i.v.) q2w x 3
                      <br />
                      Cyclophosphamid (2.500 mg/m<sup>2</sup> i.v.) q2w x 3
                    </p>

                    <GraphLegend
                      items={[
                        {
                          type: "rectangle",
                          color: "blue",
                          label: "Epirubicin",
                        },
                        {
                          type: "rectangle",
                          color: "brown",
                          label: "Cyclophosphamid",
                        },
                        {
                          type: "rectangle",
                          color: "green",
                          label: "Paclitaxel",
                        },
                        {
                          type: "triangle",
                          color: "darkBlue",
                          label: "G-CSF",
                        },
                        {
                          type: "none",
                          label: "<strong>TAM:</strong> Tamoxifen",
                        },
                      ]}
                    />
                    <FullWidthCarousel
                      bordered
                      slides={[
                        {
                          body: (
                            <Graph imagePath={dosage1.default} alt="iddEPC" />
                          ),
                        },
                        {
                          body: (
                            <Graph imagePath={dosage2.default} alt="iddEPC" />
                          ),
                        },
                        {
                          body: (
                            <Graph imagePath={dosage3.default} alt="iddEPC" />
                          ),
                        },
                      ]}
                    />

                    <h3>
                      Empfehlung <Abbreviation>ago</Abbreviation>
                      -Richtlinie
                    </h3>
                    <p>N ≥ 4+: ++</p>
                    <TextReference references={["ref16"]} />
                    <TextInfo>
                      AGO - Empfehlungsgrade
                      <br />
                      ++ Diese therapeutische Intervention hat einen hohen
                      Nutzen für Patienten, kann uneingeschränkt empfohlen
                      werden und sollte durchgeführt werden.
                      <br />
                      + Diese therapeutische Intervention ist für Patienten von
                      begrenztem Nutzen und kann durchgeführt werden.
                      <br />
                      +/- Diese therapeutische Intervention hat keinen Nutzen
                      gezeigt für Patienten und darf nur im Einzelfall
                      durchgeführt werden. Laut aktuellem Kenntnisstand kann
                      eine generelle Empfehlung nicht gegeben werden.
                      <br />
                      - Diese therapeutische Intervention kann von Nachteil sein
                      für Patienten und wird eher nicht durchgeführt.
                      <br />
                      -- Diese therapeutische Intervention ist von klarem
                      Nachteil für Patienten und sollten auf jeden Fall
                      vermieden oder unterlassen werden.
                    </TextInfo>

                    <h3>Protokollklassifikation</h3>
                    <ul>
                      <li>
                        Klassifikation: aktueller Standard dosisintensivierte
                        Therapie
                      </li>
                      <li>Intensität: dosisintensivierte Therapie</li>
                      <li>Therapiemodus: adjuvant</li>
                      <li>Therapieintention: kurativ</li>
                    </ul>

                    <h3>Risiken</h3>
                    <p>
                      Neutropenie: hoch
                      <br />
                      Febrile Neutropenie: hoch (&gt; 20 %){" "}
                      <TextReference references={["ref13"]} />
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: (
                <p
                  css={css`
                    margin-top: 0;
                    margin-bottom: 0;
                  `}
                >
                  Supportivtherapie
                  <TextReference references={["ref2"]} />
                </p>
              ),
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Protokoll Neutropenieprophylaxe</h3>
                    <ul>
                      <li>
                        Primäre Prophylaxe im 1. Zyklus initiiert und in 9
                        Zyklen weitergeführt
                      </li>
                    </ul>
                    <h3>Filgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "3-10"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "5 μg/kg KG/Tag → 30 Mio. E."]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q14d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc1"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <h3>Pegfilgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "2"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "6 mg"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q14d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc2"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <p>
                      Wir danken Prof. Dr. Michael P. Lux für die Initiative,
                      Mitgestaltung und Mitentwicklung der Kasuistik und
                      Therapie.
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default FnPraxisCasuisticsMammaCaIntensivied;
