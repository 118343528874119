import { TextWithTooltip } from "components/ui";
import { useContext } from "react";
import abbreviations from "../data/abbreviations";
import GlobalContext from "../context/globalContext";

interface AbbreviationProps {
  children: string;
}

export function Abbreviation(props: AbbreviationProps) {
  const abbreviationKey = props.children.toLowerCase();
  const { currentPage } = useContext(GlobalContext);
  const pageScopeAbbreviation =
    currentPage !== null &&
    abbreviations[currentPage.pageScope as keyof typeof abbreviations]
      ? abbreviations[currentPage.pageScope as keyof typeof abbreviations]
      : undefined;

  const abbreviation =
    pageScopeAbbreviation !== undefined &&
    pageScopeAbbreviation[abbreviationKey as keyof typeof pageScopeAbbreviation]
      ? pageScopeAbbreviation[
          abbreviationKey as keyof typeof pageScopeAbbreviation
        ]
      : {
          explanation: "",
          abbreviation: "",
        };

  return (
    <TextWithTooltip
      caption={abbreviation.explanation}
      text={abbreviation.abbreviation}
    />
  );
}

export default Abbreviation;
