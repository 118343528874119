import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import ProfessionalInformationButton from "../../components/ProfessionalInformationButton";
import { resources } from "../../data/resources";

export function MdsTherapyLowRiskImmunomodulatorsLenalidomidTherapy() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapie Immunmodulatoren - Lenalidomid</h1>
        <ul>
          <li>
            Ca. 60 % der <Abbreviation>mds</Abbreviation>
            -Patient:innen mit einer singulären Deletion am Chromosom 5 und
            einer transfusionspflichtigen Anämie bei{" "}
            <Abbreviation>ipss</Abbreviation>
            -Risiko „LOW“ bzw. „<Abbreviation>int</Abbreviation>
            -1“ sprechen mit dem Ergebnis einer Transfusionsunabhängigkeit an
          </li>
          <li>50-75% der Patient:innen erreichen zytogenetische Remissionen</li>
          <li>
            Ähnlich gutes Ansprechen bei Patient:innen mit nur einer
            Zusatzaberration (außer von Chromosom 7)
          </li>
        </ul>
        <p>Vor Therapiebeginn: Bestimmung der TP53-Mutation</p>
        <ul>
          <li>
            Patient:innen mit Mutation sollten regelmäßig auf eine klonale
            Evolution und auf Erwerb einer biallelischen TP53-Mutation überwacht
            werden
          </li>
        </ul>
        <p>
          Bei Patient:innen mit <Abbreviation>mds</Abbreviation> ohne
          Veränderungen am Chromosom 5 ist die Effektivität von Lenalidomid
          gering.
        </p>
        <ul>
          <li>
            Strenge Abwägung der Therapie wird empfohlen{" "}
            <TextReference references={["ref1"]} />
          </li>
        </ul>
        <p>
          Link zu Fachinfo Lenalidomid HEXAL
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.lenalidomid.url}
          />
        </p>
        <p>
          Link zu Fachinfo Revlimid
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.revlimid.url}
          />
        </p>
      </PagePadding>
    </>
  );
}
