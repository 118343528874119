/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

export interface DynamicSpacingProps {
  spacing?: number;
}

export function DynamicSpacing(props: DynamicSpacingProps) {
  const { spacing = 1 } = props;
  const styles = {
    root: css`
      display: block;
      margin-top: ${spacing * 8}px;
    `,
  };
  return <div css={styles.root} />;
}

export default DynamicSpacing;
