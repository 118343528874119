/** @jsxImportSource @emotion/react */

import {
  Accordion,
  DynamicSpacing,
  FullWidthCarousel,
  Graph,
  GraphLegend,
  PagePadding,
  PlusIcon,
  Table,
  AccordionNodeItem,
  TableHead,
  TableBody,
  TableRow,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";

import * as dosage1 from "../../assets/img/fn/praxis/kasuistik-mammakarzinom-standard-1.svg";
import * as dosage2 from "../../assets/img/fn/praxis/kasuistik-mammakarzinom-standard-2.svg";
import * as dosage3 from "../../assets/img/fn/praxis/kasuistik-mammakarzinom-standard-3.svg";
import TextReference from "../../components/TextReference";
import { css } from "@emotion/react";
import TextInfo from "../../components/TextInfo";

export function FnPraxisCasuisticsMammaCaStandard() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Rolle der Supportivtherapie beim Mammakarzinom</h1>
        <ul>
          <li>
            Adjuvante Therapie des Mammakarzinoms{" "}
            <Abbreviation>hrplus</Abbreviation>/
            <Abbreviation>her2</Abbreviation> mit hohem Risiko
          </li>
          <li>Standardtherapie</li>
          <li>69-jährige postmenopausale Patientin, 65 kg</li>
        </ul>

        <Accordion
          items={[
            {
              title: "Symptome & Diagnostik",
              defaultOpen: true,
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Anamnese</h3>
                    <ul>
                      <li>
                        <Abbreviation>g3</Abbreviation>/
                        <Abbreviation>p3</Abbreviation>
                      </li>
                      <li>Z. n. Reduktionsplastik bds. 2002</li>
                      <li>
                        Nebenerkrankungen: Hashimoto-Thyreoiditis, Hypertonie,{" "}
                        <Abbreviation>copd</Abbreviation> bei Nikotinabusus
                      </li>
                    </ul>

                    <h3>Februar 2018</h3>
                    <p>Vorstellung mit einem Tastbefund rechts zentral</p>

                    <h3>Familienanamnese</h3>
                    <p>
                      Keine Mammakarzinome oder Ovarialkarzinome in der Familie
                    </p>

                    <h3>Diagnostik</h3>
                    <ul>
                      <li>
                        Klinik: Tastbefund, cT2 cN+, mit Mamilleneinziehung und
                        Verdacht auf Beteiligung des{" "}
                        <Abbreviation>mak</Abbreviation>s
                      </li>
                      <li>
                        Mammasonographie: irregulär begrenzter Herd von 10 x 15
                        mm
                      </li>
                      <li>
                        Mammographie: strahliger Herd, Verdacht auf Narbe mit
                        Verbindung zur Mamille und Retraktion sowie Kutis
                        Verdickung
                      </li>
                      <li>
                        <Abbreviation>mrt</Abbreviation>: großer spikulierter
                        Herdbefund zentral in der Mamma 2,8 x 2,8 cm; weitere
                        Herdbefunde im unteren äußeren Quadranten auf ca. 7–8
                        Uhr, 1,6 x 1,3 cm präpektoral, sowie 0,5 x 0,9 cm;
                        Lymphknoten mit betonter Rinde rechts axillär
                      </li>
                      <li>
                        Beurteilung: <Abbreviation>birads</Abbreviation> 5
                        rechts, Verdacht auf multizentrisches Mammakarzinom
                        rechts, cT4b cN+
                      </li>
                    </ul>

                    <h3>Stanzbiopsie</h3>
                    <ul>
                      <li>
                        Mammastanzbiopsien (rechts) mit ausgedehnter, diffuser
                        Infiltration durch ein mäßig differenziertes invasives
                        Mammakarzinom (<Abbreviation>g2</Abbreviation>, 3+2+1=6
                        nach Elston & Ellis); assoziiertes intermediate-grade{" "}
                        <Abbreviation>dcis</Abbreviation>;{" "}
                        <Abbreviation>b5b</Abbreviation>
                      </li>
                      <li>
                        Immunhistochemie: Östrogenhormonrezeptor 20 %,
                        Progesteronrezeptor 15 %, Ki67 inhomogen/fokal bis 50 %,{" "}
                        <Abbreviation>her2</Abbreviation> negativ
                      </li>
                      <li>Staging ohne Anhalt für Fernmetastasen</li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Medikamentöse Tumor-Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Therapie</h3>
                    <p>
                      <Abbreviation>ec</Abbreviation> →{" "}
                      <Abbreviation>p</Abbreviation> Epirubicin (90 mg/m
                      <sup>2</sup> <Abbreviation>kof</Abbreviation>
                      )/Cyclophosphamid (600 mg/m
                      <sup>2</sup> KOF) q3w x 4 Paclitaxel (80 mg/m
                      <sup>2</sup> KOF) q1w x 12
                    </p>

                    <GraphLegend
                      items={[
                        {
                          type: "rectangle",
                          color: "blue",
                          label: "Epirubicin",
                        },
                        {
                          type: "rectangle",
                          color: "brown",
                          label: "Cyclophosphamid",
                        },
                        {
                          type: "rectangle",
                          color: "green",
                          label: "Paclitaxel",
                        },
                        {
                          type: "triangle",
                          color: "darkBlue",
                          label: "G-CSF",
                        },
                      ]}
                    />
                    <FullWidthCarousel
                      bordered
                      slides={[
                        {
                          body: (
                            <Graph imagePath={dosage1.default} alt="EC - P" />
                          ),
                        },
                        {
                          body: (
                            <Graph imagePath={dosage2.default} alt="EC - P" />
                          ),
                        },
                        {
                          body: (
                            <Graph imagePath={dosage3.default} alt="EC - P" />
                          ),
                        },
                      ]}
                    />

                    <h3>Empfehlung AGO-Richtlinie</h3>
                    <PlusIcon color="primary" />
                    <PlusIcon color="primary" />
                    <br />
                    <TextReference references={["ref16"]} />
                    <TextInfo>
                      AGO - Empfehlungsgrade
                      <br />
                      ++ Diese therapeutische Intervention hat einen hohen
                      Nutzen für Patienten, kann uneingeschränkt empfohlen
                      werden und sollte durchgeführt werden.
                      <br />
                      + Diese therapeutische Intervention ist für Patienten von
                      begrenztem Nutzen und kann durchgeführt werden.
                      <br />
                      +/- Diese therapeutische Intervention hat keinen Nutzen
                      gezeigt für Patienten und darf nur im Einzelfall
                      durchgeführt werden. Laut aktuellem Kenntnisstand kann
                      eine generelle Empfehlung nicht gegeben werden.
                      <br />
                      - Diese therapeutische Intervention kann von Nachteil sein
                      für Patienten und wird eher nicht durchgeführt.
                      <br />
                      -- Diese therapeutische Intervention ist von klarem
                      Nachteil für Patienten und sollten auf jeden Fall
                      vermieden oder unterlassen werden.
                    </TextInfo>

                    <h3>Protokollklassifikation</h3>
                    <p>
                      Klassifikation: aktueller Standard
                      <br />
                      Intensität: Standard-Dosis
                      <br />
                      Therapiemodus: neoadjuvante Systemtherapie
                      <br />
                      Therapieintention: kurativ
                    </p>

                    <h3>Risiko</h3>
                    <ul>
                      <li>
                        Risiko febrile Neutropenie: 6–18 %{" "}
                        <TextReference references={["ref17", "ref18"]} /> →{" "}
                        mittel (10–20 %)
                      </li>
                      <li>
                        Patientenbezogene Risikofaktoren{" "}
                        <Abbreviation>fn</Abbreviation>: Alter &gt; 65 Jahre,{" "}
                        <Abbreviation>copd</Abbreviation>, Hypertonie
                        <TextReference references={["ref2"]} />
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: (
                <p
                  css={css`
                    margin-top: 0;
                    margin-bottom: 0;
                  `}
                >
                  Supportivtherapie
                  <TextReference references={["ref2"]} />
                </p>
              ),
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Protokoll Neutropenieprophylaxe</h3>
                    <p>
                      Primäre <Abbreviation>gcsf</Abbreviation>
                      -Prophylaxe im 1. Zyklus initiiert und in 4 Zyklen
                      weitergeführt
                    </p>
                    <h3>Filgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "3-10 (8 Tage)"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Dosierung",
                            <>
                              5 μg/kg KG/Tag → 30 Mio.{" "}
                              <Abbreviation>e</Abbreviation>
                            </>,
                          ]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q21d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc1"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <h3>Pegfilgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "2"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "6 mg"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q21d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc2"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <p>
                      Wir danken Prof. Dr. Michael P. Lux für die Initiative,
                      Mitgestaltung und Mitentwicklung der Kasuistik und
                      Therapie.
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default FnPraxisCasuisticsMammaCaStandard;
