import { SvgIcon, SvgIconProps } from "@mui/material";

export function SyringeIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Icon Therapie _Spritze"
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <path
        data-name="Rechteck 597"
        style={{ fill: "none" }}
        d="M0 0h34v34H0z"
      />
      <path
        data-name="Pfad 432"
        d="M38.565 19.613 33.848 14.9a.545.545 0 0 0-.771.771l.726.726-2.9 2.9-2.223-2.223a.545.545 0 0 0-.771.771l.68.68-2.722 2.722-3.221 3.312-2.087 2.087a2.242 2.242 0 0 0-.635 1.361 2.3 2.3 0 0 0 .59 1.5l1.361 1.361L19.2 33.54a.522.522 0 0 0 0 .771.552.552 0 0 0 .726 0l2.674-2.677L23.958 33a2.242 2.242 0 0 0 1.361.635h.045a2.185 2.185 0 0 0 1.452-.59l8.12-8.12.68.68a.552.552 0 0 0 .726 0 .522.522 0 0 0 0-.771l-1-1.089-1.179-1.179 2.9-2.9.726.726a.552.552 0 0 0 .726 0 .493.493 0 0 0 .05-.779zM26.136 32.224a1 1 0 0 1-.68.272.9.9 0 0 1-.635-.272l-1.769-1.769-1.769-1.724a1.03 1.03 0 0 1-.272-.726.9.9 0 0 1 .272-.635l1.724-1.724 1.633 1.633a.552.552 0 0 0 .726 0 .522.522 0 0 0 0-.771l-1.633-1.633 2.54-2.586 1.627 1.634a.552.552 0 0 0 .726 0 .522.522 0 0 0 0-.771L27 21.518l2.314-2.359 4.854 4.854zm7.258-10.434-1.724-1.723 2.9-2.9 1.73 1.72z"
        transform="translate(-10.395 -8.522)"
        style={{ fill: "currentcolor" }}
      />
    </SvgIcon>
  );
}

export default SyringeIcon;
