import { TableBody } from "@mui/material";
import { useState } from "react";
import {
  DynamicSpacing,
  ScoreButtonGroup,
  Table,
  TableRow,
} from "components/ui";

export function NordicScore() {
  const initialState = {
    ery: {
      activeId: "",
      value: 0,
    },
    trf: {
      activeId: "",
      value: 0,
    },
  };

  const [score, setScore] = useState(initialState);

  const onScoreChange = (
    id: string,
    scoreField: "ery" | "trf",
    value: number
  ) => {
    if (score[scoreField].activeId === id) {
      setScore({
        ...score,
        [scoreField]: {
          activeId: "",
          value: 0,
        },
      });
    } else {
      setScore({
        ...score,
        [scoreField]: {
          activeId: id,
          value,
        },
      });
    }
  };

  return (
    <>
      <h2>Endogener Erythropoetin-Spiegel im Serum [U/I]</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) =>
          onScoreChange(id, "ery", id === "ery1" ? 2 : id === "ery2" ? 1 : -3)
        }
        scores={[
          { id: "ery1", label: "< 100", value: 2 },
          { id: "ery2", label: "100 – 500", value: 1 },
          { id: "ery3", label: "> 500", value: -3 },
        ]}
      />

      <h2>Transfusionen</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => onScoreChange(id, "trf", id === "trf1" ? 2 : -2)}
        scores={[
          { id: "trf1", label: "< 2/Monat", value: 2 },
          { id: "trf2", label: "≥ 2/Monat", value: -2 },
        ]}
      />

      <DynamicSpacing spacing={6} />
      <h3>IPSS: Low/Int-1 Score</h3>
      <Table>
        <TableBody>
          <TableRow
            alignCells={["left", "right"]}
            entries={["> +1", "Gutes Ansprechen, ca. 74 %"]}
          />
          <TableRow
            alignCells={["left", "right"]}
            entries={["–1 bis +1", "Mittleres Ansprechen, ca. 23 %"]}
          />
          <TableRow
            alignCells={["left", "right"]}
            entries={["< –1", "Schlechtes Ansprechen, ca. 7 %"]}
          />
        </TableBody>
      </Table>
    </>
  );
}

export default NordicScore;
