import { Grid } from "@mui/material";
import { useState } from "react";
import {
  ArrowSeparator,
  ButtonGroup,
  ChipLineSeparator,
  DecisionTreeWrapper,
  ResultBox,
  SeparatorWrapper,
} from "components/ui";

type SelectionState = {
  hbPlus1: null | boolean;
  hbPlus2: null | boolean;
};

export function EpoetinAlfaDosageDecisionTree() {
  const initialState: SelectionState = {
    hbPlus1: null,
    hbPlus2: null,
  };

  const [selected, setSelected] = useState<SelectionState>(initialState);
  const showResult = () => selected.hbPlus1 || selected.hbPlus2 !== null;
  const requestHbPlus2 = () => selected.hbPlus1 === false;

  const resultText = () => {
    let result = "";
    if (selected.hbPlus1 || selected.hbPlus2) {
      result = "Beibehaltung der Dosierung Hb-Ziel (ca. 12 g/dl/7,45 mmol/l)";
    } else if (selected.hbPlus1 === false && selected.hbPlus2 === false) {
      result = "Abbruch der Therapie";
    }
    return result;
  };
  const resultColor = () => {
    let result: "error" | "success" = "error";
    if (selected.hbPlus1 || selected.hbPlus2) {
      result = "success";
    } else if (selected.hbPlus1 === false && selected.hbPlus2 === false) {
      result = "error";
    }
    return result;
  };

  return (
    <DecisionTreeWrapper>
      <Grid container>
        <Grid item xs={12}>
          <ResultBox
            label="<strong>Therapiestart mit Epoetin alfa HEXAL<sup>®</sup> bei Hb ≤ 10 g/dl (≤ 6,21 mmol/l):<br /></strong>1 x pro Woche 450 I.E./kg Epoetin alfa HEXAL<sup>®</sup> oder 3 x pro Woche 150 I.E./kg"
            variant="small"
            color="primary"
          />
          <SeparatorWrapper>
            <ChipLineSeparator label="Dauer: 4 Wochen" />
          </SeparatorWrapper>
          <ResultBox
            label="Hb-Anstieg ≥ 1 g/dl (≥ 0,62 mmol/l) oder Retikulozytenzahl-Anstieg ≥ 40.000/μl"
            variant="small"
            color="primary"
          />
          <SeparatorWrapper>
            <ArrowSeparator color="primary" />
          </SeparatorWrapper>
          <ButtonGroup
            results={[
              { label: "ja", color: "primary", id: "hbPlus1Y" },
              { label: "nein", color: "primary", id: "hbPlus1N" },
            ]}
            onClick={(id) => {
              const resetTree = selected.hbPlus1 !== null;
              if (resetTree && selected.hbPlus1 === (id === "hbPlus1Y")) {
                setSelected((values) => ({
                  ...values,
                  hbPlus1: null,
                  hbPlus2: null,
                }));
              } else {
                setSelected((values) => ({
                  ...values,
                  hbPlus1: id === "hbPlus1Y",
                  hbPlus2: null,
                }));
              }
            }}
          />
          {requestHbPlus2() && (
            <>
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ResultBox
                label="Dosiserhöhung auf 300 I.E./kg 3 x Woche"
                variant="small"
                color="primary"
              />
              <SeparatorWrapper>
                <ChipLineSeparator label="Dauer: 4 Wochen" />
              </SeparatorWrapper>
              <ResultBox
                label="Hb-Anstieg ≥ 1 g/dl (≥ 0,62 mmol/l) oder Retikulozytenzahl-Anstieg ≥ 40.000/μl"
                variant="small"
                color="primary"
              />
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ButtonGroup
                results={[
                  { label: "ja", color: "primary", id: "hbPlus2Y" },
                  { label: "nein", color: "primary", id: "hbPlus2N" },
                ]}
                onClick={(id) => {
                  const resetTree = selected.hbPlus2 !== null;
                  if (resetTree && selected.hbPlus2 === (id === "hbPlus2Y")) {
                    setSelected((values) => ({ ...values, hbPlus2: null }));
                  } else {
                    setSelected((values) => ({
                      ...values,
                      hbPlus2: id === "hbPlus2Y",
                    }));
                  }
                }}
              />
            </>
          )}
          {showResult() && (
            <>
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ResultBox
                label={resultText()}
                variant="large"
                color={resultColor()}
              />
            </>
          )}
        </Grid>
      </Grid>
    </DecisionTreeWrapper>
  );
}

export default EpoetinAlfaDosageDecisionTree;
