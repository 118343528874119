import ReactPlayer from "react-player";

export interface VideoProps {
  url: string;
}

export function Video(props: VideoProps) {
  return (
    <div>
      <ReactPlayer url={props.url} controls width="100%" height="100%" />
    </div>
  );
}

export default Video;
