import { TableBody } from "@mui/material";
import { useState } from "react";
import {
  DynamicSpacing,
  ScoreButtonGroup,
  SignedNumber,
  Table,
  TableRow,
} from "components/ui";

export function MdsIpssRScore() {
  const initialState = {
    karotype: {
      activeId: "",
      value: 0,
    },
    blasts: {
      activeId: "",
      value: 0,
    },
    hb: {
      activeId: "",
      value: 0,
    },
    thrombos: {
      activeId: "",
      value: 0,
    },
    neutrophils: {
      activeId: "",
      value: 0,
    },
  };

  const [score, setScore] = useState(initialState);

  const onScoreChange = (
    id: string,
    scoreField: "karotype" | "blasts" | "hb" | "thrombos" | "neutrophils",
    value: number
  ) => {
    if (score[scoreField].activeId === id) {
      setScore({
        ...score,
        [scoreField]: {
          activeId: "",
          value: 0,
        },
      });
    } else {
      setScore({
        ...score,
        [scoreField]: {
          activeId: id,
          value,
        },
      });
    }
  };

  return (
    <>
      <h2>Karotyp</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => {
          let value;
          switch (id) {
            case "b":
              value = 1;
              break;
            case "c":
              value = 2;
              break;
            case "d":
              value = 3;
              break;
            case "e":
              value = 4;
              break;
            default:
              value = 0;
          }
          onScoreChange(id, "karotype", value);
        }}
        scores={[
          { id: "a", label: "A", value: 0 },
          { id: "b", label: "B", value: 1 },
          { id: "c", label: "C", value: 2 },
          { id: "d", label: "D", value: 3 },
          { id: "e", label: "E", value: 4 },
        ]}
      />
      <h3>Legende</h3>
      <p>
        A: Sehr gut (-Y, del(11q))
        <br />
        B: Gut (Normal, del(5q), del(12p), del(20q), Doppel-Klon mit del(5q)
        außer chr7)
        <br />
        C: Intermediär (del(7q), +8, +19, i(17q), andere Einzel- oder
        Doppel-Klone)
        <br />
        D: Schlecht (-7, inv(3)/t(3q)/del(3q), Doppel-Klon mit –7/del(7q),
        komplex (3 Aberrationen))
        <br />
        E: Sehr schlecht (komplex &gt;3 Aberrationen)
        <br />
      </p>

      <h2>Blasten (%)</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => {
          let value;
          switch (id) {
            case "bl2":
              value = 1;
              break;
            case "bl3":
              value = 2;
              break;
            case "bl4":
              value = 3;
              break;
            default:
              value = 0;
          }
          onScoreChange(id, "blasts", value);
        }}
        scores={[
          { id: "bl1", label: "≤ 2", value: 0 },
          { id: "bl2", label: "> 2 – < 5", value: 1 },
          { id: "bl3", label: "5 – 10", value: 2 },
          { id: "bl4", label: "> 10", value: 3 },
        ]}
      />

      <h2>Hb-Wert (g/dl)</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => {
          let value;
          switch (id) {
            case "hb2":
              value = 1;
              break;
            case "hb3":
              value = 1.5;
              break;
            default:
              value = 0;
          }
          onScoreChange(id, "hb", value);
        }}
        scores={[
          { id: "hb1", label: "≥ 10", value: 0 },
          { id: "hb2", label: "8 – < 10", value: 1 },
          { id: "hb3", label: "< 8", value: 1.5 },
        ]}
      />

      <h2>Thrombos (/nl)</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => {
          let value;
          switch (id) {
            case "thr2":
              value = 0.5;
              break;
            case "thr3":
              value = 1;
              break;
            default:
              value = 0;
          }
          onScoreChange(id, "thrombos", value);
        }}
        scores={[
          { id: "thr1", label: "≥ 100", value: 0 },
          { id: "thr2", label: "50 – < 100", value: 0.5 },
          { id: "thr3", label: "< 50", value: 1 },
        ]}
      />

      <h2>Neutrophile (/nl)</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => {
          onScoreChange(id, "neutrophils", id === "n2" ? 0.5 : 0);
        }}
        scores={[
          { id: "n1", label: "≥ 0,8", value: 0 },
          { id: "n2", label: "< 0,8", value: 0.5 },
        ]}
      />

      <DynamicSpacing spacing={3} />
      <h2>Ihre Auswahl</h2>
      <p>
        Haben Sie eine Auswahl mit dem Wert 0 getroffen, wird diese zur besseren
        Übersichtlichkeit nicht mit angezeigt.
      </p>
      <Table>
        <TableBody>
          {score.karotype.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Schwere der Symptome",
                <SignedNumber key={"karotype"}>
                  {score.karotype.value}
                </SignedNumber>,
              ]}
            />
          )}
          {score.blasts.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Hypotension",
                <SignedNumber key={"blasts"}>
                  {score.blasts.value}
                </SignedNumber>,
              ]}
            />
          )}
          {score.hb.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Aktive COPD",
                <SignedNumber key={"hb"}>{score.hb.value}</SignedNumber>,
              ]}
            />
          )}
          {score.thrombos.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Art der Tumorerkrankung",
                <SignedNumber key={"thrombos"}>
                  {score.thrombos.value}
                </SignedNumber>,
              ]}
            />
          )}
          {score.neutrophils.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Dehydrierung",
                <SignedNumber key={"neutrophils"}>
                  {score.neutrophils.value}
                </SignedNumber>,
              ]}
            />
          )}
        </TableBody>
      </Table>
      <DynamicSpacing spacing={3} />
      <h3>Risiko-Score</h3>
      <Table>
        <TableBody>
          <TableRow
            alignCells={["left", "right"]}
            entries={["≤ 1,5", "Sehr niedriges Risiko"]}
          />
          <TableRow
            alignCells={["left", "right"]}
            entries={["2 – 3", "Niedriges Risiko"]}
          />
          <TableRow
            alignCells={["left", "right"]}
            entries={["3,5 – 4,5", "Mittleres Risiko"]}
          />
          <TableRow
            alignCells={["left", "right"]}
            entries={["5 – 6", "Hohes Risiko"]}
          />
          <TableRow
            alignCells={["left", "right"]}
            entries={["> 6", "Sehr hohes Risiko"]}
          />
        </TableBody>
      </Table>
    </>
  );
}
