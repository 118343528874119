/** @jsxImportSource @emotion/react */

import {
  TableCell,
  TableHead as MuiTableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { css } from "@emotion/react";

type CellAlignment = "left" | "center" | "right" | "inherit" | "justify";

export interface TableHeadProps {
  labels: string[];
  alignCells?: CellAlignment[];
}

export function TableHead(props: TableHeadProps) {
  return (
    <MuiTableHead>
      <TableRow>
        {props.labels.map((label, index) => (
          <TableCell
            key={index}
            css={css`
              padding-bottom: 16px;
              padding-top: 21px;
              padding-left: 16px;
              padding-right: 16px;
              border-color: rgba(130, 145, 154, 0.3);
            `}
            align={
              props.alignCells && props.alignCells[index]
                ? props.alignCells[index]
                : undefined
            }
          >
            <Typography
              variant="overline"
              color="primary"
              dangerouslySetInnerHTML={{ __html: label.toUpperCase() }}
            />
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
}

export default TableHead;
