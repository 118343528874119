import { SvgIcon, SvgIconProps } from "@mui/material";

export function MinusIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Komponente 106 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        data-name="Rechteck 860"
        style={{ fill: "none" }}
        d="M0 0h35v35H0z"
      />
      <path
        data-name="Linie 145"
        transform="rotate(90 6.5 24)"
        style={{ stroke: "currentcolor", strokeLinecap: "round", fill: "none" }}
        d="M0 0v26"
      />
    </SvgIcon>
  );
}

export default MinusIcon;
