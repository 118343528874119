/** @jsxImportSource @emotion/react */

import {
  ScoreButtonGroup,
  SignedNumber,
  Table,
  TableHead,
  TableRow,
} from "components/ui";
import { useState } from "react";
import { css } from "@emotion/react";
import { TableBody, Typography, useTheme } from "@mui/material";

export function MasccScore() {
  const theme = useTheme();

  const initialState = {
    grv: {
      activeId: "",
      value: 0,
    },
    hypo: {
      activeId: "",
      value: 0,
    },
    copd: {
      activeId: "",
      value: 0,
    },
    tumor: {
      activeId: "",
      value: 0,
    },
    dhdr: {
      activeId: "",
      value: 0,
    },
    fbr: {
      activeId: "",
      value: 0,
    },
    age: {
      activeId: "",
      value: 0,
    },
  };

  const [score, setScore] = useState(initialState);

  const onScoreChange = (
    id: string,
    scoreField: "grv" | "hypo" | "copd" | "tumor" | "dhdr" | "fbr" | "age",
    value: number
  ) => {
    if (score[scoreField].activeId === id) {
      setScore({
        ...score,
        [scoreField]: {
          activeId: "",
          value: 0,
        },
      });
    } else {
      setScore({
        ...score,
        [scoreField]: {
          activeId: id,
          value,
        },
      });
    }
  };

  return (
    <>
      <h2>Schwere der Symptome</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) =>
          onScoreChange(id, "grv", id === "grv1" ? 5 : id === "grv2" ? 3 : 0)
        }
        scores={[
          { id: "grv1", label: "Keine oder milde Symptome", value: 5 },
          { id: "grv2", label: "Moderate Symptome", value: 3 },
          { id: "grv3", label: "Schwere Symptome", value: 0 },
        ]}
      />
      <h2>Hypotension</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => onScoreChange(id, "hypo", id === "hypo1" ? 0 : 5)}
        scores={[
          { id: "hypo1", label: "Ja", value: 0 },
          { id: "hypo2", label: "Nein", value: 5 },
        ]}
      />
      <h2>Aktive COPD</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => onScoreChange(id, "copd", id === "copd1" ? 0 : 4)}
        scores={[
          { id: "copd1", label: "Ja", value: 0 },
          { id: "copd2", label: "Nein", value: 4 },
        ]}
      />
      <h2>Art der Tumorerkrankung</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) =>
          onScoreChange(id, "tumor", id === "tumor1" || id === "tumor2" ? 4 : 0)
        }
        scores={[
          { id: "tumor1", label: "Solider Tumor", value: 4 },
          {
            id: "tumor2",
            label: "Hämatologisch, ohne Pilzinfektion",
            value: 4,
          },
          { id: "tumor3", label: "Hämatologisch, mit Pilzinfektion", value: 0 },
        ]}
      />
      <h2>Dehydrierung</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => onScoreChange(id, "dhdr", id === "dhdr1" ? 0 : 3)}
        scores={[
          { id: "dhdr1", label: "Ja", value: 0 },
          { id: "dhdr2", label: "Nein", value: 3 },
        ]}
      />
      <h2>Status bei Fieberanstieg</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => onScoreChange(id, "fbr", id === "fbr1" ? 3 : 0)}
        scores={[
          { id: "fbr1", label: "Ambulant", value: 3 },
          { id: "fbr2", label: "Stationär", value: 0 },
        ]}
      />
      <h2>Alter</h2>
      <ScoreButtonGroup
        singleSelect
        outlined
        onChange={(id) => onScoreChange(id, "age", id === "age1" ? 2 : 0)}
        scores={[
          { id: "age1", label: "< 60 Jahre", value: 2 },
          { id: "age2", label: "≥ 60 Jahre", value: 0 },
        ]}
      />
      <h2
        css={css`
          margin-top: 63px;
        `}
      >
        Ihre Auswahl
      </h2>
      <p>
        Haben Sie eine Auswahl mit dem Wert 0 getroffen, wird diese zur besseren
        Übersichtlichkeit nicht mit angezeigt.
      </p>
      <Table>
        <TableBody>
          {score.grv.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Schwere der Symptome",
                <SignedNumber key={"grv"}>{score.grv.value}</SignedNumber>,
              ]}
            />
          )}
          {score.hypo.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Hypotension",
                <SignedNumber key={"hypo"}>{score.hypo.value}</SignedNumber>,
              ]}
            />
          )}
          {score.copd.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Aktive COPD",
                <SignedNumber key={"copd"}>{score.copd.value}</SignedNumber>,
              ]}
            />
          )}
          {score.tumor.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Art der Tumorerkrankung",
                <SignedNumber key={"tumor"}>{score.tumor.value}</SignedNumber>,
              ]}
            />
          )}
          {score.dhdr.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Dehydrierung",
                <SignedNumber key={"dhdr"}>{score.dhdr.value}</SignedNumber>,
              ]}
            />
          )}
          {score.fbr.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Status bei Fieberanstieg",
                <SignedNumber key={"fbr"}>{score.fbr.value}</SignedNumber>,
              ]}
            />
          )}
          {score.age.value !== 0 && (
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Alter",
                <SignedNumber key={"age"}>{score.age.value}</SignedNumber>,
              ]}
            />
          )}
        </TableBody>
      </Table>
      <div
        css={css`
          margin-top: 32px;
        `}
      >
        <Table>
          <TableHead
            alignCells={["center", "center", "left"]}
            labels={["MASCC Score", "FN-Risiko", "Empfehlung"]}
          />
          <TableBody>
            <TableRow
              alignCells={["center", "center", "left"]}
              entries={[
                <Typography key={"suc_low1"} color={theme.palette.success.main}>
                  &ge; 21
                </Typography>,
                <Typography key={"suc_low2"} color={theme.palette.success.main}>
                  Niedriges Risiko
                </Typography>,
                <Typography key={"suc_low3"} color={theme.palette.success.main}>
                  Ambulante empirische antibiotische Therapie möglich
                </Typography>,
              ]}
            />
            <TableRow
              alignCells={["center", "center", "left"]}
              entries={[
                <Typography key={"suc_high1"} color={theme.palette.error.main}>
                  &lt; 21
                </Typography>,
                <Typography key={"suc_high2"} color={theme.palette.error.main}>
                  Hohes Risiko
                </Typography>,
                <Typography key={"suc_high3"} color={theme.palette.error.main}>
                  Stationäre empirische antibiotische Therapie notwendig
                </Typography>,
              ]}
            />
          </TableBody>
        </Table>
      </div>
    </>
  );
}

export default MasccScore;
