/** @jsxImportSource @emotion/react */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  useTheme,
} from "@mui/material";
import React, { ReactNode, useState } from "react";
import { css } from "@emotion/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export interface ToggleBoxProps {
  title: ReactNode | string;
  children: ReactNode;
  forceOpen?: boolean;
  transparentBody?: boolean;
  type?: "default" | "alternative";
}

export function ToggleBox(props: ToggleBoxProps) {
  const { type = "default" } = props;
  const [expanded, setExpanded] = useState<boolean>(false);
  const theme = useTheme();

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const styles = {
    root: css``,
    summary: css`
      cursor: ${props.forceOpen ? "default !important" : "pointer"};
      border-bottom: none;
      .MuiAccordionSummary-content {
        color: ${type === "default" ? "inherit" : theme.palette.primary.main};
      }
    `,
    summaryClosed: css`
      background-color: ${type === "default"
        ? "#89b0d2"
        : theme.palette.hxBackground.primary} !important;
      color: #fff;
      .MuiAccordionSummary-expandIconWrapper {
        color: #fff;
      }
    `,
    summaryExpanded: css`
      background-color: ${type === "default"
        ? "#89b0d2"
        : theme.palette.hxBackground.primary} !important;
      color: ${type === "default" ? "#fff" : theme.palette.primary.main};
      .MuiAccordionSummary-expandIconWrapper {
        color: #fff;
      }
    `,
    details: css`
      border-bottom: none;
      background-color: ${props.transparentBody
        ? "none"
        : theme.palette.hxBackground.primary};
      p,
      ul,
      ol {
        margin-top: 0;
        margin-bottom: 0;
      }
    `,
  };

  return (
    <Accordion
      css={styles.root}
      expanded={props.forceOpen || expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        css={[
          styles.summary,
          props.forceOpen || expanded
            ? styles.summaryExpanded
            : styles.summaryClosed,
        ]}
        expandIcon={props.forceOpen ? undefined : <ExpandMoreIcon />}
      >
        {props.title}
      </AccordionSummary>
      <AccordionDetails css={styles.details}>{props.children}</AccordionDetails>
    </Accordion>
  );
}

export default ToggleBox;
