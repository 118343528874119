import styled from "@emotion/styled";

// Font import Roboto
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

/* eslint-disable-next-line */
export interface UiProps {}

const StyledUi = styled.div``;

export function Ui() {
  return (
    <StyledUi>
      <h1>Welcome to Ui!</h1>
    </StyledUi>
  );
}

export default Ui;
