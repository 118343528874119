import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import TextReference from "../../components/TextReference";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";

export function FnDisease() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Erkrankung</h1>
        <h2>Definition & potenzielle Auswirkungen</h2>
        <p>
          Febrile Neutropenie (FN) und Neutropenie-assoziierte Infektionen sind
          ein bedeutsamer Faktor für Morbidität und Mortalität nach
          zytotoxischer Therapie. Bei stationärer Aufnahme wegen einer FN liegt
          die Mortalität ohne Begleiterkrankungen bei 2,6%, mit einer oder zwei
          Komorbiditäten steigen die Raten auf 10,3% bzw. 21,4%.
          <TextReference references={["ref41"]} />
        </p>
        <h2>Potenzielle Auswirkungen</h2>
        <ul>
          <li>Dosisreduktion der Chemotherapie und/oder Zyklusverzögerungen</li>
          <li>Hospitalisierung</li>
          <li>Verringerte Lebensqualität</li>
          <li>
            Gefahr des Therapieabbruchs - insbesondere in der (neo-)adjuvanten
            Situation
          </li>
          <li>
            <strong>Morbidität</strong>
          </li>
          <li>
            <strong>Mortalität</strong>
          </li>
        </ul>
        <h2>Neutropenie</h2>
        <p>Verminderung der neutrophilen Granulozyten im Blut:</p>
        <ul>
          <li>Neutrophile (Stab- und Segmentkernige) &lt;500/µl oder</li>
          <li>
            Neutrophile (Stab- und Segmentkernige) &lt;1.000/µl mit einem
            vorhersehbaren Abfall auf &lt;500/µl innerhalb der nächsten 2 Tage
          </li>
        </ul>
        <h2>Febrile Neutropenie</h2>
        <p>
          Verminderung der neutrophilen Granulozyten im Blut begleitet von
          deutlich erhöhter Körpertemperatur:
        </p>
        <ul>
          <li>Fieber von &ge; 38 °C über mindestens eine Stunde oder</li>
          <li>
            einmaliger Fieberanstieg auf mehr als 38,3 °C
            <TextReference references={["ref1", "ref2"]} />
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default FnDisease;
