/** @jsxImportSource @emotion/react */

import {
  Accordion,
  DynamicSpacing,
  Graph,
  PagePadding,
  Table,
  AccordionNodeItem,
  TableHead,
  TableBody,
  TableRow,
} from "components/ui";

import TextReference from "../../components/TextReference";
import { css } from "@emotion/react";
import * as dosage from "../../assets/img/fn/praxis/kasuistik-indolentes-lymphom.svg";
import Abbreviation from "../../components/Abbreviation";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import HexalSeo from "../../components/HexalSeo";

export function FnPraxisCasuisticsIndolentLymphoma() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Rolle der Supportivtherapie in der Hämato-Onkologie</h1>
        <p>Indolentes Lymphom (Chronische lymphatische Leukämie)</p>
        <p>
          77-jähriger Patient, 68 kg, 167 cm, <Abbreviation>ecog</Abbreviation>{" "}
          1
        </p>

        <Accordion
          items={[
            {
              title: "Symptome & Diagnostik",
              defaultOpen: true,
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Aktuelle Anamnese 01/2018</h3>
                    <p>
                      Vorstellung bei progredienter Splenomegalie, links
                      zervikaler Lymphknotenvergrößerung, Nachtschweiß (1 x
                      nächtliches Kleiderwechseln/Nacht), Bizytopenie (Anämie
                      und Thrombozytopenie) bei Leukozytose.
                    </p>

                    <h3>Vorerkrankungen</h3>
                    <ul>
                      <li>Arterielle Hypertonie</li>
                      <li>Diabetes mellitus</li>
                    </ul>

                    <h3>Diagnose</h3>
                    <ul>
                      <li>
                        Körperlicher Untersuchungsbefund: zervikal linksseitig
                        vereinzelte Lymphknoten tastbar, inguinale Lymphknoten
                        beidseitig leicht vergrößert. Leber nicht palpabel, Milz
                        etwa 5 cm unterhalb des Rippenbogens tastbar.
                      </li>
                      <li>
                        <strong>Labor:</strong> Leukozyten 25/nl (Norm
                        3,5–10/nl), 81 % Lymphozyten,{" "}
                        <Abbreviation>hb</Abbreviation> 8,3 g/dl (Norm 12–16
                        g/dl), Thrombozyten 89/nl (Norm: 150–360/nl),{" "}
                        <Abbreviation>ldh</Abbreviation> 756 U/l (Norm &lt; 245
                        U/l)
                      </li>
                    </ul>

                    <h3>
                      Bildgebung – <Abbreviation>ct</Abbreviation> und{" "}
                      <Abbreviation>mrt</Abbreviation> 01/2018
                    </h3>
                    <ul>
                      <li>
                        Pathologisch vergrößerte Lymphknoten beidseits zervikal,
                        links führend.
                      </li>
                      <li>
                        Hepatosplenomegalie bei Verdacht auf Lymphombefall der
                        Milz.
                      </li>
                      <li>
                        Lymphombulk im Bereich der Leberpforte paratruncal mit
                        Ummauerung des Truncus coeliacus.
                      </li>
                      <li>
                        Pathologisch vergrößerte retroperitoneale und
                        parailiakale Lymphknoten.
                      </li>
                    </ul>

                    <h3>Knochenmark-Diagnostik vom 23.01.2018</h3>
                    <ul>
                      <li>
                        <strong>Zytologie:</strong>{" "}
                        <Abbreviation>km</Abbreviation>
                        -Ausstriche mit Bröckelmaterial von gesteigerter
                        Zellularität, Z3. Megakaryopoese nur sporadisch
                        vorhanden, Erythropoese deutlich reduziert, ebenso die
                        Granulopoese. Das Bild wird beherrscht von einer
                        Infiltration durch ca. 70–80 % reife kleine lymphatische
                        Zellen. Beurteilung: KM-Infiltration durch ein niedrig
                        malignes Non-Hodgkin-Lymphom. Befund gut vereinbar mit{" "}
                        <Abbreviation>cll</Abbreviation>.
                      </li>
                      <li>
                        <strong>Durchflusszytometrie:</strong> Im
                        Untersuchungsgate liegen ca. 23 % der KM-Zellen nach
                        Ficoll. Darin enthalten sind ca. 70 %
                        CD5/19/23/(38)/skappa-positive B-Zellen nachweisbar.
                        Gegenwärtig ist keine CD20-Expression nachweisbar.
                        Bewertung: KM-Infiltration durch ein niedrig malignes
                        Non-Hodgkin-Lymphom, vom Phänotyp einer CLL
                        entsprechend.
                      </li>
                      <li>
                        <strong>Genetik:</strong> Keine TP53-Mutation, keine
                        del(17p13).
                      </li>
                    </ul>

                    <h3>Abschließende Diagnose</h3>
                    <p>
                      Chronisch lymphatische Leukämie, Stadium Binet C
                      (Hämoglobin &lt; 10 g/dl, Thrombozyten &lt; 100.000/μl)
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Medikamentöse Tumor-Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <p>Rituximab-Bendamustin q4w x 6</p>

                    <h3>Dosierung</h3>
                    <p>
                      Rituximab (R, 375 mg/m<sup>2</sup>{" "}
                      <Abbreviation>kof</Abbreviation>
                      ) Tag 1
                      <br />
                      Bendamustin (B, 90 mg/m<sup>2</sup> KOF) Tag 2 + 3
                    </p>
                    <Graph
                      imagePath={dosage.default}
                      alt="Rituximab-Bendamustin "
                      legend={[
                        {
                          type: "rectangle",
                          color: "lightViolet",
                          label: "Rituximab",
                        },
                        {
                          type: "rectangle",
                          color: "yellow",
                          label: "Bendamustin",
                        },
                      ]}
                      largeWidth="50%"
                      bordered
                    />

                    <h3>Empfehlungsgrad S3-Leitlinie</h3>
                    <p>
                      Ältere und komorbide Patient:innen: A{" "}
                      <TextReference references={["ref2"]} />
                    </p>

                    <h3>Protokollklassifikation</h3>
                    <ul>
                      <li>
                        Klassifikation: Standard für ältere und komorbide
                        Patient:innen
                      </li>
                      <li>Intensität: weniger intensive Chemo-Immuntherapie</li>
                      <li>Therapieintention: palliativ</li>
                    </ul>

                    <h3>Risiken</h3>
                    <p>
                      Febrile Neutropenie: 10–20 % (mittleres Risiko){" "}
                      <TextReference references={["ref12"]} />
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: (
                <p
                  css={css`
                    margin-top: 0;
                    margin-bottom: 0;
                  `}
                >
                  Supportivtherapie
                  <TextReference references={["ref11"]} />
                </p>
              ),
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Therapieverlauf</h3>
                    <ul>
                      <li>
                        Fieber unklarer Genese an Tag 10 des 1. Zyklus in der
                        Neutropenie
                      </li>
                      <li>
                        Stationäre Aufnahme und Einleitung einer empirischen
                        antibiotischen Therapie mit Piperacillin/Tazobactam →
                        hierunter anhaltende Fieberfreiheit ohne Hinweis auf
                        einen Infekt-Fokus
                      </li>
                      <li>
                        Zeitgerechter Beginn des 2. Zyklus Rituximab-Bendamustin
                      </li>
                    </ul>
                    <p>
                      Bei febriler Neutropenie im 1. Zyklus besteht die
                      Indikation zu einer{" "}
                      <strong>
                        <Abbreviation>gcsf</Abbreviation>
                        -Sekundärprophylaxe
                      </strong>{" "}
                      nach folgendem Schema, Sekundärprophylaxe im 2. Zyklus
                      initiiert und in 5 Zyklen weitergeführt.
                    </p>

                    <h3>Filgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "Ab d 6"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "5 μg/kg KG/Tag → 30 Mio. E."]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q28d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc1"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <h3>Pegfilgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "6"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "6 mg"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q28d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc2"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <h3>Weiterer Verlauf</h3>
                    <ul>
                      <li>
                        Im Verlauf keine weiteren Episoden einer febrilen
                        Neutropenie unter der Sekundärprophylaxe
                      </li>
                      <li>Komplikationslose Beendigung der 6 Zyklen</li>
                    </ul>

                    <p>
                      Wir danken Dr. Daniela Tölle und Prof. Dr. Georg Heß für
                      die Initiative, Mitgestaltung und Mitentwicklung der
                      Kasuistik und Therapie.
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default FnPraxisCasuisticsIndolentLymphoma;
