import { Chip as MuiChip } from "@mui/material";

/* eslint-disable-next-line */
export interface ChipProps {
  color?: "primary" | "error" | "success" | "warning";
  label: string;
}

export function Chip(props: ChipProps) {
  const { color = "primary" } = props;

  return <MuiChip label={props.label} color={color} />;
}

export default Chip;
