/** @jsxImportSource @emotion/react */

import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";

export interface BreadcrumbsProps {
  breadcrumbs: { path: string; label: string }[];
}

export function Breadcrumbs(props: BreadcrumbsProps) {
  const breadcrumbs = props.breadcrumbs.map((item, index) => {
    // Last item not clickable!
    if (index === props.breadcrumbs.length - 1) {
      return <span key={index}>{item.label}</span>;
    }
    return (
      <Link key={index} to={item.path}>
        {item.label}
      </Link>
    );
  });
  return (
    <div
      css={css`
        margin-top: 0px;
        margin-bottom: 16px;
        // ol li {
        // display: inline-flex;
        // text-overflow: ellipsis;
        // flex: 1;
        // white-space: nowrap;
        // }
      `}
    >
      <MuiBreadcrumbs>{breadcrumbs}</MuiBreadcrumbs>
    </div>
  );
}

export default Breadcrumbs;
