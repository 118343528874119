export const resources = {
  videos: {
    applicationZiextenzoFilgrastim: {
      url: "https://linkedlines.box.com/shared/static/i269d74gc3r3f1426rmheq1bi2vihony.mp4",
    },
    applicationEpoetinAlpha: {
      url: "https://linkedlines.box.com/s/24aanv8427h10bwo1gj2dmenrp5vrv63",
    },
  },
  documents: {
    deferasirox: {
      url: "https://www.google.de",
    },
    epoetinAlpha: {
      url: "https://www.google.de",
    },
    erypo: {
      url: "https://www.google.de",
    },
    exjade: {
      url: "https://www.google.de",
    },
    filgrastim: {
      url: "https://www.google.de",
    },
    lenalidomid: {
      url: "https://www.google.de",
    },
    reblozyl: {
      url: "https://www.google.de",
    },
    revlimid: {
      url: "https://www.google.de",
    },
    ziextenzo: {
      url: "https://www.google.de",
    },
  },
};
