import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import MdsTherapyLowRisk from "./therapyLowRisk";
import MdsTherapyIronOverload from "./therapyIronOverload";

export function MdsTherapy() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const lowRisk = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRisk
  );
  if (lowRisk) {
    navigationItems.push({
      link: {
        label: lowRisk.title,
        isExternal: false,
        url: lowRisk.path,
      },
      description: lowRisk.description,
    });
  }

  const ironOverload = currentPage?.children.find(
    (child) => child.view === MdsTherapyIronOverload
  );
  if (ironOverload) {
    navigationItems.push({
      link: {
        label: ironOverload.title,
        isExternal: false,
        url: ironOverload.path,
      },
      description: ironOverload.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapeutische Maßnahmen</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsTherapy;
