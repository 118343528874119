import { useState } from "react";
import {
  ArrowSeparator,
  ButtonGroup,
  DecisionTreeWrapper,
  ResultBox,
  ScoreButtonGroup,
  SeparatorWrapper,
} from "components/ui";
import { Grid } from "@mui/material";

type SelectionState = {
  hb: null | string;
  symptomatic: null | boolean;
};

export function EsaCiaDiagnosticsDecisionTree() {
  const initialState: SelectionState = {
    hb: null,
    symptomatic: null,
  };

  const [selected, setSelected] = useState<SelectionState>(initialState);
  const requestDosage = () => selected.hb === "hbMed";
  const showResult = () =>
    selected.hb === "hbNorm" ||
    selected.hb === "hbLow" ||
    selected.symptomatic !== null;

  const getResultText = () => {
    let result: string;

    switch (selected.hb) {
      case "hbNorm":
        result = "Keine prophylaktische ESA-Therapie";
        break;
      case "hbMed":
        if (selected.symptomatic === null) {
          result = "";
        } else {
          result =
            "Mit individualisierter Behandlung und minimalem therapeutischen Aufwand Hb-Zielwert erhalten";
        }
        break;
      case "hbLow":
        result =
          "Transfusionsbedarf nach individuellen Faktoren abschätzen, ESA-/Eisen-Therapie erwägen";
        break;
      default:
        result = "";
    }

    return result;
  };
  const getResultColor = () => {
    let result: "success" | "warning" | "error";

    switch (selected.hb) {
      case "hbNorm":
        result = "success";
        break;
      case "hbMed":
        if (selected.symptomatic === null) {
          result = "error";
        } else {
          result = "warning";
        }
        break;
      case "hbLow":
        result = "warning";
        break;
      default:
        result = "error";
    }

    return result;
  };

  const getResultAddText = () => {
    const result = "ESA-Therapie".concat(
      selected.symptomatic === false ? " (individualisiert)" : "",
      " bis Hb-Zielwert 12 g/dl (7,45 mmol/l)"
    );

    return result;
  };

  return (
    <DecisionTreeWrapper>
      <Grid container>
        <Grid item xs={12}>
          <ScoreButtonGroup
            onChange={(id) => {
              const resetTree = selected.hb !== null;
              if (resetTree && selected.hb === id) {
                setSelected(initialState);
              } else if (resetTree) {
                const newState = { ...initialState };
                newState.hb = id;
                setSelected(newState);
              } else {
                setSelected((values) => ({ ...values, hb: id }));
              }
            }}
            outlined
            singleSelect
            scores={[
              { id: "hbNorm", label: "Hb-Wert normal" },
              { id: "hbMed", label: "Hb-Wert 8 – 10 g/dl (4,97 – 6,2 mmol/l)" },
              { id: "hbLow", label: "Hb-Wert < 8 g/dl (< 4,97 mmol/l)" },
            ]}
          />
          {requestDosage() && (
            <>
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ButtonGroup
                results={[
                  { label: "Symptomatisch", color: "primary", id: "symp" },
                  { label: "Asymptomatisch", color: "primary", id: "asymp" },
                ]}
                onClick={(id) => {
                  const resetTree = selected.symptomatic !== null;
                  if (resetTree && selected.symptomatic === (id === "symp")) {
                    setSelected((values) => ({ ...values, symptomatic: null }));
                  } else {
                    setSelected((values) => ({
                      ...values,
                      symptomatic: id === "symp",
                    }));
                  }
                }}
              />
            </>
          )}
          {showResult() && (
            <>
              {requestDosage() && (
                <>
                  <SeparatorWrapper>
                    <ArrowSeparator color="primary" />
                  </SeparatorWrapper>
                  <ResultBox
                    label={getResultAddText()}
                    variant="small"
                    color="primary"
                  />
                </>
              )}
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ResultBox
                label={getResultText()}
                variant="large"
                color={getResultColor()}
              />
            </>
          )}
        </Grid>
      </Grid>
    </DecisionTreeWrapper>
  );
}

export default EsaCiaDiagnosticsDecisionTree;
