import { SvgIcon, SvgIconProps } from "@mui/material";

export function PlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Komponente 104 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        data-name="Rechteck 1346"
        style={{ fill: "none" }}
        d="M0 0h35v35H0z"
      />
      <g data-name="+ Icon 1">
        <path
          data-name="Linie 144"
          transform="translate(18 5)"
          style={{
            stroke: "currentcolor",
            strokeLinecap: "round",
            fill: "none",
          }}
          d="M0 0v26"
        />
        <path
          data-name="Linie 145"
          transform="rotate(90 6.5 24.5)"
          style={{
            stroke: "currentcolor",
            strokeLinecap: "round",
            fill: "none",
          }}
          d="M0 0v26"
        />
      </g>
    </SvgIcon>
  );
}

export default PlusIcon;
