import { ReactNode } from "react";
import { useAuth } from "../hooks/Authentication";
import { Navigate, useLocation } from "react-router-dom";

export interface ProtectedRouteProps {
  children: ReactNode;
}
export function ProtectedRoute(props: ProtectedRouteProps) {
  const { authed } = useAuth();
  const location = useLocation();

  if (!authed) {
    return <Navigate to={"/login"} replace state={{ from: location }} />;
  }

  return <>{props.children}</>;
}

export default ProtectedRoute;
