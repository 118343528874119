import { ReactNode, useContext } from "react";
import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import FnRiskMascc from "./riskMascc";
import GlobalContext from "../../context/globalContext";
import FnRiskFactors from "./riskRiskFactors";

export function FnRisk() {
  const { currentPage } = useContext(GlobalContext);
  const navigationItems: { link: Link; description: string | ReactNode }[] = [];

  const riskMascc = currentPage?.children.find(
    (child) => child.view === FnRiskMascc
  );
  if (riskMascc) {
    navigationItems.push({
      link: {
        label: riskMascc.title,
        isExternal: false,
        url: riskMascc.path,
      },
      description: riskMascc.description,
    });
  }

  const riskFactors = currentPage?.children.find(
    (child) => child.view === FnRiskFactors
  );
  if (riskFactors) {
    navigationItems.push({
      link: {
        label: riskFactors.title,
        isExternal: false,
        url: riskFactors.path,
      },
      description: riskFactors.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Risiko</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default FnRisk;
