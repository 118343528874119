import { SvgIcon, SvgIconProps } from "@mui/material";

export function MagnifierIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      id="Gruppe_1884"
      data-name="Gruppe 1884"
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
    >
      <g id="Icon_Lupe" data-name="Icon Lupe">
        <rect
          id="Rechteck_600"
          data-name="Rechteck 600"
          width="61"
          height="61"
          fill="none"
        />
        <path
          id="Pfad_435"
          data-name="Pfad 435"
          d="M60.012,56.992,47.476,44.836A18.376,18.376,0,0,0,53.1,31.619,18.913,18.913,0,0,0,34.051,13,18.788,18.788,0,0,0,15,31.619,19.124,19.124,0,0,0,45.107,46.766L57.841,59.211a1.552,1.552,0,0,0,2.172-2.219ZM18.06,31.619a16,16,0,0,1,31.982,0,16,16,0,0,1-31.982,0Z"
          transform="translate(-7.105 -6.158)"
          fill="#0460a9"
        />
      </g>
    </SvgIcon>
  );
}

export default MagnifierIcon;
