import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";
import TextReference from "../../components/TextReference";
import { MdsIpssRScore } from "../../components/Scores/MdsIpssRScore";

export function MdsDiagnosticPrognostics() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Prognostische Faktoren</h1>
        <p>
          Zur besseren Abschätzung der individuellen Prognose sollten neben
          Alter, Geschlecht und Komorbiditäten vor allem krankheitsbiologische
          Parameter herangezogen werden.
        </p>

        <h2>Wichtigste zusätzliche Prognoseparameter:</h2>
        <ul>
          <li>Medullärer Blastenanteil</li>
          <li>Zytogenetische Befunde</li>
          <li>Transfusionsbedarf</li>
          <li>Blutzellwerte</li>
          <li>
            <Abbreviation>ldh</Abbreviation>
          </li>
        </ul>

        <h2>Validierte Prognosesysteme</h2>
        <p>
          Zur Abschätzung des individuellen Risikos der Patient:innen:{" "}
          <TextInfo>
            Für beide Prognosesysteme ist die zytogenetische Analyse von
            hämatopoetischen Progenitorzellen (Knochenmark) erforderlich.
          </TextInfo>{" "}
          <TextReference references={["ref1"]} />
        </p>
        <ul>
          {/*<li>*/}
          {/*  <strong>*/}
          {/*    International Prognostic Scoring System (*/}
          {/*    <Abbreviation>ipss</Abbreviation>)*/}
          {/*  </strong>*/}
          {/*  <ul>*/}
          {/*    <li>*/}
          {/*      Berücksichtigt den Einfluss der Zytopenien, der chromosomalen*/}
          {/*      Veränderungen sowie den Gehalt an Knochenmarkblasten*/}
          {/*    </li>*/}
          {/*  </ul>*/}
          {/*</li>*/}
          <li>
            <strong>
              International Prognostic Scoring System – Revised (IPSS-R)
            </strong>
          </li>
          <ul>
            <li>
              Ergänzung des IPSS um weitere zytogenetische Kategorien mit hoher
              Gewichtung der Hochrisiko-Befunde
            </li>
            <li>
              Zusätzliche modifizierte Gewichtung des Anteils der
              Knochenmarkblasten
            </li>
            <li>Detaillierteres Eingehen auf die Ausprägung der Zytopenien</li>
          </ul>
        </ul>

        <h2>
          <Abbreviation>ipssr</Abbreviation>
        </h2>
        <p>
          Wählen Sie das zutreffende Feld in der jeweiligen Kategorie (Karyotyp,
          Blasten (%), etc.) aus, um zu sehen, welche Scoring-Punkte, für die
          jeweils in Frage kommenden Werte nach dem IPSS-R vergeben werden.
          Zählen Sie die Punkte zusammen, und vergleichen Sie Ihren Wert
          anschließend mit dem unten aufgeschlüsselten Risikoscore.
        </p>
        <MdsIpssRScore />
      </PagePadding>
    </>
  );
}

export default MdsDiagnosticPrognostics;
