/** @jsxImportSource @emotion/react */

import { ReactNode, useRef } from "react";
import { css, SerializedStyles } from "@emotion/react";
import { useDrag, useDrop, XYCoord } from "react-dnd";

// TODO: Misleading error: "Identifier not found in 'dnd-core'  import/named"
// import { Identifier } from "dnd-core";
type Identifier = string | symbol;

export const ItemTypes = {
  CARD: "card",
};

export interface DragableWrapperProps {
  children: ReactNode;
  index: number;
  id: string;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  additionalStyles?: SerializedStyles;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export function DragableWrapper(props: DragableWrapperProps) {
  const { index, moveItem, id } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return { handlerId: monitor.getHandlerId() };
    },
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveItem(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    // type any here is required and expected
    // eslint-disable-next-line
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const styles = {
    root: css`
      cursor: move;
      a {
        cursor: move;
      }
    `,
  };
  return (
    <div
      css={[styles.root, props.additionalStyles]}
      ref={ref}
      data-handler-id={handlerId}
    >
      {props.children}
    </div>
  );
}

export default DragableWrapper;
