import { useContext } from "react";
import { Reference } from "components/ui";
import references from "../data/references";
import GlobalContext from "../context/globalContext";

interface TextReferenceProps {
  // children: string;
  references: string[];
  isInDarkElement?: boolean;
}

export function TextReference(props: TextReferenceProps) {
  const { currentPage } = useContext(GlobalContext);

  // const modalContent = props.references.map((reference, index) => {
  //   const refrenceKey = reference.toLowerCase();
  //   const pageScopedReference =
  //     currentPage !== null && references[currentPage.pageScope as keyof typeof references]
  //       ? references[currentPage.pageScope as keyof typeof references]
  //       : undefined;
  //   const referenceText = pageScopedReference !== undefined
  //   && pageScopedReference[refrenceKey as keyof typeof pageScopedReference]
  //     ? pageScopedReference[refrenceKey as keyof typeof pageScopedReference]
  //     : "";
  //
  //   return <p key={index} dangerouslySetInnerHTML={{ __html: referenceText}} />;
  // });
  const modalContent = (
    <>
      <p>
        <strong>Referenzen:</strong>
      </p>
      {props.references.map((reference, index) => {
        const refrenceKey = reference.toLowerCase();
        const pageScopedReference =
          currentPage !== null &&
          references[currentPage.pageScope as keyof typeof references]
            ? references[currentPage.pageScope as keyof typeof references]
            : undefined;
        const referenceText =
          pageScopedReference !== undefined &&
          pageScopedReference[refrenceKey as keyof typeof pageScopedReference]
            ? "<strong>".concat(
                (index + 1).toString(),
                ".</strong> ",
                pageScopedReference[
                  refrenceKey as keyof typeof pageScopedReference
                ]
              )
            : "";

        return (
          <p key={index} dangerouslySetInnerHTML={{ __html: referenceText }} />
        );
      })}
    </>
  );

  return (
    <Reference
      darkMode={props.isInDarkElement}
      content={modalContent}
      isNode
      type="reference"
    />
  );
}

export default TextReference;
