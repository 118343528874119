import { MainBackground, PagePadding } from "../components/ui";
import PasswordLoginForm from "../components/Authentication/PasswordLoginForm";

export function LoginPage() {
  return (
    <>
      <MainBackground />
      <PagePadding>
        <h1>Für Ärzt:innen und med. Fachpersonal</h1>
        <p>
          Die von Ihnen aufgerufenen Inhalte sind ausschließlich medizinischen
          Fachkreisen vorbehalten. Bitte loggen Sie sich ein, um den gewählten
          Inhalt nutzen zu können.
        </p>
        <PasswordLoginForm />
      </PagePadding>
    </>
  );
}

export default LoginPage;
