import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";

export function MdsPraxisPracticalTips() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Praxistipps</h1>
        <p>Do’s</p>
        <ul>
          <li>
            <Abbreviation>esa</Abbreviation>
            -Therapie Zeit geben, kontrollierte Dosisanpassung (ca. 4 Wochen
            warten bis zu einer Dosissteigerung)
            <ul>
              <li>Bei der ESA-Dosisanpassung an der Symptomatik orientieren</li>
              <li>
                Kontrolle des <Abbreviation>hb</Abbreviation>
                -Wertes ist abhängig vom Transfusionsbedarf
              </li>
            </ul>
          </li>
          <li>
            Überwachung aufgrund erhöhten Risikos für Hypertonie, Thrombose
          </li>
          <li>
            Ein Kreatinin-Anstieg ist zu erwarten, fällt aber überwiegend nur
            gering aus
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default MdsPraxisPracticalTips;
