import {
  NavigationList,
  PagePadding,
  NavigationListItem,
  Link,
} from "components/ui";
import { useContext } from "react";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import HexalSeo from "../../components/HexalSeo";
import GlobalContext from "../../context/globalContext";
import CiaDiseaseDefinition from "./diseaseDefinition";
import CiaDiseaseSymptoms from "./diseaseSymptoms";

export function CiaDisease() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const definition = currentPage?.children.find(
    (child) => child.view === CiaDiseaseDefinition
  );
  if (definition) {
    navigationItems.push({
      link: {
        label: definition.title,
        isExternal: false,
        url: definition.path,
      },
      description: definition.description,
    });
  }

  const symptoms = currentPage?.children.find(
    (child) => child.view === CiaDiseaseSymptoms
  );
  if (symptoms) {
    navigationItems.push({
      link: {
        label: symptoms.title,
        isExternal: false,
        url: symptoms.path,
      },
      description: symptoms.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Erkrankung</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default CiaDisease;
