import { CheckList } from "components/ui";
import Abbreviation from "../Abbreviation";
import TextInfo from "../TextInfo";

export function TransfusionTriggerChecklist() {
  return (
    <>
      <CheckList
        labelParent="Bei jeder EK-Gabe erneute Angabe des Transfusionstriggers"
        childBoxes={[
          {
            id: "trf0",
            label: "(Ausnahme: Massivtransfusion)",
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent={
          <>
            <Abbreviation>hb</Abbreviation> &lt; 7 g/dl{" "}
            <TextInfo>
              Es erfolgt keine unterschiedliche Betrachtung von weiblichen und
              männlichen Patient:innen aufgrund des geringeren Blutvolumens von
              weiblichen Patient:innen
            </TextInfo>
          </>
        }
        childBoxes={[
          {
            id: "trfb",
            label: "Unabhängig von Kompensationsfähigkeit",
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent={
          <>
            <Abbreviation>hb</Abbreviation> 7–8 g/dl
          </>
        }
        childBoxes={[
          {
            id: "hb7a",
            label:
              "Hinweise auf anämische Hypoxie (Tachykardie, Hypotension, EKG-Ischämie, Laktatazidose)",
            checked: false,
          },
          {
            id: "hb7b",
            label: (
              <>
                Kompensation eingeschränkt, Risikofaktoren vorhanden (
                <Abbreviation>khk</Abbreviation>, Herzinsuffizienz,{" "}
                <Abbreviation>pavk</Abbreviation>, zerebrovaskuläre
                Erkrankungen)
              </>
            ),
            checked: false,
          },
          {
            id: "hb7c",
            label: "Sonstige Indikationen",
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent="Hb 8–10 g/dl"
        childBoxes={[
          {
            id: "hb10a",
            label:
              "Hinweise auf anämische Hypoxie (Tachykardie, Hypotension, EKG-Ischämie, Laktatazidose). Die Transfusion bei Hb > 8 g/dl ist mit einem unklaren Nutzen-Risiko-Verhältnis verbunden (Indikation nur in Einzelfällen, sehr schwacher Empfehlungsgrad (2C))",
            checked: false,
          },
        ]}
      />
    </>
  );
}

export default TransfusionTriggerChecklist;
