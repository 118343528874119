import { SvgIcon, SvgIconProps } from "@mui/material";

export function ThermometerIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Icon Thermometer"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        data-name="Rechteck 601"
        style={{ fill: "none" }}
        d="M0 0h35v35H0z"
      />
      <g data-name="Gruppe 908">
        <path
          data-name="Pfad 436"
          d="M34.406 15.49a5.615 5.615 0 0 0-7.882 0l-.642.642-5.957 5.957-.5.55a5.589 5.589 0 0 0-1.65 3.941 4.328 4.328 0 0 1-1.329 3.162l-.962.962a2.569 2.569 0 0 0-.779 1.833 2.493 2.493 0 0 0 .779 1.833 2.569 2.569 0 0 0 1.833.779 2.493 2.493 0 0 0 1.833-.779l.962-.962a4.4 4.4 0 0 1 3.158-1.328 5.51 5.51 0 0 0 3.941-1.65l7.149-7.149a5.384 5.384 0 0 0 .046-7.791zM16.212 33.684a1.549 1.549 0 0 1 0-2.2l.6-.6 2.2 2.2-.6.6a1.577 1.577 0 0 1-2.2 0zm17.46-11.09-7.149 7.149a4.4 4.4 0 0 1-3.162 1.329 5.387 5.387 0 0 0-3.529 1.283l-2.246-2.246a5.569 5.569 0 0 0 1.283-3.529 4.328 4.328 0 0 1 1.331-3.162l.137-.137.962.962a.558.558 0 0 0 .733 0 .527.527 0 0 0 0-.779l-.962-.964 2.2-2.2.962.962a.558.558 0 0 0 .733 0 .527.527 0 0 0 0-.779l-.965-.96 2.2-2.2.962.962a.558.558 0 0 0 .733 0 .527.527 0 0 0 0-.779l-.962-.962.275-.275a4.481 4.481 0 0 1 6.37 0 4.4 4.4 0 0 1 .094 6.325z"
          transform="translate(-7.964 -7.517)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 437"
          d="M39.467 24.8 32 32.221A.527.527 0 0 0 32 33a.558.558 0 0 0 .733 0l7.424-7.424a.527.527 0 0 0 0-.779.429.429 0 0 0-.69.003z"
          transform="translate(-17.241 -13.341)"
          style={{ fill: "currentcolor" }}
        />
      </g>
    </SvgIcon>
  );
}

export default ThermometerIcon;
