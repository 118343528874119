import { SvgIcon, SvgIconProps } from "@mui/material";

export function BloodBagIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="41.244"
      height="55.118"
      viewBox="0 0 41.244 55.118"
    >
      <g
        id="Gruppe_1889"
        data-name="Gruppe 1889"
        transform="translate(-19 -17.121)"
      >
        <g
          id="Komponente_114_2"
          data-name="Komponente 114 – 2"
          transform="translate(19 17.121)"
        >
          <g
            id="Rechteck_1371"
            data-name="Rechteck 1371"
            fill="#fff"
            stroke="#0f60a7"
            strokeWidth="2"
          >
            <path
              d="M5,0H25a5,5,0,0,1,5,5V31.593a9,9,0,0,1-9,9H9a9,9,0,0,1-9-9V5A5,5,0,0,1,5,0Z"
              stroke="none"
            />
            <path
              d="M5,1H25a4,4,0,0,1,4,4V31.593a8,8,0,0,1-8,8H9a8,8,0,0,1-8-8V5A4,4,0,0,1,5,1Z"
              fill="none"
            />
          </g>
          <path
            id="Pfad_1921"
            data-name="Pfad 1921"
            d="M16430.066-5937.78a15.085,15.085,0,0,0,.656,6.932c1.25,3.246,4.32,6.229,12.473,3.9s11.418,1.358,11.729,4.66"
            transform="translate(-16414.924 5977.292)"
            fill="none"
            stroke="#0f60a7"
            strokeWidth="2.5"
          />
          <g
            id="Gruppe_1883"
            data-name="Gruppe 1883"
            transform="translate(6.217 13.854)"
          >
            <path
              id="Pfad_442"
              data-name="Pfad 442"
              d="M25.236,31.57a8.253,8.253,0,0,1-5.679-2.244,7.3,7.3,0,0,1,0-10.743l5.216-4.967a.628.628,0,0,1,.85,0l5.254,4.967a7.3,7.3,0,0,1,0,10.743A8.09,8.09,0,0,1,25.236,31.57Zm0-16.777-4.829,4.562a6.226,6.226,0,0,0,0,9.161,7.077,7.077,0,0,0,9.659,0,6.226,6.226,0,0,0,0-9.161Z"
              transform="translate(-17.2 -13.45)"
              fill="#0f60a7"
            />
            <g
              id="Pfad_1923"
              data-name="Pfad 1923"
              transform="translate(9.473 10.028)"
              fill="#fff"
            >
              <path
                d="M 4.04638671875 7.592776775360107 C 2.090896606445312 7.592776775360107 0.4999967217445374 6.001876831054688 0.4999967217445374 4.04638671875 C 0.4999967217445374 2.090896606445312 2.090896606445312 0.4999967217445374 4.04638671875 0.4999967217445374 C 6.001876831054688 0.4999967217445374 7.592776775360107 2.090896606445312 7.592776775360107 4.04638671875 C 7.592776775360107 6.001876831054688 6.001876831054688 7.592776775360107 4.04638671875 7.592776775360107 Z"
                stroke="none"
              />
              <path
                d="M 4.04638671875 0.9999966621398926 C 2.366596698760986 0.9999966621398926 0.9999966621398926 2.366596698760986 0.9999966621398926 4.04638671875 C 0.9999966621398926 5.726176738739014 2.366596698760986 7.092776775360107 4.04638671875 7.092776775360107 C 5.726176738739014 7.092776775360107 7.092776775360107 5.726176738739014 7.092776775360107 4.04638671875 C 7.092776775360107 2.366596698760986 5.726176738739014 0.9999966621398926 4.04638671875 0.9999966621398926 M 4.04638671875 -2.86102294921875e-06 C 6.281147003173828 -2.86102294921875e-06 8.092776298522949 1.81162691116333 8.092776298522949 4.04638671875 C 8.092776298522949 6.281147003173828 6.281147003173828 8.092776298522949 4.04638671875 8.092776298522949 C 1.81162691116333 8.092776298522949 -2.86102294921875e-06 6.281147003173828 -2.86102294921875e-06 4.04638671875 C -2.86102294921875e-06 1.81162691116333 1.81162691116333 -2.86102294921875e-06 4.04638671875 -2.86102294921875e-06 Z"
                stroke="none"
                fill="#0f60a7"
              />
            </g>
            <path
              id="Pfad_1918"
              data-name="Pfad 1918"
              d="M3.78,0H1.206"
              transform="translate(11.026 14.074)"
              fill="none"
              stroke="#0f60a7"
              strokeLinecap="round"
              strokeWidth="1"
            />
          </g>
          <line
            id="Linie_193"
            data-name="Linie 193"
            x2="27.691"
            transform="translate(0.809 8.379)"
            fill="none"
            stroke="#0f60a7"
            strokeWidth="2"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default BloodBagIcon;
