import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";

export function FnTherapy() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapie Chemotherapie-induzierte Neutropenie</h1>
        <ul>
          <li>
            Das alleinige Vorhandensein einer febrilen Neutropenie nach
            Tumortherapie rechtfertigt nicht die{" "}
            <Abbreviation>gcsf</Abbreviation>
            -Gabe.
          </li>
          <li>
            Für Patient:innen mit febriler Neutropenie liegen keine
            ausreichenden Daten eines klinischen Vorteils vor, die eine
            routinemäßige G-CSF-Therapie rechtfertigen.
          </li>
          <li>
            Die prophylaktische G-CSF-Gabe richtet sich nach dem Risiko, eine
            febrile Neutropenie entsprechend der verwendeten Chemotherapie und
            der individuellen Risikofaktoren zu entwickeln.{" "}
            <TextReference references={["ref2"]} />
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default FnTherapy;
