import {
  DragAndDropModeSwitch,
  FavoritesListElement,
  NavigationList,
  PagePadding,
} from "components/ui";
import { useContext, useState } from "react";
import { css } from "@mui/material";
import GlobalContext from "../context/globalContext";
import BreadCrumbNavigation from "../components/BreadCrumbNavigation";
import { getPagePrefixForPageScope } from "../util/pageHandlingHelpers";
import { useFavorites } from "../hooks/FavoriteHandling";

export function Favorites() {
  const styles = {
    headlineSegment: css`
      position: relative;
      margin-bottom: 23px;
    `,
    dragAndDropSwitch: css`
      position: absolute;
      top: 0;
      right: 0;
    `,
  };

  const { favorites, moveFavorites } = useContext(GlobalContext);
  const { removeFavoriteByFullPath } = useFavorites();
  const [dragAndDropIsActive, setDragAndDropIsActive] = useState(false);

  return (
    <PagePadding>
      <BreadCrumbNavigation />
      <div css={styles.headlineSegment}>
        <h1>Favoriten</h1>
        <div css={styles.dragAndDropSwitch}>
          <DragAndDropModeSwitch
            onClick={() => setDragAndDropIsActive(!dragAndDropIsActive)}
          />
        </div>
      </div>
      <NavigationList>
        {favorites.map((favorite, index) => (
          <FavoritesListElement
            key={favorite.fullPath}
            link={{
              label: getPagePrefixForPageScope(
                favorite.view.pageScope,
                "short"
              ).concat(" – ", favorite.view.title),
              isExternal: false,
              url: favorite.fullPath,
            }}
            fullPath={favorite.fullPath}
            description={favorite.view.description}
            listIndexPosition={index}
            moveElement={moveFavorites}
            dragAndDropModeIsAvtive={dragAndDropIsActive}
            onFavoriteToggleClick={() =>
              removeFavoriteByFullPath(favorite.fullPath)
            }
          />
          // <DragableWrapper
          //   key={favorite.fullPath}
          //   additionalStyles={cardStyles}
          //   index={index}
          //   id={favorite.fullPath}
          //   moveItem={moveFavorites}
          // >
          //   <NavigationListItem
          //     link={{
          //       label: getPagePrefixForPageScope(favorite.view.pageScope, "short").concat(" – ", favorite.view.title),
          //       isExternal: false,
          //       url: favorite.fullPath,
          //     }}
          //     description={favorite.view.description}
          //   />
          // </DragableWrapper>
        ))}
      </NavigationList>
    </PagePadding>
  );
}

export default Favorites;
