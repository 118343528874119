import {
  NavigationList,
  PagePadding,
  NavigationListItem,
  Link,
} from "components/ui";
import { ReactNode, useContext } from "react";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import HexalSeo from "../../components/HexalSeo";
import FnPraxisCasuisticsMorbusHodgkin from "./praxisCasuisticsMorbusHodgkin";
import FnPraxisCasuisticsIndolentLymphoma from "./praxisCasuisticsIndolentLymphoma";
import FnPraxisCasuisticsMammaCa from "./praxisCasuisticsMammaCa";
import GlobalContext from "../../context/globalContext";

export function FnPraxisCasuistics() {
  const { currentPage } = useContext(GlobalContext);
  const navigationItems: { link: Link; description: string | ReactNode }[] = [];

  const morbusHodgkin = currentPage?.children.find(
    (child) => child.view === FnPraxisCasuisticsMorbusHodgkin
  );
  if (morbusHodgkin) {
    navigationItems.push({
      link: {
        label: morbusHodgkin.title,
        isExternal: false,
        url: morbusHodgkin.path,
      },
      description: morbusHodgkin.description,
    });
  }

  const indolentLymphoma = currentPage?.children.find(
    (child) => child.view === FnPraxisCasuisticsIndolentLymphoma
  );
  if (indolentLymphoma) {
    navigationItems.push({
      link: {
        label: indolentLymphoma.title,
        isExternal: false,
        url: indolentLymphoma.path,
      },
      description: indolentLymphoma.description,
    });
  }

  const mammaCa = currentPage?.children.find(
    (child) => child.view === FnPraxisCasuisticsMammaCa
  );
  if (mammaCa) {
    navigationItems.push({
      link: {
        label: mammaCa.title,
        isExternal: false,
        url: mammaCa.path,
      },
      description: mammaCa.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Kasuistiken</h1>
        <p>
          <strong>Rolle der Supportivtherapie</strong>
        </p>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default FnPraxisCasuistics;
