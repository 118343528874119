import { useState } from "react";
import { Grid } from "@mui/material";
import {
  Alert,
  ArrowSeparator,
  ButtonGroup,
  ColoredText,
  DecisionTreeWrapper,
  DynamicSpacing,
  ResultBox,
  SeparatorWrapper,
} from "components/ui";

type SelectionState = {
  surgeryType: null | string;
  transfusion: null | string;
  ironStatus: null | string;
  hbStatus: null | string;
};

export function AnemiaManagementDecisionTree() {
  const initialState: SelectionState = {
    surgeryType: null,
    transfusion: null,
    ironStatus: null,
    hbStatus: null,
  };

  const [selected, setSelected] = useState<SelectionState>(initialState);

  const showResult = () => {
    console.log("Selected", selected);
    return (
      selected.surgeryType === "emergency" ||
      selected.transfusion === "low" ||
      selected.ironStatus === "low" ||
      selected.hbStatus !== null
    );
  };
  const requestTransfusion = () => selected.surgeryType === "planned";
  const requestIronStatus = () => selected.transfusion === "high";
  const showIronLowWorkflow = () => selected.ironStatus === "low";
  const requestHbStatus = () => selected.ironStatus === "ok";
  const showHbLowWorkflow = () => selected.hbStatus === "low";

  return (
    <DecisionTreeWrapper>
      <Grid container>
        <Grid item xs={12}>
          <ButtonGroup
            results={[
              { label: "Notfall", color: "primary", id: "emergency" },
              { label: "Geplante OP (<24 h)", color: "primary", id: "planned" },
            ]}
            onClick={(id) => {
              const resetTree = selected.surgeryType !== null;
              if (resetTree && selected.surgeryType === id) {
                setSelected(initialState);
              } else {
                setSelected((values) => ({
                  ...values,
                  surgeryType: id,
                  transfusion: null,
                  ironStatus: null,
                  hbStatus: null,
                }));
              }
            }}
          />
          {requestTransfusion() && (
            <>
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <p>
                <ColoredText color="primary">
                  Wahrscheinlichkeit einer Transfusion
                </ColoredText>
              </p>
              <ButtonGroup
                results={[
                  { label: "< 10 %", color: "primary", id: "low" },
                  { label: "≥ 10 %", color: "primary", id: "high" },
                ]}
                onClick={(id) => {
                  const resetTree = selected.transfusion !== null;
                  if (resetTree && selected.transfusion === id) {
                    setSelected((values) => ({
                      ...values,
                      transfusion: null,
                      ironStatus: null,
                      hbStatus: null,
                    }));
                  } else {
                    setSelected((values) => ({
                      ...values,
                      transfusion: id,
                      ironStatus: null,
                      hbStatus: null,
                    }));
                  }
                }}
              />
              {requestIronStatus() && (
                <>
                  <SeparatorWrapper>
                    <ArrowSeparator color="primary" />
                  </SeparatorWrapper>
                  <p>
                    <ColoredText color="primary">
                      Eisenstatus so bald wie möglich bestimmen
                    </ColoredText>
                  </p>
                  <Alert
                    content="Eisenmangel: Ferritin < 100 ng/ml oder Transferrinsättigung < 20 % oder Ferritin < 300 ng/ml falls HV/NI/Infl."
                    type="info"
                  />
                  <DynamicSpacing spacing={3} />
                  <ButtonGroup
                    results={[
                      { label: "Kein Eisenmangel", color: "primary", id: "ok" },
                      { label: "Eisenmangel", color: "primary", id: "low" },
                    ]}
                    onClick={(id) => {
                      const resetTree = selected.transfusion !== null;
                      if (resetTree && selected.transfusion === id) {
                        setSelected((values) => ({
                          ...values,
                          ironStatus: null,
                          hbStatus: null,
                        }));
                      } else {
                        setSelected((values) => ({
                          ...values,
                          ironStatus: id,
                          hbStatus: null,
                        }));
                      }
                    }}
                  />
                  {showIronLowWorkflow() && (
                    <>
                      <SeparatorWrapper>
                        <ArrowSeparator color="primary" />
                      </SeparatorWrapper>
                      <ResultBox
                        label="Eisen intravenös"
                        variant="small"
                        color="primary"
                      />
                    </>
                  )}
                  {requestHbStatus() && (
                    <>
                      <SeparatorWrapper>
                        <ArrowSeparator color="primary" />
                      </SeparatorWrapper>
                      <ButtonGroup
                        results={[
                          { label: "Hb <13 g/dl", color: "primary", id: "low" },
                          {
                            label: "Hb ≥13 g/dl",
                            color: "primary",
                            id: "high",
                          },
                        ]}
                        onClick={(id) => {
                          const resetTree = selected.hbStatus !== null;
                          if (resetTree && selected.hbStatus === id) {
                            setSelected((values) => ({
                              ...values,
                              hbStatus: null,
                            }));
                          } else {
                            setSelected((values) => ({
                              ...values,
                              hbStatus: id,
                            }));
                          }
                        }}
                      />
                      {showHbLowWorkflow() && (
                        <>
                          <SeparatorWrapper>
                            <ArrowSeparator color="primary" />
                          </SeparatorWrapper>
                          <ResultBox
                            label="Zusätzliche ärztliche Beratung"
                            variant="small"
                            color="primary"
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {showResult() && (
            <>
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ResultBox label="Operation" variant="large" color="warning" />
            </>
          )}
        </Grid>
      </Grid>
    </DecisionTreeWrapper>
  );
}

export default AnemiaManagementDecisionTree;
