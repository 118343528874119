import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";

export function FnPraxisPracticalTips() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Tipps für die Praxis</h1>
        <p>Do’s</p>
        <ul>
          <li>
            <span>
              Primärprophylaxe bei ≥ 20 % <Abbreviation>fn</Abbreviation>
              -Risiko und bei 10-20 % plus patientenspezifische
              Risikofaktor-Bewertung
            </span>
          </li>
          <li>
            <span>
              Evaluierung und Re-Evaluierung der patientenspezifischen
              Risikofaktoren und der Indikation der{" "}
              <Abbreviation>gcsf</Abbreviation>
              -Gabe bei jedem Zyklus
            </span>
          </li>
          <li>
            <span>
              Rasches Handeln bei Auftreten einer FN. Zeit ist Überleben!
            </span>
          </li>
          <li>
            <span>
              Ausführliche Aufklärung der Patient:innen über Risiko und
              Verhaltensmaßnahmen; u.a.
            </span>
            <ul>
              <li>Vermeiden von Menschenansammlungen während des Nadirs</li>
              <li>Menschen mit Erkältungen nicht treffen</li>
              <li>Regelmäßiges Temperaturmessen</li>
              <li>Bei Fieber sofort betreuende Ärzt:innen aufsuchen</li>
              <li>Abgekochte Nahrung zu sich nehmen</li>
              <li>Prüfung Grippeschutz/Corona-Impfung vor Therapiebeginn</li>
            </ul>
          </li>
        </ul>

        <p>Dont’s</p>
        <ul>
          <li>
            <span>
              Es gibt Therapien, die mit einer hohen Rate an Neutropenie
              einhergehen, wo jedoch kaum FN zu erwarten sind. Hier ist die Gabe
              von G-CSF nicht empfohlen{" "}
              <TextInfo>
                z. B. <Abbreviation>abvd</Abbreviation> bei Morbus Hodgkin, hier
                und soll die <Abbreviation>ctx</Abbreviation> am Tag 15 bei
                manifester Neutropenie ohne Dosisreduktion fortgesetzt werden
              </TextInfo>
            </span>
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default FnPraxisPracticalTips;
