import { Reference } from "components/ui";
import { ReactNode } from "react";

interface TextInfoProps {
  children: string | ReactNode;
  isInDarkElement?: boolean;
}

export function TextInfo(props: TextInfoProps) {
  const modalContent = (
    <>
      <p>
        <strong>Info:</strong>
      </p>
      {props.children}
    </>
  );
  return (
    <Reference
      darkMode={props.isInDarkElement}
      content={modalContent}
      type="info"
    />
  );
}

export default TextInfo;
