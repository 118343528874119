/** @jsxImportSource @emotion/react */

import { TextField } from "@mui/material";
import { ChangeEvent } from "react";
import { css } from "@emotion/react";

export interface InputFieldProps {
  label: string;
  value?: string;
  onChange?: (value: string) => void;
  type?: "password";
  name?: string;
  hasError?: boolean;
  autoComplete?: "on" | "off";
}
export function InputField(props: InputFieldProps) {
  const { autoComplete = "on" } = props;
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const styles = {
    root: css`
      width: 100%;
      border: solid 1px #82919a;
      border-radius: 11px;
      fieldset,
      .MuiFormLabel-root {
        color: #bdbdbd;
      }
    `,
  };

  return (
    <TextField
      css={styles.root}
      value={props.value}
      error={props.hasError}
      onChange={handleChange}
      variant={"outlined"}
      label={props.label}
      type={props.type}
      name={props.name}
      autoComplete={autoComplete}
    />
  );
}

export default InputField;
