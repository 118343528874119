import { SvgIcon, SvgIconProps } from "@mui/material";

export function ShieldIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <path
        data-name="Rechteck 608"
        style={{ fill: "none" }}
        d="M0 0h34v34H0z"
      />
      <g data-name="Gruppe 920">
        <path
          data-name="Pfad 443"
          d="M30.48 24.8a1.157 1.157 0 0 0-1.166 1.166v1.7h-1.748a1.157 1.157 0 0 0-1.166 1.169v2.107a1.157 1.157 0 0 0 1.166 1.166h1.7v1.7a1.157 1.157 0 0 0 1.166 1.166h2.107a1.157 1.157 0 0 0 1.166-1.166v-1.7h1.7a1.157 1.157 0 0 0 1.166-1.166v-2.107a1.21 1.21 0 0 0-1.121-1.166h-1.7v-1.7a1.157 1.157 0 0 0-1.163-1.169zm2.2 1.166v2.242a.545.545 0 0 0 .538.538h2.242c.045 0 .09.09.09.09v2.107c0 .09-.045.09-.09.09h-2.246a.545.545 0 0 0-.538.538v2.242c0 .09-.045.09-.09.09H30.48c-.09 0-.09-.045-.09-.09v-2.244a.545.545 0 0 0-.538-.538H27.61c-.09 0-.09-.045-.09-.09v-2.106c0-.09.045-.09.09-.09h2.29a.545.545 0 0 0 .538-.538v-2.241c0-.09.045-.09.09-.09h2.107c.041 0 .041 0 .041.09z"
          transform="translate(-14.565 -13.682)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 444"
          d="m35.315 16.021-9.5-3.721h-.4l-9.5 3.721a.436.436 0 0 0-.314.493c0 .314.09 7.173.314 9.952.224 3.542 6.949 8.921 9.549 8.921a.27.27 0 0 0 .179-.045c.045 0 .134.045.179.045 2.6 0 9.28-5.38 9.549-8.921.224-2.779.314-9.639.314-9.952a.524.524 0 0 0-.37-.493zm-1.031 10.356c-.224 3.093-6.635 7.935-8.473 7.935a.27.27 0 0 0-.179.045c-.045 0-.134-.045-.179-.045-1.838 0-8.294-4.842-8.473-7.935-.224-2.376-.314-8.025-.314-9.5l8.966-3.5 8.966 3.5c.002 1.475-.09 7.123-.314 9.5z"
          transform="translate(-8.596 -6.786)"
          style={{ fill: "currentcolor" }}
        />
      </g>
    </SvgIcon>
  );
}

export default ShieldIcon;
