import { SvgIcon, SvgIconProps } from "@mui/material";

export function ReferenceIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Referenz Icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path style={{ fill: "none" }} d="M0 0h20v20H0z" />
      <path
        data-name="↳Color"
        d="M7.992 16A8 8 0 1 1 16 8a8.005 8.005 0 0 1-8.008 8zM8 1.6A6.4 6.4 0 1 0 14.4 8 6.407 6.407 0 0 0 8 1.6z"
        transform="translate(2 2)"
        style={{ fill: "inherit" }}
      />
      <path
        data-name="Pfad 1910"
        d="M2.739 9.84.232 9.093.614 7.84l2.507.93-.075-2.856h1.27l-.083 2.9L6.7 7.9l.381 1.26-2.548.755 1.644 2.25-1.029.78L3.6 10.554l-1.492 2.333-1.038-.755z"
        transform="translate(6.344 .571)"
        style={{ fill: "inherit" }}
      />
    </SvgIcon>
  );
}

export default ReferenceIcon;
