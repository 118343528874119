import { BasicLink, IconButton } from "components/ui";

export interface ProfessionalInformationButtonProps {
  color?: "primary" | "warning" | "success" | "error";
  url: string;
  label?: string;
}

export function ProfessionalInformationButton(
  props: ProfessionalInformationButtonProps
) {
  const { label = "Zur Fachinformation" } = props;
  return (
    <BasicLink
      disableExternalLinkModal
      link={{
        url: props.url,
        isExternal: true,
        title: "Zur Fachinformation",
        label,
      }}
    >
      <IconButton label={label} color={props.color} />
    </BasicLink>
  );
}

export default ProfessionalInformationButton;
