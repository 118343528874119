import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";

export function MdsDisease() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Definition und Symptome</h1>
        <h2>Definition</h2>
        <h3>
          Myelodysplastisches Syndrom <TextReference references={["ref1"]} />
        </h3>
        <ul>
          <li>Klonale Erkrankungen der hämatopoetischen Stammzelle</li>
          <li>
            Dysplasien von Blut- und Knochenmarkzellen mit hämatopoetischer
            Insuffizienz
          </li>
          <li>
            Störung der Interaktion zwischen Stammzellen und Stroma/Nische
          </li>
          <li>In bis zu 100 % zyto- und/oder molekulargenetische Anomalien</li>
          <li>
            Verbunden mit erhöhtem Risiko einer akuten myeloischen Leukämie (
            <Abbreviation>aml</Abbreviation>)
          </li>
          <li>
            kann Chemo- und/oder Strahlentherapie-assoziiert sein (ca. 10 %)
          </li>
        </ul>
        <h2>Symptome</h2>
        <p>
          Anämie ist mit einem Auftreten in 70-80 % der Fälle die häufigste
          Erstmanifestation eines <Abbreviation>mds</Abbreviation>.
        </p>
        <h3>Typische Symptome der Anämie</h3>
        <ul>
          <li>Dyspnoe, insbesondere bei Belastung</li>
          <li>Körperliche Schwäche</li>
          <li>Herzrasen</li>
          <li>Kopfschmerzen</li>
          <li>
            Verstärkung einer bestehenden Herz- oder zerebrovaskulären
            Insuffizienz oder einer koronaren Herzerkrankung
          </li>
          <li>
            Allgemeine Blässe (<Abbreviation>hb</Abbreviation> &lt; 9g/dl),
            Blässe der Schleimhäute (Hb meist unter 10 g/dl) und des Nagelbettes
            (Hb meist unter 8 g/dl)
          </li>
          <li>
            Unspezifische Beschwerden wie Appetitlosigkeit, gastrointestinale
            Beschwerden und Fatigue
          </li>
          <li>
            Bei rascher Entwicklung der Anämie mögliche Sehstörungen bzw.
            Verwirrungszustände
            <TextReference references={["ref1"]} />
          </li>
          <li>Schlafstörungen</li>
          <li>Konzentrationsprobleme</li>
          <li>Depression</li>
        </ul>
      </PagePadding>
    </>
  );
}

export default MdsDisease;
