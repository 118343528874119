/** @jsxImportSource @emotion/react */

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import { useState } from "react";
import SignedNumber from "../SignedNumber/SignedNumber";

export interface ScoreButtonGroupProps {
  scores: { id: string; label: string; value?: string | number }[];
  outlined?: boolean;
  onChange?: (id: string) => void;
  singleSelect?: boolean;
}

export function ScoreButtonGroup(props: ScoreButtonGroupProps) {
  const theme = useTheme();
  const [activeScore, setActiveScore] = useState<string[] | false>(false);

  const handleClick = (id: string) => {
    if (props.singleSelect) {
      handleSingleSelectClick(id);
    } else {
      handleMultiSelectClick(id);
    }
    if (props.onChange) {
      props.onChange(id);
    }
  };

  const handleMultiSelectClick = (id: string) => {
    if (!activeScore) {
      setActiveScore([id]);
    } else if (activeScore.includes(id) && activeScore.length === 1) {
      setActiveScore(false);
    } else if (activeScore.includes(id)) {
      setActiveScore(activeScore.filter((score) => score !== id));
    } else {
      setActiveScore([id, ...activeScore]);
    }
  };

  const handleSingleSelectClick = (id: string) => {
    if (!activeScore) {
      setActiveScore([id]);
    } else if (activeScore.includes(id)) {
      setActiveScore(false);
    } else {
      setActiveScore([id]);
    }
  };

  const styles = {
    root: css`
      border: ${props.outlined
        ? `1px solid ${theme.palette.primary.main}`
        : "none"};
      border-bottom: none;
      border-radius: 7px;
    `,
    cell: css`
      padding-bottom: 6px;
      padding-top: 6px;
      padding-left: 12px;
      padding-right: 12px;
      border-bottom: ${theme.palette.primary.main} 1px solid;
      color: inherit;
    `,
    text: css`
      color: inherit;
    `,
    active: css`
      background-color: ${theme.palette.hxBackground.primary};
      color: ${theme.palette.primary.main};
      cursor: pointer;
    `,
    inActive: css`
      background-color: transparent;
      color: #82919a;
      cursor: ${props.onChange ? "pointer" : "initial"};
    `,
  };

  return (
    <TableContainer css={styles.root}>
      <Table>
        <TableBody>
          {props.scores.map((score) => (
            <TableRow
              key={score.id}
              css={
                props.onChange && activeScore && activeScore.includes(score.id)
                  ? styles.active
                  : styles.inActive
              }
              onClick={() => handleClick(score.id)}
            >
              <TableCell css={styles.cell} align="left">
                <Typography css={styles.text} variant="body1">
                  {score.label}
                </Typography>
              </TableCell>
              {(score.value || score.value === 0) && (
                <TableCell css={styles.cell} align="right">
                  <Typography css={styles.text} variant="body1">
                    <SignedNumber>{score.value as number}</SignedNumber>
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ScoreButtonGroup;
