import { Alert, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import ProfessionalInformationButton from "../../components/ProfessionalInformationButton";
import { resources } from "../../data/resources";

export function FnProphylaxisDosage() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Dosierung Filgrastim HEXAL
          <sup>®</sup> und ZIEXTENZO
          <sup>®</sup> (Pegfilgrastim)
        </h1>
        <h2>
          Filgrastim HEXAL
          <sup>®</sup> (kurzwirksames G-CSF)
        </h2>
        <h3>Dosierung bei konventioneller Chemotherapie:</h3>
        <p>
          Empfohlene Dosis: 0,5 Mio. E./kg/Tag (5 μg/kg/Tag) von Tag 2-5{" "}
          <Abbreviation>ctx</Abbreviation>, 1x tgl. s.c. bis zur absoluten
          Neutrophilenzahl &gt;2-3 x 10<sup>9</sup>/l{" "}
          <TextReference references={["ref42"]} />
        </p>
        <ul>
          <li>
            Nach konventioneller <Abbreviation>ctx</Abbreviation> solider
            Tumore, Lymphome und lymphatischer Leukämie kann mit einer
            Behandlungsdauer von bis zu 14 Tagen bis zum Erreichen dieser
            Kriterien gerechnet werden.
          </li>
          <li>
            Nach Induktions- und Konsolidierungstherapie einer{" "}
            <Abbreviation>aml</Abbreviation> kann die Behandlungsdauer erheblich
            länger sein (bis zu 38 Tage).
            <TextReference references={["ref8"]} />
          </li>
        </ul>
        <Alert
          content={
            <>
              <span>CAVE:</span>
              <br />
              <span>
                Bei Patient:innen mit CTx tritt typischerweise eine
                vorübergehende Steigerung der Anzahl neutrophiler Granulozyten
                1-2 Tage nach Beginn der Therapie auf. Für einen anhaltenden
                Therapieerfolg sollte Filgrastim HEXAL
                <sup>®</sup> jedoch nicht abgesetzt werden, bevor der Nadir
                überwunden ist. <TextReference references={["ref8"]} />
              </span>
            </>
          }
          type="warning"
        />

        <p>
          Link zu Fachinfo Filgrastim HEXAL<sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.filgrastim.url}
          />
        </p>

        <h2>
          ZIEXTENZO
          <sup>®</sup> (Pegfilgrastim – langwirksames G-CSF)
        </h2>
        <p>Empfohlene Dosis:</p>
        <ul>
          <li>Pro CTx-Zyklus 6 mg s.c.</li>
          <li>
            frühestens 24 Stunden nach CTx
            <TextReference references={["ref9"]} />
          </li>
        </ul>
        <p>
          Link zu Fachinfo ZIEXTENZO
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.ziextenzo.url}
          />
        </p>
      </PagePadding>
    </>
  );
}

export default FnProphylaxisDosage;
