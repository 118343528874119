import { Image, PagePadding, Video } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";

import * as preparationImg from "../../assets/img/fn/application/illustration-vorbereitung.svg";
import * as locationImg from "../../assets/img/fn/application/injektionsstelle.svg";
import * as application01Img from "../../assets/img/fn/application/illustration-anwendung-01.svg";
import * as application02Img from "../../assets/img/fn/application/illustration-anwendung-02.svg";
import * as application03Img from "../../assets/img/fn/application/illustration-anwendung-03.svg";
import { resources } from "../../data/resources";

export function FnProphylaxisApplication() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Anwendung Fertigspritze Filgrastim HEXAL
          <sup>®</sup> und ZIEXTENZO
          <sup>®</sup> (Pegfilgrastim)
        </h1>
        <h2>Schritt 1: Vorbereitungen</h2>
        <Image
          image={{
            path: preparationImg.default,
            alt: "Vorbereitung Fertigspritze",
          }}
        />
        <ol>
          <li>
            Entnehmen Sie der Packung aus dem Kühlschrank eine Fertigspritze,
            ohne sie zu schütteln.
          </li>
          <li>
            Lassen Sie die Fertigspritze bei Raumtemperatur für circa 30 Minuten
            liegen, damit die Injektion angenehmer wird. Erwärmen Sie die
            Fertigspritze nicht auf eine andere Weise (z. B. nicht in der
            Mikrowelle oder in heißem Wasser).
          </li>
          <li>Waschen Sie sich gründlich die Hände.</li>
        </ol>

        <h2>Schritt 2: Wo sollte die Injektion erfolgen?</h2>
        <Image
          image={{
            path: locationImg.default,
            alt: "Injektionsstellen",
          }}
        />
        <ol>
          <li>
            <span>
              Am besten geeignet für eine Selbstinjektion sind die Oberseiten
              der Oberschenkel <strong>A</strong> und der Unterbauch{" "}
              <strong>B</strong>, mit Ausnahme des Bereichs um den Nabel herum.
            </span>
          </li>
          <li>
            Wechseln Sie jedes Mal die Injektionsstelle, um zu verhindern, dass
            in einem Bereich Schmerzen auftreten.
          </li>
          <li>
            Wenn Sie die Spritze geben, kann die Injektion auch auf der
            Rückseite der Arme erfolgen.
          </li>
        </ol>

        <h2>Schritt 3: Wie geben Sie die Injektion?</h2>
        <Image
          image={{
            path: application01Img.default,
            alt: "Anwendung Fertigspritze I",
          }}
        />
        <Image
          image={{
            path: application02Img.default,
            alt: "Anwendung Fertigspritze II",
          }}
        />
        <Image
          image={{
            path: application03Img.default,
            alt: "Fertigspritze",
          }}
        />
        <ol>
          <li>
            Desinfizieren Sie die Injektionsstelle (z. B. mit einem
            Alkoholtupfer).
          </li>
          <li>Entfernen Sie vorsichtig die Nadelschutzkappe von der Nadel.</li>
          <li>
            Halten Sie die Haut zwischen Daumen und Zeigefinger fest, ohne sie
            zu quetschen.
          </li>
          <li>
            <span>
              Stechen Sie die Nadel in einem Winkel von circa 45 Grad
              vollständig unter die Haut <strong>C</strong>.
            </span>
          </li>
          <li>
            Drücken Sie den Kolben langsam und vollständig herab. Die Spritze
            muss restlos geleert sein.
          </li>
          <li>
            <span>
              Ziehen Sie die Nadel bei gedrücktem Kolben vorsichtig heraus. Erst
              danach die Hautfalte loslassen <strong>D</strong>.
            </span>
          </li>
          <li>
            <span>
              Lassen Sie nun den Kolben los <strong>E</strong>. Das
              Nadelschutzsystem schiebt sich automatisch über die Nadel{" "}
              <strong>F</strong>.
            </span>
          </li>
          <li>
            <span>
              Entsorgen Sie gebrauchte Spritzen in einem Behälter für spitze
              Gegenstände (verschließbarer, durchstichsicherer Behälter). Wenn
              Sie weitere Fragen zur Entsorgung haben, wenden Sie sich bitte an
              Ihre/n Apotheker/in.{" "}
              <TextReference references={["ref8", "ref9"]} />
            </span>
          </li>
        </ol>

        <h2>Anwendungsvideo zur Fertigspritze</h2>
        <Video url={resources.videos.applicationZiextenzoFilgrastim.url} />
        <p>Hinweis: Das Video ist nur online abrufbar</p>
      </PagePadding>
    </>
  );
}
