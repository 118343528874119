import { TableBody } from "@mui/material";
import {
  Alert,
  ArrowSeparator,
  DynamicSpacing,
  PagePadding,
  SeparatorWrapper,
  Table,
  TableRow,
  TextBox,
} from "components/ui";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import { resources } from "../../data/resources";
import ProfessionalInformationButton from "../../components/ProfessionalInformationButton";

export function MdsTherapyLowRiskImmunomodulatorsLenalidomidDosage() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Dosierung Lenalidomid HEXAL
          <sup>®</sup>
        </h1>
        <p>
          Die Behandlung mit Lenalidomid HEXAL
          <sup>®</sup> darf nicht begonnen werden, wenn die{" "}
          <Abbreviation>anc</Abbreviation> &lt; 0,5 x 10
          <sup>9</sup>
          /l und/oder die Zahl der Thrombozyten &lt; 25 x 10
          <sup>9</sup>
          /l ist. <TextReference references={["ref4"]} />
        </p>

        <Alert
          content={
            <>
              <span>CAVE:</span>
              <br />
              <span>
                Lenalidomid ist strukturverwandt zu Thalidomid. Thalidomid ist
                eine bekanntermaßen beim Menschen teratogen wirkende Substanz,
                die schwere, lebensbedrohliche Fehlbildungen verursacht. Die
                durch Lenalidomid in Affen verursachten Fehlbildungen sind
                vergleichbar mit denen, die für Thalidomid beschrieben sind.
                Wenn Lenalidomid während der Schwangerschaft eingenommen wird,
                ist beim Menschen ein teratogener Effekt von Lenalidomid zu
                erwarten.
              </span>
            </>
          }
          type={"warning"}
        />
        <p>
          <ProfessionalInformationButton
            url={resources.documents.lenalidomid.url}
          />
        </p>

        <h2>
          Empfohlene Dosierung <TextReference references={["ref4"]} />
        </h2>
        <Table>
          <TableBody>
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Initialdosis",
                "10 mg 1x täglich an den Tagen 1-21 jedes 28-Tage-Zyklus",
              ]}
            />
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Dosisstufe 1",
                "5 mg 1x täglich an den Tagen 1-28 jedes 28-Tage-Zyklus",
              ]}
            />
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Dosisstufe 2",
                "2,5 mg 1x täglich an den Tagen 1-28 jedes 28-Tage-Zyklus",
              ]}
            />
            <TableRow
              alignCells={["left", "right"]}
              entries={[
                "Dosisstufe 3",
                "2,5 mg jeden zweiten Tag an den Tagen 1-28 jedes 28-Tage-Zyklus",
              ]}
            />
          </TableBody>
        </Table>

        <h2>
          Empfohlene Vorgehensweise bei Veränderung bestimmter Blutparameter
        </h2>

        <h3>Thrombozytopenie</h3>
        <TextBox title="Beobachtete Veränderung" color="primary">
          Abfall der Thrombozytenzahl auf &lt; 25 x 109 g/l
        </TextBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <TextBox title="Vorgehen" color="error">
          Unterbrechung der Lenalidomid-Behandlung
        </TextBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <TextBox title="Beobachtete Veränderung" color="primary">
          Wiederanstieg der Thrombozytenzahl auf ≥ 25 x 10<sup>9</sup> g/l bis
          &lt; 50 x 10<sup>9</sup> g/l bei mindestens 2 Messungen für ≥ 7 Tage
          oder Wiederanstieg der Thrombozytenzahl irgendwann auf ≥ 50 x 10
          <sup>9</sup> g/l
        </TextBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <TextBox title="Vorgehen" color="warning">
          Fortsetzung von Lenalidomid auf der nächstniedrigeren Dosisstufe
          (Dosisstufe –1, –2 oder –3)
        </TextBox>

        <DynamicSpacing spacing={5} />
        <h3>Neutropenie</h3>
        <TextBox title="Beobachtete Veränderung" color="primary">
          Abfall der absoluten Neutrophilenzahl (ANC) auf &lt; 0,5 x 10
          <sup>9</sup> g/l
        </TextBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <TextBox title="Vorgehen" color="error">
          Unterbrechung der Lenalidomid-Behandlung
        </TextBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <TextBox title="Beobachtete Veränderung" color="primary">
          Wiederanstieg der ANC auf ≥ 0,5 x 10<sup>9</sup> g/l
        </TextBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <TextBox title="Vorgehen" color="warning">
          Fortsetzung von Lenalidomid auf der nächstniedrigeren Dosisstufe
          (Dosisstufe –1, –2 oder –3)
        </TextBox>

        <h2>Absetzen der Lenalidomid-Behandlung bei</h2>
        <Alert
          content={
            <ul>
              <li>
                Patient:innen, die innerhalb von 4 Monaten nach Therapiebeginn
                nicht zumindest ein geringfügiges erythrozytäres Ansprechen in
                Form einer mindestens 50%igen Abnahme des Transfusionsbedarfs
                zeigen
              </li>
              <li>
                Patient:innen ohne Transfusionen, die keinen Hämoglobin-Anstieg
                von 1 g/dl zeigen
              </li>
            </ul>
          }
          type="info"
        />
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskImmunomodulatorsLenalidomidDosage;
