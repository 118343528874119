import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import MdsDiagnosticMds from "./diagnosticMds";
import MdsDiagnosticClassification from "./diagnosticClassification";
import MdsDiagnosticPrognostics from "./diagnosticPrognostics";

export function MdsDiagnostics() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const diagnosticsMds = currentPage?.children.find(
    (child) => child.view === MdsDiagnosticMds
  );
  if (diagnosticsMds) {
    navigationItems.push({
      link: {
        label: diagnosticsMds.title,
        isExternal: false,
        url: diagnosticsMds.path,
      },
      description: diagnosticsMds.description,
    });
  }

  const classificationMds = currentPage?.children.find(
    (child) => child.view === MdsDiagnosticClassification
  );
  if (classificationMds) {
    navigationItems.push({
      link: {
        label: classificationMds.title,
        isExternal: false,
        url: classificationMds.path,
      },
      description: classificationMds.description,
    });
  }

  const prognostics = currentPage?.children.find(
    (child) => child.view === MdsDiagnosticPrognostics
  );
  if (prognostics) {
    navigationItems.push({
      link: {
        label: prognostics.title,
        isExternal: false,
        url: prognostics.path,
      },
      description: prognostics.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik/Prognose</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsDiagnostics;
