import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import { MdsTherapyLowRiskImmunomodulatorsLenalidomidTherapy } from "./therapyLowRiskImmunomodulatorsLenalidomidTherapy";
import MdsTherapyLowRiskImmunomodulatorsLenalidomidDosage from "./therapyLowRiskImmunomodulatorsLenalidomidDosage";

export function MdsTherapyLowRiskImmunomodulators() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const therapy = currentPage?.children.find(
    (child) =>
      child.view === MdsTherapyLowRiskImmunomodulatorsLenalidomidTherapy
  );
  if (therapy) {
    navigationItems.push({
      link: {
        label: therapy.title,
        isExternal: false,
        url: therapy.path,
      },
      description: therapy.description,
    });
  }

  const dosage = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskImmunomodulatorsLenalidomidDosage
  );
  if (dosage) {
    navigationItems.push({
      link: {
        label: dosage.title,
        isExternal: false,
        url: dosage.path,
      },
      description: dosage.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapie Immunmodulatoren</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskImmunomodulators;
