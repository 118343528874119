import { CalculationBox, DynamicSpacing, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import DosageDeferasiroxDecisionTree from "../../components/DecisionTrees/DosageDeferasiroxDecisionTree";

export function MdsTherapyIronOverloadDeferasiroxDosage() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Dosierung Deferasirox HEXAL
          <sup>®</sup>
        </h1>
        <p>Initiale Tagesdosis</p>
        <ul>
          <li>
            7 mg/kg <Abbreviation>kg</Abbreviation> bei ca. &lt; 2 Einheiten{" "}
            <Abbreviation>ek</Abbreviation>
            /Monat
          </li>
          <li>14 mg/kg KG bei ca. 2-4 Einheiten EK/Monat</li>
          <li>21 mg/kg KG bei ca. &gt; 4 Einheiten EK/Monat</li>
        </ul>
        <p>
          Es wird empfohlen, das Serumferritin einmal monatlich zu bestimmen und
          die Deferasirox HEXAL<sup>®</sup>-Dosis, sofern erforderlich, alle 3
          bis 6 Monate anzupassen. <TextReference references={["ref12"]} />
          Klicken Sie auf die Serumferritinkonzentration. Anschließend wird
          Ihnen angezeigt, welche Dosisanpassung in der aktuellen Deferasirox
          HEXAL<sup>®</sup> Fachinformation empfohlen wird.{" "}
          <TextReference references={["ref12"]} />
        </p>
        <DosageDeferasiroxDecisionTree />

        <h2>Beispielberechnung der individuellen Tagesdosis</h2>
        <CalculationBox
          title="Beispiel A:"
          left={{
            title: "Körpergewicht",
            content: "75 kg",
          }}
          right={{
            title: "Dosis",
            content: "14 mg/kg/Tag",
          }}
          description={{
            title: "Gesamtdosis = 1050 mg/Tag",
            content: (
              <ul>
                <li>3 x 360 mg Tabletten pro Tag</li>
              </ul>
            ),
          }}
        />
        <DynamicSpacing spacing={2} />
        <CalculationBox
          title="Beispiel B:"
          left={{
            title: "Körpergewicht",
            content: "55 kg",
          }}
          right={{
            title: "Dosis",
            content: "14 mg/kg/Tag",
          }}
          description={{
            title: "Gesamtdosis = 770 mg/Tag",
            content: (
              <ul>
                <li>2 x 360 mg Tabletten pro Tag</li>
                <li>1 x 90 mg Tabletten pro Tag</li>
              </ul>
            ),
          }}
        />

        <h2>Therapiedauer</h2>
        <ul>
          <li>
            Einsatz bis zum Erreichen von Lebereisenkonzentration &lt; 3 mg{" "}
            <Abbreviation>fe</Abbreviation>
            /g <Abbreviation>tg</Abbreviation> gemessen mittels speziellem MRT
            <br />
            oder Serumferritin &lt; 300 μg/l{" "}
            <TextReference references={["ref12"]} />
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default MdsTherapyIronOverloadDeferasiroxDosage;
