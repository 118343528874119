/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import {
  BottomNavigationAction,
  BottomNavigation as MuiBottomNavigation,
} from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { css } from "@emotion/react";
import { Link } from "../ts/link";
import BasicLink from "../BasicLink/BasicLink";
import { useAuth } from "../../../../hooks/Authentication";
/* eslint-disable-next-line */
export interface BottomNavigationProps {
  favoritesLink: Link;
  homeButtonLink: Link;
}

const styles = {
  wrapper: css`
    height: 70px;
  `,
  bottomNavigation: css``,
};

const StyledBottomNavigation = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 0;
  width: 100%;
  left: 0;
  .MuiBottomNavigation-root {
    background-color: ${({ theme }) => theme.palette.hxBackground.primary};
  }
  .MuiBottomNavigationAction-root {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export function BottomNavigation(props: BottomNavigationProps) {
  const { authed } = useAuth();

  return (
    <div css={styles.wrapper}>
      <StyledBottomNavigation>
        <MuiBottomNavigation>
          <BottomNavigationAction
            icon={
              <BasicLink link={props.homeButtonLink}>
                <HomeOutlinedIcon />
              </BasicLink>
            }
          />
          {authed && (
            <BottomNavigationAction
              icon={
                <BasicLink link={props.favoritesLink}>
                  <StarBorderOutlinedIcon />
                </BasicLink>
              }
            />
          )}
          <BottomNavigationAction icon={<MoreHorizOutlinedIcon />} />
        </MuiBottomNavigation>
      </StyledBottomNavigation>
    </div>
  );
}

export default BottomNavigation;
