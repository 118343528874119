import { PagePadding, Table, TableBody, TableRow } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import AnemiaCheckList from "../../components/Checklists/AnemiaCheckList";
import TextInfo from "../../components/TextInfo";
import Abbreviation from "../../components/Abbreviation";

export function CiaDiagnosticAnemia() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik</h1>

        <ul>
          <li>
            Wichtige Basis für die Therapie ist die gezielte Anamnese und
            Diagnostik zur Differenzierung der Anämie{" "}
            <TextInfo>
              Hinweis: aufgrund der schlechten Prognose erfordert die Diagnose
              Anämie durch <Abbreviation>mnpct</Abbreviation> eine dringende
              Einleitung von therapeutischen Maßnahmen, z.B. eine allogene{" "}
              <Abbreviation>hszt</Abbreviation>. Somit hat ein Ausschluss von
              Mn-PCT eine große Bedeutung
            </TextInfo>
          </li>
          <li>
            Bei Tumorpatient:innen können mehrere Störungen gleichzeitig
            vorliegen
          </li>
          <li>
            Je nach diagnostischem Befund müssen die entsprechenden Ursachen
            differenziert behandelt werden{" "}
            <TextReference references={["ref1"]} />
          </li>
          <li>Bei symptomatischer Anämie ist eine Behandlung indiziert</li>
          <li>Symptomatik kann durch Komorbiditäten verstärkt werden</li>
        </ul>

        <h2>
          Diagnostik der Anämie <TextReference references={["ref1"]} />
        </h2>
        <p>
          Für die Diagnostik der Anämie kommen zahlreiche Parameter in Frage.
          Nachfolgend finden Sie eine Auflistung aus der S3-Leitlinie Supportive
          Therapie. <TextReference references={["ref1"]} /> Per Klick auf eine
          Kategorie (z.B. Basis-Labordiagnostik) öffnen sich die dazugehörigen
          Parameter. Die Ankreuzfunktion kann Ihnen als Hilfestellung dienen,
          falls Sie bspw. für eine/n bestimmte/n Patient:in die durchgeführten
          Diagnosen markieren wollen.
        </p>

        <AnemiaCheckList />

        <h3>Berechnung des Retikulozytenproduktionsindex:</h3>
        <p>
          Der RPI kann mit folgender Formel näherungsweise berechnet werden:
        </p>
        <p>
          RPI = (Retikulozytenzahl (%) x tatsächlicher [Hk] (l/l)) /
          (Reifungszeit d x 0,45 (l/l))
        </p>
        <p>
          Der Wert 0,45 steht für den &#34;idealen&#34; Hämatokrit. Als Wert für{" "}
          &lt;d&gt; ist dabei einzusetzen:
        </p>
        <Table>
          <TableBody>
            <TableRow
              alignCells={["left", "right"]}
              entries={["Hämatokrit (l/l): ", "Reifungszeit (d):"]}
            />
            <TableRow alignCells={["left", "right"]} entries={["0,45", "1"]} />
            <TableRow
              alignCells={["left", "right"]}
              entries={["0,35", "1,5"]}
            />
            <TableRow alignCells={["left", "right"]} entries={["0,25", "2"]} />
            <TableRow
              alignCells={["left", "right"]}
              entries={["0,15", "2,5"]}
            />
          </TableBody>
        </Table>
      </PagePadding>
      <p>Interpretation:</p>
      <p>
        Bei normaler Regeneration ohne Anämie, liegt der RPI bei etwa 1. Bei
        einer Anämie dient der RPI der funktionellen Einteilung:
      </p>
      <ul>
        <li>
          RPI ≥ 2: Anämie mit adäquater Regeneration (bzw. normaler Erythropoese
        </li>
        <li>
          RPI &lt; 2: Anämie mit inadäquater Regeneration (bzw.
          erythropoetischer Insuffizienz).
        </li>
      </ul>
    </>
  );
}

export default CiaDiagnosticAnemia;
