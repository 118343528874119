import { CheckList, TextLink } from "components/ui";
import Abbreviation from "../Abbreviation";
import TextInfo from "../TextInfo";
import { getInternalLinkForPage } from "../../util/pageHandlingHelpers";
import MdsDiagnosticMds from "../../views/mds/diagnosticMds";

export function AnemiaCheckList() {
  const linkMdsDiagnostics = getInternalLinkForPage(
    MdsDiagnosticMds,
    "de",
    "Diagnostik MDS zum Nachweis einer klonalen myeloischen Neoplasie"
  );
  return (
    <>
      <CheckList
        labelParent="Ausschluss zusätzlicher Ursachen der Anämie, klinische Untersuchung"
        childBoxes={[
          {
            id: "addC1",
            label: "Absoluter und funktioneller Eisenmangel",
            checked: false,
          },
          {
            id: "addC2",
            label: "Blutungen",
            checked: false,
          },
          {
            id: "addC3",
            label: "Vitamin B12 (Cobalamin)- und Folsäuremangel",
            checked: false,
          },
          {
            id: "addC4",
            label: "Hämolyse",
            checked: false,
          },
          {
            id: "addC5",
            label: "Nierenfunktionsstörung ",
            checked: false,
          },
          {
            id: "addC6",
            label: (
              <>
                Hämatologische Systemerkrankung, z.B.{" "}
                <Abbreviation>mnpct</Abbreviation>{" "}
                <TextInfo>
                  siehe <TextLink link={linkMdsDiagnostics} />
                </TextInfo>
              </>
            ),
            checked: false,
          },
          {
            id: "addC7",
            label: (
              <>
                Sonstige internistische Erkrankung{" "}
                <TextInfo>
                  Infektion, chronische entzündliche Erkrankung
                </TextInfo>
              </>
            ),
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent="Basis-Labordiagnostik"
        childBoxes={[
          {
            id: "lab1",
            label: (
              <>
                Differentialblutbild mit <Abbreviation>hb</Abbreviation>,{" "}
                <Abbreviation>hk</Abbreviation>,{" "}
                <Abbreviation>mcv</Abbreviation>,{" "}
                <Abbreviation>mch</Abbreviation>, quantitative Retikulozytenzahl
              </>
            ),
            checked: false,
          },
          {
            id: "lab2",
            label: "Eisenstatus: Ferritin, Transferrin, Transferrinsättigung",
            checked: false,
          },
          {
            id: "lab3",
            label: "Holo-Trans-Cobalamin (Vitamin B12), Folsäure",
            checked: false,
          },
          {
            id: "lab4",
            label: (
              <>
                Entzündungsparameter: <Abbreviation>bsg</Abbreviation>,{" "}
                Fibrinogen, <Abbreviation>crp</Abbreviation>,{" "}
                <Abbreviation>ldh</Abbreviation>
              </>
            ),
            checked: false,
          },
          {
            id: "lab5",
            label:
              "Routinelabor mit Leber- und Nierenfunktionsparametern: Bilirubin, Transaminasen, Albumin, Kreatinin",
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent="Ergänzende Labordiagnostik"
        childBoxes={[
          {
            id: "addLab1",
            label: "Erythropoetinspiegel, hypochrome Erythrozyten",
            checked: false,
          },
          // {
          //   id: "addLab2",
          //   label: (
          //     <>
          //       <Abbreviation>chr</Abbreviation>,{" "}
          //       <Abbreviation>zpp</Abbreviation>,{" "}
          //       <Abbreviation>stfr</Abbreviation>
          //     </>
          //   ),
          //   checked: false,
          // },
          {
            id: "addLab3",
            label: (
              <>
                Hämolyseparameter: <Abbreviation>ldh</Abbreviation>,
                Haptoglobin, Coombs-Test
              </>
            ),
            checked: false,
          },
          {
            id: "addLab4",
            label: (
              <>
                Blutungsdiagnostik: Thromboplastinzeit (Quick,{" "}
                <Abbreviation>inr</Abbreviation>), Stuhl auf Blut, Urinstatus
              </>
            ),
            checked: false,
          },
          {
            id: "addLab5",
            label: "Retikulozytenhämoglobin (CHr)",
            checked: false,
          },
          {
            id: "addLab6",
            label: "Zink-Protoporphyrin (ZPP)",
            checked: false,
          },
          {
            id: "addLab7",
            label: "löslicher Transferrinrezeptor (sTfR)",
            checked: false,
          },
          {
            id: "addLab8",
            label: "ggf. Retikulozytenproduktionsindex (RPI)",
            checked: false,
          },
        ]}
      />
    </>
  );
}

export default AnemiaCheckList;
