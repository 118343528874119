import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import MdsPraxisPracticalTips from "./praxisPracticalTips";
import MdsPraxisCasuistics from "./praxisCasuistics";

export function MdsPraxis() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const practicalTips = currentPage?.children.find(
    (child) => child.view === MdsPraxisPracticalTips
  );
  if (practicalTips) {
    navigationItems.push({
      link: {
        label: practicalTips.title,
        isExternal: false,
        url: practicalTips.path,
      },
      description: practicalTips.description,
    });
  }

  const casuistics = currentPage?.children.find(
    (child) => child.view === MdsPraxisCasuistics
  );
  if (casuistics) {
    navigationItems.push({
      link: {
        label: casuistics.title,
        isExternal: false,
        url: casuistics.path,
      },
      description: casuistics.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Aus der Praxis</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsPraxis;
