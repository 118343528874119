import {
  Accordion,
  DynamicSpacing,
  Graph,
  PagePadding,
  Table,
  TableBody,
  TableRow,
  AccordionNodeItem,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";

import * as dosage from "../../assets/img/mds/praxis/kasuistik-mds-del5q.svg";

export function MdsPraxisCasuisticsMdsDel5q() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Behandlung einer symptomatischen und transfusionsbedürftigen Anämie
          mit Lenalidomid bei <Abbreviation>mds</Abbreviation> del(5q)
        </h1>
        <p>
          46-jährige Patientin, 62 kg, 169 cm, <Abbreviation>ecog</Abbreviation>{" "}
          1
        </p>

        <Accordion
          items={[
            {
              title: "Symptome & Diagnostik",
              defaultOpen: true,
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Aktuelle Anamnese 05/2009</h3>
                    <ul>
                      <li>
                        Vorstellung bei der Hausärztin mit progredienter
                        Belastungsdyspnoe mit transfusionspflichtiger Anämie
                      </li>
                    </ul>

                    <h3>Vorerkrankungen</h3>
                    <ul>
                      <li>Keine Vorerkrankungen bekannt</li>
                    </ul>

                    <h3>Diagnose</h3>
                    <ul>
                      <li>Körperlicher Untersuchungsbefund: Unauffällig</li>
                      <li>
                        Labor: <Abbreviation>hb</Abbreviation> 8,1 g/dl,{" "}
                        <Abbreviation>l</Abbreviation> 3,3{" "}
                        <Abbreviation>gpt</Abbreviation>
                        /l, <Abbreviation>anc</Abbreviation> 1,60 Gpt/l,{" "}
                        <Abbreviation>thr</Abbreviation> 93 Gpt/l
                      </li>
                    </ul>

                    <h3>Knochenmark-Diagnostik 06/2009</h3>
                    <ul>
                      <li>Genetik: Isolierte Deletion 5q</li>
                    </ul>

                    <h3>Abschließende Diagnose</h3>
                    <ul>
                      <li>
                        <Abbreviation>mds</Abbreviation> mit Deletion 5q
                      </li>
                      <li>
                        Gemäß <Abbreviation>ipss</Abbreviation>: Kategorie
                        „intermediate-1 risk“
                      </li>
                      <li>
                        Gemäß <Abbreviation>ipssr</Abbreviation>: Kategorie
                        „intermediate risk“{" "}
                        <TextReference references={["ref13"]} />
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Medikamentöse MDS-Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <p>
                      Lenalidomid q4w x 6
                      <br />
                      10 mg einmal täglich Tag 1 bis Tag 21
                    </p>

                    <h3>Dosierung</h3>
                    <Graph
                      imagePath={dosage.default}
                      alt="Lenalidomid"
                      legend={[
                        {
                          type: "rectangle",
                          color: "turquoise",
                          label: "Lenalidomid",
                        },
                      ]}
                      largeWidth="50%"
                      bordered
                    />

                    <h3>Protokollklassifikation</h3>
                    <ul>
                      <li>
                        Klassifikation: Standard für symptomatische
                        Patient:innen mit transfusionsbedürftiger Anämie und
                        del(5q) <TextReference references={["ref1"]} />
                      </li>
                      <li>Intensität: nicht intensiv</li>
                      <li>Therapieintention: palliativ</li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Weiterer Verlauf",
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>09/2010</h3>
                    <ul>
                      <li>
                        Beginn der Transfusionspflicht für{" "}
                        <Abbreviation>ek</Abbreviation>s
                      </li>
                    </ul>

                    <h3>11/2010</h3>
                    <ul>
                      <li>Beginn mit Lenalidomid, wie oben beschrieben</li>
                      <li>Komplikationslose Beendigung der 6 Zyklen</li>
                    </ul>

                    <h3>04/2011</h3>
                    <ul>
                      <li>Knochenmarkpunktion: komplette hämatologische und</li>
                      <li>zytogenetische Remission</li>
                    </ul>

                    <h3>07/2012</h3>
                    <ul>
                      <li>
                        Therapieende bei anhaltender Remission nach insgesamt 20
                        Zyklen
                      </li>
                    </ul>

                    <h3>04/2016</h3>
                    <ul>
                      <li>
                        Weiterhin therapiefrei bei anhaltender hämatologischer
                        Remission
                      </li>
                      <li>
                        Knochenmark-Diagnostik:
                        <ul>
                          <li>Zytologie: einzelne Metaphasen mit del(5q)</li>
                        </ul>
                      </li>
                    </ul>

                    <h3>04/2016</h3>
                    <ul>
                      <li>
                        Weiterhin therapiefrei bei anhaltender hämatologischer
                        Remission
                      </li>
                      <li>
                        Knochenmark-Diagnostik:
                        <ul>
                          <li>Zytologie: einzelne Metaphasen mit del(5q)</li>
                        </ul>
                      </li>
                    </ul>

                    <h3>12/2018</h3>
                    <ul>
                      <li>
                        Weiterhin therapiefrei bei anhaltender hämatologischer
                        Remission
                      </li>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            <Abbreviation>hb</Abbreviation> 13,2 g/dl,{" "}
                            <Abbreviation>l</Abbreviation> 3,8{" "}
                            <Abbreviation>gpt</Abbreviation>
                            /l, <Abbreviation>anc</Abbreviation> 1,81 Gpt/l,{" "}
                            <Abbreviation>thr</Abbreviation> 68 Gpt/l à{" "}
                            Thrombozytopenie
                          </li>
                        </ul>
                      </li>
                      <li>
                        Knochenmark-Diagnostik:
                        <ul>
                          <li>
                            Genetik: kein Hinweis auf zusätzliche genetische
                            Aberrationen, insbesondere keine Mutation in den
                            Genen ASXL1, TP53, SF3B1, SRSF2, U2AF1, TET2 oder
                            DNMT3A
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <h3>02/2021</h3>
                    <ul>
                      <li>
                        Weiterhin therapiefrei, Belastungsdyspnoe aufgrund einer
                        progredienten Anämie
                      </li>
                      <li>
                        Knochenmark-Diagnostik vom 17.02.:
                        <ul>
                          <li>
                            hämatologisches und zytogenetisches Rezidiv des{" "}
                            <Abbreviation>mds</Abbreviation> mit del(5q), neuer
                            TP53-Mutationen, keine neu aufgetretenen
                            zusätzlichen Chromosomenanomalien
                            (Karyotypevolution)
                          </li>
                        </ul>
                      </li>
                      <li>
                        Therapiestart Lenalidomid:
                        <br />
                      </li>
                    </ul>
                    <Table>
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "5 mg"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Dosisintervall",
                            "1x tgl. Tag 1 bis Tag 21 q4w x 6",
                          ]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc1"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />

                    <h3>12.05.2021</h3>
                    <ul>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            <Abbreviation>hb</Abbreviation> 11,4 g/dl,{" "}
                            <Abbreviation>l</Abbreviation> 2,7{" "}
                            <Abbreviation>gpt</Abbreviation>
                            /l, <Abbreviation>anc</Abbreviation> 0,99 Gpt/l,{" "}
                            <Abbreviation>thr</Abbreviation> 77 Gpt/l
                          </li>
                          <li>Thrombozyto- und Neutropenie</li>
                        </ul>
                      </li>
                      <li>
                        Therapieanpassung:
                        <br />
                      </li>
                    </ul>
                    <Table>
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "5 mg"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Dosisintervall",
                            "1x tgl. Tag 1 bis Tag 14 q4w x 6",
                          ]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc2"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />

                    <h3>10.11.2021</h3>
                    <ul>
                      <li>
                        Anhaltende komplette hämatologische und zytogenetische
                        Remission mit stabilem Blutbild
                      </li>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            <Abbreviation>hb</Abbreviation> 13,5 g/dl,{" "}
                            <Abbreviation>l</Abbreviation> 4,9{" "}
                            <Abbreviation>gpt</Abbreviation>
                            /l, <Abbreviation>anc</Abbreviation> 2,25 Gpt/l,{" "}
                            <Abbreviation>thr</Abbreviation> 101 Gpt/l
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default MdsPraxisCasuisticsMdsDel5q;
