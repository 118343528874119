import { useState } from "react";
import {
  ArrowSeparator,
  ButtonGroup,
  DecisionTreeWrapper,
  ResultBox,
  ScoreButtonGroup,
  SeparatorWrapper,
} from "components/ui";
import { Grid } from "@mui/material";

type SelectionState = {
  ferritin: null | string;
  dosage: null | string;
};

export function DosageDeferasiroxDecisionTree() {
  const initialState: SelectionState = {
    ferritin: null,
    dosage: null,
  };

  const [selected, setSelected] = useState<SelectionState>(initialState);
  const requestDosage = () => selected.ferritin === "ferrMed2";
  const showResult = () =>
    selected.ferritin === "ferrLow" ||
    selected.ferritin === "ferrMed1" ||
    selected.ferritin === "ferrHigh" ||
    selected.dosage !== null;

  const getResultText = () => {
    let result: string;

    switch (selected.ferritin) {
      case "ferrLow":
        result = "Unterbrechung erwägen";
        break;
      case "ferrMed1":
        result = "Reduktion erwägen in Schritten von 3,5–7 mg/kg/Tag";
        break;
      case "ferrMed2":
        if (selected.dosage === "dosLow") {
          result = "Keine Dosisanpassungen";
        } else if (selected.dosage === "dosHigh") {
          result = "Reduktion erwägen in Schritten von 3,5–7 mg/kg/Tag";
        } else {
          result = "";
        }
        break;
      case "ferrHigh":
        result =
          "Steigerung erwägen in Schritten von 3,5–7 mg/kg/Tag (bis max. 28 mg/kg/Tag)";
        break;
      default:
        result = "";
    }

    return result;
  };
  const getResultColor = () => {
    let result: "success" | "warning" | "error";

    switch (selected.ferritin) {
      case "ferrLow":
        result = "error";
        break;
      case "ferrMed1":
        result = "warning";
        break;
      case "ferrMed2":
        if (selected.dosage === "dosLow") {
          result = "success";
        } else if (selected.dosage === "dosHigh") {
          result = "warning";
        } else {
          result = "error";
        }
        break;
      case "ferrHigh":
        result = "warning";
        break;
      default:
        result = "error";
    }

    return result;
  };

  return (
    <DecisionTreeWrapper>
      <Grid container>
        <Grid item xs={12}>
          <ScoreButtonGroup
            onChange={(id) => {
              const resetTree = selected.ferritin !== null;
              if (resetTree && selected.ferritin === id) {
                setSelected(initialState);
              } else if (resetTree) {
                const newState = { ...initialState };
                newState.ferritin = id;
                setSelected(newState);
              } else {
                setSelected((values) => ({ ...values, ferritin: id }));
              }
            }}
            outlined
            singleSelect
            scores={[
              { id: "ferrLow", label: "Serumferritin: < 500 ng/ml" },
              { id: "ferrMed1", label: "Serumferritin: 500–1.000 ng/ml" },
              { id: "ferrMed2", label: "Serumferritin: 1.000–2.500 ng/ml" },
              { id: "ferrHigh", label: "Serumferritin: > 2.500 ng/ml" },
            ]}
          />
          {requestDosage() && (
            <>
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ButtonGroup
                results={[
                  {
                    label: "Dosis < 21 mg/Kg/Tag",
                    color: "primary",
                    id: "dosLow",
                  },
                  {
                    label: "Dosis > 21 mg/Kg/Tag",
                    color: "primary",
                    id: "dosHigh",
                  },
                ]}
                onClick={(id) => {
                  const resetTree = selected.dosage !== null;
                  if (resetTree && selected.dosage === id) {
                    setSelected((values) => ({ ...values, dosage: null }));
                  } else {
                    setSelected((values) => ({ ...values, dosage: id }));
                  }
                }}
              />
            </>
          )}
          {showResult() && (
            <>
              <SeparatorWrapper>
                <ArrowSeparator color="primary" />
              </SeparatorWrapper>
              <ResultBox
                label={getResultText()}
                variant="large"
                color={getResultColor()}
              />
            </>
          )}
        </Grid>
      </Grid>
    </DecisionTreeWrapper>
  );
}

export default DosageDeferasiroxDecisionTree;
