import {
  NavigationList,
  PagePadding,
  NavigationListItem,
  Link,
} from "components/ui";
import { ReactNode, useContext } from "react";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import HexalSeo from "../../components/HexalSeo";
import FnPraxisCasuisticsMammaCaIntensivied from "./praxisCasuisticsMammaCaIntensivied";
import FnPraxisCasuisticsMammaCaStandard from "./praxisCasuisticsMammaCaStandard";
import FnPraxisCasuisticsMammaCaTense from "./praxisCasuisticsMammaCaTense";
import GlobalContext from "../../context/globalContext";

export function FnPraxisCasuisticsMammaCa() {
  const { currentPage } = useContext(GlobalContext);
  const navigationItems: { link: Link; description: string | ReactNode }[] = [];

  const intensifiedTherapy = currentPage?.children.find(
    (child) => child.view === FnPraxisCasuisticsMammaCaIntensivied
  );
  if (intensifiedTherapy) {
    navigationItems.push({
      link: {
        label: intensifiedTherapy.title,
        isExternal: false,
        url: intensifiedTherapy.path,
      },
      description: intensifiedTherapy.description,
    });
  }

  const standardTherapy = currentPage?.children.find(
    (child) => child.view === FnPraxisCasuisticsMammaCaStandard
  );
  if (standardTherapy) {
    navigationItems.push({
      link: {
        label: standardTherapy.title,
        isExternal: false,
        url: standardTherapy.path,
      },
      description: standardTherapy.description,
    });
  }

  const tenseTherapy = currentPage?.children.find(
    (child) => child.view === FnPraxisCasuisticsMammaCaTense
  );
  if (tenseTherapy) {
    navigationItems.push({
      link: {
        label: tenseTherapy.title,
        isExternal: false,
        url: tenseTherapy.path,
      },
      description: tenseTherapy.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Mammakarzinom</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default FnPraxisCasuisticsMammaCa;
