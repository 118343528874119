import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import ProfessionalInformationButton from "../../components/ProfessionalInformationButton";
import { resources } from "../../data/resources";

export function MdsTherapyLowRiskLuspatercept() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Therapie Hämatopoetische Differenzierungsfaktoren – Luspatercept
        </h1>
        <p>
          Luspartecept ist indiziert für die Behandlung von erwachsenen
          Patient:innen mit
        </p>
        <ul>
          <li>
            transfusionsabhängiger Anämie aufgrund von myelodysplastischen
            Syndromen (<Abbreviation>mds</Abbreviation>
            ) mit Ringsideroblasten
            <br />
            und
          </li>
          <li>sehr niedrigem, niedrigem oder intermediärem Risiko.</li>
        </ul>
        <p>
          die auf eine Erythropoetin-basierte Therapie nicht zufriedenstellend
          angesprochen haben oder dafür nicht geeignet sind.{" "}
          <TextReference references={["ref5"]} />
        </p>

        <p>
          Link zu Fachinfo Reblozyl
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.reblozyl.url}
          />
        </p>
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskLuspatercept;
