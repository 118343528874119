import {
  Image,
  MainBackground,
  PagePadding,
  PrimaryNavButton,
} from "components/ui";
import { useContext, useState } from "react";
import GlobalContext from "../context/globalContext";
import { Grid } from "@mui/material";

export function Home() {
  const { currentMarket } = useContext(GlobalContext);
  const [heroPath, setHeroPath] = useState<string | undefined>(undefined);
  let heroAlt;
  switch (currentMarket) {
    case "at":
      import("../assets/img/introHeader/intro-header_at.png").then((image) =>
        setHeroPath(image.default)
      );
      heroAlt = "Sandoz TherapieGuide";
      break;
    default:
      import("../assets/img/introHeader/intro-header_de.png").then((image) =>
        setHeroPath(image.default)
      );
      heroAlt = "Hexal TherapieGuide";
  }

  return (
    <>
      <MainBackground />
      <PagePadding>
        {heroPath && <Image image={{ alt: heroAlt, path: heroPath }} />}
        <Grid container spacing="24px">
          <Grid item xs={12} md={6}>
            <PrimaryNavButton
              link={{
                isExternal: false,
                url: "/febrile-neutropenie",
                label: "Febrile Neutropenie",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryNavButton
              link={{
                isExternal: false,
                url: "/cia",
                label: "Chemotherapie-induzierte Anämie",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryNavButton
              link={{
                isExternal: false,
                url: "/op",
                label: "Prä- und postoperative Anämie",
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PrimaryNavButton
              link={{
                isExternal: false,
                url: "/mds",
                label: "MDS-assoziierte Anämie",
              }}
            />
          </Grid>
        </Grid>
      </PagePadding>
    </>
  );
}

export default Home;
