/** @jsxImportSource @emotion/react */

import { ReactNode, useState } from "react";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "../Modal/Modal";
import ReferenceIcon from "../Icons/ReferenceIcon";

export interface ReferenceProps {
  content: string | ReactNode;
  type: "info" | "reference";
  isNode?: boolean;
  darkMode?: boolean;
}

export function Reference(props: ReferenceProps) {
  const theme = useTheme();
  const [active, setActive] = useState(false);

  const modalContent =
    props.type === "info" || props.isNode ? (
      <div>{props.content}</div>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: props.content as string }} />
    );

  const styles = {
    clickable: css`
      cursor: pointer;
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: text-top;
      margin-left: 3px;
      color: ${props.darkMode ? "#fff" : theme.palette.primary.main};
      &:hover {
        color: ${props.darkMode
          ? theme.palette.hxBackground.primary
          : theme.palette.primary.light};
      }
    `,
    icon: css`
      width: 20px;
      height: 20px;
    `,
  };
  return (
    <>
      <span
        css={styles.clickable}
        onClick={(event) => {
          event.stopPropagation();
          setActive(!active);
        }}
      >
        {props.type === "reference" && <ReferenceIcon css={styles.icon} />}
        {props.type === "info" && <InfoOutlinedIcon css={styles.icon} />}
      </span>
      <Modal
        open={active}
        onClose={(event) => {
          if (event) event.stopPropagation();
          setActive(false);
        }}
      >
        {modalContent}
      </Modal>
    </>
  );
}

export default Reference;
