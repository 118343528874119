/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import {
  Alert,
  ButtonGroup,
  DecisionTreeWrapper,
  Highlight,
  ResultBox,
  VerticalLine,
} from "components/ui";

export function ChemoInducedFnRiskDecisionTree() {
  const initialState: { baseRisk: string | null; riskFactors: string | null } =
    {
      baseRisk: null,
      riskFactors: null,
    };

  const [risks, setRisks] = useState(initialState);

  const showBaseRiskResults = () => risks.baseRisk !== null;

  return (
    <DecisionTreeWrapper>
      <Grid container>
        <Grid item xs={12}>
          <ButtonGroup
            results={[
              { label: "hoch", color: "error", id: "base1" },
              { label: "intermediär", color: "warning", id: "base2" },
              { label: "gering", color: "success", id: "base3" },
            ]}
            onClick={(id) => {
              if (risks.baseRisk === id) {
                setRisks((values) => ({ ...values, baseRisk: null }));
              } else {
                setRisks((values) => ({ ...values, baseRisk: id }));
              }
            }}
          />
          <div
            css={css`
              display: ${showBaseRiskResults() ? "block" : "none"};
            `}
          >
            <VerticalLine
              columns={3}
              position={
                risks.baseRisk === "base1"
                  ? 1
                  : risks.baseRisk === "base2"
                  ? 2
                  : 3
              }
              color={
                risks.baseRisk === "base1"
                  ? "error"
                  : risks.baseRisk === "base2"
                  ? "warning"
                  : "success"
              }
            />
            <ResultBox
              label={
                risks.baseRisk === "base1"
                  ? "G-CSF-Prophylaxe indiziert"
                  : risks.baseRisk === "base2"
                  ? "Bewertung von patientenbezogenen Risikofaktoren, erhöhtes Risiko"
                  : "G-CSF-Prophylaxe nicht indiziert"
              }
              variant="small"
              color={
                risks.baseRisk === "base1"
                  ? "error"
                  : risks.baseRisk === "base2"
                  ? "warning"
                  : "success"
              }
            />
          </div>
          <div
            css={css`
              display: ${risks.baseRisk === "base2" ? "block" : "none"};
              margin-top: 29px;
            `}
          >
            <ol>
              <li>Alter über 65 Jahre</li>
              <li>
                Niedriger Performance-Status (niedriger Kamofsky-Index, hoher
                ECOG-Score)
              </li>
              <li>
                Komorbiditäten: COPD, Herzinsuffizienz NYHA III–IV,
                HIV-Erkrankung, Autoimmunerkrankung, deutlich eingeschränkte
                Nierenfunktion
              </li>
              <li>Weit fortgeschrittene, symptomatische Tumorerkrankung</li>
              <li>In der Vergangenheit erfolgte Chemotherapie</li>
              <li>
                Laborparameter: Anämie, Lymphozytopenie &lt; 700/µl,
                Hypalbuminämie, Hyperbilirubinämie
              </li>
            </ol>
            <p>
              <Highlight>
                Definition des FN-Gesamtrisikos für Patient:innen mit geplanter
                Chemotherapie
              </Highlight>
            </p>
            <p>Wählen Sie aus, ob Ihr Patient:in Risikofaktoren hat.</p>
            <ButtonGroup
              results={[
                { label: "mit Risikofaktoren", color: "error", id: "factors1" },
                {
                  label: "ohne RisikoFaktoren",
                  color: "success",
                  id: "factors2",
                },
              ]}
              onClick={(id) => {
                if (risks.riskFactors === id) {
                  setRisks((values) => ({ ...values, riskFactors: null }));
                } else {
                  setRisks((values) => ({ ...values, riskFactors: id }));
                }
              }}
            />
            <div
              css={css`
                display: ${risks.riskFactors !== null ? "block" : "none"};
              `}
            >
              <VerticalLine
                columns={2}
                position={risks.riskFactors === "factors1" ? 1 : 2}
                color={risks.riskFactors === "factors1" ? "error" : "success"}
              />
              <ResultBox
                label={
                  risks.riskFactors === "factors1"
                    ? "G-CSF-Prophylaxe indiziert"
                    : "G-CSF-Prophylaxe nicht indiziert"
                }
                variant="small"
                color={risks.riskFactors === "factors1" ? "error" : "success"}
              />
              <div
                css={css`
                  margin-top: 17px;
                `}
              >
                <Alert content="Neubewertung bei jedem Zyklus" type="warning" />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </DecisionTreeWrapper>
  );
}

export default ChemoInducedFnRiskDecisionTree;
