import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import ProfessionalInformationButton from "../../components/ProfessionalInformationButton";
import { resources } from "../../data/resources";

export function MdsTherapyIronOverloadDeferasirox() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapie mit Eisenchelatoren – Deferasirox</h1>
        <p>
          Polytransfundierte Patient:innen sind längerfristig durch die
          begleitende sekundäre Hämochromatose (Kardiomyopathie) bedroht.
        </p>

        <h2>
          Therapie mit Eisenchelatoren kann erwogen werden bei Patient:innen:
        </h2>
        <ul>
          <li>
            mit einer Lebenserwartung von mehr als 2 Jahren
            <br />
            und
          </li>
          <li>
            nach mindestens 20 <Abbreviation>ek</Abbreviation> bzw. bei einem
            Serumferritinspiegel von &gt;1000 ng/ml
          </li>
        </ul>

        <h2>
          Besonderer Stellenwert der Eisenchelation vor allogener
          Stammzelltransplantation
        </h2>
        <ul>
          <li>
            Empfehlung der Eisenchelation bis zum Beginn der Konditionierung,
            aufgrund der Assoziation der Eisenüberladung mit erhöhter Mortalität{" "}
            <TextReference references={["ref1"]} />
          </li>
        </ul>

        <p>
          Link zu Fachinfo Deferasirox HEXAL
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.deferasirox.url}
          />
        </p>
        <p>
          Link zu Fachinfo Exjade
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton url={resources.documents.exjade.url} />
        </p>
      </PagePadding>
    </>
  );
}

export default MdsTherapyIronOverloadDeferasirox;
