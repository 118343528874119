import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";

export function CiaTherapyOptions() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Therapieoptionen bei Chemotherapie-induzierter Anämie{" "}
          <TextReference references={["ref1"]} />
        </h1>
        <ul>
          <li>Indikation der Anämietherapie bei klinischen Beschwerden</li>
          <li>
            Nur ein erniedrigter <Abbreviation>hb</Abbreviation>
            -Wert reicht zur Indikationsstellung nicht aus
          </li>
        </ul>

        <h2>Therapieansätze nach Schweregrad der Anämie:</h2>
        <ul>
          <li>Erythropoese-stimulierende Agenzien</li>
          <li>Eisensubstitution</li>
          <li>Erythrozyten-Transfusionen</li>
        </ul>
      </PagePadding>
    </>
  );
}

export default CiaTherapyOptions;
