import { ReactNode, useContext } from "react";
import {
  NavigationList,
  PagePadding,
  NavigationListItem,
  Link,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import FnPraxisPracticalTips from "./praxisPracticalTips";
import FnPraxisCasuistics from "./praxisCasuistics";
import FnPraxisBiosimilars from "./praxisBiosimilars";
import GlobalContext from "../../context/globalContext";

export function FnPraxis() {
  const { currentPage } = useContext(GlobalContext);

  const navigationItems: { link: Link; description: string | ReactNode }[] = [];

  const practicalTips = currentPage?.children.find(
    (child) => child.view === FnPraxisPracticalTips
  );
  if (practicalTips) {
    navigationItems.push({
      link: {
        label: practicalTips.title,
        isExternal: false,
        url: practicalTips.path,
      },
      description: practicalTips.description,
    });
  }
  const casuistics = currentPage?.children.find(
    (child) => child.view === FnPraxisCasuistics
  );
  if (casuistics) {
    navigationItems.push({
      link: {
        label: casuistics.title,
        isExternal: false,
        url: casuistics.path,
      },
      description: casuistics.description,
    });
  }

  const biosimilars = currentPage?.children.find(
    (child) => child.view === FnPraxisBiosimilars
  );
  if (biosimilars) {
    navigationItems.push({
      link: {
        label: biosimilars.title,
        isExternal: false,
        url: biosimilars.path,
      },
      description: biosimilars.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Aus der Praxis</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default FnPraxis;
