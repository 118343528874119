import { Accordion, PagePadding, AccordionTabItem } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";
import TextReference from "../../components/TextReference";

export function MdsDiagnosticClassification() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Klassifikation</h1>
        <p>
          Aktuelle <Abbreviation>who</Abbreviation>
          -Klassifikation unterteilt in 2 große Gruppen:
        </p>
        <ul>
          <li>
            reine <Abbreviation>mds</Abbreviation>
          </li>
          <li>gemischte myelodysplastisch-myeloproliferative Neoplasien</li>
        </ul>
        <p>
          Diskriminierender Blastenanteil in Blut und Knochenmark bei akuter
          Leukämie: 20 %{" "}
          <TextInfo>
            Der aktuell gültige Prognosescore für die MDS (
            <Abbreviation>ipssr</Abbreviation>) umfasst jedoch weiterhin
            Patient:innen mit bis zu 30 % Blasten.
          </TextInfo>
        </p>
        <p>
          Aus diagnostischen, prognostischen und therapeutischen Erwägungen ist
          eine Chromosomenanalyse erforderlich.{" "}
          <TextReference references={["ref1"]} />
        </p>

        <h2>
          <Abbreviation>who</Abbreviation>
          -Klassifikation (2016) myelodysplastischer Syndrome
          <TextReference references={["ref1"]} />
        </h2>
        <p>
          Wie die vielen unterschiedlichen myelodysplastischen Syndrome von der
          WHO aufgeteilt werden, zeigt Ihnen die folgende Tabelle. Klicken Sie
          auf die Kategorie (z.B. MDS mit Einliniendysplasie) um herauszufinden,
          wie das jeweilige Syndrom charakterisiert wird.
        </p>

        <Accordion
          summaryAlwaysPrimary
          items={[
            {
              title: "MDS mit Einliniendysplasie",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="1" />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem left="Zytopenien" right="1 oder 2" />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right={
                        <>
                          &lt;15 % / &lt;5 %
                          <TextInfo>Falls SF3B1 mutiert</TextInfo>
                        </>
                      }
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM <5 %, PB <1 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Alle, außer del(5q) +/- 1 andere Nicht-Chr.-7-Aberration"
                    />
                  ),
                },
              ],
            },
            {
              title: "MDS mit Mehrliniendysplasie",
              body: [
                {
                  content: (
                    <AccordionTabItem
                      left="Dysplastische Reihen"
                      right="2 oder 3"
                    />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="1-3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right={
                        <>
                          &lt;15 % / &lt;5 %
                          <TextInfo>Falls SF3B1 mutiert</TextInfo>
                        </>
                      }
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM <5 %, PB <1 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Alle, außer del(5q) +/- 1 andere Nicht-Chr.-7-Aberration"
                    />
                  ),
                },
              ],
            },
            {
              title: "MDS mit Ringsideroblasten und Einliniendysplasie",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="1" />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem left="Zytopenien" right="1 oder 2" />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right={
                        <>
                          ≥15 % / ≥5 % <TextInfo>Falls SF3B1 mutiert</TextInfo>
                        </>
                      }
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM <5 %, PB <1 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Alle, außer del(5q) +/- 1 andere Nicht-Chr.-7-Aberration 5q"
                    />
                  ),
                },
              ],
            },
            {
              title: "MDS mit Ringsideroblasten und Mehrliniendysplasie",
              body: [
                {
                  content: (
                    <AccordionTabItem
                      left="Dysplastische Reihen"
                      right="2 oder 3"
                    />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="1-3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right={
                        <>
                          ≥15 % / ≥5 % <TextInfo>Falls SF3B1 mutiert</TextInfo>
                        </>
                      }
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM <5 %, PB <1 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Alle, außer del(5q) +/- 1 andere Nicht-Chr.-7-Aberration"
                    />
                  ),
                },
              ],
            },
            {
              title: "MDS mit del(5q)",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="1-3" />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="1-3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM <5 %, PB <1 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="del(5q) isoliert oder mit 1 anderen Nicht-Chr.-7-Aberration"
                    />
                  ),
                },
              ],
            },
            {
              title: "MDS mit Blastenexzess (1)",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="0-3" />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="1-3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM 5-9 % oder PB 2-4 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
              ],
            },
            {
              title: "MDS mit Blastenexzess (2)",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="0-3" />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="1-3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM 10-19 % oder PB 5-19 % oder Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
              ],
            },
            {
              title: "MDS unklassifizierbar, mit 1 % peripheren Blasten",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="1-3" />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="1-3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right={
                        <>
                          BM &lt; 5 %, PB = 1 %{" "}
                          <TextInfo>
                            1 % periphere Blasten müssen zu 2 verschiedenen
                            Zeitpunkten beurteilt werden
                          </TextInfo>
                          , keine Auerstäbchen
                        </>
                      }
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
              ],
            },
            {
              title:
                "MDS unklassifizierbar, mit Einliniendysplassie und Panzytopenie",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="1" />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right="Irrelevant"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM <5 %, PB <1 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right="Alle, außer del(5q) +/- 1 andere Nicht-Chr.-7-Aberration"
                    />
                  ),
                },
              ],
            },
            {
              title:
                "MDS unklassifizierbar, auf der Grundlage definierender zytogenetischer Veränderungen",
              body: [
                {
                  content: (
                    <AccordionTabItem left="Dysplastische Reihen" right="0" />
                  ),
                },
                {
                  content: <AccordionTabItem left="Zytopenien" right="1-3" />,
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Ringsideroblasten
                          <TextInfo>% der erythroiden Zellen</TextInfo>
                        </>
                      }
                      right={
                        <>
                          &lt;15 %{" "}
                          <TextInfo>
                            Fälle mit ≥15 % Ringsideroblasten haben
                            definitionsgemäß eine signifikante Dyserythropoese
                            und sind daher MDS mit Ringsideroblasten und
                            Einliniendysplasie
                          </TextInfo>
                        </>
                      }
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Blasten im Knochenmark (
                          <Abbreviation>bm</Abbreviation>) und peripheren Blut (
                          <Abbreviation>pb</Abbreviation>)
                        </>
                      }
                      right="BM <5 %, PB <1 %, keine Auerstäbchen"
                    />
                  ),
                },
                {
                  content: (
                    <AccordionTabItem
                      left={
                        <>
                          Karyotyp
                          <TextInfo>konventionelle Bänderung</TextInfo>
                        </>
                      }
                      right={
                        <>
                          <Abbreviation>mds</Abbreviation>
                          -definierende Abnormalität
                        </>
                      }
                    />
                  ),
                },
              ],
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default MdsDiagnosticClassification;
