import { FC } from "react";
import FnHome from "./views/fn";
import FnDisease from "./views/fn/disease";
import FnDiagnostics from "./views/fn/diagnostics";
import FnDiagnosticNeutropenia from "./views/fn/diagnosticNeutropenia";
import FnDiagnosticFn from "./views/fn/diagnosticFn";
import FnDiagnosticSepsis from "./views/fn/diagnosticSepsis";
import FnDiagnosticPulmonaryInfiltrates from "./views/fn/diagnosticPulmonaryInfiltrates";
import FnRisk from "./views/fn/risk";
import FnRiskMascc from "./views/fn/riskMascc";
import FnRiskFactors from "./views/fn/riskRiskFactors";
import FnProphylaxis from "./views/fn/prophylaxis";
import FnProphylaxisGCsfProphylaxis from "./views/fn/prophylaxisGCsfProphylaxis";
import FnProphylaxisCtRegimes from "./views/fn/prophylaxisCtRegimes";
import FnProphylaxisDosage from "./views/fn/prophylaxisDosage";
import { FnProphylaxisApplication } from "./views/fn/prophylaxisApplication";
import FnTherapy from "./views/fn/therapy";
import FnPraxis from "./views/fn/praxis";
import FnPraxisPracticalTips from "./views/fn/praxisPracticalTips";
import FnPraxisCasuistics from "./views/fn/praxisCasuistics";
import FnPraxisCasuisticsMorbusHodgkin from "./views/fn/praxisCasuisticsMorbusHodgkin";
import FnPraxisCasuisticsIndolentLymphoma from "./views/fn/praxisCasuisticsIndolentLymphoma";
import FnPraxisCasuisticsMammaCa from "./views/fn/praxisCasuisticsMammaCa";
import FnPraxisCasuisticsMammaCaIntensivied from "./views/fn/praxisCasuisticsMammaCaIntensivied";
import FnPraxisCasuisticsMammaCaStandard from "./views/fn/praxisCasuisticsMammaCaStandard";
import FnPraxisCasuisticsMammaCaTense from "./views/fn/praxisCasuisticsMammaCaTense";
import FnPraxisBiosimilars from "./views/fn/praxisBiosimilars";
import Favorites from "./views/favorites";
import { PageScopeType } from "./ts/PageScopeTypes";
import Home from "./views";
import MdsHome from "./views/mds";
import MdsDisease from "./views/mds/disease";
import MdsDiagnostics from "./views/mds/diagnostics";
import MdsTherapy from "./views/mds/therapy";
import MdsPraxis from "./views/mds/praxis";
import MdsDiagnosticMds from "./views/mds/diagnosticMds";
import MdsDiagnosticClassification from "./views/mds/diagnosticClassification";
import MdsDiagnosticPrognostics from "./views/mds/diagnosticPrognostics";
import MdsTherapyLowRisk from "./views/mds/therapyLowRisk";
import MdsTherapyLowRiskManagement from "./views/mds/therapyLowRiskManagement";
import MdsTherapyLowRiskEsa from "./views/mds/therapyLowRiskEsa";
import MdsTherapyLowRiskEsaTherapy from "./views/mds/therapyLowRiskEsaTherapy";
import MdsTherapyLowRiskEsaDosage from "./views/mds/therapyLowRiskEsaDosage";
import MdsTherapyLowRiskEsaApplication from "./views/mds/therapyLowRiskEsaApplication";
import MdsTherapyLowRiskEsaNordicScore from "./views/mds/therapyLowRiskEsaNordicScore";
import MdsTherapyLowRiskImmunomodulators from "./views/mds/therapyLowRiskImmunomodulators";
import { MdsTherapyLowRiskImmunomodulatorsLenalidomidTherapy } from "./views/mds/therapyLowRiskImmunomodulatorsLenalidomidTherapy";
import MdsTherapyLowRiskImmunomodulatorsLenalidomidDosage from "./views/mds/therapyLowRiskImmunomodulatorsLenalidomidDosage";
import MdsTherapyLowRiskLuspatercept from "./views/mds/therapyLowRiskLuspatercept";
import MdsTherapyIronOverload from "./views/mds/therapyIronOverload";
import MdsTherapyIronOverloadTransfusion from "./views/mds/therapyIronOverloadTransfusion";
import MdsTherapyIronOverloadDeferasirox from "./views/mds/therapyIronOverloadDeferasirox";
import MdsTherapyIronOverloadDeferasiroxDosage from "./views/mds/therapyIronOverloadDeferasiroxDosage";
import MdsPraxisPracticalTips from "./views/mds/praxisPracticalTips";
import MdsPraxisCasuistics from "./views/mds/praxisCasuistics";
import MdsPraxisCasuisticsMdsDel5q from "./views/mds/praxisCasuisticsMdsDel5q";
import MdsPraxisCasuisticsMdsMld from "./views/mds/praxisCasuisticsMdsMld";
import MdsPraxisCasuisticsCardio from "./views/mds/praxisCasuisticsCardio";
import CiaHome from "./views/cia";
import CiaDisease from "./views/cia/disease";
import CiaDiseaseSymptoms from "./views/cia/diseaseSymptoms";
import CiaDiagnostics from "./views/cia/diagnostics";
import CiaDiseaseDefinition from "./views/cia/diseaseDefinition";
import CiaTherapy from "./views/cia/therapy";
import CiaPbm from "./views/cia/pbm";
import CiaPraxis from "./views/cia/praxis";
import CiaDiagnosticAnemia from "./views/cia/diagnosticAnemia";
import CiaDiagnosticIronDeficiency from "./views/cia/diagnosticIronDeficiency";
import CiaTherapyOptions from "./views/cia/therapyOptions";
import CiaTherapyEsa from "./views/cia/therapyEsa";
import CiaTherapyEsaEsaCia from "./views/cia/therapyEsaEsaCia";
import CiaTherapyEsaDosage from "./views/cia/therapyEsaDosage";
import CiaTherapyEsaApplication from "./views/cia/therapyEsaApplication";
import CiaTherapyIronSubstitution from "./views/cia/therapyIronSubstitution";
import CiaTherapyEC from "./views/cia/therapyEC";
import CiaPraxisTipps from "./views/cia/praxisTipps";
import CiaPraxisCasuistic from "./views/cia/praxisCasuistic";
import OpHome from "./views/op";
import OpAnemia from "./views/op/anemia";
import OpCauses from "./views/op/causes";
import OpManagement from "./views/op/management";

export interface SiteMapEntry {
  path: string;
  name: string;
  title: string;
  description: string;
  keywords: string;
  pageScope: PageScopeType;
  view: FC | null;
  children: SiteMapEntry[];
  config?: {
    disableFavorite?: boolean;
    protectedRoute?: boolean;
  };
}

const sitemap: SiteMapEntry[] = [
  {
    path: "",
    name: "Home",
    title: "Home",
    description: "Home",
    keywords: "Home",
    pageScope: "home",
    view: Home,
    config: {
      protectedRoute: true,
    },
    children: [
      {
        path: "favoriten",
        name: "Favoriten",
        title: "Favoriten",
        description: "Favoriten",
        keywords: "Favoriten",
        pageScope: "home",
        view: Favorites,
        children: [],
        config: {
          disableFavorite: true,
          protectedRoute: true,
        },
      },
      {
        path: "febrile-neutropenie",
        name: "Febrile Neutropenie",
        title: "Febrile Neutropenie",
        description:
          "Übersichtsseite zu den verschiedenen Themen des Kapitels Febrile Neutropenie",
        keywords: "Febrile Neutropenie",
        pageScope: "fn",
        view: FnHome,
        config: {
          protectedRoute: true,
        },
        children: [
          {
            path: "erkrankung",
            name: "Erkrankung",
            title: "Erkrankung",
            description:
              "Definition und potenzielle Auswirkungen der febrilen Neutropenie",
            keywords: "Erkrankung",
            pageScope: "fn",
            view: FnDisease,
            children: [],
            config: {
              protectedRoute: true,
            },
          },
          {
            path: "diagnostik",
            name: "Diagnostik",
            title: "Diagnostik",
            description: "Diagnostik der Neutropenie und febrilen Neutropenie",
            keywords: "Diagnostik",
            pageScope: "fn",
            view: FnDiagnostics,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "neutropenie",
                name: "Diagnostik Neutropenie",
                title: "Diagnostik Neutropenie",
                description:
                  "Hier finden Sie Informationen zu Diagnostik und Klassifizierung der Neutropenie",
                keywords: "Diagnostik",
                pageScope: "fn",
                view: FnDiagnosticNeutropenia,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "fn",
                name: "Diagnostik & initiales Management FN",
                title: "Diagnostik & initiales Management FN",
                description:
                  "Hier finden Sie eine Checkliste zu Diagnostik und initialem Management der febrilen Neutropenie",
                keywords: "Diagnostik",
                pageScope: "fn",
                view: FnDiagnosticFn,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "sepsis",
                name: "Diagnostik der Sepsis",
                title: "Diagnostik der Sepsis",
                description:
                  "Hier finden Sie Hinweise, die auf eine Sepsis hindeuten, um diese rechtzeitig zu erkennen",
                keywords: "Diagnostik",
                pageScope: "fn",
                view: FnDiagnosticSepsis,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "lungeninfiltrate",
                name: "Diagnostik Lungeninfiltrate",
                title: "Diagnostik Lungeninfiltrate",
                description:
                  "In diesem Bereich finden Sie Hinweise zu Vorgehen und Therapie bei dieser häufigen Komplikation",
                keywords: "Diagnostik",
                pageScope: "fn",
                view: FnDiagnosticPulmonaryInfiltrates,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
          {
            path: "risiko",
            name: "Risiko",
            title: "Risiko",
            description:
              "Risikofaktoren und -einschätzung der febrilen Neutropenie",
            keywords: "Risikofaktoren, Risikoeinschätzung, MASCC Score",
            pageScope: "fn",
            view: FnRisk,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "risikoeinschaetzung",
                name: "Risikoeinschätzung (MASCC Score)",
                title: "Risikoeinschätzung (MASCC Score)",
                description:
                  "Hier finden Sie den MASCC Risiko-Score zur Einschätzung von Komplikationsrate und Mortalität bei FN",
                keywords: "Risikoeinschätzung, MASCC Score",
                pageScope: "fn",
                view: FnRiskMascc,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "risikofaktoren",
                name: "Risikofaktoren",
                title: "Risikofaktoren",
                description:
                  "Hier finden Sie eine Beschreibung der Risikofaktoren für FN",
                keywords: "Risikofaktoren",
                pageScope: "fn",
                view: FnRiskFactors,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
          {
            path: "prophylaxe",
            name: "FN-Prophylaxe",
            title: "FN-Prophylaxe",
            description: "Prophylaxe der febrilen Neutropenie",
            keywords: "Prophylaxe",
            pageScope: "fn",
            view: FnProphylaxis,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "g-csf-prophylaxe",
                name: "G-CSF-Prophylaxe der FN",
                title: "G-CSF-Prophylaxe der FN",
                description:
                  "Hier finden Sie Informationen zur prophylaktischen G-CSF-Gabe",
                keywords: "Prophylaxe",
                pageScope: "fn",
                view: FnProphylaxisGCsfProphylaxis,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "chemotherapie-regime",
                name: "FN Risiko CTx-Regime",
                title: "FN Risiko CTx-Regime",
                description:
                  "In diesem Bereich finden Sie Risiko-Klassifikationen verschiedener Chemotherapie-Regime",
                keywords: "FN-Risiko, Chemotherapie",
                pageScope: "fn",
                view: FnProphylaxisCtRegimes,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "dosierung",
                name: "Dosierung",
                title: "Dosierung",
                description:
                  "In diesem Bereich finden Sie Erläuterungen zur Dosierung von Filgrastim und Pegfilgrastim",
                keywords: "Dosierung",
                pageScope: "fn",
                view: FnProphylaxisDosage,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "anwendung",
                name: "Anwendung Fertigspritze",
                title: "Anwendung Fertigspritze",
                description:
                  "In diesem Bereich finden Sie eine Erklärung zur Anwendung der Fertigspritze",
                keywords: "Fertigspritze",
                pageScope: "fn",
                view: FnProphylaxisApplication,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
          {
            path: "therapie-cin",
            name: "Therapie CIN",
            title: "Therapie CIN",
            description: "Hinweise zur Therapie bei CIN",
            keywords: "Therapie",
            pageScope: "fn",
            view: FnTherapy,
            config: {
              protectedRoute: true,
            },
            children: [],
          },
          {
            path: "aus-der-praxis",
            name: "Aus der Praxis",
            title: "Aus der Praxis",
            description:
              "Übersichtsseite zu den Themen des Unterkapitels aus der Praxis",
            keywords: "Aus der Praxis",
            pageScope: "fn",
            view: FnPraxis,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "praxistipps",
                name: "Praxistipps",
                title: "Praxistipps",
                description: "Hier finden Sie aktuelle Praxistipps",
                keywords: "Praxistipps",
                pageScope: "fn",
                view: FnPraxisPracticalTips,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "kasuistiken",
                name: "Kasuistiken",
                title: "Kasuistiken",
                description:
                  "In diesem Bereich finden Sie verschiedene Kasuistiken",
                keywords: "Kasuistik",
                pageScope: "fn",
                view: FnPraxisCasuistics,
                config: {
                  protectedRoute: true,
                },
                children: [
                  {
                    path: "morbus-hodgkin",
                    name: "Morbus Hodgkin",
                    title: "Morbus Hodgkin",
                    description:
                      "Kasuistik zur Rolle der Supportivtherapie bei Morbus Hodgkin",
                    keywords: "Morbus Hodgkin",
                    pageScope: "fn",
                    view: FnPraxisCasuisticsMorbusHodgkin,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "indolentes-lymphom",
                    name: "Indolentes Lymphom",
                    title: "Indolentes Lymphom",
                    description:
                      "Kasuistik zur Rolle der Supportivtherapie beim indolenten Lymphom",
                    keywords: "Indolentes Lymphom",
                    pageScope: "fn",
                    view: FnPraxisCasuisticsIndolentLymphoma,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "mammakarzinom",
                    name: "Kasuistiken – Mammakarzinom",
                    title: "Kasuistiken – Mammakarzinom",
                    description:
                      "Rolle der Supportivtherapie beim Mammakarzinom",
                    keywords: "Kasuistiken",
                    pageScope: "fn",
                    view: FnPraxisCasuisticsMammaCa,
                    config: {
                      protectedRoute: true,
                    },
                    children: [
                      {
                        path: "dosisintensivierte-therapie",
                        name: "Dosisintensivierte Therapie",
                        title: "Dosisintensivierte Therapie",
                        description:
                          "Kasuistik zur Rolle der Supportivtherapie beim Mammakarzinom – Dosisintensivierte Therapie",
                        keywords:
                          "Kasuistik zur Rolle der Supportivtherapie beim Mammakarzinom – Dosisintensivierte Therapie",
                        pageScope: "fn",
                        view: FnPraxisCasuisticsMammaCaIntensivied,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                      {
                        path: "standardtherapie",
                        name: "Standardtherapie",
                        title: "Standardtherapie",
                        description:
                          "Kasuistik zur Rolle der Supportivtherapie beim Mammakarzinom – Standardtherapie",
                        keywords: "Standardtherapie, Mammakarzinom",
                        pageScope: "fn",
                        view: FnPraxisCasuisticsMammaCaStandard,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                      {
                        path: "dosisdichte-therapie",
                        name: "Dosisdichte Therapie",
                        title: "Dosisdichte Therapie",
                        description:
                          "Kasuistik zur Rolle der Supportivtherapie beim Mammakarzinom – Dosisdichte Therapie",
                        keywords: "Dosisdichte Therapie, Mammakarzinom",
                        pageScope: "fn",
                        view: FnPraxisCasuisticsMammaCaTense,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                path: "biosimilars",
                name: "Biosimilars",
                title: "Biosimilars",
                description:
                  "Bedeutung von Biosimilars für das Gesundheitssystem",
                keywords: "Biosimilars",
                pageScope: "fn",
                view: FnPraxisBiosimilars,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
        ],
      },
      {
        path: "mds",
        name: "MDS-assoziierte Anämie",
        title: "MDS-assoziierte Anämie",
        description:
          "Übersichtsseite zu den verschiedenen Themen des Kapitels MDS-assoziierte Anämie",
        keywords: "MDS-assoziierte Anämie",
        pageScope: "mds",
        view: MdsHome,
        config: {
          protectedRoute: true,
        },
        children: [
          {
            path: "erkrankung",
            name: "Erkrankung",
            title: "Erkrankung",
            description: "Definition und Symptome der MDS",
            keywords: "Erkrankung",
            pageScope: "mds",
            view: MdsDisease,
            config: {
              protectedRoute: true,
            },
            children: [],
          },
          {
            path: "diagnostik",
            name: "Diagnostik / Prognose",
            title: "Diagnostik / Prognose",
            description: "Übersichtsseite zur Diagnostik der MDS",
            keywords: "Diagnostik",
            pageScope: "mds",
            view: MdsDiagnostics,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "diagnostik-mds",
                name: "Diagnostik MDS",
                title: "Diagnostik MDS",
                description:
                  "Hier finden Sie Informationen zur Diagnosestellung der MDS",
                keywords: "Diagnostik",
                pageScope: "mds",
                view: MdsDiagnosticMds,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "klassifikation",
                name: "Klassifikation",
                title: "Klassifikation",
                description:
                  "Hier finden Sie die aktuelle WHO-Klassifikation der MDS",
                keywords: "Klassifikation",
                pageScope: "mds",
                view: MdsDiagnosticClassification,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "prognostische-faktoren",
                name: "Prognostische Faktoren",
                title: "Prognostische Faktoren",
                description: "Risiko-Scores zur Abschätzung der Prognose",
                keywords: "Risikoeinschätzung, IPSS-R, IPSS-M, Prognose",
                pageScope: "mds",
                view: MdsDiagnosticPrognostics,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
          {
            path: "therapeutische-massnahmen",
            name: "Therapeutische Maßnahmen",
            title: "Therapeutische Maßnahmen",
            description: "Übersichtsseite zu therapeutischen Maßnahmen",
            keywords: "Therapie, Maßnahmen",
            pageScope: "mds",
            view: MdsTherapy,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "management-mds-assoziierte-anaemie",
                name: "Management Niedrigrisiko MDS-assoziierte Anämie",
                title: "Management Niedrigrisiko MDS-assoziierte Anämie",
                description:
                  "Informationen zum Management bei MDS-assoziierter Anämie und zu verschiedenen Therapien",
                keywords: "Management, MDS, Anämie",
                pageScope: "mds",
                view: MdsTherapyLowRisk,
                config: {
                  protectedRoute: true,
                },
                children: [
                  {
                    path: "management",
                    name: "Management Niedrigrisiko MDS-assoziierte Anämie",
                    title: "Management Niedrigrisiko MDS-assoziierte Anämie",
                    description:
                      "Überblick zum Management der Niedrigrisiko MDS-assoziierten Anämie",
                    keywords: "Management, MDS, Anämie",
                    pageScope: "mds",
                    view: MdsTherapyLowRiskManagement,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "esa",
                    name: "Therapie ESA - Epoetin",
                    title: "Therapie ESA - Epoetin",
                    description: "Informationen zur Therapie mit ESA",
                    keywords: "ESA",
                    pageScope: "mds",
                    view: MdsTherapyLowRiskEsa,
                    config: {
                      protectedRoute: true,
                    },
                    children: [
                      {
                        path: "esa-therapie",
                        name: "Erythropoese stimulierende Agenzien – Epoetin",
                        title: "Erythropoese stimulierende Agenzien – Epoetin",
                        description:
                          "Hier finden Sie allgemeine Informationen zur Therapie mit ESA",
                        keywords: "ESA, Epoetin",
                        pageScope: "mds",
                        view: MdsTherapyLowRiskEsaTherapy,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                      {
                        path: "dosierung-epoetin-alfa",
                        name: "Dosierung",
                        title: "Dosierung",
                        description:
                          "Hier finden Sie die Empfehlungen zur Dosierung von Epoetin alfa HEXAL®",
                        keywords: "Dosierung",
                        pageScope: "mds",
                        view: MdsTherapyLowRiskEsaDosage,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                      {
                        path: "anwendung-fertigspritze",
                        name: "Anwendung Fertigspritze Epoetin alfa HEXAL®",
                        title: "Anwendung Fertigspritze Epoetin alfa HEXAL®",
                        description:
                          "In diesem Bereich finden Sie eine Erklärung zur Anwendung der Fertigspritze",
                        keywords: "Fertigspritze",
                        pageScope: "mds",
                        view: MdsTherapyLowRiskEsaApplication,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                      {
                        path: "nordic-score",
                        name: "Abschätzen ESA-Therapieerfolg – Nordic Score",
                        title: "Abschätzen ESA-Therapieerfolg – Nordic Score",
                        description:
                          "Hier finden Sie eine Beschreibung des Nordic Scores zur Abschätzung des Therapieerfolgs",
                        keywords: "Therapieerfolg, Nordic Score",
                        pageScope: "mds",
                        view: MdsTherapyLowRiskEsaNordicScore,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                    ],
                  },
                  {
                    path: "immunmodulatoren",
                    name: "Immunmodulatoren - Lenalidomid",
                    title: "Immunmodulatoren - Lenalidomid",
                    description:
                      "MDS-Therapie mit Immunmodulatoren - Lenalidomid",
                    keywords: "Immunmodulatoren, Lenalidomid",
                    pageScope: "mds",
                    view: MdsTherapyLowRiskImmunomodulators,
                    config: {
                      protectedRoute: true,
                    },
                    children: [
                      {
                        path: "lenalidomid",
                        name: "Therapie Lenalidomid",
                        title: "Therapie Lenalidomid",
                        description:
                          "Hier finden Sie Informationen zur Therapie mit Lenalidomid HEXAL®",
                        keywords: "Immunmodulatoren, Lenalidomid",
                        pageScope: "mds",
                        view: MdsTherapyLowRiskImmunomodulatorsLenalidomidTherapy,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                      {
                        path: "dosierung-lenalidomid",
                        name: "Dosierung Lenalidomid",
                        title: "Dosierung Lenalidomid",
                        description:
                          "Hier finden Sie die Dosierungsempfehlungen von Lenalidomid HEXAL®",
                        keywords: "Immunmodulatoren, Lenalidomid",
                        pageScope: "mds",
                        view: MdsTherapyLowRiskImmunomodulatorsLenalidomidDosage,
                        config: {
                          protectedRoute: true,
                        },
                        children: [],
                      },
                    ],
                  },
                  {
                    path: "haematopoetische-differenzierungsfaktoren",
                    name: "Hämatopoetische Differenzierungsfaktoren - Luspatercept",
                    title:
                      "Hämatopoetische Differenzierungsfaktoren - Luspatercept",
                    description:
                      "Informationen zur Therapie mit hämatopoetischen Differenzierungsfaktoren",
                    keywords:
                      "Hämatopoetische Differenzierungsfaktoren, Luspatercept",
                    pageScope: "mds",
                    view: MdsTherapyLowRiskLuspatercept,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                ],
              },
              {
                path: "management-eisenueberladung",
                name: "Management transfusionsbedingte Eisenüberladung",
                title: "Management transfusionsbedingte Eisenüberladung",
                description:
                  "Informationen zu den Risiken und zur Therapie bei transfusionsbedingter Eisenüberladung",
                keywords: "Transfusionsbedingte Eisenüberladung",
                pageScope: "mds",
                view: MdsTherapyIronOverload,
                config: {
                  protectedRoute: true,
                },
                children: [
                  {
                    path: "management",
                    name: "Transfusionsbedingte Eisenüberladung",
                    title: "Transfusionsbedingte Eisenüberladung",
                    description:
                      "Hier erfahren Sie, wie Sie die Risiken einer transfusionsbedingten Eisenüberladung reduzieren",
                    keywords: "Transfusionsbedingte Eisenüberladung",
                    pageScope: "mds",
                    view: MdsTherapyIronOverloadTransfusion,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "deferasirox",
                    name: "Eisenchelatoren - Deferasirox",
                    title: "Eisenchelatoren - Deferasirox",
                    description:
                      "Hier finden Sie Informationen zur Therapie mit Eisenchelatoren",
                    keywords: "Eisenchelatoren, Deferasirox",
                    pageScope: "mds",
                    view: MdsTherapyIronOverloadDeferasirox,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "dosierung-deferasirox",
                    name: "Dosierung Deferasirox",
                    title: "Dosierung Deferasirox",
                    description:
                      "Hier finden Sie die empfohlene Dosierung von Deferasirox HEXAL®",
                    keywords: "Dosierung, Deferasirox",
                    pageScope: "mds",
                    view: MdsTherapyIronOverloadDeferasiroxDosage,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                ],
              },
            ],
          },
          {
            path: "aus-der-praxis",
            name: "Aus der Praxis",
            title: "Aus der Praxis",
            description:
              "Übersichtsseite zu den Themen des Unterkapitels aus der Praxis",
            keywords: "Praxis",
            pageScope: "mds",
            view: MdsPraxis,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "praxistipps",
                name: "Praxistipps",
                title: "Praxistipps",
                description: "Hier finden Sie aktuelle Praxistipps",
                keywords: "Praxis",
                pageScope: "mds",
                view: MdsPraxisPracticalTips,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "kasuistiken",
                name: "Kasuistiken",
                title: "Kasuistiken",
                description:
                  "In diesem Bereich finden Sie verschiedene Kasuistiken",
                keywords: "Kasuistiken",
                pageScope: "mds",
                view: MdsPraxisCasuistics,
                config: {
                  protectedRoute: true,
                },
                children: [
                  {
                    path: "mds-del-5q",
                    name: "MDS del(5q)",
                    title: "MDS del(5q)",
                    description:
                      "Symptomatische und transfusionsbedürftige Anämie - bei MDS del(5q)",
                    keywords: "Kasuistiken",
                    pageScope: "mds",
                    view: MdsPraxisCasuisticsMdsDel5q,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "mds-mld",
                    name: "MDS-MLD",
                    title: "MDS-MLD",
                    description:
                      "Symptomatische und transfusionsbedürftige Anämie – bei Subtyp MDS-MLD",
                    keywords: "Kasuistiken",
                    pageScope: "mds",
                    view: MdsPraxisCasuisticsMdsMld,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "kardio",
                    name: "Symptomatische und transfusionsbedürftige Anämie",
                    title: "Symptomatische und transfusionsbedürftige Anämie",
                    description:
                      "Symptomatische und transfusionsbedürftige Anämie",
                    keywords: "Kasuistiken",
                    pageScope: "mds",
                    view: MdsPraxisCasuisticsCardio,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "cia",
        name: "Chemotherapie-induzierte Anämie",
        title: "Chemotherapie-induzierte Anämie",
        description:
          "Übersichtsseite zu den verschiedenen Themen des Kapitels Chemotherapie-induzierte Anämie",
        keywords: "Chemotherapie-induzierte Anämie, CIA",
        pageScope: "cia",
        view: CiaHome,
        config: {
          protectedRoute: true,
        },
        children: [
          {
            path: "erkrankung",
            name: "Erkrankung",
            title: "Erkrankung",
            description: "Definition und Symptome der CIA",
            keywords: "Erkrankung",
            pageScope: "cia",
            view: CiaDisease,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "definition",
                name: "Definition",
                title: "Definition",
                description:
                  "Hier finden Sie die Definitionen verschiedener Formen der Anämie",
                keywords: "Definition",
                pageScope: "cia",
                view: CiaDiseaseDefinition,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "symptome",
                name: "Symptome",
                title: "Symptome",
                description:
                  "Hier finden Sie eine Auflistung der häufigsten Symptome",
                keywords: "Symptome",
                pageScope: "cia",
                view: CiaDiseaseSymptoms,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
          {
            path: "diagnostik",
            name: "Diagnostik",
            title: "Diagnostik",
            description: "Übersichtsseite zur Diagnostik der Anämie",
            keywords: "Diagnostik",
            pageScope: "cia",
            view: CiaDiagnostics,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "anaemie",
                name: "Anämie",
                title: "Anämie",
                description:
                  "Hier finden Sie Informationen zur Diagnosestellung der Anämie",
                keywords: "Diagnostik",
                pageScope: "cia",
                view: CiaDiagnosticAnemia,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "eisenmangel",
                name: "Eisenmangel bei Chemotherapie-induzierter Anämie",
                title: "Eisenmangel bei Chemotherapie-induzierter Anämie",
                description:
                  "Hier finden Sie Informationen zur Therapie des Eisenmangels bei CIA",
                keywords: "Diagnostik",
                pageScope: "cia",
                view: CiaDiagnosticIronDeficiency,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
          {
            path: "therapie",
            name: "Therapie",
            title: "Therapie",
            description: "Übersichtsseite zur Therapie der CIA",
            keywords: "Therapie",
            pageScope: "cia",
            view: CiaTherapy,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "therapieoptionen",
                name: "Therapieoptionen bei CIA",
                title: "Therapieoptionen bei CIA",
                description:
                  "Hier finden Sie allgemeine Informationen zur Therapie der CIA",
                keywords: "Therapie",
                pageScope: "cia",
                view: CiaTherapyOptions,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "esa",
                name: "ESA bei CIA",
                title: "ESA bei CIA",
                description:
                  "Hier finden Sie Hinweise zur Therapie mit ESA bei CIA",
                keywords: "ESA, Erythropoese-stimulierende Agenzien, Therapie",
                pageScope: "cia",
                view: CiaTherapyEsa,
                config: {
                  protectedRoute: true,
                },
                children: [
                  {
                    path: "esa-bei-cia",
                    name: "Erythropoese-stimulierende Agenzien bei CIA",
                    title: "Erythropoese-stimulierende Agenzien bei CIA",
                    description:
                      "Hier finden Sie Informationen zu Nutzen, Risiko und Diagnostik",
                    keywords:
                      "ESA, Erythropoese-stimulierende Agenzien, Therapie",
                    pageScope: "cia",
                    view: CiaTherapyEsaEsaCia,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "dosierung",
                    name: "Dosierung & Dosisanpassung",
                    title: "Dosierung & Dosisanpassung",
                    description:
                      "Hier finden Sie Informationen zur Dosierung von Epoetin alfa HEXAL®",
                    keywords: "ESA, Dosierung, Epoetin alfa HEXAL®",
                    pageScope: "cia",
                    view: CiaTherapyEsaDosage,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                  {
                    path: "anwendung-fertigspritze",
                    name: "Anwendung Fertigspritze Epoetin alfa HEXAL®",
                    title: "Anwendung Fertigspritze Epoetin alfa HEXAL®",
                    description:
                      "Hier finden Sie Hinweise zur Anwendung der Fertigspritze",
                    keywords: "Anwendung, Fertigspritze, Epoetin alfa HEXAL®",
                    pageScope: "cia",
                    view: CiaTherapyEsaApplication,
                    config: {
                      protectedRoute: true,
                    },
                    children: [],
                  },
                ],
              },
              {
                path: "eisensubstitution",
                name: "Eisensubstitution bei CIA",
                title: "Eisensubstitution bei CIA",
                description:
                  "Hier finden Sie Hinweise zur Eisensubstitution bei CIA",
                keywords: "Eisensubstitution",
                pageScope: "cia",
                view: CiaTherapyIronSubstitution,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "ek",
                name: "Transfusion von Erythrozytenkonzentraten",
                title: "Transfusion von Erythrozytenkonzentraten",
                description:
                  "Hier finden Sie Hinweise zur Transfusion von Erythrozytenkonzentraten",
                keywords: "Transfusion, EK",
                pageScope: "cia",
                view: CiaTherapyEC,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
          {
            path: "pbm",
            name: "Patient Blood Management",
            title: "Patient Blood Management",
            description: "Informationen zum Patient Blood Management",
            keywords: "Patient Blood Management, PBM",
            pageScope: "cia",
            view: CiaPbm,
            config: {
              protectedRoute: true,
            },
            children: [],
          },
          {
            path: "aus-der-praxis",
            name: "Aus der Praxis",
            title: "Aus der Praxis",
            description:
              "Übersichtsseite zu den Themen des Unterkapitels Aus der Praxis",
            keywords: "Praxis",
            pageScope: "cia",
            view: CiaPraxis,
            config: {
              protectedRoute: true,
            },
            children: [
              {
                path: "praxistipps",
                name: "Praxistipps",
                title: "Praxistipps",
                description: "Hier finden Sie aktuelle Praxistipps",
                keywords: "Praxis",
                pageScope: "cia",
                view: CiaPraxisTipps,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
              {
                path: "kasuistik",
                name: "Kasuistik",
                title: "Kasuistik",
                description:
                  "Kasuistik zur Rolle der Supportivtherapie beim Mammakarzinom",
                keywords: "Mammakarzinom",
                pageScope: "cia",
                view: CiaPraxisCasuistic,
                config: {
                  protectedRoute: true,
                },
                children: [],
              },
            ],
          },
        ],
      },
      {
        path: "op",
        name: "Prä- und postoperatives Anämiemanagement",
        title: "Prä- und postoperatives Anämiemanagement",
        description:
          "Übersichtsseite zu den verschiedenen Themen des Kapitels Prä- und postoperatives Anämiemanagement",
        keywords: "Prä- und postoperatives Anämiemanagement",
        pageScope: "op",
        view: OpHome,
        config: {
          protectedRoute: true,
        },
        children: [
          {
            path: "praeoperative_anaemie",
            name: "Prä-operative Anämie",
            title: "Prä-operative Anämie",
            description:
              "Hier finde Sie die Definition und Prävalenz der präoperativen Anämie",
            keywords: "Präoperative Anämie",
            pageScope: "op",
            view: OpAnemia,
            config: {
              protectedRoute: true,
            },
            children: [],
          },
          {
            path: "ursachen",
            name: "Ursachen",
            title: "Ursachen",
            description:
              "Hier finden Sie eine Übersicht der Ursachen der präoperativen Anämie",
            keywords: "Präoperative Anämie",
            pageScope: "op",
            view: OpCauses,
            config: {
              protectedRoute: true,
            },
            children: [],
          },
          {
            path: "prae-und_post-operatives_anaemiemanagement",
            name: "Anämiemanagement",
            title: "Anämiemanagement",
            description: "Vorgehen bei Prä- und postoperativer Anämie",
            keywords: "Anämiemanagement",
            pageScope: "op",
            view: OpManagement,
            config: {
              protectedRoute: true,
            },
            children: [],
          },
        ],
      },
    ],
  },
];

export default sitemap;
