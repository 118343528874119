import { Grid } from "@mui/material";
import { useContext } from "react";
import {
  BloodDropIcon,
  ColoredText,
  DiagnosticsIcon,
  LightBulbIcon,
  MainBackground,
  MedicBookIcon,
  PagePadding,
  PrimaryNavIconButton,
  SyringeIcon,
} from "components/ui";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import CiaDisease from "./disease";
import CiaDiagnostics from "./diagnostics";
import CiaTherapy from "./therapy";
import CiaPbm from "./pbm";
import CiaPraxis from "./praxis";

export function CiaHome() {
  const { currentPage } = useContext(GlobalContext);

  const diseaseChild = currentPage?.children.find(
    (child) => child.view === CiaDisease
  );
  const diagnosticsChild = currentPage?.children.find(
    (child) => child.view === CiaDiagnostics
  );
  const therapyChild = currentPage?.children.find(
    (child) => child.view === CiaTherapy
  );
  const patientBloodManagementChild = currentPage?.children.find(
    (child) => child.view === CiaPbm
  );
  const praxisChild = currentPage?.children.find(
    (child) => child.view === CiaPraxis
  );

  return (
    <>
      <HexalSeo />
      <MainBackground />
      <PagePadding>
        <h2>
          <ColoredText color="primary">
            Chemotherapie-induzierte Anämie
          </ColoredText>
        </h2>

        <Grid container spacing="24px">
          {diseaseChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: diseaseChild.title,
                  isExternal: false,
                  url: diseaseChild.path,
                  title: "Zum Kapitel Erkrankung",
                }}
                icon={<MedicBookIcon />}
              />
            </Grid>
          )}
          {diagnosticsChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: diagnosticsChild.title,
                  isExternal: false,
                  url: diagnosticsChild.path,
                  title: "Zum Kapitel Diagnostik",
                }}
                icon={<DiagnosticsIcon />}
              />
            </Grid>
          )}
          {therapyChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: therapyChild.title,
                  isExternal: false,
                  url: therapyChild.path,
                  title: "Zum Kapitel Therapie",
                }}
                icon={<SyringeIcon />}
              />
            </Grid>
          )}
          {patientBloodManagementChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: patientBloodManagementChild.title,
                  isExternal: false,
                  url: patientBloodManagementChild.path,
                  title: "Zum Kapitel Patient Blood Management",
                }}
                icon={<BloodDropIcon />}
              />
            </Grid>
          )}
          {praxisChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: praxisChild.title,
                  isExternal: false,
                  url: praxisChild.path,
                  title: "Zum Kapitel Aus der Praxis",
                }}
                icon={<LightBulbIcon />}
              />
            </Grid>
          )}
        </Grid>
      </PagePadding>
    </>
  );
}

export default CiaHome;
