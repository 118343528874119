import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import MdsTherapyLowRiskEsaTherapy from "./therapyLowRiskEsaTherapy";
import MdsTherapyLowRiskEsaDosage from "./therapyLowRiskEsaDosage";
import MdsTherapyLowRiskEsaApplication from "./therapyLowRiskEsaApplication";
import MdsTherapyLowRiskEsaNordicScore from "./therapyLowRiskEsaNordicScore";

export function MdsTherapyLowRiskEsa() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const therapy = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskEsaTherapy
  );
  if (therapy) {
    navigationItems.push({
      link: {
        label: therapy.title,
        isExternal: false,
        url: therapy.path,
      },
      description: therapy.description,
    });
  }

  const dosage = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskEsaDosage
  );
  if (dosage) {
    navigationItems.push({
      link: {
        label: dosage.title,
        isExternal: false,
        url: dosage.path,
      },
      description: dosage.description,
    });
  }

  const application = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskEsaApplication
  );
  if (application) {
    navigationItems.push({
      link: {
        label: application.title,
        isExternal: false,
        url: application.path,
      },
      description: application.description,
    });
  }

  const nordicScore = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskEsaNordicScore
  );
  if (nordicScore) {
    navigationItems.push({
      link: {
        label: nordicScore.title,
        isExternal: false,
        url: nordicScore.path,
      },
      description: nordicScore.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapie ESA</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskEsa;
