import { ReactNode, useContext } from "react";
import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import FnProphylaxisGCsfProphylaxis from "./prophylaxisGCsfProphylaxis";
import FnProphylaxisCtRegimes from "./prophylaxisCtRegimes";
import FnProphylaxisDosage from "./prophylaxisDosage";
import { FnProphylaxisApplication } from "./prophylaxisApplication";
import GlobalContext from "../../context/globalContext";

export function FnProphylaxis() {
  const { currentPage } = useContext(GlobalContext);
  const navigationItems: { link: Link; description: string | ReactNode }[] = [];

  const gcsfProphylaxis = currentPage?.children.find(
    (child) => child.view === FnProphylaxisGCsfProphylaxis
  );
  if (gcsfProphylaxis) {
    navigationItems.push({
      link: {
        label: gcsfProphylaxis.title,
        isExternal: false,
        url: gcsfProphylaxis.path,
      },
      description: gcsfProphylaxis.description,
    });
  }

  const ctRegimes = currentPage?.children.find(
    (child) => child.view === FnProphylaxisCtRegimes
  );
  if (ctRegimes) {
    navigationItems.push({
      link: {
        label: ctRegimes.title,
        isExternal: false,
        url: ctRegimes.path,
      },
      description: ctRegimes.description,
    });
  }

  const dosage = currentPage?.children.find(
    (child) => child.view === FnProphylaxisDosage
  );
  if (dosage) {
    navigationItems.push({
      link: {
        label: dosage.title,
        isExternal: false,
        url: dosage.path,
      },
      description: dosage.description,
    });
  }

  const application = currentPage?.children.find(
    (child) => child.view === FnProphylaxisApplication
  );
  if (application) {
    navigationItems.push({
      link: {
        label: application.title,
        isExternal: false,
        url: application.path,
      },
      description: application.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Prophylaxe</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default FnProphylaxis;
