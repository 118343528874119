import { SvgIcon, SvgIconProps } from "@mui/material";

export function ModalCloseIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="9.899"
      height="9.899"
      viewBox="0 0 9.899 9.899"
      {...props}
    >
      <g data-name="Close Button">
        <path
          data-name="Linie 184"
          transform="rotate(45 3.743 11.45)"
          style={{ fill: "none", stroke: "#2660a4", strokeWidth: "2px" }}
          d="M0 0v12"
        />
        <path
          data-name="Linie 185"
          transform="rotate(45 -.5 1.207)"
          style={{ fill: "none", stroke: "#2660a4", strokeWidth: "2px" }}
          d="M0 0h12"
        />
      </g>
    </SvgIcon>
  );
}

export default ModalCloseIcon;
