import { Graph, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";

import * as dosageAdjustment from "../../assets/img/mds/therapy/grafik-dosisanpassung.svg";

export function MdsTherapyLowRiskEsaDosage() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Dosierung Epoetin alfa HEXAL
          <sup>®</sup> <TextReference references={["ref3"]} />
        </h1>
        <p>
          Epoetin alfa HEXAL
          <sup>®</sup> bei symptomatischer Anämie:{" "}
          <Abbreviation>hb</Abbreviation> &le; 10 g/dl (&le; 6,2 mmol/l)
        </p>

        <h2>Empfohlene Anfangsdosierung</h2>
        <ul>
          <li>
            450 <Abbreviation>ie</Abbreviation> /kg{" "}
            <Abbreviation>kg</Abbreviation>{" "}
            <TextInfo>
              max. Gesamtdosis von 40.000 <Abbreviation>ie</Abbreviation>
            </TextInfo>
          </li>
          <li>1 x wöchentlich</li>
          <li>Abstand von mind. 5 Tagen</li>
        </ul>

        <h2>Dosisanpassung</h2>
        <ul>
          <li>
            Erhaltung des Zielbereichs: 10–12 g/dl (6,2–7,5 mmol/l) Empfehlung:
            8–12 Wochen nach Beginn der Therapie Beurteilung des initialen
            erythroiden Ansprechens
          </li>
          <li>
            Erhöhung oder Reduktion sollte jeweils um einen Dosisschritt
            erfolgen
          </li>
          <li>
            <Abbreviation>hb</Abbreviation> &gt; 12 g/dl (7,5 mmol/l) sollte
            vermieden werden
          </li>
        </ul>
        <Graph
          bordered
          largeWidth="50%"
          imagePath={dosageAdjustment.default}
          alt="Dosisanpassung Epoetin alfa"
        />

        <h2>Dosisunterbrechung und Dosisreduktion</h2>
        <ul>
          <li>
            <Abbreviation>hb</Abbreviation> &gt; 12 g/dl (7,5 mmol/l): Gabe von
            Epoetin alfa HEXAL
            <sup>®</sup> sollte unterbrochen werden
          </li>
          <li>
            Hb &lt; 11 g/dl: Gabe kann mit der gleichen Dosis oder der um einen
            Dosisschritt darunter liegenden Dosis, je nach ärztlicher
            Beurteilung, wieder aufgenommen werden
          </li>
          <li>
            Rascher Anstieg des Hb-Wertes (&gt;2 g/dl über 4 Wochen): Senkung
            der Dosis um einen Dosisschritt in Betracht ziehen
          </li>
        </ul>

        <h2>Dosiserhöhung</h2>
        <ul>
          <li>
            Dosis sollte max. 1.050 IE/kg KG (Gesamtdosis 80.000 IE) wöchentlich
            nicht überschreiten.
          </li>
          <li>
            Dosis um einen Dosisschritt anheben, falls der Patient/die Patientin
            nicht mehr anspricht oder der Hb-Wert nach einer Reduktion um &ge; 1
            g/dl abfällt.
          </li>
          <li>
            Zwischen den Dosissteigerungen sollte ein Abstand von mind. 4 Wochen
            liegen.
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskEsaDosage;
