/** @jsxImportSource @emotion/react */

import { Alert, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";

export interface ResultBoxProps {
  label: string;
  variant: "large" | "small";
  color: "error" | "warning" | "success" | "primary";
}

export function ResultBox(props: ResultBoxProps) {
  const theme = useTheme();
  const styles = {
    root: css`
      justify-content: center;
      padding-top: ${props.variant === "small" ? 0 : "6px"};
      padding-bottom: ${props.variant === "small" ? 0 : "6px"};
    `,
    large: css`
      width: 100%;
      height: auto;
      border-radius: 0;
      span {
        padding: 15px;
      }
    `,
    small: css`
      border-radius: 7px;
      min-width: 100%;
    `,
    label: css`
      color: ${theme.palette[props.color].main};
      text-align: center;
    `,
  };

  return (
    <Alert
      css={[styles[props.variant], styles.root]}
      icon={false}
      variant={props.variant === "small" ? "outlined" : undefined}
      severity={props.color === "primary" ? "info" : props.color}
    >
      <Typography
        css={styles.label}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: props.label }}
      />
    </Alert>
  );
}

export default ResultBox;
