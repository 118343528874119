export interface SignedNumberProps {
  children: number;
}

export function SignedNumber(props: SignedNumberProps) {
  return (
    <span>
      {!isNaN(props.children)
        ? props.children >= 0
          ? `+ ${props.children.toLocaleString("de")}`
          : `- ${Math.abs(props.children as number).toLocaleString("de")}`
        : props.children}
    </span>
  );
}

export default SignedNumber;
