import { SvgIcon, SvgIconProps } from "@mui/material";

export function MedicBookIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <path
        data-name="Rechteck 593"
        style={{ fill: "none" }}
        d="M0 0h34v34H0z"
      />
      <g data-name="Gruppe 905">
        <path
          data-name="Pfad 419"
          d="M35.739 13.539A.547.547 0 0 0 35.2 13H18.792a3.186 3.186 0 0 0-3.192 3.1v15.555a3.818 3.818 0 0 0 3.821 3.821H35.2a.547.547 0 0 0 .539-.539v-21.4zM18.252 34.217a2.793 2.793 0 0 1-1.618-2.517V18.574a3.22 3.22 0 0 0 1.618.9zm16.408.225H19.331V19.563H34.66zm0-18.7H19.6a.547.547 0 0 0-.539.539.519.519 0 0 0 .539.539h15.06v1.708H18.882a2.383 2.383 0 0 1-2.2-2.293 2.11 2.11 0 0 1 2.113-2.113H34.66z"
          transform="translate(-8.587 -7.32)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 420"
          d="M30.114 39.732h1.843v1.843a1.238 1.238 0 0 0 1.214 1.214h2.248a1.238 1.238 0 0 0 1.214-1.214v-1.843h1.843a1.238 1.238 0 0 0 1.214-1.214V36.27a1.209 1.209 0 0 0-1.214-1.214h-1.844v-1.842A1.238 1.238 0 0 0 35.418 32H33.17a1.238 1.238 0 0 0-1.214 1.214v1.843h-1.842A1.238 1.238 0 0 0 28.9 36.27v2.248a1.27 1.27 0 0 0 1.214 1.214zm-.135-3.461a.144.144 0 0 1 .135-.135H32.5a.547.547 0 0 0 .539-.539v-2.383a.144.144 0 0 1 .135-.135h2.248a.144.144 0 0 1 .135.135V35.6a.547.547 0 0 0 .539.539h2.382a.132.132 0 0 1 .135.135v2.248a.144.144 0 0 1-.135.135h-2.386a.547.547 0 0 0-.539.539v2.382a.144.144 0 0 1-.135.135H33.17a.144.144 0 0 1-.135-.135v-2.386a.547.547 0 0 0-.539-.539h-2.382a.144.144 0 0 1-.135-.135z"
          transform="translate(-15.908 -17.779)"
          style={{ fill: "currentcolor" }}
        />
      </g>
    </SvgIcon>
  );
}

export default MedicBookIcon;
