const abbreviations = {
  fn: {
    abvd: {
      abbreviation: "ABVD",
      explanation:
        "Adriamycin (Doxorubicin), Bleomycin, Vinblastin, Dacarbazin",
    },
    ac: {
      abbreviation: "AC",
      explanation: "Doxorubicin, Cyclophosphamid",
    },
    acp: {
      abbreviation: "AC-P",
      explanation: "Doxorubicin, Cyclophosphamid, Paclitaxel",
    },
    adc: {
      abbreviation: "ADC",
      explanation: "Antikörper-Drug-Konjugat",
    },
    ago: {
      abbreviation: "AGO",
      explanation: "Arbeitsgemeinschaft Gynäkologische Onkologie",
    },
    aml: {
      abbreviation: "AML",
      explanation: "Akute Myeloische Leukämie",
    },
    b5b: {
      abbreviation: "B5b",
      explanation: "Invasives Karzinom",
    },
    bal: {
      abbreviation: "BAL",
      explanation: "Bronchoalveoläre Lavage",
    },
    beacopp: {
      abbreviation: "BEACOPP",
      explanation:
        "Bleomycin, Etoposid, Adriamycin, Cyclophosphamid, Vincristin, Procarbazin, Prednisolon",
    },
    bet: {
      abbreviation: "BET",
      explanation: "Brusterhaltende Therapie",
    },
    birads: {
      abbreviation: "BI-RADS",
      explanation: "Breast Imaging Reporting and Data System",
    },
    br: {
      abbreviation: "BR",
      explanation: "Bendamustin, Rituximab",
    },
    bsg: {
      abbreviation: "BSG",
      explanation: "Blutsenkung",
    },
    cll: {
      abbreviation: "CLL",
      explanation: "Chronische lymphatische Leukämie",
    },
    copd: {
      abbreviation: "COPD",
      explanation: "Chronisch obstruktive Lungenerkrankung",
    },
    crp: {
      abbreviation: "CRP",
      explanation: "C-reaktives Protein",
    },
    ct: {
      abbreviation: "CT",
      explanation: "Computertomografie",
    },
    ctcae: {
      abbreviation: "CTCAE",
      explanation: "Common Terminology Criteria for Adverse Events",
    },
    ctx: {
      abbreviation: "CTx",
      explanation: "Chemotherapie",
    },
    dcis: {
      abbreviation: "DCIS",
      explanation: "Duktales Carcinoma in situ",
    },
    ddacp: {
      abbreviation: "ddAC-P",
      explanation:
        "Dosisdichte Therapie mit Doxorubicin, Cyclophosphamid, danach Paclitaxel",
    },
    ddecpcb: {
      abbreviation: "ddEC-PCb",
      explanation:
        "Dosisdichte Therapie mit Epirubicin, Cyclophosphamid, danach Paclitaxel, Carboplatin",
    },
    ddecpx12: {
      abbreviation: "ddEC-Px12",
      explanation:
        "Dosisdichte Therapie mit Epirubicin, Cyclophosphamid, danach Paclitaxel",
    },
    ddectx4: {
      abbreviation: "ddEC-Tx4",
      explanation:
        "Dosisdichte Therapie mit Epirubicin, Cyclophosphamid, danach Docetaxel",
    },
    e: {
      abbreviation: "E.",
      explanation: "Einheit",
    },
    ec: {
      abbreviation: "EC",
      explanation: "Epirubicin, Cyclophosphamid",
    },
    ecog: {
      abbreviation: "ECOG",
      explanation: "Performance-Status der Eastern Cooperative Oncology Group",
    },
    ecp: {
      abbreviation: "EC-P",
      explanation: "Epirubicin, Cyclophosphamid, Paclitaxel",
    },
    fcr: {
      abbreviation: "FC-R",
      explanation: "Fludarabin, Cyclophosphamid, Rituximab",
    },
    fio2: {
      abbreviation: "F<sub>i</sub>O<sub>2</sub>",
      explanation: "inspiratorische Sauerstoffkonzentration",
    },
    fn: {
      abbreviation: "FN",
      explanation: "Febrile Neutropenie",
    },
    g2: {
      abbreviation: "G2",
      explanation: "Grading G2, mäßig differenziert",
    },
    g3: {
      abbreviation: "G3",
      explanation: "Grading G3, schlecht differenziert",
    },
    gcsf: {
      abbreviation: "G-CSF",
      explanation: "Granulozyten-Kolonie-stimulierender Faktor",
    },
    hb: {
      abbreviation: "Hb",
      explanation: "Hämoglobin",
    },
    her2: {
      abbreviation: "HER2-",
      explanation: "Human epidermal growth factor receptor 2 negative",
    },
    hr: {
      abbreviation: "HR",
      explanation: "Hormonrezeptor",
    },
    hrplus: {
      abbreviation: "HR+",
      explanation: "Hormonrezeptor-positiv",
    },
    iddepc: {
      abbreviation: "iddEPC",
      explanation:
        "Dosisintensivierte Therapie mit Epirubicin, Paclitaxel, Cyclophosphamid",
    },
    km: {
      abbreviation: "KM",
      explanation: "Knochenmark",
    },
    kof: {
      abbreviation: "KOF",
      explanation: "Körperoberfläche",
    },
    ldh: {
      abbreviation: "LDH",
      explanation: "Laktat-Dehydrogenase",
    },
    mak: {
      abbreviation: "MAK",
      explanation: "Mamillen-Areola-Komplex",
    },
    mrt: {
      abbreviation: "MRT",
      explanation: "Magnetresonanztomografie",
    },
    mascc: {
      abbreviation: "MASCC",
      explanation: "Multinational Association of Supportive Care in Cancer",
    },
    nabpec: {
      abbreviation: "nabP-EC",
      explanation:
        "Paclitaxel als an Albumin gebundene Nanopartikel-Formulierung, Epirubicin, Cyclophosphamid",
    },
    nyha: {
      abbreviation: "NYHA",
      explanation: "New York Heart Association",
    },
    p: {
      abbreviation: "P",
      explanation: "Paclitaxel",
    },
    p175: {
      abbreviation: "P175",
      explanation: "Paclitaxel 175 mg/m<sup>2</sup>",
    },
    p3: {
      abbreviation: "P3",
      explanation: "P-Scoring-System, diffuse Metastasen",
    },
    pao2: {
      abbreviation: "P<sub>a</sub>O<sub>2</sub>",
      explanation: "arterieller O2-Partialdruck",
    },
    pp: {
      abbreviation: "PP",
      explanation: "Primär-Prophylaxe",
    },
    r: {
      abbreviation: "R",
      explanation: "Rituximab",
    },
    rchop: {
      abbreviation: "R-CHOP",
      explanation:
        "Rituximab, Cyclophosphamid/Hydroxydaunorubicin (Doxorubicin)/Vincristin/Prednison",
    },
    sc: {
      abbreviation: "s.c.",
      explanation: "subkutan",
    },
    sd: {
      abbreviation: "SD",
      explanation: "Standardabweichung",
    },
    snb: {
      abbreviation: "SNB",
      explanation: "Sentinel-Lymphknoten-Biopsie",
    },
    sp: {
      abbreviation: "SP",
      explanation: "Sekundär-Prophylaxe",
    },
    t100: {
      abbreviation: "T100",
      explanation: "Docetaxel 100 mg/m<sup>2</sup>",
    },
    tac: {
      abbreviation: "TAC",
      explanation: "Docetaxel, Doxorubicin, Cyclophosphamid",
    },
    tc: {
      abbreviation: "TC",
      explanation: "Docetaxel, Cyclophosphamid",
    },
    tcbh: {
      abbreviation: "TCbH",
      explanation: "Docetaxel, Carboplatin, Trastuzumab",
    },
    tdxd: {
      abbreviation: "T-Dxd",
      explanation: "Trastuzumab Deruxtecan",
    },
    tdm1: {
      abbreviation: "T-DM1",
      explanation: "Trastuzumab-Emtansin",
    },
    tp53: {
      abbreviation: "TP53",
      explanation: "Tumor-Protein 53",
    },
  },
  cia: {
    ago: {
      abbreviation: "AGO",
      explanation: "Arbeitsgemeinschaft Gynäkologische Onkologie",
    },
    aml: {
      abbreviation: "AML",
      explanation: "Akute myeloische Leukämie",
    },
    bet: {
      abbreviation: "BET",
      explanation: "Brusterhaltende Therapie",
    },
    birads: {
      abbreviation: "BI-RADS",
      explanation: "Breast Imaging-Reporting and Data System",
    },
    bsg: {
      abbreviation: "BSG",
      explanation: "Blutsenkungsgeschwindigkeit",
    },
    chr: {
      abbreviation: "CHr",
      explanation: "Retikulozytenhämoglobin",
    },
    cia: {
      abbreviation: "CIA",
      explanation: "Chemotherapie-induzierte Anämie",
    },
    crp: {
      abbreviation: "CRP",
      explanation: "C-reaktives Protein",
    },
    ctx: {
      abbreviation: "CTx",
      explanation: "Chemotherapie-Regime",
    },
    ek: {
      abbreviation: "EK",
      explanation: "Erythrozytenkonzentrat",
    },
    esa: {
      abbreviation: "ESA",
      explanation: "Erythropoese-stimulierende Agenzien",
    },
    gvhd: {
      abbreviation: "GvHD",
      explanation: "Graft-versus-Host-Krankheit",
    },
    hb: {
      abbreviation: "Hb",
      explanation: "Hämoglobin",
    },
    her2: {
      abbreviation: "HER2",
      explanation: "Human epidermal growth factor receptor 2",
    },
    hk: {
      abbreviation: "Hk",
      explanation: "Hämatokrit",
    },
    hszt: {
      abbreviation: "HSZT",
      explanation: "Hämatopoetische Stammzelltransplantation",
    },
    hv: {
      abbreviation: "HV",
      explanation: "Herzversagen",
    },
    iddepc: {
      abbreviation: "iddEPC",
      explanation:
        "Dosisintensivierte Therapie mit Epirubicin, Paclitaxel, Cyclophosphamid",
    },
    infl: {
      abbreviation: "Infl",
      explanation: "Akute oder chronische Inflammation",
    },
    inr: {
      abbreviation: "INR",
      explanation: "International Normalized Ratio",
    },
    khk: {
      abbreviation: "KHK",
      explanation: "Koronare Herzkrankheit",
    },
    ldh: {
      abbreviation: "LDH",
      explanation: "Laktat-Dehydrogenase",
    },
    maca: {
      abbreviation: "MaCa",
      explanation: "Mammakarzinom",
    },
    mch: {
      abbreviation: "MCH",
      explanation:
        "Engl. mean corpuscular haemoglobin, mittleres korpusukuläres Hämoglobin",
    },
    mcv: {
      abbreviation: "MCV",
      explanation:
        "Engl. mean corpuscular volume, mittleres Erythrozyten-Einzelvolumen",
    },
    mnpct: {
      abbreviation: "Mn-pCT",
      explanation:
        "Engl. Myeloid neoplasms post cytotoxic therapy, Myeloproliferative Neoplasien nach zytotoxischer Therapie",
    },
    ni: {
      abbreviation: "NI",
      explanation: "Niereninsuffizienz",
    },
    pavk: {
      abbreviation: "pAVK",
      explanation: "Periphere arterielle Verschlusskrankheit",
    },
    pbm: {
      abbreviation: "PBM",
      explanation: "Patient Blood Management",
    },
    rpi: {
      abbreviation: "RPI",
      explanation: "Retikulozytenproduktionsindex",
    },
    snb: {
      abbreviation: "SNB",
      explanation: "Sentinel-Node-Biopsie",
    },
    stfr: {
      abbreviation: "sTfR",
      explanation: "löslicher Transferrin Rezeptor",
    },
    te: {
      abbreviation: "TE",
      explanation: "Thromboembolische Ereignisse",
    },
    tsat: {
      abbreviation: "TSAT",
      explanation: "Transferrinsätigung",
    },
    zpp: {
      abbreviation: "ZPP",
      explanation: "Zink-Protoporphyrin",
    },
  },
  mds: {
    acml: {
      abbreviation: "aCML",
      explanation: "Atypische chronische myeloische Leukämie",
    },
    aml: {
      abbreviation: "AML",
      explanation: "Akute myeloische Leukämie",
    },
    anc: {
      abbreviation: "ANC",
      explanation:
        "Absolute Neutrophilenzahl (engl.: absolute neutrophil count)",
    },
    atg: {
      abbreviation: "ATG",
      explanation: "Anti-Thymozytenglobulin",
    },
    az: {
      abbreviation: "AZ",
      explanation: "Allgemeinzustand",
    },
    bm: {
      abbreviation: "BM",
      explanation: "Knochenmark",
    },
    csa: {
      abbreviation: "CsA",
      explanation: "Cyclosporin A",
    },
    ecog: {
      abbreviation: "ECOG",
      explanation: "Eastern Cooperative Oncology Group",
    },
    efs: {
      abbreviation: "EFS",
      explanation: "Ereignisfreies Überleben (engl.: event free survival)",
    },
    ek: {
      abbreviation: "EK",
      explanation: "Erythrozyten-Konzentrat",
    },
    epo: {
      abbreviation: "EPO",
      explanation: "Erythropoetin",
    },
    esa: {
      abbreviation: "ESA",
      explanation: "Erythropoese stimulierende Agenzien",
    },
    fabm6: {
      abbreviation: "FAB-M6",
      explanation: "FAB-Klassifikation: Akute Erythroleukämie (Erythrämie)",
    },
    fe: {
      abbreviation: "Fe",
      explanation: "Eisen",
    },
    fish: {
      abbreviation: "FISH",
      explanation: "Fluorescent in situ hybridization",
    },
    gcsf: {
      abbreviation: "G-CSF",
      explanation: "Granulozyten-Kolonie-stimulierender Faktor",
    },
    gpt: {
      abbreviation: "Gpt",
      explanation: "Gigapartikel",
    },
    hb: {
      abbreviation: "Hb",
      explanation: "Hämoglobin",
    },
    hla: {
      abbreviation: "HLA",
      explanation:
        "Histokompatibilitätskomplex (engl.: human leukocyte antigen)",
    },
    icr: {
      abbreviation: "ICR",
      explanation: "Interkostalraum",
    },
    ie: {
      abbreviation: "IE",
      explanation: "Internationale Einheiten",
    },
    int: {
      abbreviation: "INT",
      explanation: "Intermediate",
    },
    ipss: {
      abbreviation: "IPSS",
      explanation: "International Prognostic Scoring System",
    },
    ipssr: {
      abbreviation: "IPSS-R",
      explanation: "International Prognostic Scoring System – Revised",
    },
    kg: {
      abbreviation: "KG",
      explanation: "Körpergewicht",
    },
    km: {
      abbreviation: "KM",
      explanation: "Knochenmark",
    },
    kmp: {
      abbreviation: "KMP",
      explanation: "Knochenmarkspunktion",
    },
    l: {
      abbreviation: "L",
      explanation: "Leukozyten",
    },
    ldh: {
      abbreviation: "LDH",
      explanation: "Lactatdehydrogenase",
    },
    lgl: {
      abbreviation: "LGL",
      explanation: "Large Granular Lymphocytes",
    },
    mds: {
      abbreviation: "MDS",
      explanation: "Myelodysplastisches Syndrom",
    },
    mdsmld: {
      abbreviation: "MDS-MLD",
      explanation: "Myelodysplastisches Syndrom mit Mehrliniendysplasie",
    },
    nsar: {
      abbreviation: "NSAR",
      explanation: "Nicht-steroidales Antirheumatikum",
    },
    omf: {
      abbreviation: "OMF",
      explanation: "Osteomyelofibrose",
    },
    po: {
      abbreviation: "p.o.",
      explanation: "Oral",
    },
    pb: {
      abbreviation: "PB",
      explanation: "Peripheres Blut",
    },
    pnh: {
      abbreviation: "PNH",
      explanation: "Paroxysmale nächtliche Hämoglobinurie",
    },
    prca: {
      abbreviation: "PRCA",
      explanation: "Pure Red Cell Aplasia",
    },
    sc: {
      abbreviation: "s.c.",
      explanation: "Subkutan",
    },
    sepo: {
      abbreviation: "sEPO",
      explanation: "Serum-Erythropoetin",
    },
    tbc: {
      abbreviation: "Tbc",
      explanation: "Tuberkulose",
    },
    tep: {
      abbreviation: "TEP",
      explanation: "Total-Endoprothese",
    },
    tg: {
      abbreviation: "TG",
      explanation: "Trockengewicht",
    },
    thr: {
      abbreviation: "Thr",
      explanation: "Thrombozyten",
    },
    who: {
      abbreviation: "WHO",
      explanation: "World Health Organization",
    },
  },
  op: {
    ek: {
      abbreviation: "EK",
      explanation: "Erythrozytenkonzentrat",
    },
    hb: {
      abbreviation: "Hb",
      explanation: "Hämoglobin",
    },
  },
  home: {},
};

export default abbreviations;
