/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { css } from "@emotion/react";

export interface PagePaddingProps {
  children: ReactNode;
}

export function PagePadding(props: PagePaddingProps) {
  const styles = {
    root: css`
      padding: 20px 17px;
    `,
  };
  return <div css={styles.root}>{props.children}</div>;
}

export default PagePadding;
