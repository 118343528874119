/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import {
  CalculationBoxContentCell,
  CalculationBoxContentCellProps,
} from "./CalculationBoxContentCell";

export interface CalculationBoxProps {
  title?: string;
  left: CalculationBoxContentCellProps;
  right: CalculationBoxContentCellProps;
  description?: CalculationBoxContentCellProps;
}

export function CalculationBox(props: CalculationBoxProps) {
  const styles = {
    root: css`
      background: #eaf3fb;
      border-radius: 7px;
      padding: 12px;
    `,
    title: css`
      margin-bottom: 12px;
    `,
    body: css`
      display: flex;
      flex-wrap: wrap;
    `,
    column: css`
      text-align: left;
      width: calc(50% - 70px);
      flex-grow: 1;
    `,
    middle: css`
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      flex-grow: 1;
    `,
    description: css`
      margin-top: 16px;
      flex-grow: 1;
    `,
  };

  return (
    <div css={styles.root}>
      {props.title && (
        <Typography css={styles.title} variant="h3">
          {props.title}
        </Typography>
      )}
      <div css={styles.body}>
        <div css={styles.column}>
          <CalculationBoxContentCell
            title={props.left.title}
            content={props.left.content}
          />
        </div>
        <div css={styles.middle}>
          <Typography variant="body2">X</Typography>
        </div>
        <div css={styles.column}>
          <CalculationBoxContentCell
            title={props.right.title}
            content={props.right.content}
          />
        </div>
        {props.description && (
          <div css={styles.description}>
            <CalculationBoxContentCell
              title={props.description.title}
              content={props.description.content}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CalculationBox;
