import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import CiaTherapyEsaEsaCia from "./therapyEsaEsaCia";
import CiaTherapyEsaDosage from "./therapyEsaDosage";
import CiaTherapyEsaApplication from "./therapyEsaApplication";

export function CiaTherapyEsa() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const esaCia = currentPage?.children.find(
    (child) => child.view === CiaTherapyEsaEsaCia
  );
  if (esaCia) {
    navigationItems.push({
      link: {
        label: esaCia.title,
        isExternal: false,
        url: esaCia.path,
      },
      description: esaCia.description,
    });
  }

  const dosage = currentPage?.children.find(
    (child) => child.view === CiaTherapyEsaDosage
  );
  if (dosage) {
    navigationItems.push({
      link: {
        label: dosage.title,
        isExternal: false,
        url: dosage.path,
      },
      description: dosage.description,
    });
  }

  const application = currentPage?.children.find(
    (child) => child.view === CiaTherapyEsaApplication
  );
  if (application) {
    navigationItems.push({
      link: {
        label: application.title,
        isExternal: false,
        url: application.path,
      },
      description: application.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>ESA bei CIA</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default CiaTherapyEsa;
