import { SvgIcon, SvgIconProps } from "@mui/material";

export function SandozIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="306.93"
      height="29.69"
      viewBox="0 0 306.93 29.69"
    >
      <g id="Schriftzug" transform="translate(-17.2 -240.19)">
        <g id="Gruppe_1934" data-name="Gruppe 1934">
          <path
            id="Pfad_2142"
            data-name="Pfad 2142"
            d="M151.75,263.66V243.61h-5.52v-1.57H159.1v1.57h-5.55v20.05Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2143"
            data-name="Pfad 2143"
            d="M171,263.66V253.49c0-2.44-.87-4.72-2.89-4.72-1.22,0-2.66.77-4.49,2.5v12.38h-1.73v-23.2l1.73-.26v9.37c2.12-1.8,3.37-2.37,4.94-2.37,2.53,0,4.17,2.34,4.17,5.78v10.68H171Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2144"
            data-name="Pfad 2144"
            d="M187.95,262.73a14.465,14.465,0,0,1-5.17,1.06c-3.88,0-5.81-2.18-5.81-8.18,0-5.42,2.25-8.41,6-8.41,3.47,0,5.42,2.86,5.42,6.87,0,.58-.03,1.38-.06,1.83h-9.56c0,4.4,1.35,6.35,4.27,6.35a18.607,18.607,0,0,0,4.56-.74Zm-1.32-8.6c0-2.79-.96-5.45-3.82-5.45-2.98,0-3.95,3.02-4.04,5.78h7.86Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2145"
            data-name="Pfad 2145"
            d="M192.73,263.66V247.33h1.41l.19,2.44c2.28-2.44,3.08-2.57,4.27-2.57h.74v1.67h-.8c-1.54,0-2.31.67-4.08,2.5v12.29Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2146"
            data-name="Pfad 2146"
            d="M211.05,263.66l-.22-2.37c-2.34,2.02-3.59,2.5-5.52,2.5-2.5,0-4.17-1.96-4.17-4.72,0-3.05,2.37-4.62,8.15-4.62h1.41v-.74c0-3.05-.48-5.04-3.79-5.04a14.444,14.444,0,0,0-4.65.99l-.35-1.35a14.947,14.947,0,0,1,5.26-1.12c3.79,0,5.26,1.83,5.26,5.94v10.52h-1.38Zm-.35-7.77h-1.6c-4.91,0-6.13.99-6.13,3.05,0,1.83,1.03,3.3,2.76,3.3,1.7,0,2.86-.61,4.97-2.53v-3.82Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2147"
            data-name="Pfad 2147"
            d="M217.53,269.88V247.32h1.38l.22,2.37c2.25-1.96,3.5-2.5,4.91-2.5,2.98,0,4.78,3.4,4.78,8.25,0,5.29-2.21,8.34-5.17,8.34-1.12,0-2.44-.58-4.4-2.31v8.15Zm1.74-10.17c1.83,1.7,3.18,2.53,4.2,2.53,2.05,0,3.59-2.82,3.59-6.74,0-3.88-1.25-6.74-3.4-6.74-1.12,0-2.57.77-4.4,2.5v8.45Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2148"
            data-name="Pfad 2148"
            d="M233.1,243.22v-2.66h1.73v2.66Zm0,20.44V247.33h1.73v16.33Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2149"
            data-name="Pfad 2149"
            d="M250.04,262.73a14.465,14.465,0,0,1-5.17,1.06c-3.88,0-5.81-2.18-5.81-8.18,0-5.42,2.25-8.41,6-8.41,3.47,0,5.42,2.86,5.42,6.87,0,.58-.03,1.38-.06,1.83h-9.56c0,4.4,1.35,6.35,4.27,6.35a18.607,18.607,0,0,0,4.56-.74Zm-1.32-8.6c0-2.79-.96-5.45-3.82-5.45-2.98,0-3.95,3.02-4.04,5.78h7.86Z"
            fill="#788abf"
          />
        </g>
        <g id="Gruppe_1935" data-name="Gruppe 1935">
          <path
            id="Pfad_2150"
            data-name="Pfad 2150"
            d="M264.16,255.61H261.3v-3.63h7.19v10.33a15.33,15.33,0,0,1-6.8,1.67c-5.9,0-8.31-2.66-8.31-11.04,0-7.6,2.92-11.23,8.82-11.23a12.447,12.447,0,0,1,6.19,1.8l-1.25,3.21a10.639,10.639,0,0,0-4.33-1.25c-3.3,0-4.78,2.05-4.78,7.41,0,5.74,1.19,7.35,3.95,7.35a6.435,6.435,0,0,0,2.18-.42v-4.2Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2151"
            data-name="Pfad 2151"
            d="M281.07,263.66l-.35-1.73c-2.02,1.67-3.18,2.09-4.78,2.09-2.57,0-4.33-1.86-4.33-5.71V247.14h4.2v10.04c0,1.64.55,2.98,1.8,2.98a4.352,4.352,0,0,0,2.73-1.51V247.13h4.24v16.52h-3.51Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2152"
            data-name="Pfad 2152"
            d="M287.91,244.5v-4.01h4.2v4.01Zm0,19.16V247.14h4.2v16.52Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2153"
            data-name="Pfad 2153"
            d="M304.69,263.66l-.35-1.73c-1.99,1.6-3.18,2.09-4.49,2.09-2.98,0-5.04-2.82-5.04-8.41,0-5.94,2.5-8.82,5.68-8.82,1.15,0,2.09.45,3.46,1.64v-7.44l4.2-.64v23.33h-3.46Zm-.74-11.55a4.138,4.138,0,0,0-2.57-1.44c-1.35,0-2.31,1.51-2.31,4.81,0,3.11.8,4.68,2.18,4.68a4.455,4.455,0,0,0,2.69-1.51v-6.54Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2154"
            data-name="Pfad 2154"
            d="M323.91,262.66a16.47,16.47,0,0,1-6.45,1.32c-4.84,0-6.61-2.73-6.61-8.5,0-5.74,2.5-8.7,7.06-8.7,3.59,0,6.22,2.34,6.22,7.35a22.918,22.918,0,0,1-.13,2.57h-8.92c0,2.73.9,3.85,2.98,3.85a20.913,20.913,0,0,0,5.07-.8Zm-3.92-9.24c0-1.89-.64-3.37-2.41-3.37-1.8,0-2.5,1.73-2.53,3.59h4.94Z"
            fill="#0561a8"
          />
        </g>
        <path
          id="Pfad_2155"
          data-name="Pfad 2155"
          d="M125.91,257.76h7.17v5.77H114.59l8.27-13.51h-6.5v-5.78h17.67Zm-21-7.66a3.448,3.448,0,0,0-3.52,3.78c0,2.28,1.36,3.8,3.52,3.8a3.8,3.8,0,0,0,0-7.58m-.07,13.99c-5.93,0-10.44-4.05-10.44-10.19,0-6.01,4.38-10.22,10.41-10.22s10.69,4.11,10.69,10.22-3.93,10.19-10.66,10.19m-21.8-14.07h-.8v7.75h.8c2.39,0,3.64-1.2,3.64-3.74,0-2.36-1.2-4.01-3.64-4.01m-.2,13.45-2.21.05H75.32V244.24h5.06l2.2.03c6.27,0,11.07,2.26,11.07,9.67-.01,7.02-4.53,9.53-10.81,9.53m-16.34.06-5.8-9.39v9.39H54.02V244.25H60.7l5.8,9.46v-9.46h6.63v19.28ZM42.96,250.49l-1.67,6.04h3.34Zm3.18,13.04-.56-2.44H40.37l-.7,2.44H32.43l6.73-19.28h7.85l6.42,19.28Zm-21.18.56a18.182,18.182,0,0,1-7.76-2.08l2.39-5.42c1.47,1.06,2.9,2.08,4.8,2.08.51,0,1.54-.11,1.54-.91,0-.82-.76-.99-1.77-1.3-2.26-.67-5.85-1.32-5.85-6.09,0-4.86,3.73-6.67,8.06-6.67a11.866,11.866,0,0,1,6.45,1.98l-2.13,4.88a6.321,6.321,0,0,0-3.62-1.55c-1.08,0-1.75.31-1.75.98,0,.69,1.08.94,1.72,1.15,2.25.69,5.95,1.44,5.95,6.21.01,4.89-3.61,6.74-8.03,6.74"
          fill="#0561a8"
        />
      </g>
    </SvgIcon>
  );
}

export default SandozIcon;
