import { Helmet } from "react-helmet";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
// export interface MainBackgroundProps {}

export function MainBackground() {
  return (
    <Helmet>
      <style>
        {
          "body { background-image: linear-gradient(to bottom, rgba(232, 243, 252, 0.19), #e8f3fc); }"
        }
      </style>
    </Helmet>
  );
}

export default MainBackground;
