import { SvgIcon, SvgIconProps } from "@mui/material";

export function CrossIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Icon Kreuz"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        data-name="Rechteck 605"
        style={{ fill: "none" }}
        d="M0 0h35v35H0z"
      />
      <path
        data-name="Pfad 441"
        d="M25.416 33.263h-4.369a1.969 1.969 0 0 1-1.942-1.942v-3.964h-3.963a1.969 1.969 0 0 1-1.942-1.941v-4.369a1.969 1.969 0 0 1 1.942-1.942h3.964v-3.963a1.969 1.969 0 0 1 1.941-1.942h4.369a1.969 1.969 0 0 1 1.942 1.942v3.964h3.964a1.969 1.969 0 0 1 1.942 1.942v4.369a1.969 1.969 0 0 1-1.942 1.942h-3.965v3.964a1.917 1.917 0 0 1-1.941 1.94zM15.142 20.36a.709.709 0 0 0-.688.688v4.369a.709.709 0 0 0 .688.688h4.611a.6.6 0 0 1 .607.607v4.611a.709.709 0 0 0 .688.688h4.369a.709.709 0 0 0 .688-.688v-4.572a.6.6 0 0 1 .607-.607h4.611a.709.709 0 0 0 .688-.688v-4.409a.709.709 0 0 0-.688-.688h-4.572a.6.6 0 0 1-.607-.607v-4.61a.709.709 0 0 0-.688-.688h-4.409a.709.709 0 0 0-.688.688v4.611a.6.6 0 0 1-.607.607z"
        transform="translate(-5.731 -5.732)"
        style={{ fill: "currentcolor" }}
      />
    </SvgIcon>
  );
}

export default CrossIcon;
