/** @jsxImportSource @emotion/react */

import { TableCell, TableRow as MuiTableRow, Typography } from "@mui/material";
import { css } from "@emotion/react";
import { ReactNode } from "react";

type CellAlignment = "left" | "center" | "right" | "inherit" | "justify";

export interface TableRowProps {
  entries: string[] | ReactNode[];
  alignCells?: CellAlignment[];
  dynamicCellWidth?: boolean;
}

export function TableRow(props: TableRowProps) {
  return (
    <MuiTableRow>
      {props.entries.map((entry, index) => (
        <TableCell
          size="small"
          key={index}
          css={css`
            padding-bottom: 12px;
            padding-top: 22px;
            padding-left: 16px;
            padding-right: 16px;
            border-color: rgba(130, 145, 154, 0.3);
            width: ${props.dynamicCellWidth
              ? `${100 / props.entries.length}%`
              : "auto"};
          `}
          align={
            props.alignCells && props.alignCells[index]
              ? props.alignCells[index]
              : undefined
          }
        >
          <Typography
            variant="body1"
            css={css`
              color: #82919a;
            `}
            component="span"
          >
            {entry}
          </Typography>
        </TableCell>
      ))}
    </MuiTableRow>
  );
}

export default TableRow;
