import { Grid } from "@mui/material";
import {
  ColoredText,
  DiagnosticsIcon,
  LightBulbIcon,
  MainBackground,
  MedicBookIcon,
  PagePadding,
  PrimaryNavIconButton,
  ShieldIcon,
  SyringeIcon,
  WarningIcon,
} from "components/ui";
import { useContext } from "react";
import HexalSeo from "../../components/HexalSeo";
import FnDisease from "./disease";
import FnDiagnostics from "./diagnostics";
import FnRisk from "./risk";
import FnProphylaxis from "./prophylaxis";
import FnTherapy from "./therapy";
import FnPraxis from "./praxis";
import GlobalContext from "../../context/globalContext";

export function FnHome() {
  const { currentPage } = useContext(GlobalContext);

  const diseaseChild = currentPage?.children.find(
    (child) => child.view === FnDisease
  );
  const diagnosticsChild = currentPage?.children.find(
    (child) => child.view === FnDiagnostics
  );
  const riskChild = currentPage?.children.find(
    (child) => child.view === FnRisk
  );
  const prophylaxisChild = currentPage?.children.find(
    (child) => child.view === FnProphylaxis
  );
  const therapyChild = currentPage?.children.find(
    (child) => child.view === FnTherapy
  );
  const praxisChild = currentPage?.children.find(
    (child) => child.view === FnPraxis
  );

  return (
    <>
      <HexalSeo />
      <MainBackground />
      <PagePadding>
        <h2>
          <ColoredText color="primary">Febrile Neutropenie</ColoredText>
        </h2>

        <Grid container spacing="24px">
          {diseaseChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: diseaseChild.title,
                  isExternal: false,
                  url: diseaseChild.path,
                  title: "Zum Kapitel Erkrankung",
                }}
                icon={<MedicBookIcon />}
              />
            </Grid>
          )}
          {diagnosticsChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: diagnosticsChild.title,
                  isExternal: false,
                  url: diagnosticsChild.path,
                  title: "Zum Kapitel Diagnostik",
                }}
                icon={<DiagnosticsIcon />}
              />
            </Grid>
          )}
          {riskChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: riskChild.title,
                  isExternal: false,
                  url: riskChild.path,
                  title: "Zum Kapitel Risiko",
                }}
                icon={<WarningIcon />}
              />
            </Grid>
          )}
          {prophylaxisChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: prophylaxisChild.title,
                  isExternal: false,
                  url: prophylaxisChild.path,
                  title: "Zum Kapitel FN-Prophylaxe",
                }}
                icon={<ShieldIcon />}
              />
            </Grid>
          )}
          {therapyChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: therapyChild.title,
                  isExternal: false,
                  url: therapyChild.path,
                  title: "Zum Kapitel Therapie CIN",
                }}
                icon={<SyringeIcon />}
              />
            </Grid>
          )}
          {praxisChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: praxisChild.title,
                  isExternal: false,
                  url: praxisChild.path,
                  title: "Zum Kapitel Aus der Praxis",
                }}
                icon={<LightBulbIcon />}
              />
            </Grid>
          )}
        </Grid>
      </PagePadding>
    </>
  );
}

export default FnHome;
