import { Breadcrumbs } from "components/ui";
import { useContext } from "react";
import { getCurrentPageTree } from "../util/pageHandlingHelpers";
import GlobalContext from "../context/globalContext";

export function BreadCrumbNavigation() {
  const breadcrumbs: { path: string; label: string }[] = [];

  const { currentPage } = useContext(GlobalContext);
  const currentPageTree = getCurrentPageTree(currentPage);

  let path = "/";
  // breadcrumbs.push({path, label: "Home"});
  currentPageTree.forEach((entry) => {
    path = entry.path.length > 0 ? `${path + entry.path}/` : path;
    breadcrumbs.push({
      path,
      label: entry.name,
    });
  });

  return <Breadcrumbs breadcrumbs={breadcrumbs} />;
}

export default BreadCrumbNavigation;
