/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";

export interface ColoredTextProps {
  color: "primary" | "success" | "warning" | "error";
  children: ReactNode;
}

export function ColoredText(props: ColoredTextProps) {
  const theme = useTheme();

  const styles = {
    root: css`
      display: inline-block;
      color: ${theme.palette[props.color].main};
    `,
  };

  return <div css={styles.root}>{props.children}</div>;
}

export default ColoredText;
