/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import { GraphLegend } from "../GraphLegend/GraphLegend";
import { GraphLegendItemProps } from "../GraphLegend/GraphLegendItem";

export interface GraphProps {
  imagePath: string;
  alt: string;
  legend?: GraphLegendItemProps[];
  bordered?: boolean;
  largeWidth?: "25%" | "50%" | "75%" | "100%";
}

export function Graph(props: GraphProps) {
  const theme = useTheme();
  const styles = {
    root: css``,
    legend: css`
      margin-bottom: 15px;
    `,
    graph: css`
      width: 100%;
      text-align: center;
      border: ${props.bordered
        ? `1px solid ${theme.palette.primary.main}`
        : "none"};
      border-radius: ${props.bordered ? "13px" : "0"};
      img {
        max-width: 100%;
        min-width: 100%;
        height: auto;
      }
      ${theme.breakpoints.up("md2")} {
        width: ${props.largeWidth ? props.largeWidth : "100%"};
      }
    `,
  };
  return (
    <div css={styles.root}>
      {props.legend && (
        <div css={styles.legend}>
          <GraphLegend items={props.legend} />
        </div>
      )}
      <div css={styles.graph}>
        <img src={props.imagePath} alt={props.alt} />
      </div>
    </div>
  );
}

export default Graph;
