/** @jsxImportSource @emotion/react */
import { HexalIcon, Layout, SandozIcon } from "components/ui";
import { ReactNode, useContext } from "react";
import { useFavorites } from "../hooks/FavoriteHandling";
import GlobalContext from "../context/globalContext";
import { getCurrentPageTree } from "../util/pageHandlingHelpers";
import { css } from "@emotion/react";

export interface LayoutWrapperProps {
  children: ReactNode;
  disableLayout?: boolean;
}

export function LayoutWrapper(props: LayoutWrapperProps) {
  const { currentMarket } = useContext(GlobalContext);
  const { toggleFavoriteStatusForCurrentPage, currentPageIsFavorite } =
    useFavorites();
  const { currentPage } = useContext(GlobalContext);
  const currentPageTree = getCurrentPageTree(currentPage);
  const disableFavorite = currentPage?.config?.disableFavorite;

  const styles = {
    icon: css`
      width: 100%;
    `,
  };

  let AppIcon: ReactNode;
  switch (currentMarket) {
    case "at":
      AppIcon = <SandozIcon css={styles.icon} />;
      break;
    default:
      AppIcon = <HexalIcon css={styles.icon} />;
  }

  return (
    <Layout
      onFavClick={toggleFavoriteStatusForCurrentPage}
      favIsActive={currentPageIsFavorite()}
      favoritesLink={{
        label: "",
        isExternal: false,
        url: "favoriten",
      }}
      homeButtonLink={{
        label: "",
        isExternal: false,
        url: "",
      }}
      disableFavorite={disableFavorite}
      disableBackLink={currentPageTree.length === 1}
      appIcon={AppIcon}
    >
      {props.children}
    </Layout>
  );
}

export default LayoutWrapper;
