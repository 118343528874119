/** @jsxImportSource @emotion/react */

/* eslint-disable-next-line */
import { css } from "@emotion/react";

export interface IntroHeaderProps {
  image: {
    path: string;
    alt: string;
  };
  onClick?: () => void;
}

export function Image(props: IntroHeaderProps) {
  const styles = {
    root: css`
      width: 100%;
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
      }
    `,
  };
  return (
    <div css={styles.root} onClick={props.onClick}>
      <img src={props.image.path} alt={props.image.alt} />
    </div>
  );
}

export default Image;
