/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Typography } from "@mui/material";

export interface GraphLegendItemProps {
  type: "rectangle" | "triangle" | "none" | "empty";
  label: string;
  color?:
    | "blue"
    | "violet"
    | "lightViolet"
    | "brown"
    | "orange"
    | "green"
    | "red"
    | "grey"
    | "yellow"
    | "darkBlue"
    | "turquoise";
}

export function GraphLegendItem(props: GraphLegendItemProps) {
  let color;
  switch (props.color) {
    case "orange":
      color = "#f89359";
      break;
    case "green":
      color = "#859853";
      break;
    case "grey":
      color = "#c3c3c3";
      break;
    case "violet":
      color = "#bea1ed";
      break;
    case "lightViolet":
      color = "#cf8bd3";
      break;
    case "red":
      color = "#f47575";
      break;
    case "brown":
      color = "#98772d";
      break;
    case "yellow":
      color = "#fddb88";
      break;
    case "darkBlue":
      color = "#0460a9";
      break;
    case "turquoise":
      color = "#50bcbd";
      break;
    case "blue":
    default:
      color = "#4e5a9f";
      break;
  }

  const styles = {
    root: css`
      display: flex;
      align-items: flex-start;
    `,
    symbol: css`
      padding-top: ${props.type === "triangle" ? "5" : "8"}px;
      display: ${props.type === "none" ? "none" : "block"};
    `,
    triangle: css`
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6.5px 11px 6.5px;
      border-color: transparent transparent ${color} transparent;
    `,
    rectangle: css`
      width: 15px;
      height: 7px;
      background-color: ${color};
    `,
    empty: css`
      width: 15px;
    `,
    label: css`
      padding-left: ${props.type === "none" ? 0 : "13px"};
      color: #82919a;
    `,
  };

  return (
    <div css={styles.root}>
      <div css={styles.symbol}>
        <div
          css={
            props.type === "rectangle"
              ? styles.rectangle
              : props.type === "triangle"
              ? styles.triangle
              : styles.empty
          }
        />
      </div>
      <Typography
        css={styles.label}
        component="div"
        variant="body1"
        dangerouslySetInnerHTML={{ __html: props.label }}
      />
    </div>
  );
}

export default GraphLegendItem;
