import { Route, Routes } from "react-router-dom";
// import Home from "../views";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import sitemap from "../sitemapDe";
import { RouteData } from "../ts/RoutingTypes";
import { parseSitemapEntry } from "../util/pageHandlingHelpers";
import { GlobalContextProvider } from "../context/globalContext";
import GlobalContextWrapper from "../components/GlobalContextWrapper";
import LayoutWrapper from "../components/LayoutWrapper";
import Login from "../views/login";
import Imprint from "../views/imprint";
import ProtectedRoute from "../components/ProtectedRoute";
import { AuthContextProvider } from "../context/authContext";

export function App() {
  const routes: RouteData[] = [];
  // TODO: Implement AT Handling
  sitemap.forEach((entry) => {
    routes.push(...parseSitemapEntry(entry));
  });

  const dragAndDropOptions = {
    enableMouseEvents: true,
  };

  return (
    <AuthContextProvider>
      <DndProvider backend={TouchBackend} options={dragAndDropOptions}>
        <GlobalContextProvider currentPage={null} currentPath={null}>
          <GlobalContextWrapper>
            <LayoutWrapper>
              <Routes>
                {/* <Route path={"/"} element={<Home />} /> */}
                {routes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.protectedRoute ? (
                        <ProtectedRoute>
                          <route.element />
                        </ProtectedRoute>
                      ) : (
                        <route.element />
                      )
                    }
                  />
                ))}
                <Route path={"/login"} element={<Login />} />
                <Route path={"/impressum"} element={<Imprint />} />
              </Routes>
            </LayoutWrapper>
          </GlobalContextWrapper>
        </GlobalContextProvider>
      </DndProvider>
    </AuthContextProvider>
  );
}

export default App;
