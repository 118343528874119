/** @jsxImportSource @emotion/react */

import {
  ArrowSeparator,
  ChipLineSeparator,
  PagePadding,
  ResultBox,
  SeparatorWrapper,
  ToggleBox,
} from "components/ui";
import { css } from "@emotion/react";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";

export function FnDiagnosticSepsis() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik der Sepsis</h1>
        <ul>
          <li>
            Es gibt keine Evidenz, dass sich Sepsis und septischer Schock bei
            neutropenischen und nicht-neutropenischen Patient:innen
            unterscheiden
          </li>
          <li>
            Neutropenische Patient:innen mit Infektionszeichen sollen täglich
            auf Symptome einer Sepsis untersucht werden{" "}
            <TextReference references={["ref14"]} />
          </li>
        </ul>
        <p>
          Wann liegt eine Sepsis vor, wann ein septischer Schock?
          <br />
          Mit dem folgenden Diagramm können Sie einsehen, welche Kriterien nach
          der Leitlinie der DGHO erfüllt sein müssen, damit eine Sepsis oder ein
          septischer Schock vorliegen.
        </p>
        <ToggleBox forceOpen title="Neutropenie">
          <p>
            Neutrophile &lt; 500/µl oder &lt; 1.000/µl mit voraussichtlichem
            Abfall auf &lt; 500/µl innerhalb der nächsten 2 Tage
          </p>
        </ToggleBox>
        <SeparatorWrapper>
          <ChipLineSeparator label="und" />
        </SeparatorWrapper>
        <ToggleBox forceOpen title="Infektion">
          <p>Nachweis oder Verdacht</p>
        </ToggleBox>
        <SeparatorWrapper>
          <ChipLineSeparator label="und" />
        </SeparatorWrapper>
        <ToggleBox title="Weitere charakteristische Symptome">
          <ToggleBox title="Allgemeinsymptome" forceOpen type="alternative">
            <ul>
              <li>
                <strong>Fieber</strong> oder <strong>Hypothermie</strong>
              </li>
              <li>
                <strong>Tachykardie:</strong> Puls &gt; 90/min oder &gt; 2{" "}
                <Abbreviation>sd</Abbreviation> oberhalb des Altersnormwertes
              </li>
              <li>
                <strong>Tachypnoe/Dyspnoe:</strong> Atemfrequenz &gt; 30/min
              </li>
              <li>
                <strong>Mentale Veränderungen</strong>
              </li>
              <li>
                <strong>Ödeme:</strong> klinische Ödeme oder positive
                Flüssigkeitsbilanz &gt; 20 ml/kg über 24 Stunden
              </li>
            </ul>
          </ToggleBox>
          <SeparatorWrapper>
            <ChipLineSeparator label="und / oder" />
          </SeparatorWrapper>
          <ToggleBox title="Entzündungsparameter" forceOpen type="alternative">
            <ul>
              <li>
                <strong>
                  <Abbreviation>crp</Abbreviation>
                </strong>{" "}
                oder <strong>Procalcitonin</strong> &gt; 2{" "}
                <Abbreviation>sd</Abbreviation> oberhalb des Altersnormwertes
              </li>
            </ul>
          </ToggleBox>
          <SeparatorWrapper>
            <ChipLineSeparator label="und / oder" />
          </SeparatorWrapper>
          <ToggleBox title="Gewebeperfusion" forceOpen type="alternative">
            <ul>
              <li>
                <strong>Hyperlaktatämie</strong> (&gt; 3mmol/l)
              </li>
              <li>
                <strong>Niedriger Capillary Refill</strong> (Fingernagelprobe)
              </li>
            </ul>
          </ToggleBox>
          <SeparatorWrapper>
            <ChipLineSeparator label="und / oder" />
          </SeparatorWrapper>
          <ToggleBox title="Organversagen" forceOpen type="alternative">
            <ul>
              <li>
                Absinken der peripheren <strong>Sauerstoff-Sättigung</strong>
              </li>
              <li>
                <strong>Arterielle Hypoxämie:</strong>{" "}
                <Abbreviation>pao2</Abbreviation>/
                <Abbreviation>fio2</Abbreviation> &lt; 300 mmHg
              </li>
              <li>
                Akute <strong>Oligurie:</strong> Urinmenge &lt; 0,5 ml/kg/h über
                2 Stunden
              </li>
              <li>
                <strong>Kreatininanstieg</strong>
              </li>
              <li>
                <strong>Gerinnungsstörungen</strong>
              </li>
              <li>
                <strong>Ileus</strong>
              </li>
              <li>
                <strong>Hyperbilirubinämie</strong>
              </li>
            </ul>
          </ToggleBox>
          <SeparatorWrapper>
            <ChipLineSeparator label="und / oder" />
          </SeparatorWrapper>
          <ToggleBox title="Hämodynamik" forceOpen type="alternative">
            <ul>
              <li>
                <strong>Systolischer Blutdruck &lt; 90 mmHg</strong>
              </li>
              <li>
                <strong>Mittlerer arterieller Druck: &lt; 70 mmHg</strong> oder
                Absinken des systolischen Blutdrucks &gt; 40 mmHg oder &lt; 2{" "}
                <Abbreviation>sd</Abbreviation> des Altersnormwertes
              </li>
              <li>
                <strong>Venöse Sauerstoff-Sättigung &lt; 70 %</strong>
              </li>
              <li>
                <strong>
                  Kardialer Index &gt; 3,5 l/min/m
                  <sup>2</sup>
                </strong>
              </li>
            </ul>
          </ToggleBox>
        </ToggleBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <div
          css={css`
            width: 100%;
            text-align: center;
          `}
        >
          <ResultBox color="warning" variant="large" label="Sepsis" />
        </div>
        <SeparatorWrapper>
          <ChipLineSeparator label="und" />
        </SeparatorWrapper>
        <ToggleBox forceOpen title="Vasopressoren erforderlich">
          <p>
            für einen mittleren arteriellen Druck &ge; 65 mmHg (trotz adäquater
            Flüssigkeitssubstitution)
          </p>
        </ToggleBox>
        <SeparatorWrapper>
          <ChipLineSeparator label="und" />
        </SeparatorWrapper>
        <ToggleBox forceOpen title="Serum-Laktat">
          <p>&gt; 2 mmol/l</p>
        </ToggleBox>
        <SeparatorWrapper>
          <ArrowSeparator color="primary" />
        </SeparatorWrapper>
        <div
          css={css`
            width: 100%;
            text-align: center;
          `}
        >
          <ResultBox color="error" variant="large" label="Septischer Schock" />
        </div>
      </PagePadding>
    </>
  );
}

export default FnDiagnosticSepsis;
