import { PagePadding, Table, TableHead, TableRow } from "components/ui";
import { TableBody } from "@mui/material";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import TextInfo from "../../components/TextInfo";

export function MdsDiagnosticMds() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Diagnostik <Abbreviation>mds</Abbreviation>
        </h1>
        <p>
          Die Diagnostik der MDS beinhaltet{" "}
          <TextReference references={["ref1"]} />
        </p>
        <ol>
          <li>Ausschluss zahlreicher Differenzialdiagnosen</li>
          <li>Anfertigung eines Blutbildes & Differenzialblutbildes</li>
          <li>Knochenmarkuntersuchung</li>
        </ol>

        <h2>
          Differenzialdiagnosen der <Abbreviation>mds</Abbreviation>{" "}
          <TextReference references={["ref1"]} />
        </h2>
        <Table>
          <TableHead
            labels={["Differenzialdiagnose", "Diagnostisches Verfahren"]}
          />
          <TableBody>
            <TableRow
              entries={[
                "Klassische Blutungsanämie",
                "Labor, Anamnese, Blut im Stuhl (Hämatoccult)",
              ]}
            />
            <TableRow
              entries={[
                <>
                  Aplastische Anämie, <Abbreviation>prca</Abbreviation>
                </>,
                "Histologie, Zytogenetik",
              ]}
            />
            <TableRow
              entries={[
                <>
                  Toxischer <Abbreviation>bm</Abbreviation>
                  -Schaden{" "}
                  <TextInfo>
                    Alkohol, Blei, <Abbreviation>nsar</Abbreviation> etc.
                  </TextInfo>
                </>,
                "Anamnese",
              ]}
            />
            <TableRow
              entries={[
                <>
                  Reaktive BM-Veränderungen
                  <TextInfo>
                    Sepsis, HIV, chronische Infekte,{" "}
                    <Abbreviation>tbc</Abbreviation>, Autoimmunerkrankungen etc.
                  </TextInfo>
                </>,
                "Zytologie, Anamnese, Labor",
              ]}
            />
            <TableRow
              entries={["Monozytose anderer Genese", "Anamnese, Labor"]}
            />
            <TableRow
              entries={[
                <Abbreviation key={"pnh"}>pnh</Abbreviation>,
                "Immunphänotypisierung",
              ]}
            />
            <TableRow
              entries={[
                "Immunthrombozytopenie",
                "Ggf. Zytologie, Anamnese, Verlauf",
              ]}
            />
            <TableRow
              entries={[
                "Megaloblastäre Anämien",
                "Vitamin-B12-/Folsäure-spiegel",
              ]}
            />
            <TableRow
              entries={["Hyperspleniesyndrom", "Anamnese/Klinik/Splenomegalie"]}
            />
            <TableRow
              entries={[
                <>
                  Akute Leukämien
                  <TextInfo>
                    speziell Erythroleukämie, <Abbreviation>fabm6</Abbreviation>
                  </TextInfo>
                </>,
                "Zytogenetik, Molekulargenetik",
              ]}
            />
            <TableRow
              entries={[
                <>
                  Myeloproliferative Erkrankungen
                  <TextInfo>
                    speziell <Abbreviation>acml</Abbreviation>,{" "}
                    <Abbreviation>omf</Abbreviation>
                  </TextInfo>
                </>,
                "Histologie, Zytogenetik, Molekulargenetik",
              ]}
            />
            <TableRow
              entries={[
                <>
                  Haarzellenleukämie, <Abbreviation>lgl</Abbreviation>
                </>,
                "Zytologie, Immunphänotypisierung, Molekulargenetik",
              ]}
            />
            <TableRow
              entries={[
                "Kongenitale dyserythropoetische Anämien (selten)",
                "Molekulargenetik",
              ]}
            />
          </TableBody>
        </Table>

        <h2>
          Diagnostik der <Abbreviation>mds</Abbreviation>{" "}
          <TextReference references={["ref1", "ref15"]} />
        </h2>
        <Table>
          <TableHead labels={["Peripheres Blut", "Knochenmark"]} />
          <TableBody>
            <TableRow
              entries={[
                "Blutbild",
                "Zytologie mit Eisen- und Esterasefärbung, ggf. Peroxidasefärbung",
              ]}
            />
            <TableRow
              entries={[
                "Retikulozyten",
                <>
                  Zytogenetik, ggf. mit <Abbreviation>fish</Abbreviation>{" "}
                  <TextInfo>
                    Chromosomen 5, 7, 8, 13, 17p, 20, ggf. weitere
                  </TextInfo>
                </>,
              ]}
            />
            <TableRow entries={["Differenzialblutbild", "Histologie"]} />
            <TableRow
              entries={[
                <Abbreviation key={"ldh"}>ldh</Abbreviation>,
                "Immunphänotypisierung",
              ]}
            />
            <TableRow entries={["Ferritin", "Molekulargenetik (SF3B1)"]} />
            <TableRow entries={["Erythropoetin-Spiegel", ""]} />
            <TableRow entries={["Folsäure", ""]} />
            <TableRow entries={["Vitamin B12", ""]} />
            <TableRow
              entries={[
                <>
                  Ggf. <Abbreviation>hla</Abbreviation>
                  -Typisierung
                </>,
                "",
              ]}
            />
          </TableBody>
        </Table>
      </PagePadding>
    </>
  );
}

export default MdsDiagnosticMds;
