import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";

export function CiaDiseaseSymptoms() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Typische Symptome der Anämie</h1>
        <p>
          Folgende Symptome können auftreten:{" "}
          <TextReference references={["ref1", "ref11"]} />
        </p>

        <ul>
          <li>Müdigkeit</li>
          <li>Blässe (insbesondere der Schleimhäute)</li>
          <li>Fatigue</li>
          <li>Erschöpfung (körperlich/kognitiv)</li>
          <li>reduzierte Leistungsfähigkeit (körperlich/kognitiv)</li>
          <li>Konzentrationsschwäche</li>
          <li>Schlafstörungen</li>
          <li>Kopfschmerzen</li>
          <li>Schwindel/Ohrensausen</li>
          <li>Synkope</li>
          <li>Schwäche</li>
          <li>Depression</li>
          <li>Belastungsdyspnoe</li>
          <li>Tachykardie etc.</li>
          <li>Herzrasen</li>
          <li>Orthostase-Syndrom</li>
          <li>Tachypnoe</li>
          <li>Hypotonie</li>
        </ul>
      </PagePadding>
    </>
  );
}

export default CiaDiseaseSymptoms;
