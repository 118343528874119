import { Alert, PagePadding, TextLink } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import ProfessionalInformationButton from "../../components/ProfessionalInformationButton";
import { getInternalLinkForPage } from "../../util/pageHandlingHelpers";
import MdsTherapyLowRiskEsaNordicScore from "./therapyLowRiskEsaNordicScore";
import { resources } from "../../data/resources";

export function MdsTherapyLowRiskEsaTherapy() {
  // TODO: Implement handling for AT
  const NordicScorePage = MdsTherapyLowRiskEsaNordicScore;
  const urlNordicScoreLink = getInternalLinkForPage(
    NordicScorePage,
    "de",
    "Hier mehr erfahren"
  );

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapie Erythropoese stimulierende Agenzien – Epoetin</h1>
        <p>
          <Abbreviation>esa</Abbreviation>
          -Therapie, klassisch:
        </p>
        <ul>
          <li>
            Subkutanes Erythropoetin 40.000 <Abbreviation>ie</Abbreviation>
            /Woche
          </li>
          <li>
            Bei unzureichender Wirkung ggf. steigern auf 80.000{" "}
            <Abbreviation>ie</Abbreviation>
            /Woche
          </li>
          <li>
            Verzögerungserythropoetin: 300 µg wöchentlich bzw. 500 µg
            zweiwöchentlich <TextReference references={["ref1"]} />
          </li>
        </ul>
        <p>
          Die Therapie muss in Anlehnung an den Nordic Score erfolgen{" "}
          <TextLink link={urlNordicScoreLink} />
        </p>
        <p>
          Ergebnisse von Studien in vitro/in vivo (Tiermodell) Studienergebnisse
          zur Assoziation von ESA mit erhöhtem Tumorwachstum sind uneinheitlich.
          Keine klinische Evidenz, die zeigt, dass leitliniengerechte ESA-Gabe (
          <Abbreviation>hb</Abbreviation>
          -Zielwert &lt;12 g/dl) die Tumorprogression stimuliert oder die
          Rezidivrate erhöht. <TextReference references={["ref2"]} />
          <br />
          Es gibt jedoch keinen Hinweis in klinischen Studien auf ein erhöhtes{" "}
          <Abbreviation>aml</Abbreviation>-Risiko, dafür aber auf ein
          verbessertes Gesamtüberleben.{" "}
          <TextReference references={["ref17", "ref18"]} />
        </p>
        <Alert
          content={
            <>
              <span>CAVE:</span>
              <br />
              <span>
                Das Patient:innen-spezifische Nutzen-Risiko einer
                leitliniengerechten ESA-Therapie sollte individuell abgewogen
                werden.
              </span>
            </>
          }
          type="warning"
        />
        <p>
          Link zu Fachinfo Epoetin alpha HEXAL
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton
            url={resources.documents.epoetinAlpha.url}
          />
        </p>
        <p>
          Link zu Fachinfo Erypo
          <sup>®</sup>
        </p>
        <p>
          <ProfessionalInformationButton url={resources.documents.erypo.url} />
        </p>
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskEsaTherapy;
