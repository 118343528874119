import { SvgIcon, SvgIconProps } from "@mui/material";

export function InfoIconInverted(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Icon Info negativ"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <path style={{ fill: "none" }} d="M0 0h20v20H0z" />
      <path
        data-name="Color"
        d="M7.992 16A8 8 0 1 1 16 8a8.005 8.005 0 0 1-8.008 8zM8 1.6A6.4 6.4 0 1 0 14.4 8 6.407 6.407 0 0 0 8 1.6zM8.8 12H7.2V7.2h1.6V12zm0-6.4H7.2V4h1.6v1.6z"
        transform="translate(2 2)"
        style={{ fill: "#fff" }}
      />
    </SvgIcon>
  );
}

export default InfoIconInverted;
