import { Alert, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";

export function OpAnemia() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Präoperative Anämie</h1>
        <ul>
          <li>
            Von der WHO definierte <Abbreviation>hb</Abbreviation>
            -Grenzwerte für Anämie auf Meereshöhe in Abhängigkeit von der
            Population:
            <ul>
              <li>Frauen (nicht schwanger): &lt; 12 g/dl</li>
              <li>Frauen (schwanger): &lt; 11 g/dl</li>
              <li>Männer: &lt; 13 g/dl</li>
            </ul>
          </li>
          <li>
            Prävalenz der Anämie jeglicher Ursache bei präoperativen
            Patient:innen: 10,5 bis 47,9 %
          </li>
        </ul>

        <Alert
          content={
            <>
              <span>CAVE:</span>
              <ul>
                <li>
                  Präoperative Anämie ist ein eigenständiger, ernst zu nehmender
                  Risikofaktor für das Auftreten postoperativer Komplikation
                </li>
                <li>
                  Nicht therapierte Anämie sollte als Kontraindikation für einen
                  elektiven Eingriff gelten{" "}
                  <TextReference references={["ref1"]} />
                </li>
              </ul>
            </>
          }
          type="warning"
        />
      </PagePadding>
    </>
  );
}

export default OpAnemia;
