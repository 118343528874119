/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Typography } from "@mui/material";
import { ReactNode } from "react";

export interface AccordionTabItemProps {
  left: string | ReactNode;
  right: string | ReactNode;
}

export function AccordionTabItem(props: AccordionTabItemProps) {
  const styles = {
    root: css`
      display: flex;
      align-items: center;
    `,
    cell: css`
      width: 50%;
      color: inherit;
    `,
  };
  return (
    <div css={styles.root}>
      <Typography css={styles.cell} variant="body1">
        {props.left}
      </Typography>
      <Typography css={styles.cell} variant="body1">
        {props.right}
      </Typography>
    </div>
  );
}
