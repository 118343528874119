/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { Typography } from "@mui/material";
import { css } from "@emotion/react";

export interface CalculationBoxContentCellProps {
  title?: string;
  content: string | ReactNode;
}

export function CalculationBoxContentCell(
  props: CalculationBoxContentCellProps
) {
  const styles = {
    root: css`
      color: #82919a;
    `,
    title: css`
      margin-bottom: 5px;
    `,
    body: css`
      ul,
      ol,
      p {
        margin-top: 0;
        padding-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    `,
  };

  return (
    <div css={styles.root}>
      {props.title && (
        <Typography css={styles.title} variant="body2">
          {props.title}
        </Typography>
      )}
      <div css={styles.body}>{props.content}</div>
    </div>
  );
}

export default CalculationBoxContentCell;
