/** @jsxImportSource @emotion/react */

/* eslint-disable-next-line */
import { Link } from "../ts/link";
import { Button, Typography } from "@mui/material";
import { ReactNode } from "react";
import { css } from "@emotion/react";
import BasicLink from "../BasicLink/BasicLink";

export interface PrimaryNavIconButtonProps {
  link: Link;
  icon: ReactNode;
}

export function PrimaryNavIconButton(props: PrimaryNavIconButtonProps) {
  const styles = {
    button: css`
      width: 100%;
      min-width: 131px;
      background-color: #ffffff;
      border-radius: 11px;
      position: relative;
      .MuiButton-startIcon {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 30%; //35.49%;
        height: auto;
        svg {
          width: 100%;
          height: auto;
        }
      }
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    `,
    label: css`
      position: absolute;
      top: 60%;
      left: 0;
      right: 0;
      text-align: center;
      padding-left: 8px;
      padding-right: 8px;
      text-transform: initial;
    `,
  };
  return (
    <BasicLink link={props.link}>
      <Button css={styles.button} startIcon={props.icon}>
        <Typography css={styles.label} variant="h3">
          {props.link.label}
        </Typography>
      </Button>
    </BasicLink>
  );
}

export default PrimaryNavIconButton;
