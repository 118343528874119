import { SvgIcon, SvgIconProps } from "@mui/material";

export function BloodDropPlusIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      id="Icon_Präoperative_Anämie"
      xmlns="http://www.w3.org/2000/svg"
      width="54.454"
      height="52"
      viewBox="0 0 54.454 52"
    >
      <rect
        id="Rechteck_606"
        data-name="Rechteck 606"
        width="35"
        height="35"
        fill="none"
      />
      <g id="Gruppe_1883" data-name="Gruppe 1883">
        <path
          id="Pfad_442"
          data-name="Pfad 442"
          d="M34.858,56.781a17.367,17.367,0,0,1-12.479-5.367,18.521,18.521,0,0,1,0-25.691L33.839,13.846a1.3,1.3,0,0,1,1.868,0L47.252,25.724a18.521,18.521,0,0,1,0,25.691A17.034,17.034,0,0,1,34.858,56.781Zm0-40.12L24.246,27.571a15.8,15.8,0,0,0,0,21.908,14.643,14.643,0,0,0,21.223,0,15.8,15.8,0,0,0,0-21.908Z"
          transform="translate(-8.373 -6.548)"
          fill="#0460a9"
        />
        <g
          id="Pfad_1920"
          data-name="Pfad 1920"
          transform="translate(33 35.13)"
          fill="#fff"
        >
          <path
            d="M 8.434814453125 15.86962413787842 C 4.335244655609131 15.86962413787842 1.000004410743713 12.53438472747803 1.000004410743713 8.434814453125 C 1.000004410743713 4.335244655609131 4.335244655609131 1.000004410743713 8.434814453125 1.000004410743713 C 12.53438472747803 1.000004410743713 15.86962413787842 4.335244655609131 15.86962413787842 8.434814453125 C 15.86962413787842 8.932924270629883 15.82427406311035 9.423754692077637 15.59250450134277 10.4585542678833 L 15.59028434753418 10.46844482421875 L 15.5882740020752 10.47837448120117 C 15.30222415924072 11.88895416259766 14.43849468231201 13.22144412994385 13.15620422363281 14.23038482666016 C 11.81273460388184 15.2874641418457 10.1359748840332 15.86962413787842 8.434814453125 15.86962413787842 Z"
            stroke="none"
          />
          <path
            d="M 8.434814453125 2.000004768371582 C 4.88664436340332 2.000004768371582 2.000004768371582 4.88664436340332 2.000004768371582 8.434814453125 C 2.000004768371582 11.98298454284668 4.88664436340332 14.86962413787842 8.434814453125 14.86962413787842 C 9.912694931030273 14.86962413787842 11.36984443664551 14.36350440979004 12.53784465789795 13.44448471069336 C 13.61808395385742 12.59452438354492 14.37271499633789 11.44098472595215 14.60822486877441 10.27963447570801 L 14.61667442321777 10.24000453948975 C 14.82823467254639 9.295434951782227 14.86962413787842 8.862833976745605 14.86962413787842 8.434814453125 C 14.86962413787842 4.88664436340332 11.98298454284668 2.000004768371582 8.434814453125 2.000004768371582 M 8.434814453125 3.814697265625e-06 C 13.09323501586914 3.814697265625e-06 16.86962509155273 3.776394844055176 16.86962509155273 8.434814453125 C 16.86962509155273 9.026433944702148 16.80871391296387 9.603824615478516 16.56832504272461 10.6771240234375 C 15.89619445800781 13.9915246963501 12.50161457061768 16.86962509155273 8.434814453125 16.86962509155273 C 3.776394844055176 16.86962509155273 3.814697265625e-06 13.09323501586914 3.814697265625e-06 8.434814453125 C 3.814697265625e-06 3.776394844055176 3.776394844055176 3.814697265625e-06 8.434814453125 3.814697265625e-06 Z"
            stroke="none"
            fill="#1460a9"
          />
        </g>
        <path
          id="Pfad_1918"
          data-name="Pfad 1918"
          d="M8.038,0H1.206"
          transform="translate(36.813 43.565)"
          fill="none"
          stroke="#1761a6"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
      <path
        id="Pfad_1919"
        data-name="Pfad 1919"
        d="M32.376,24.8a1.7,1.7,0,0,0-1.708,1.708V29H28.108A1.7,1.7,0,0,0,26.4,30.711V33.8A1.7,1.7,0,0,0,28.108,35.5h2.5V38a1.7,1.7,0,0,0,1.708,1.708H35.4A1.7,1.7,0,0,0,37.1,38V35.5h2.5A1.7,1.7,0,0,0,41.308,33.8V30.711A1.773,1.773,0,0,0,39.666,29h-2.5v-2.5A1.7,1.7,0,0,0,35.463,24.8Zm3.218,1.708v3.284a.8.8,0,0,0,.788.788h3.284c.066,0,.131.131.131.131V33.8c0,.131-.066.131-.131.131H36.382a.8.8,0,0,0-.788.788V38c0,.131-.066.131-.131.131H32.376c-.131,0-.131-.066-.131-.131V34.717a.8.8,0,0,0-.788-.788H28.173c-.131,0-.131-.066-.131-.131V30.711c0-.131.066-.131.131-.131h3.349a.8.8,0,0,0,.788-.788V26.508c0-.131.066-.131.131-.131h3.087C35.594,26.376,35.594,26.376,35.594,26.508Z"
        transform="translate(13.146 -22.956)"
        fill="#0f60a7"
      />
    </SvgIcon>
  );
}

export default BloodDropPlusIcon;
