/** @jsxImportSource @emotion/react */

import {
  Checkbox as MuiCheckBox,
  useTheme,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { css } from "@emotion/react";
import { OverridableStringUnion } from "@mui/types";
import { CheckboxPropsColorOverrides } from "@mui/material/Checkbox/Checkbox";
import { ChangeEvent, ReactNode } from "react";

export interface CheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  onChange?: (newValue: boolean) => void;
  indeterminate?: boolean;
  label: string | ReactNode;
  color?: OverridableStringUnion<
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "default",
    CheckboxPropsColorOverrides
  >;
}

export function Checkbox(props: CheckboxProps) {
  // const { color = "#82919a" } = props;
  const theme = useTheme();
  const fontColor =
    props.color && props.color !== "default"
      ? theme.palette[props.color].main
      : "#82919a";

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.checked);
    }
  };

  const styles = {
    root: css`
      align-items: flex-start;
    `,
    checkbox: css`
      padding: 0;
    `,
    label: css`
      padding-left: 12px;
      padding-top: 2px;
    `,
  };
  return (
    <FormControlLabel
      css={styles.root}
      disabled={props.disabled}
      control={
        <MuiCheckBox
          css={styles.checkbox}
          color={props.color}
          checked={props.checked}
          indeterminate={props.indeterminate}
          onChange={handleChange}
        />
      }
      label={
        <Typography
          css={styles.label}
          color={fontColor}
          variant="body1"
          component="div"
        >
          {props.label}
        </Typography>
      }
    />
  );
}

export default Checkbox;
