import { Alert, DynamicSpacing, PagePadding, TextLink } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import Abbreviation from "../../components/Abbreviation";
import { getInternalLinkForPage } from "../../util/pageHandlingHelpers";
import CiaTherapyEC from "../cia/therapyEC";
import CiaPbm from "../cia/pbm";
import AnemiaManagementDecisionTree from "../../components/DecisionTrees/AnemiaManagementDecisionTree";
import TextInfo from "../../components/TextInfo";

export function OpManagement() {
  const linkTransfusions = getInternalLinkForPage(
    CiaTherapyEC,
    "de",
    "Mehr zu Transfusion von Erythrozytenkonzentraten"
  );
  const linkPmb = getInternalLinkForPage(
    CiaPbm,
    "de",
    "Mehr zu  Patient-Blood-Management"
  );

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Prä- und postoperatives Anämiemanagement</h1>
        <h2>Differentialdiagnostik und Therapiesteuerung</h2>
        <p>
          Zur Differentialdiagnostik und anschließenden Therapiesteuerung
          werden, teils routinemäßig vorhandene, Blutparameter herangezogen,
          z.B.: <TextReference references={["ref9"]} />
        </p>
        <ul>
          <li>
            Transferrinsättigung
            <TextInfo>Normalwert: 16-45 %. Bei Eisenmangel: &lt;20 %.</TextInfo>
          </li>
          <li>
            Mittleres korpuskulares Hämoglobin
            <TextInfo>
              Normalwert: 28-33 g/dL. Bei Eisenmangel: &lt;27 g/dL.
            </TextInfo>
          </li>
          <li>
            Ferritin
            <TextInfo>
              Normalwert: 18-360 ng/mL. Bei Eisenmangel: &lt;30 ng/mL. CAVE: Bei
              Fällen von chronischer Niereninsuffizienz, chronischer
              Herzinsuffizienz oder Infektionen, wird Eisenmangel bei einem
              Ferritin-Level &lt;100 ng/mL diagnostiziert.
            </TextInfo>
          </li>
          <li>
            Mittleres zelluläres Volumen
            <TextInfo>
              Normalwert: 80-96 fL. Bei Eisenmangel: &lt;80 fL.
            </TextInfo>
          </li>
          <li>
            Retikulozytenhämoglobin
            <TextInfo>
              Normalwert: 18-360 ng/mL. Bei Eisenmangel: &lt;30 ng/mL.
            </TextInfo>
          </li>
        </ul>

        <h2>
          Algorithmus präoperatives Anämiemanagement{" "}
          <TextReference references={["ref2"]} />
        </h2>
        <p>
          Der folgende Algorithmus orientiert sich an maßgeblichen
          Schwellenwerten. Er kann Ihnen helfen, Diagnose und Therapie zu
          vereinfachen. Klicken Sie sich durch, um herauszufinden bei welchen
          Schwellenwerten welche Therapie(form) angezeigt ist.
        </p>

        <AnemiaManagementDecisionTree />

        <h2>Korrektur einer Eisenmangelanämie</h2>
        <p>
          Die Therapie einer Anämie bei chronischen Erkrankungen ist präoperativ
          häufig nur schwer durchführbar. Die Korrektur einer Eisenmangelanämie
          ist im Vergleich ein relativ einfaches Vorgehen.{" "}
          <TextReference references={["ref3"]} /> Sie wird, vorausgesetzt es
          bleibt genügend Zeit, vor nicht-kardialen Operationen stark empfohlen,
          um eine Transfusion von Erythrozytenkonzentraten während der
          Operationen zu vermeiden. <TextReference references={["ref9"]} />
        </p>
        <ul>
          <li>
            Aufgrund der hohen Inzidenzrate ist die Korrektur einer
            Eisenmangelanämie im Fokus des präoperativen Anämiemanagements{" "}
            <TextReference references={["ref3", "ref4", "ref5", "ref6"]} />
          </li>
          <li>
            Orale Eisenprodukte sind wegen der häufig knappen Vorlaufzeit zur
            anstehenden OP oft ineffektiv{" "}
            <TextReference references={["ref7"]} />
          </li>
          <li>
            Vorwiegendes <strong>Mittel der Wahl ist parenterales Eisen</strong>
            <ul>
              <li>
                Für eine maximale Steigerung des <Abbreviation>hb</Abbreviation>
                -Wertes sollten{" "}
                <strong>
                  idealerweise 2-4 Wochen zwischen Therapie und Eingriff
                </strong>{" "}
                liegen <TextReference references={["ref7"]} />
              </li>
              <li>
                Auch bei einem kürzeren Zeitraum wurde gezeigt, dass eine
                Eisengabe wenige Tage vor oder auch nach der OP zu einem
                relevanten Hb-Anstieg und einem besseren Patient:innen Outcome
                beitragen kann <TextReference references={["ref8"]} />
              </li>
            </ul>
          </li>
          <li>
            Aufschub einer elektiven Operation bis zum Zeitpunkt der optimal
            vorbereiteten Patient:innen sollte individuell diskutiert werden{" "}
            <TextReference references={["ref3"]} />
          </li>
        </ul>

        <Alert
          content={
            <>
              <span>CAVE:</span>
              <br />
              Schnelle <Abbreviation>ek</Abbreviation>
              -Transfusionen während der OP, werfen neben medizinischen auch
              juristische und ethische Fragen auf.{" "}
              <TextReference references={["ref3"]} />
            </>
          }
          type="warning"
        />
        <DynamicSpacing spacing={3} />
        <TextLink link={linkTransfusions} />

        <h2>Unnötige Blutverluste</h2>
        <p>
          Erhöhte Wahrscheinlichkeit für Bluttransfusion und die assoziierten
          Risiken entsteht neben der präoperativen Anämie durch unnötige
          Blutverluste.
        </p>
        <p>Hauptursachen hierfür sind u.a.:</p>
        <ul>
          <li>Größere intraoperative Blutverluste</li>
          <li>Koagulopathie</li>
          <li>
            Iatrogene Blutverluste
            <TextReference references={["ref3"]} />
          </li>
        </ul>
        <TextLink link={linkPmb} />
      </PagePadding>
    </>
  );
}

export default OpManagement;
