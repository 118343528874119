/** @jsxImportSource @emotion/react */

import { ReactNode, MouseEvent } from "react";
import { Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import StarIcon from "@mui/icons-material/Star";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import BasicLink from "../BasicLink/BasicLink";
import { Link } from "../ts/link";
import DragableWrapper from "../DragableWrapper/DragableWrapper";

export interface FavoritesListElementProps {
  link: Link;
  fullPath: string;
  listIndexPosition: number;
  description?: string | ReactNode;
  dragAndDropModeIsAvtive?: boolean;
  onFavoriteToggleClick?: () => void;
  moveElement?: (dragIndex: number, hoverIndex: number) => void;
}

export function FavoritesListElement(props: FavoritesListElementProps) {
  const theme = useTheme();

  const styles = {
    root: css`
      position: relative;
      border-bottom: 1px solid #82919a;
      padding-bottom: 14px;
      margin-top: 19px;
      a {
        text-decoration: none;
      }
    `,
    linkRow: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      color: ${theme.palette.primary.main};
    `,
    linkLabel: css`
      width: 88%;
      text-decoration: none;
    `,
    linkIcon: css`
      position: absolute;
      right: 0;
      top: 0;
      width: 12%;
      text-align: right;
      color: ${theme.palette.primary.main};
      cursor: ${props.dragAndDropModeIsAvtive ? "move" : "pointer"};
    `,
    descriptionRow: css`
      color: #82919a;
      margin-top: 14px;
    `,
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!props.dragAndDropModeIsAvtive && props.onFavoriteToggleClick) {
      props.onFavoriteToggleClick();
    }
  };

  function ElementWrapper({ children }: { children: ReactNode }) {
    return props.moveElement && props.dragAndDropModeIsAvtive ? (
      <DragableWrapper
        index={props.listIndexPosition}
        id={props.fullPath}
        moveItem={props.moveElement}
      >
        {children}
      </DragableWrapper>
    ) : (
      <BasicLink link={props.link}>{children}</BasicLink>
    );
  }

  return (
    <div css={styles.root}>
      <ElementWrapper>
        <div css={styles.linkIcon} onClick={handleClick}>
          {props.dragAndDropModeIsAvtive ? <DragHandleIcon /> : <StarIcon />}
        </div>
        <div css={styles.linkRow}>
          <Typography variant="h3" component="div" css={styles.linkLabel}>
            {props.link.label}
          </Typography>
        </div>
        {props.description && (
          <Typography
            variant="body3"
            component="div"
            css={styles.descriptionRow}
          >
            {props.description}
          </Typography>
        )}
      </ElementWrapper>
    </div>
  );
}

export default FavoritesListElement;
