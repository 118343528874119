import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import CiaDiagnosticAnemia from "./diagnosticAnemia";
import CiaDiagnosticIronDeficiency from "./diagnosticIronDeficiency";

export function CiaDiagnostics() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const anemia = currentPage?.children.find(
    (child) => child.view === CiaDiagnosticAnemia
  );
  if (anemia) {
    navigationItems.push({
      link: {
        label: anemia.title,
        isExternal: false,
        url: anemia.path,
      },
      description: anemia.description,
    });
  }

  const ironDeficiency = currentPage?.children.find(
    (child) => child.view === CiaDiagnosticIronDeficiency
  );
  if (ironDeficiency) {
    navigationItems.push({
      link: {
        label: ironDeficiency.title,
        isExternal: false,
        url: ironDeficiency.path,
      },
      description: ironDeficiency.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default CiaDiagnostics;
