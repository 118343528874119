import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import CiaTherapyOptions from "./therapyOptions";
import CiaTherapyEsa from "./therapyEsa";
import CiaTherapyIronSubstitution from "./therapyIronSubstitution";
import CiaTherapyEC from "./therapyEC";

export function CiaTherapy() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const options = currentPage?.children.find(
    (child) => child.view === CiaTherapyOptions
  );
  if (options) {
    navigationItems.push({
      link: {
        label: options.title,
        isExternal: false,
        url: options.path,
      },
      description: options.description,
    });
  }

  const esa = currentPage?.children.find(
    (child) => child.view === CiaTherapyEsa
  );
  if (esa) {
    navigationItems.push({
      link: {
        label: esa.title,
        isExternal: false,
        url: esa.path,
      },
      description: esa.description,
    });
  }

  const ironSubstitution = currentPage?.children.find(
    (child) => child.view === CiaTherapyIronSubstitution
  );
  if (ironSubstitution) {
    navigationItems.push({
      link: {
        label: ironSubstitution.title,
        isExternal: false,
        url: ironSubstitution.path,
      },
      description: ironSubstitution.description,
    });
  }

  const ec = currentPage?.children.find((child) => child.view === CiaTherapyEC);
  if (ec) {
    navigationItems.push({
      link: {
        label: ec.title,
        isExternal: false,
        url: ec.path,
      },
      description: ec.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Therapie</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default CiaTherapy;
