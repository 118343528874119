import {
  Accordion,
  PagePadding,
  Table,
  TableBody,
  TableRow,
  AccordionNodeItem,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";

export function MdsPraxisCasuisticsCardio() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Behandlung einer symptomatischen und transfusionsbedürftigen Anämie
          mit Erythrozytenkonzentraten und Deferasirox
        </h1>
        <p>
          80-jähriger Patient, 97 kg, 170 cm, <Abbreviation>ecog</Abbreviation>{" "}
          1
        </p>

        <Accordion
          items={[
            {
              title: "Symptome & Diagnostik",
              defaultOpen: true,
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Aktuelle Anamnese 07/2020</h3>
                    <ul>
                      <li>
                        Vorstellung bei der Hausärztin mit langsam zunehmender
                        Belastungsdyspnoe
                      </li>
                      <li>
                        Kardiale Genese der Beschwerden wahrscheinlich, aber
                        ambulante kardiologische Vorstellung inkl.
                        Echokardiographie erbringt keine wegweisenden Befunde
                      </li>
                      <li>
                        Blutbildkontrolle zeigt auffällige Anämie um 9 g/dl und
                        normwertige Thrombozyten und Leukozyten auf.
                        Retrospektiv kann die Hausärztin die Anämie, mit
                        langsamer Verschlechterungstendenz, bereits seit ca.
                        drei Jahren nachweisen.
                      </li>
                      <li>
                        Nach Ausschluss eines Substratmangels und empirischer
                        Eisensubstitution Überweisung zum niedergelassenen
                        Hämatologen
                      </li>
                    </ul>

                    <h3>Vorerkrankungen</h3>
                    <ul>
                      <li>
                        Vorhofflimmern, mittelgradige Aortenstenose und Z. n.
                        Myokardinfarkt
                      </li>
                      <li>
                        Diabetes mellitus Typ 2, Z. n. multiplen
                        Wirbelkörperfrakturen, Z. n. Knie-
                        <Abbreviation>tep</Abbreviation> links
                      </li>
                    </ul>

                    <h3>Diagnose</h3>
                    <ul>
                      <li>
                        Körperlicher Untersuchungsbefund:
                        <ul>
                          <li>
                            Systolikum über 2. <Abbreviation>icr</Abbreviation>{" "}
                            sowie über Erb, ansonsten unauffällig
                          </li>
                        </ul>
                      </li>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            <Abbreviation>hb</Abbreviation>: 7,0 g/dl,{" "}
                            <Abbreviation>l</Abbreviation>: 5,6{" "}
                            <Abbreviation>gpt</Abbreviation>
                            /l, <Abbreviation>anc</Abbreviation>: 3,73 Gpt/l,{" "}
                            <Abbreviation>thr</Abbreviation>: 336 Gpt/l
                          </li>
                        </ul>
                      </li>
                      <li>Kein Substratmangel</li>
                      <li>Keine unentdeckte virale Infektion</li>
                      <li>
                        <Abbreviation>epo</Abbreviation>
                        -Spiegel: &gt;500 U/l
                      </li>
                    </ul>

                    <h3>Knochenmark-Diagnostik von 07/2020</h3>
                    <ul>
                      <li>Keine Auffälligkeiten in der Zytogenetik</li>
                      <li>Ca. 4,5 % Blasten im Knochenmark</li>
                      <li>Nachweis einer U2AF1-Mutation</li>
                    </ul>

                    <h3>Abschließende Diagnose</h3>
                    <ul>
                      <li>
                        <Abbreviation>mdsmld</Abbreviation>
                      </li>
                      <li>
                        Gemäß <Abbreviation>ipss</Abbreviation>: Kategorie „low
                        risk“
                      </li>
                      <li>
                        Gemäß <Abbreviation>ipssr</Abbreviation>: Kategorie
                        „intermediate risk“{" "}
                        <TextReference references={["ref13"]} />
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Medikamentöse MDS-Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        Regelmäßige Transfusion von Erythrozytenkonzentraten
                        (ca. 4 EKs pro Monat/2 EKs alle zwei Wochen)
                      </li>
                      <li>Start Eisenchelationstherapie mit Deferasirox:</li>
                    </ul>
                    <Table>
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "720 mg"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "0-0-1"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"po"}>po</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Weiterer Verlauf",
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>12.08.2021</h3>
                    <p>
                      Nach insgesamt knapp einjähriger Therapiedauer verstirbt
                      der Patient infolge einer kardialen Dekompensation im
                      Gesamtkontext der multiplen kardialen Vorerkrankungen.
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default MdsPraxisCasuisticsCardio;
