/** @jsxImportSource @emotion/react */

import { Alert as MuiAlert, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { ReactNode } from "react";

export interface FeedbackProps {
  content: string | ReactNode;
  type: "info" | "warning";
  variant?: "outlined";
}

export function Alert(props: FeedbackProps) {
  const theme = useTheme();
  const fontColor = theme.palette[props.type].main;

  const styles = {
    root: css`
      margin-top: 32px;
      margin-bottom: 32px;
    `,
    content: css`
      color: ${fontColor};
      ul,
      ol {
        color: ${fontColor};
        margin-block-start: 0;
        margin-top: 0;
        padding-top: 0;
        margin-block-end: 0;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      ul li:before,
      ol li:before {
        background-color: ${fontColor};
      }
      p {
        color: ${fontColor};
      }
    `,
  };

  const variant = props.variant
    ? props.variant
    : props.type === "info"
    ? "outlined"
    : undefined;

  return (
    <MuiAlert css={styles.root} severity={props.type} variant={variant}>
      <Typography css={styles.content} variant="body1" component="div">
        {props.content}
      </Typography>
    </MuiAlert>
  );
}

export default Alert;
