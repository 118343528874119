import {
  NavigationList,
  PagePadding,
  NavigationListItem,
  Link,
} from "components/ui";
import { useContext } from "react";
import HexalSeo from "../../components/HexalSeo";
import FnDiagnosticNeutropenia from "./diagnosticNeutropenia";
import FnDiagnosticFn from "./diagnosticFn";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import FnDiagnosticSepsis from "./diagnosticSepsis";
import FnDiagnosticPulmonaryInfiltrates from "./diagnosticPulmonaryInfiltrates";
import GlobalContext from "../../context/globalContext";

export function FnDiagnostics() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const diagnosisNeutropenia = currentPage?.children.find(
    (child) => child.view === FnDiagnosticNeutropenia
  );
  if (diagnosisNeutropenia) {
    navigationItems.push({
      link: {
        label: diagnosisNeutropenia.title,
        isExternal: false,
        url: diagnosisNeutropenia.path,
      },
      description: diagnosisNeutropenia.description,
    });
  }

  const diagnosisFn = currentPage?.children.find(
    (child) => child.view === FnDiagnosticFn
  );
  if (diagnosisFn) {
    navigationItems.push({
      link: {
        label: diagnosisFn.title,
        isExternal: false,
        url: diagnosisFn.path,
      },
      description: diagnosisFn.description,
    });
  }

  const diagnosisSepsis = currentPage?.children.find(
    (child) => child.view === FnDiagnosticSepsis
  );
  if (diagnosisSepsis) {
    navigationItems.push({
      link: {
        label: diagnosisSepsis.title,
        isExternal: false,
        url: diagnosisSepsis.path,
      },
      description: diagnosisSepsis.description,
    });
  }

  const diagnosisPulmonaryInfiltrates = currentPage?.children.find(
    (child) => child.view === FnDiagnosticPulmonaryInfiltrates
  );
  if (diagnosisPulmonaryInfiltrates) {
    navigationItems.push({
      link: {
        label: diagnosisPulmonaryInfiltrates.title,
        isExternal: false,
        url: diagnosisPulmonaryInfiltrates.path,
      },
      description: diagnosisPulmonaryInfiltrates.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default FnDiagnostics;
