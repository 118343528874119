import { Alert, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";
import TextReference from "../../components/TextReference";
import NordicScore from "../../components/Scores/NordicScore";

export function MdsTherapyLowRiskEsaNordicScore() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Abschätzen <Abbreviation>esa</Abbreviation>
          -Therapieerfolg – Nordic Score
        </h1>
        <p>Unter Berücksichtigung der prädiktiven Faktoren</p>
        <ul>
          <li>Erythropoetinspiegel &lt;200 (500) U/l</li>
          <li>
            geringe Transfusionsabhängigkeit{" "}
            <TextInfo>
              maximal 2 <Abbreviation>ek</Abbreviation> in 8 Wochen
            </TextInfo>
          </li>
          <li>
            <Abbreviation>ipss</Abbreviation> LOW/
            <Abbreviation>int</Abbreviation>
            -1 <Abbreviation>mds</Abbreviation>
          </li>
        </ul>
        <p>
          kann ein Ansprechen bei bis zu 75 % der entsprechend ausgewählten
          Patient:innen erreicht werden.
        </p>

        <Alert
          content={
            <ul>
              <li>Ansprechen i. d. R. nach spätestens 6 Monaten Therapie</li>
              <li>
                zu erwartende mediane Dauer des Ansprechens: 2 Jahre{" "}
                <TextReference references={["ref16"]} />
              </li>
              <li>
                Bei ausbleibendem Ansprechen: Behandlung sollte beendet werden
              </li>
            </ul>
          }
          type="info"
        />

        <h2>
          Modifizierter Score der Nordic <Abbreviation>mds</Abbreviation> Group
          zur Abschätzung des <Abbreviation>esa</Abbreviation>
          -Therapieerfolgs <TextReference references={["ref1"]} />
        </h2>
        <p>
          Der Score der Nordic MDS-Group berücksichtigt die Transfusionsfrequenz
          sowie die Höhe des endogenen Erythropoetin-Spiegels. Die Addition des
          Score-Wertes für die Transfusionsfrequenz und des Score-Wertes für den
          endogenen Erythropoetin-Spiegel ergibt den Wert, der mit der
          Wahrscheinlichkeit auf ein Ansprechen auf eine Therapie mit
          Erythropoese stimulierenden Medikamenten korreliert.
        </p>
        <NordicScore />
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskEsaNordicScore;
