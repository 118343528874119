/** @jsxImportSource @emotion/react */
import { Button, Card, InputField } from "../ui";
import { css } from "@emotion/react";
import { useAuth } from "../../hooks/Authentication";
import { useState } from "react";
import { checkUserNamePassword, hashPassword } from "../../util/authHelpers";

export function PasswordLoginForm() {
  const { login } = useAuth();

  const initialFieldValues = {
    userName: "",
    password: "",
  };

  const [form, setForm] = useState(initialFieldValues);
  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const setUserName = (userName: string) => {
    setForm((values) => ({ password: values.password, userName }));
  };
  const setPassword = (password: string) => {
    setForm((values) => ({ password: password, userName: values.userName }));
  };

  const handleSubmit = () => {
    const userNameIsValid = form.userName.length !== 0;
    const passwordIsValid = form.password.length !== 0;
    setUserNameError(!userNameIsValid);
    setPasswordError(!passwordIsValid);

    if (
      userNameIsValid &&
      passwordIsValid &&
      checkUserNamePassword(form.userName, form.password)
    ) {
      login("password", hashPassword(form.password));
    } else {
      setUserNameError(true);
      setPasswordError(true);
    }
  };

  const styles = {
    copy: css`
      text-align: center;
      color: rgba(0, 0, 0, 0.38);
    `,
    row: css`
      margin-top: 16px;
    `,
  };

  return (
    <Card title={"Ihr HEXAL Login"}>
      <form>
        <div>
          <InputField
            hasError={userNameError}
            label={"Benutzername"}
            onChange={(value) => setUserName(value)}
            name={"pwUserName"}
          />
        </div>
        <div css={styles.row}>
          <InputField
            hasError={passwordError}
            label={"Passwort"}
            type={"password"}
            onChange={(value) => setPassword(value)}
            name={"pwPassword"}
            autoComplete={"off"}
          />
        </div>
        <div css={styles.row}>
          <Button onClick={handleSubmit}>Jetzt anmelden</Button>
        </div>
      </form>
      <div css={[styles.copy, styles.row]}>
        Fall Sie ihre Zugangsdaten vergessen haben, kontaktieren Sie bitte Ihren
        Hexal Außendienstmitarbeiter.
      </div>
    </Card>
  );
}

export default PasswordLoginForm;
