import {
  Link,
  NavigationList,
  PagePadding,
  NavigationListItem,
} from "components/ui";
import { useContext } from "react";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import MdsTherapyIronOverloadTransfusion from "./therapyIronOverloadTransfusion";
import MdsTherapyIronOverloadDeferasirox from "./therapyIronOverloadDeferasirox";
import MdsTherapyIronOverloadDeferasiroxDosage from "./therapyIronOverloadDeferasiroxDosage";

export function MdsTherapyIronOverload() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const management = currentPage?.children.find(
    (child) => child.view === MdsTherapyIronOverloadTransfusion
  );
  if (management) {
    navigationItems.push({
      link: {
        label: management.title,
        isExternal: false,
        url: management.path,
      },
      description: management.description,
    });
  }

  const deferasirox = currentPage?.children.find(
    (child) => child.view === MdsTherapyIronOverloadDeferasirox
  );
  if (deferasirox) {
    navigationItems.push({
      link: {
        label: deferasirox.title,
        isExternal: false,
        url: deferasirox.path,
      },
      description: deferasirox.description,
    });
  }

  const deferasiroxDosage = currentPage?.children.find(
    (child) => child.view === MdsTherapyIronOverloadDeferasiroxDosage
  );
  if (deferasiroxDosage) {
    navigationItems.push({
      link: {
        label: deferasiroxDosage.title,
        isExternal: false,
        url: deferasiroxDosage.path,
      },
      description: deferasiroxDosage.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Management transfusionsbedingte Eisenüberladung</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsTherapyIronOverload;
