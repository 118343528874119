import { TableBody as MuiTableBody } from "@mui/material";
import { ReactNode } from "react";

export interface TableBodyProps {
  children: ReactNode;
}

export function TableBody(props: TableBodyProps) {
  return <MuiTableBody>{props.children}</MuiTableBody>;
}

export default TableBody;
