/** @jsxImportSource @emotion/react */

import {
  Accordion,
  DynamicSpacing,
  FullWidthCarousel,
  Graph,
  GraphLegend,
  PagePadding,
  Table,
  AccordionNodeItem,
  TableHead,
  TableBody,
  TableRow,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";

import * as dosage1 from "../../assets/img/fn/praxis/grafik-morbus-hodgkin-1-2.svg";
import * as dosage2 from "../../assets/img/fn/praxis/grafik-morbus-hodgkin-2-2.svg";
import TextReference from "../../components/TextReference";
import { css } from "@emotion/react";

export function FnPraxisCasuisticsMorbusHodgkin() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Rolle der Supportivtherapie in der Hämato-Onkologie Morbus Hodgkin
        </h1>
        <p>
          48-jähriger Patient, 64 kg, 168 cm, <Abbreviation>ecog</Abbreviation>{" "}
          1
        </p>
        <Accordion
          items={[
            {
              title: "Symptome & Diagnostik",
              defaultOpen: true,
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Aktuelle Anamnese 06/2018:</h3>
                    <ul>
                      <li>
                        Seit März 2018 rezidivierende Fieberschübe (über 39 °C)
                        und Nachtschweiß (2 x nächtliches
                        Kleiderwechseln/Nacht), außerdem Gewichtsverlust von 9
                        kg in 3 Monaten
                      </li>
                      <li>
                        In den letzten Wochen Zunahme einer allgemeinen Schwäche
                        und Abgeschlagenheit mit zunehmender Leistungsminderung
                      </li>
                    </ul>
                    <h3>Vorerkrankungen</h3>
                    <ul>
                      <li>
                        Keine Vorerkrankungen, keine Malignome in der Familie
                      </li>
                    </ul>
                    <h3>Diagnose</h3>
                    <ul>
                      <li>
                        Aus Histologie des Biopsats aus dem Mediastinum und
                        Knochenmark
                      </li>
                      <li>
                        <strong>Morbus Hodgkin</strong>
                      </li>
                    </ul>
                    <h3>Staging</h3>
                    <ul>
                      <li>
                        <strong>
                          <Abbreviation>ct</Abbreviation>:
                        </strong>{" "}
                        infrakarinaler Lymphombulk (10,5 x 6,5 x 3,5 cm),
                        mediastinaler Befall, Lymphknotenvergrößerungen zervikal
                        rechts, peripankreatisch, paraaortal und Splenomegalie
                      </li>
                      <li>
                        <strong>
                          Histologie aus einem Biopsat aus dem Mediastinum und
                          dem Knochenmark:
                        </strong>{" "}
                        Nachweis eines Morbus Hodgkin vom gemischten Typ
                      </li>
                    </ul>
                    <h3>Abschließende Diagnose</h3>
                    <ul>
                      <li>
                        <strong>Morbus Hodgkin, Stadium IV B</strong>
                      </li>
                      <li>
                        Risikofaktoren: Mediastinaler Bulk, &gt; 3 Regionen
                        betroffen, erhöhte <Abbreviation>bsg</Abbreviation>
                      </li>
                      <li>
                        Entspricht einem{" "}
                        <strong>fortgeschrittenen Stadium</strong>
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Medikamentöse Tumor-Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <p>
                      <Abbreviation>beacopp</Abbreviation> eskaliert, q3w x 6
                    </p>
                    <h3>Dosierung</h3>
                    <ul>
                      <li>
                        Doxorubicin (A, 35 mg/m<sup>2</sup>{" "}
                        <Abbreviation>kof</Abbreviation>) Tag 1
                      </li>
                      <li>
                        Cyclophosphamid (C, 1250 mg/m<sup>2</sup> KOF) Tag 1
                      </li>
                      <li>
                        Etoposid (E, 200 mg/m<sup>2</sup> KOF) Tag 1–3
                      </li>
                      <li>
                        Procarbazin (P, 100 mg/m<sup>2</sup> KOF) Tag 1–7,
                        täglich
                      </li>
                      <li>
                        Vincristin (O, 1,4 mg/m<sup>2</sup> KOF; Maximaldosis 2
                        mg absolut) Tag 8
                      </li>
                      <li>
                        Bleomycin (B, 10 mg/m<sup>2</sup> KOF) Tag 8
                      </li>
                      <li>
                        Prednison (P, 40 mg/m<sup>2</sup> KOF) Tag 1–14, täglich
                      </li>
                    </ul>
                    <GraphLegend
                      items={[
                        {
                          type: "rectangle",
                          color: "blue",
                          label: "Doxorubicin",
                        },
                        {
                          type: "rectangle",
                          color: "green",
                          label: "Vincristin",
                        },
                        {
                          type: "rectangle",
                          color: "violet",
                          label: "Etoposid",
                        },
                        {
                          type: "rectangle",
                          color: "red",
                          label: "Bleomycin",
                        },
                        {
                          type: "rectangle",
                          color: "brown",
                          label: "Cyclophosphamid",
                        },
                        {
                          type: "rectangle",
                          color: "grey",
                          label: "Procarbazin",
                        },
                        {
                          type: "rectangle",
                          color: "orange",
                          label: "Prednison",
                        },
                        {
                          type: "triangle",
                          color: "blue",
                          label: "G-CSF",
                        },
                      ]}
                    />
                    <FullWidthCarousel
                      slides={[
                        {
                          body: (
                            <Graph
                              imagePath={dosage1.default}
                              alt="BEACOPP eskaliert"
                            />
                          ),
                        },
                        {
                          body: (
                            <Graph
                              imagePath={dosage2.default}
                              alt="BEACOPP eskaliert"
                            />
                          ),
                        },
                      ]}
                      bordered
                    />
                    <h3>Empfehlungsgrad S3-Leitlinie</h3>
                    <p>
                      Erwachsene Patient:innen bis zu 60 Jahre mit
                      fortgeschrittenem Hodgkin-Lymphom: A{" "}
                      <TextReference references={["ref2"]} />
                    </p>
                    <h3>Protokollklassifikation</h3>
                    <ul>
                      <li>
                        Klassifikation: Standard für ältere und komorbide
                        Patient:innen
                      </li>
                      <li>Intensität: weniger intensive Chemo-Immuntherapie</li>
                      <li>Therapieintention: palliativ</li>
                    </ul>
                    <h3>Risiko</h3>
                    <p>
                      Febrile Neutropenie &gt;20 % (hohes Risiko){" "}
                      <TextReference references={["ref5"]} />
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: (
                <p
                  css={css`
                    margin-top: 0;
                    margin-bottom: 0;
                  `}
                >
                  Supportivtherapie
                  <TextReference references={["ref11"]} />
                </p>
              ),
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Protokoll Neutropenie-Prophylaxe</h3>
                    <ul>
                      <li>
                        Primäre Prophylaxe im 1. Zyklus initiiert und in 6
                        Zyklen weitergeführt
                      </li>
                    </ul>
                    <h3>Filgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "4-14"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "5 μg/kg KG/Tag → 30 Mio. E."]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q21d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc1"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <h3>Pegfilgrastim</h3>
                    <Table>
                      <TableHead labels={["Tag", "4"]} />
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "6 mg"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q21d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc2"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />
                    <p>
                      Wir danken Dr. Daniela Tölle und Prof. Dr. Georg Heß für
                      die Initiative, Mitgestaltung und Mitentwicklung der
                      Kasuistik und Therapie.
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default FnPraxisCasuisticsMorbusHodgkin;
