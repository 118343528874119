import {
  ColoredText,
  DiagnosticsIcon,
  LightBulbIcon,
  MainBackground,
  MedicBookIcon,
  PagePadding,
  PrimaryNavIconButton,
  SyringeIcon,
} from "components/ui";
import { Grid } from "@mui/material";
import { useContext } from "react";
import HexalSeo from "../../components/HexalSeo";
import GlobalContext from "../../context/globalContext";
import MdsDisease from "./disease";
import MdsDiagnostics from "./diagnostics";
import MdsTherapy from "./therapy";
import MdsPraxis from "./praxis";

export function MdsHome() {
  const { currentPage } = useContext(GlobalContext);

  const diseaseChild = currentPage?.children.find(
    (child) => child.view === MdsDisease
  );
  const diagnosticsChild = currentPage?.children.find(
    (child) => child.view === MdsDiagnostics
  );
  const therapyChild = currentPage?.children.find(
    (child) => child.view === MdsTherapy
  );
  const praxisChild = currentPage?.children.find(
    (child) => child.view === MdsPraxis
  );

  return (
    <>
      <HexalSeo />
      <MainBackground />
      <PagePadding>
        <h2>
          <ColoredText color="primary">MDS-assoziierte Anämie</ColoredText>
        </h2>

        <Grid container spacing="24px">
          {diseaseChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: diseaseChild.title,
                  isExternal: false,
                  url: diseaseChild.path,
                  title: "Zum Kapitel Erkrankung",
                }}
                icon={<MedicBookIcon />}
              />
            </Grid>
          )}
          {diagnosticsChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: diagnosticsChild.title,
                  isExternal: false,
                  url: diagnosticsChild.path,
                  title: "Zum Kapitel Diagnostik/Prognose",
                }}
                icon={<DiagnosticsIcon />}
              />
            </Grid>
          )}
          {therapyChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: therapyChild.title,
                  isExternal: false,
                  url: therapyChild.path,
                  title: "Zum Kapitel Therapeutische Maßnahmen",
                }}
                icon={<SyringeIcon />}
              />
            </Grid>
          )}
          {praxisChild && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: praxisChild.title,
                  isExternal: false,
                  url: praxisChild.path,
                  title: "Zum Kapitel Aus der Praxis",
                }}
                icon={<LightBulbIcon />}
              />
            </Grid>
          )}
        </Grid>
      </PagePadding>
    </>
  );
}

export default MdsHome;
