import { Grid } from "@mui/material";
import GraphLegendItem from "./GraphLegendItem";

export interface GraphLegendProps {
  items: {
    label: string;
    color?:
      | "blue"
      | "violet"
      | "lightViolet"
      | "brown"
      | "orange"
      | "green"
      | "red"
      | "grey"
      | "yellow"
      | "darkBlue"
      | "turquoise";
    type: "rectangle" | "triangle" | "none" | "empty";
  }[];
}

export function GraphLegend(props: GraphLegendProps) {
  return (
    <Grid container>
      {props.items.map((item, index) => (
        <Grid item xs={6} key={index}>
          <GraphLegendItem {...item} />
        </Grid>
      ))}
    </Grid>
  );
}

export default GraphLegend;
