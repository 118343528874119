import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";
import TextReference from "../../components/TextReference";

export function FnRiskFactors() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Risikofaktoren</h1>
        <p>
          Die Risikofaktoren für <Abbreviation>fn</Abbreviation> hängen ab von:
        </p>
        <ul>
          <li>Toxizität der Tumortherapie-Regime</li>
          <li>Patientenindividuelle Faktoren</li>
        </ul>
        <p>
          Patientenindividuelle Risikofaktoren sollen vor dem Start jedes
          Chemotherapie-Zyklus zur Abschätzung des Gesamtrisikos der febrilen
          Neutropenie evaluiert werden:
        </p>
        <ul>
          <li>Alter &gt; 65 Jahre</li>
          <li>
            Niedriger Performance-Status
            <TextInfo>
              niedriger Karnofsky-Index, hoher <Abbreviation>ecog</Abbreviation>
            </TextInfo>
          </li>
          <li>
            Komorbiditäten
            <TextInfo>
              <Abbreviation>copd</Abbreviation>, Herzinsuffizienz{" "}
              <Abbreviation>nyha</Abbreviation> III-IV, HIV-Infektion,
              Autoimmunerkrankung, deutlich eingeschränkte Nierenfunktion
            </TextInfo>
          </li>
          <li>Weit fortgeschrittene, symptomatische Tumorerkrankung</li>
          <li>In der Vergangenheit stattgehabte Chemotherapie</li>
          <li>
            Laborparameter
            <TextInfo>
              Anämie, Lymphozytopenie &lt; 700/µl, Hypalbuminämie,
              Hyperbilirubinämie
            </TextInfo>{" "}
            <TextReference references={["ref2"]} />
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default FnRiskFactors;
