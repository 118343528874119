import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import PulmonaryInfiltratesDecisionTree from "../../components/DecisionTrees/PulmonaryInfiltratesDecisionTree";

export function FnDiagnosticPulmonaryInfiltrates() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik der Lungeninfiltrate</h1>
        <p>
          Lungeninfiltrate sind eine häufige Komplikation bei Patient:innen mit
          schwerer und prolongierter Neutropenie.{" "}
          <TextReference references={["ref15"]} />
        </p>
        <h2>
          Vorgehen und Therapie:
          <TextReference references={["ref15"]} />
        </h2>
        <p>
          Nachfolgend finden Sie einen Entscheidungsbaum basierend auf der DGHO
          Leitlinie. Durch Auswählen einer Option erscheint der jeweils nächste
          Schritt. Starten Sie bei den Grundvoraussetzungen „Persistierendes
          Fieber“ oder „Symptome einer Infektion der unteren Luftwege“ an und
          entscheiden Sie welche Diagnostik wann eingesetzt wird und welche
          Therapie daraus folgt.
        </p>
        <PulmonaryInfiltratesDecisionTree />
      </PagePadding>
    </>
  );
}

export default FnDiagnosticPulmonaryInfiltrates;
