import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import TextInfo from "../../components/TextInfo";

export function MdsTherapyIronOverloadTransfusion() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Transfusionsbedingte Eisenüberladung</h1>
        <p>
          Folgen einer Eisenüberladung bei transfusionspflichtigen{" "}
          <Abbreviation>mds</Abbreviation>
          -Patient:innen: <TextReference references={["ref6", "ref7"]} />
        </p>
        <ul>
          <li>Erhöhtes Infektionsrisiko</li>
          <li>Zusätzliche Belastung der Hämatopoese</li>
          <li>Störungen der Herz-, Leber- und endokrinen Funktionen</li>
          <li>Verringertes Überleben</li>
          <li>schlechteres Outcome bei allogener Stammzelltransplantation</li>
        </ul>
        <p>
          Therapie mit Deferasirox HEXAL
          <sup>®</sup> kann
        </p>
        <ul>
          <li>
            Gesamtüberleben von Patient:innen mit Niedrigrisiko-MDS signifikant
            verlängern{" "}
            <TextInfo>
              TELESTO zeigte als erste Studie prospektiv und randomisiert einen
              eindeutig positiven Effekt der Deferasirox-Therapie auf das{" "}
              <Abbreviation>efs</Abbreviation> von Patient:innen mit
              Niedrigrisiko- oder <Abbreviation>int</Abbreviation>
              -1-Risiko-
              <Abbreviation>mds</Abbreviation>
              .
              <br />
              (Signifikant längeres medianes ereignisfreies Überleben unter
              Deferasirox vs. Placebo: 4,0 Jahre vs. 3,0 Jahre)
            </TextInfo>{" "}
            <TextReference references={["ref8", "ref14"]} />
          </li>
          <li>
            bei Langzeitanwendung Zeit bis zur Progression zu{" "}
            <Abbreviation>aml</Abbreviation> verlängern{" "}
            <TextInfo>
              Unter Kurztherapie mit Deferasirox ( &lt; 6 Monate) betrug die
              1-Jahres-Progressionsrate 44,4&nbsp;% und die
              2-Jahres-Progressionsrate 71,5&nbsp;%. Unter Langzeittherapie mit
              Deferasirox (≥ 6 Monate) waren die 1- und
              2-Jahres-Progressionsraten mit 15,3&nbsp;% bzw. 40,9&nbsp;% rund
              30&nbsp;% niedriger.
            </TextInfo>{" "}
            <TextReference references={["ref9", "ref10"]} />
          </li>
          <li>
            bei Langzeitanwendung Risiko für kardiale Ereignisse verringern
            <TextReference references={["ref9", "ref10"]} />
          </li>
          <li>
            die Hämatopoese positiv beeinflussen
            <TextReference references={["ref11"]} />
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default MdsTherapyIronOverloadTransfusion;
