import { ReactNode } from "react";
import { List as MuiList } from "@mui/material";

/* eslint-disable-next-line */
export interface ListProps {
  children: ReactNode;
}

export function List(props: ListProps) {
  return <MuiList>{props.children}</MuiList>;
}

export default List;
