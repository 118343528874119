/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import Chip from "../Chip/Chip";

/* eslint-disable-next-line */
export interface ChipLineSeparatorProps {
  label: string;
}

export function ChipLineSeparator(props: ChipLineSeparatorProps) {
  const theme = useTheme();

  const styles = {
    root: css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      :before,
      :after {
        content: "";
        display: inline-block;
        flex: 1;
        height: 0;
        width: auto;
        border-top: ${theme.palette.primary.main} 1px solid;
      }
    `,
    separator: css`
      margin: 0 4px;
    `,
  };
  return (
    <div css={styles.root}>
      <span css={styles.separator}>
        <Chip label={props.label.toUpperCase()} color="primary" />
      </span>
    </div>
  );
}

export default ChipLineSeparator;
