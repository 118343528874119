import { ReactElement } from "react";
import { Tooltip as MuiTooltip } from "@mui/material";

export interface TooltipProps {
  // any as a type here is necessary, because MuiTooltip requires this typing
  // eslint-disable-next-line
  children: ReactElement<any, any>;
  caption: string;
}

export function Tooltip(props: TooltipProps) {
  return (
    <MuiTooltip
      onClick={(event) => event.stopPropagation()}
      enterTouchDelay={1}
      leaveTouchDelay={2000}
      arrow
      title={<div dangerouslySetInnerHTML={{ __html: props.caption }} />}
    >
      {props.children}
    </MuiTooltip>
  );
}

export default Tooltip;
