/** @jsxImportSource @emotion/react */

/* eslint-disable-next-line */
import { Button, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { Link } from "../ts/link";
import BasicLink from "../BasicLink/BasicLink";

export interface PrimaryNavButtonProps {
  link: Link;
}

export function PrimaryNavButton(props: PrimaryNavButtonProps) {
  const theme = useTheme();

  const styles = {
    root: css`
      width: 100%;
      :not(:first-of-type) {
        margin-top: 17px;
      }
      a {
        text-decoration: none;
      }
    `,
    inner: css`
      width: 100%;
      height: 85px;
      border-radius: 11px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      ${theme.breakpoints.up("md")} {
        padding-top: 72px;
        padding-bottom: 72px;
      }
    `,
    label: css`
      width: 90%;
      text-align: center;
      color: ${theme.palette.primary.main};
    `,
  };
  return (
    <div css={styles.root}>
      <BasicLink link={props.link}>
        <Button css={styles.inner}>
          <Typography css={styles.label} component="div" variant="buttonStart">
            {props.link.label}
          </Typography>
        </Button>
      </BasicLink>
    </div>
  );
}

export default PrimaryNavButton;
