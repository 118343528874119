/** @jsxImportSource @emotion/react */

import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import ExternalLinkModal from "./ExternalLinkModal";
import { Link as LinkType, LinkTarget } from "../ts/link";

/* eslint-disable-next-line */
export interface BasicLinkProps {
  link: LinkType;
  children?: ReactNode;
  target?: LinkTarget;
  disableExternalLinkModal?: boolean;
  enableLinkDecoration?: boolean;
}

export function BasicLink(props: BasicLinkProps) {
  const { disableExternalLinkModal = false, enableLinkDecoration = false } =
    props;

  const theme = useTheme();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const styles = {
    modalLink: css`
      display: inline-block;
      text-decoration: ${enableLinkDecoration ? "underline" : "none"};
      cursor: pointer;
      color: ${enableLinkDecoration ? theme.palette.primary.main : "inherit"};
    `,
  };

  return (
    <>
      {props.link.isExternal && (
        <>
          {disableExternalLinkModal ? (
            <a
              title={props.link.title}
              href={props.link.url}
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.children && props.children}
              {!props.children && props.link.label}
            </a>
          ) : (
            <>
              <div css={styles.modalLink} onClick={() => setModalIsOpen(true)}>
                {props.children && props.children}
                {!props.children && props.link.label}
              </div>
              <ExternalLinkModal
                url={props.link.url}
                title={props.link.title}
                open={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
              />
            </>
          )}
        </>
      )}
      {!props.link.isExternal && (
        <Link title={props.link.title} to={props.link.url}>
          {props.children && props.children}
          {!props.children && props.link.label}
        </Link>
      )}
    </>
  );
}

export default BasicLink;
