/** @jsxImportSource @emotion/react */

/* eslint-disable-next-line */
import Checkbox from "../Checkbox/Checkbox";
import { FormControlLabel, useTheme } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { css } from "@emotion/react";

export interface CheckListProps {
  labelParent: string | ReactNode;
  prechecked?: boolean;
  childBoxes: {
    id: string;
    label: string | ReactNode;
    checked: boolean;
  }[];
  onChildBoxesChange?: (boxValues: boolean[]) => void;
}

export function CheckList(props: CheckListProps) {
  const theme = useTheme();
  const initialState = props.childBoxes.map((child) =>
    props.prechecked ? true : child.checked
  );
  const [checked, setChecked] = useState(initialState);
  const [parentChecked, setParentChecked] = useState(!!props.prechecked);

  useEffect(() => {
    if (props.onChildBoxesChange) {
      props.onChildBoxesChange(checked);
    }
  }, [checked, props]);

  const handleParentBoxClick = (newValue: boolean) => {
    setParentChecked(newValue);
    setChecked((checked) => checked.map(() => newValue));
  };

  const handleChildBoxClick = (index: number, newValue: boolean) => {
    setChecked((checked) =>
      checked.map((val, i) => {
        if (i === index) {
          return newValue;
        }
        return val;
      })
    );
  };

  const styles = {
    root: css`
      border-bottom: 1px #82919a solid;
      > label {
        margin-left: 0;
        margin-right: 0;
        > label {
          margin-left: 0;
          margin-right: 0;
        }
      }
    `,
    parent: css`
      width: 100%;
      background: ${theme.palette.hxBackground.primary};
      padding: 10px 18px 10px 10px;
      //padding-top: 10px;
      //padding-bottom: 10px;
      //padding-right: 18px;
      //padding-left: 10px;
      > label {
        margin-left: 0;
        margin-right: 0;
        > label {
          margin-left: 0;
          margin-right: 0;
        }
      }
    `,
    children: css`
      padding-top: 10px;
      padding-bottom: 7px;
      border-top: 1px #82919a solid;
    `,
    childBox: css`
      width: 100%;
      padding-left: 50px;
      padding-top: 10px;
      padding-bottom: 7px;
      border-bottom: 1px #82919a solid;
      :first-of-type {
        padding-top: 0;
      }
      :last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    `,
  };

  const children =
    props.childBoxes.length > 0 ? (
      <div css={styles.children}>
        {props.childBoxes.map((child, index) => (
          <div key={child.id} css={styles.childBox}>
            <Checkbox
              color="default"
              label={child.label}
              checked={checked[index]}
              onChange={(newValue) => handleChildBoxClick(index, newValue)}
            />
          </div>
        ))}
      </div>
    ) : (
      <></>
    );

  return (
    <div css={styles.root}>
      <div css={styles.parent}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              label={props.labelParent}
              checked={
                props.childBoxes.length > 0
                  ? checked.every((value) => value)
                  : parentChecked
              }
              indeterminate={
                checked.some((value) => !value) &&
                checked.some((value) => value)
              }
              onChange={(newValue) => handleParentBoxClick(newValue)}
            />
          }
          label=""
        />
      </div>
      {children}
    </div>
  );
}

export default CheckList;
