import {
  ColoredText,
  DynamicSpacing,
  PagePadding,
  Table,
  TableRow,
} from "components/ui";
import { TableBody } from "@mui/material";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import ChemoInducedFnRiskDecisionTree from "../../components/DecisionTrees/ChemoInducedFnRiskDecisionTree";

export function FnProphylaxisGCsfProphylaxis() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>G-CSF-Prophylaxe der FN</h1>
        <p>
          Die prophylaktische <Abbreviation>gcsf</Abbreviation>
          -Gabe richtet sich nach dem Risiko, eine febrile Neutropenie
          entsprechend der verwendeten Chemotherapie und der individuellen
          Risikofaktoren zu entwickeln. <TextReference references={["ref2"]} />
        </p>

        <h2>
          Algorithmus zur Entscheidung über G-CSF-Prophylaxe der FN bei
          Chemotherapie
        </h2>
        <p>
          Neutropenie-Guidelines empfehlen die Prophylaxe mit{" "}
          <Abbreviation>gcsf</Abbreviation> zur Reduzierung der
          Chemotherapie-induzierten febrilen Neutropenie nach folgendem
          Prüfschema. Wählen Sie eine der folgenden Risikostufen aus, um zu
          sehen, wann eine G-CSF-Gabe empfohlen wird.
        </p>
        <Table>
          <TableBody>
            <TableRow
              entries={[
                <ColoredText key={"low1"} color="success">
                  &lt; 10 %
                </ColoredText>,
                <ColoredText key={"low2"} color="success">
                  gering
                </ColoredText>,
              ]}
            />
            <TableRow
              entries={[
                <ColoredText key={"med1"} color="warning">
                  10–20 %
                </ColoredText>,
                <ColoredText key={"med2"} color="warning">
                  mittel
                </ColoredText>,
              ]}
            />
            <TableRow
              entries={[
                <ColoredText key={"hi1"} color="error">
                  &gt; 20 %
                </ColoredText>,
                <ColoredText key={"hi2"} color="error">
                  hoch
                </ColoredText>,
              ]}
            />
          </TableBody>
        </Table>
        <DynamicSpacing spacing={4} />

        <ChemoInducedFnRiskDecisionTree />

        <p>
          Um das <Abbreviation>fn</Abbreviation>
          -Gesamtrisiko bei Chemotherapien, die mit einem FN-Risiko von 10-19 %
          assoziiert sind, abzuschätzen, müssen zusätzlich auch die
          patientenbezogenen Risikofaktoren bewertet werden. Das so ermittelte
          individuelle Gesamtrisiko entscheidet, ob eine G-CSF-Prophylaxe
          angezeigt ist oder nicht.{" "}
          <TextReference references={["ref2", "ref5", "ref6", "ref7"]} />
        </p>

        <h3>Primärprophylaxe</h3>
        <p>
          Beginn der G-CSF-Behandlung im ersten Zyklus 24 bis 72 Stunden nach
          dem Ende der ersten Chemotherapie und Fortsetzung über alle Zyklen
          (wenn gemäß der Neubewertung nach jedem Zyklus angemessen).{" "}
          <TextReference references={["ref5"]} />
        </p>

        <h3>Sekundärprophylaxe</h3>
        <p>
          Beginn der G-CSF-Behandlung, wenn ein neutropenisches Ereignis im
          vorherigen Zyklus beobachtet wurde.{" "}
          <TextReference references={["ref5"]} />
        </p>
      </PagePadding>
    </>
  );
}

export default FnProphylaxisGCsfProphylaxis;
