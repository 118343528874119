import { ReactNode } from "react";
import { Typography } from "@mui/material";

export interface BigCopyProps {
  children: ReactNode;
}

export function BigCopy(props: BigCopyProps) {
  return (
    <Typography variant="h3" color="#82919a">
      {props.children}
    </Typography>
  );
}

export default BigCopy;
