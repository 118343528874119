/** @jsxImportSource @emotion/react */

import { Button as MuiButton, Typography } from "@mui/material";
import { ReactNode } from "react";
import { css } from "@emotion/react";

interface ButtonProps {
  children: ReactNode;
  color?: "primary";
  onClick?: () => void;
}
export function Button(props: ButtonProps) {
  const { color = "primary" } = props;
  const styles = {
    root: css`
      width: 100%;
      box-shadow: none;
      text-transform: none;
    `,
  };

  return (
    <MuiButton
      css={styles.root}
      variant={"contained"}
      color={color}
      onClick={props.onClick}
    >
      <Typography variant={"buttonStart"} color={"#fff"}>
        {props.children}
      </Typography>
    </MuiButton>
  );
}

export default Button;
