/** @jsxImportSource @emotion/react */

import React, {
  isValidElement,
  ReactNode,
  SyntheticEvent,
  useState,
} from "react";
import {
  Accordion as MuiAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import { ExpandLess } from "@mui/icons-material";
import Chip from "../Chip/Chip";

interface BodyElement {
  content: ReactNode;
  colorScheme?: "default" | "error" | "warning" | "success";
}

export interface AccordionItem {
  title: string | ReactNode;
  body?: BodyElement | BodyElement[];
  chipLabel?: string;
  chipType?: "primary" | "error" | "warning" | "success";
  defaultOpen?: boolean;
}

export interface AccordionProps {
  items: AccordionItem[];
  expandIcon?: ReactNode;
  summaryAlwaysPrimary?: boolean;
}

export function Accordion(props: AccordionProps) {
  let initialState: string | boolean = false;
  props.items.forEach((item, index) => {
    if (item.defaultOpen) {
      initialState = index.toString();
    }
  });

  const [expanded, setExpanded] = useState<string | false>(initialState);
  const theme = useTheme();

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {props.items.map((item, index) => (
        <MuiAccordion
          key={index}
          expanded={expanded === index.toString()}
          onChange={handleChange(index.toString())}
        >
          <AccordionSummary
            expandIcon={props.expandIcon ? props.expandIcon : <ExpandLess />}
            css={css`
              .MuiAccordionSummary-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
                p {
                  color: ${props.summaryAlwaysPrimary
                    ? theme.palette.primary.main
                    : "#82919a"};
                }
                .MuiChip-filled {
                  background-color: ${expanded === index.toString()
                    ? "#fff"
                    : "transparent"};
                }
              }
              .MuiAccordionSummary-expandIconWrapper {
                display: ${item.body ? "flex" : "none"};
              }
            `}
          >
            {isValidElement(item.title) && item.title}
            {!isValidElement(item.title) && (
              <Typography variant="body1">{item.title}</Typography>
            )}
            {item.chipLabel && (
              <span
                css={css`
                  margin-right: 10px;
                `}
              >
                <Chip color={item.chipType} label={item.chipLabel} />
              </span>
            )}
          </AccordionSummary>
          {item.body &&
            Array.isArray(item.body) &&
            item.body.map((element, index) => (
              <AccordionDetails
                key={index}
                css={css`
                  background-color: ${!element.colorScheme ||
                  element.colorScheme === "default"
                    ? "transparent"
                    : theme.palette.hxBackground[element.colorScheme]};
                  color: ${!element.colorScheme ||
                  element.colorScheme === "default"
                    ? "#82919a"
                    : theme.palette[element.colorScheme].main};
                `}
              >
                {element.content}
              </AccordionDetails>
            ))}
          {item.body && !Array.isArray(item.body) && (
            <AccordionDetails
              css={css`
                background-color: ${!item.body.colorScheme ||
                item.body.colorScheme === "default"
                  ? "transparent"
                  : theme.palette.hxBackground[item.body.colorScheme]};
                color: ${!item.body.colorScheme ||
                item.body.colorScheme === "default"
                  ? "#82919a"
                  : theme.palette[item.body.colorScheme].main};
              `}
            >
              {item.body.content}
            </AccordionDetails>
          )}
        </MuiAccordion>
      ))}
    </div>
  );
}

export default Accordion;
