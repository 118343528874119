/** @jsxImportSource @emotion/react */

/* eslint-disable-next-line */
import { Link } from "../ts/link";
import { css } from "@emotion/react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Typography, useTheme } from "@mui/material";
import { ReactNode } from "react";
import BasicLink from "../BasicLink/BasicLink";

export interface NavigationListItemProps {
  link: Link;
  description?: string | ReactNode;
}

export function NavigationListItem(props: NavigationListItemProps) {
  const theme = useTheme();

  const styles = {
    root: css`
      border-bottom: 1px solid #82919a;
      padding-bottom: 14px;
      :not(:first-of-type) {
        margin-top: 19px;
      }
      a {
        text-decoration: none;
      }
    `,
    linkRow: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      color: ${theme.palette.primary.main};
    `,
    linkLabel: css`
      width: 88%;
      text-decoration: none;
    `,
    linkIcon: css`
      width: 12%;
      text-align: right;
    `,
    descriptionRow: css`
      color: #82919a;
      margin-top: 14px;
    `,
  };

  return (
    <div css={styles.root}>
      <BasicLink link={props.link}>
        <div css={styles.linkRow}>
          <Typography variant="h3" component="div" css={styles.linkLabel}>
            {props.link.label}
          </Typography>
          <div css={styles.linkIcon}>
            <KeyboardArrowRightIcon />
          </div>
        </div>
        {props.description && (
          <Typography
            variant="body3"
            component="div"
            css={styles.descriptionRow}
          >
            {props.description}
          </Typography>
        )}
      </BasicLink>
    </div>
  );
}

export default NavigationListItem;
