/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";

export interface CardProps {
  color?: "primary";
  title: ReactNode;
  children: ReactNode;
}
export function Card(props: CardProps) {
  const { color = "primary" } = props;
  const theme = useTheme();

  const styles = {
    header: css`
      background-color: ${theme.palette[color].main};
      color: #fff;
      text-align: center;
    `,
  };

  return (
    <MuiCard>
      <CardHeader
        css={styles.header}
        title={
          <Typography variant={"h2"} color={"#fff"} component={"span"}>
            <strong>{props.title}</strong>
          </Typography>
        }
      />
      <CardContent>{props.children}</CardContent>
    </MuiCard>
  );
}

export default Card;
