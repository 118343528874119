import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import update from "immutability-helper";
import { SiteMapEntry } from "../sitemapDe";

const FAVORITES_STORAGE_KEY = "hx_favorites";

export type FavoritesType = {
  fullPath: string;
  view: SiteMapEntry;
};

export interface GlobalContextInterface {
  currentPage: null | SiteMapEntry;
  currentPath: null | string;
  currentMarket: null | "de" | "at";
  favorites: FavoritesType[];
  setCurrentPage: (page: null | SiteMapEntry) => void;
  setCurrentPath: (path: string) => void;
  setFavorites: (favorites: FavoritesType[]) => void;
  moveFavorites: (dragIndex: number, hoverIndex: number) => void;
}

const GlobalContext = createContext<GlobalContextInterface>({
  currentPage: null,
  currentPath: null,
  currentMarket: null,
  favorites: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentPage: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCurrentPath: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setFavorites: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  moveFavorites: () => {},
});

interface GlobalContextProviderProps {
  children: ReactNode;
  currentPage: null | SiteMapEntry;
  currentPath: null | string;
}

export const GlobalContextProvider: FC<GlobalContextProviderProps> = (
  props
) => {
  const favoritesFromStorage = localStorage.getItem(FAVORITES_STORAGE_KEY);

  // TODO: Implement market detection logic
  // TODO: Remove eslint-disable after current market implementation!
  // eslint-disable-next-line
  const [currentMarket, setCurrentMarket] = useState<null | "de" | "at">("de");
  const [currentPage, setCurrentPage] = useState(props.currentPage);
  const [currentPath, setCurrentPath] = useState(props.currentPath);
  const [favorites, setFavorites] = useState<FavoritesType[]>(
    favoritesFromStorage ? JSON.parse(favoritesFromStorage) : []
  );

  const moveFavorites = useCallback((dragIndex: number, hoverIndex: number) => {
    setFavorites((prevFavorites) =>
      update(prevFavorites, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevFavorites[dragIndex]],
        ],
      })
    );
  }, []);

  useEffect(() => {
    localStorage.setItem(FAVORITES_STORAGE_KEY, JSON.stringify(favorites));
  }, [favorites]);

  return (
    <GlobalContext.Provider
      value={{
        setCurrentPath,
        setCurrentPage,
        currentPath,
        currentPage,
        favorites,
        setFavorites,
        moveFavorites,
        currentMarket,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
