/** @jsxImportSource @emotion/react */

import {
  Button,
  ButtonGroup as MuiButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import { useState } from "react";

export interface ButtonGroupProps {
  results: {
    id: string;
    label: string;
    // variant: "outlined" | "filled";
    color: "default" | "error" | "warning" | "success" | "primary";
  }[];
  onClick?: (id: string) => void;
}

export function ButtonGroup(props: ButtonGroupProps) {
  const theme = useTheme();
  const [activeItem, setActiveItem] = useState<string | false>(false);

  const handleClick = (id: string) => {
    setActiveItem(id === activeItem ? false : id);
    if (props.onClick) {
      props.onClick(id);
    }
  };

  const styles = {
    root: css`
      width: 100%;
      display: flex;
      border-radius: 7px;
      .MuiButtonBase-root {
        text-transform: inherit;
      }
    `,
  };

  return (
    <MuiButtonGroup css={styles.root}>
      {props.results.map((result, index) => {
        const mainColor =
          result.color !== "default"
            ? theme.palette[result.color].main
            : theme.palette.primary.main;
        const backgroundColor =
          result.color !== "default"
            ? theme.palette.hxBackground[result.color]
            : "transparent";
        return (
          <Button
            onClick={() => handleClick(result.id)}
            disableRipple={props.onClick === undefined}
            key={index}
            css={css`
              width: 100%;
              border-color: ${mainColor};
              background-color: ${props.onClick && result.id === activeItem
                ? backgroundColor
                : "transparent"};
              &.MuiButton-root {
                cursor: ${props.onClick ? "pointer" : "default"};
              }
              :hover {
                border-color: ${mainColor};
                background-color: ${props.onClick && result.id === activeItem
                  ? backgroundColor
                  : "transparent"};
              }
            `}
          >
            <Typography
              css={css`
                color: ${result.color === "default" ? "#82919a" : mainColor};
              `}
              variant="body1"
            >
              {result.label}
            </Typography>
          </Button>
        );
      })}
    </MuiButtonGroup>
  );
}

export default ButtonGroup;
