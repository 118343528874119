/** @jsxImportSource @emotion/react */

import { Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { ReactNode } from "react";

interface TextBoxProps {
  title: string;
  children: ReactNode;
  color: "primary" | "warning" | "error";
}

export function TextBox(props: TextBoxProps) {
  const theme = useTheme();

  const styles = {
    root: css`
      background: ${theme.palette.hxBackground[props.color]};
      width: 100%;
      padding: 12px;
      padding-bottom: 10px;
    `,
    title: css`
      width: 100%;
      color: #82919a;
    `,
    content: css`
      margin-top: 5px;
      color: ${theme.palette[props.color].main};
    `,
  };

  return (
    <div css={styles.root}>
      <div css={styles.title}>
        <Typography component="span" variant="body1">
          {props.title}
        </Typography>
      </div>
      <Typography component="div" css={styles.content} variant="body1">
        {props.children}
      </Typography>
    </div>
  );
}

export default TextBox;
