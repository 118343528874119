/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { useTheme } from "@mui/material";
import { css } from "@emotion/react";

interface RoundIconWrapper {
  children: ReactNode;
}

export function RoundIconWrapper(props: RoundIconWrapper) {
  const theme = useTheme();
  const styles = {
    iconWrapper: css`
      width: 46px;
      height: 46px;
      background-color: #e8f3fc;
      border-radius: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${theme.palette.primary.main};
    `,
  };

  return <div css={styles.iconWrapper}>{props.children}</div>;
}

export default RoundIconWrapper;
