import { createTheme } from "@mui/material";

const variables = {
  page: {
    maxWidth: "1024px",
    padding: "17px",
  },
  breakpoints: {
    imageHalfWidth: 750,
  },
};

const themeColors = {
  blue: {
    main: "#0460A9",
    light: "#6fb1e5",
    dark: "#06477b",
    background: "#e8f3fc",
  },
  pink: {
    main: "#f50057",
    light: "#ff4081",
    dark: "#c51162",
    background: "#f8d5e0",
  },
  red: {
    main: "#f50357",
    light: "#fabdd2",
    dark: "#c30949",
    background: "#ffeff3",
    contrastText: "#c30949",
  },
  yellow: {
    main: "#ff9800",
    light: "#ffb74d",
    dark: "#f57c00",
    background: "#fff3e0",
    contrastText: "#f57c00",
  },
  green: {
    main: "#4caf50",
    light: "#81c784",
    dark: "#388e3c",
    background: "#ecf4ec",
    contrastText: "#388e3c",
  },
  special: {
    disabled: "#bdbdbd",
    textPrimary: "#3d454b",
    textSecondary: "#7f919b",
    textGrey: "#82919a", // TODO: Not in palette
    accordionOpen: "#89b0d2", // TODO: Not in palette
  },
};

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      md2: variables.breakpoints.imageHalfWidth,
    },
  },
  typography: {
    h1: {
      fontFamily: "Roboto",
      fontSize: "26px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "0.01px",
    },
    h2: {
      fontFamily: "Roboto",
      fontSize: "21px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.24,
      letterSpacing: "0.67px",
    },
    h3: {
      fontFamily: "Roboto",
      fontSize: "17px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.29,
      letterSpacing: "0px",
      color: themeColors.blue.main,
    },
    subtitle1: {
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.47,
      letterSpacing: "0px",
    },
    button: {
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 0.93,
      letterSpacing: "0.01px",
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0px",
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "0px",
    },
    body3: {
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.54,
      letterSpacing: "0px",
    },
    source: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "italic",
      lineHeight: 1.67,
      letterSpacing: "0px",
    },
    overline: {
      fontFamily: "Roboto",
      fontSize: "13px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.69,
      letterSpacing: "0.17px",
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.87)",
    },
    caption: {
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.25,
      letterSpacing: "0px",
      color: "rgba(0, 0, 0, 0.87)",
    },
    buttonStart: {
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.27,
      letterSpacing: "0.01px",
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
  palette: {
    hxBackground: {
      primary: themeColors.blue.background,
      secondary: themeColors.pink.background,
      error: themeColors.red.background,
      warning: themeColors.yellow.background,
      info: themeColors.blue.background,
      success: themeColors.green.background,
    },
    primary: {
      main: themeColors.blue.main,
      light: themeColors.blue.light,
      dark: themeColors.blue.dark,
    },
    secondary: {
      main: themeColors.pink.main,
      light: themeColors.pink.light,
      dark: themeColors.pink.dark,
    },
    text: {
      primary: themeColors.special.textPrimary,
      secondary: themeColors.special.textSecondary,
      disabled: themeColors.special.disabled,
    },
    action: {
      disabled: themeColors.special.disabled,
      hover: themeColors.blue.background,
      selected: themeColors.blue.background,
    },
    error: {
      main: themeColors.red.main,
      light: themeColors.red.light,
      dark: themeColors.red.dark,
      contrastText: themeColors.red.contrastText,
    },
    warning: {
      main: themeColors.yellow.main,
      light: themeColors.yellow.light,
      dark: themeColors.yellow.dark,
      contrastText: themeColors.yellow.contrastText,
    },
    info: {
      main: themeColors.blue.main,
      light: themeColors.blue.light,
      dark: themeColors.blue.dark,
      contrastText: themeColors.blue.dark,
    },
    success: {
      main: themeColors.green.main,
      light: themeColors.green.light,
      dark: themeColors.green.dark,
      contrastText: themeColors.green.contrastText,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: themeColors.blue.background,
          boxShadow: "none",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-filledError": {
            color: themeColors.red.main,
            backgroundColor: themeColors.red.background,
          },
          "&.MuiChip-filledWarning": {
            color: themeColors.yellow.dark,
            backgroundColor: themeColors.yellow.background,
          },
          "&.MuiChip-filledSuccess": {
            color: themeColors.green.main,
            backgroundColor: themeColors.green.background,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: themeColors.special.textGrey,
          fontWeight: "normal",
          lineHeight: 1.47,
          textDecoration: "none",
          "& a": {
            textDecoration: "none",
            color: "inherit",
          },
          "& > ol > li": {
            "&:before": {
              display: "none",
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: themeColors.blue.main,
          paddingTop: "7px",
          maxWidth: "150px",
          textAlign: "center",
        },
        arrow: {
          color: themeColors.blue.main,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.text-only": {
            paddingTop: "17px",
          },
          "&: before": {
            display: "none",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: themeColors.special.textGrey,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          alignItems: "center",
          justifyContent: "flex-start",
          marginRight: "28px",
          alignSelf: "flex-start",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgba(130, 145, 154, 0.3)",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          ":before": {
            display: "none",
          },
          "&.Mui-expanded": {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: themeColors.blue.main,
          fontWeight: "normal",
          lineHeight: 1.47,
          transition: "none",
          paddingLeft: "17px",
          paddingRight: "15px",
          minHeight: 0,
          borderBottom: `1px solid ${themeColors.special.textPrimary}`,
          backgroundColor: themeColors.blue.background,
          "&.Mui-expanded": {
            minHeight: 0,
            transition: "none",
            backgroundColor: themeColors.special.accordionOpen,
            borderBottom: "none",
            "& > .MuiAccordionSummary-content p": {
              color: "#fff",
            },
            color: "#fff",
            "& > .MuiAccordionSummary-expandIconWrapper": {
              color: "#fff",
            },
          },
          "& > .MuiAccordionSummary-expandIconWrapper": {
            color: themeColors.blue.main,
          },
        },
        content: {
          transition: "none",
          margin: "12.5px 0",
          "&.Mui-expanded": {
            margin: "12.5px 0",
            transition: "none",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          paddingLeft: "16px",
          paddingRight: "16px",
          paddingTop: "12px",
          paddingBottom: "12px",
          borderBottom: `1px solid ${themeColors.special.textGrey}`,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedInfo: {
          borderColor: themeColors.blue.main,
          "& svg": {
            opacity: 1,
            color: themeColors.blue.main,
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // margin: "0 auto",
          background: "rgba(61, 69, 75, 0.4)",
          backdropFilter: "blur(6px)",
          // maxWidth: variables.page.maxWidth,
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          margin: "0 auto",
          maxWidth: variables.page.maxWidth,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          marginTop: "19px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "11px",
          boxShadow: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "16px 15px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParam) => ({
        body: {
          maxWidth: variables.page.maxWidth,
          margin: "0 auto",
          minHeight: "100vh",
          sup: {
            verticalAlign: "top",
          },
          sub: {
            verticalAlign: "bottom",
          },
          "& h1": {
            ...themeParam.typography.h1,
            color: "rgba(0, 0, 0, 0.87)",
            marginBottom: "40px",
          },
          "& h2": {
            ...themeParam.typography.h2,
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: "56px",
            marginBottom: "24px",
          },
          "& h3": {
            ...themeParam.typography.h3,
            color: themeParam.palette.primary.main,
            marginTop: "48px",
          },
          p: {
            ...themeParam.typography.body1,
            color: themeColors.special.textGrey,
            marginTop: "24px",
            marginBottom: "24px",
          },
          ul: {
            ...themeParam.typography.body1,
            color: themeColors.special.textGrey,
            listStyle: "none",
            marginLeft: 0,
            paddingLeft: "16px",
          },
          li: {
            marginTop: "8px",
            marginBottom: "8px",
          },
          "li ul": {
            marginBottom: "10px",
          },
          "ul li": {
            // paddingLeft: "16px",
            "&:before": {
              content: '""',
              width: "6px",
              height: "6px",
              marginLeft: "-16px",
              marginTop: "-6px",
              display: "inline-block",
              marginRight: "10px",
              backgroundColor: themeColors.special.textGrey,
              borderRadius: "3px",
              verticalAlign: "middle",
            },
          },
          ol: {
            ...themeParam.typography.body1,
            color: themeColors.special.textGrey,
            listStyle: "none",
            counterReset: "item",
            marginLeft: 0,
            paddingLeft: "20px",
          },
          "ol li": {
            counterIncrement: "item",
            display: "flex",
            "&:before": {
              content: "counter(item)'.'",
              width: "20px",
              minWidth: "20px",
              marginLeft: "-20px",
              marginRight: "5px",
              display: "inline-block",
            },
          },
          "li p": {
            marginTop: 0,
            marginBottom: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
          a: {
            textDecoration: "none",
            color: "inherit",
          },
        },
      }),
    },
  },
});

export default theme;
