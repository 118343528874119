/** @jsxImportSource @emotion/react */

import styled from "@emotion/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { css } from "@emotion/react";
import { Typography } from "@mui/material";

/* eslint-disable-next-line */
// export interface BackLinkProps {}

const StyledBackLink = styled.div`
  display: flex;
  align-items: center;
`;

export function AppBarBackLink() {
  return (
    <StyledBackLink>
      <KeyboardArrowLeftIcon
        css={css`
          margin-right: 8px;
          align-self: start;
        `}
      />
      <Typography variant="h3">Zurück</Typography>
    </StyledBackLink>
  );
}

export default AppBarBackLink;
