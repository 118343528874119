import { Alert, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";

export function FnPraxisBiosimilars() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Bedeutung von Biosimilars für das Gesundheitssystem</h1>
        <h2>Biological = Biologikum = Biopharmazeutikum</h2>
        <ul>
          <li>
            Proteinwirkstoff, der durch Biotechnologie in gentechnisch
            veränderten Zellen oder Mikroorganismen hergestellt wird.
          </li>
          <li>Gleiche Aminosäuresequenz mit geringfügiger Variabilität</li>
        </ul>

        <h2>Biosimilar</h2>
        <ul>
          <li>
            Arzneimittel, das von den Zulassungsbehörden wegen seiner
            Ähnlichkeit in Bezug auf Qualität, Sicherheit und Wirksamkeit mit
            einem biologischen Referenzarzneimittel zugelassen wird
          </li>
          <li>
            Qualitäts- und Zulassungsanforderungen deutlich höher als für
            chemische Generika
          </li>
        </ul>

        <Alert
          content={
            <>
              Filgrastim- und Pegfilgrastim-Biosimilars sind seit 2009 bzw. 2018
              verfügbar. Biosimilars senken die Preise. Nicht nur, dass sie per
              se günstiger sind. Sie bewirken auch eine Preisabsenkung beim
              Originalprodukt. Sie{" "}
              <strong>entlasten das Gesundheitssystem</strong> und schaffen{" "}
              <strong>Therapiefreiräume für innovative Therapien</strong>.
            </>
          }
          type="info"
        />

        <p>
          Bei den Biosimilars gibt es <strong>resiliente Lieferketten</strong>{" "}
          und <strong>maximale Versorgungssicherheit</strong>. Dafür ist nicht
          nur eine Diversifizierung der Produktionsstätten verantwortlich,
          sondern auch ein starker Standort Europa.{" "}
          <TextReference references={["ref20"]} />
        </p>
      </PagePadding>
    </>
  );
}

export default FnPraxisBiosimilars;
