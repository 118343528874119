/** @jsxImportSource @emotion/react */

import { Button, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ReactNode } from "react";
import { css } from "@emotion/react";

export interface IconButtonProps {
  label: string;
  color?: "primary" | "warning" | "success" | "error";
  startIcon?: ReactNode;
  fullWidth?: boolean;
}

export function IconButton(props: IconButtonProps) {
  const { color = "primary", startIcon = <ArrowForwardIcon /> } = props;

  const styles = {
    root: css`
      text-transform: none;
      width: ${props.fullWidth ? "100%" : "auto"};
    `,
  };

  console.log("FullW?", props.fullWidth);
  console.log("Styles:", styles);

  return (
    <Button
      css={styles.root}
      variant="outlined"
      color={color}
      startIcon={startIcon}
    >
      <Typography
        color={color}
        variant="body1"
        dangerouslySetInnerHTML={{ __html: props.label }}
      />
    </Button>
  );
}

export default IconButton;
