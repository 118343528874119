import {
  Alert,
  ChipLineSeparator,
  DynamicSpacing,
  PagePadding,
  SeparatorWrapper,
  ToggleBox,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";
import TextReference from "../../components/TextReference";

export function CiaDiagnosticIronDeficiency() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Eisenmangel bei <Abbreviation>cia</Abbreviation>
        </h1>
        <ul>
          <li>Eisenmangel tritt bei Tumorpatient:innen häufig auf</li>
          <li>
            Je nach Schweregrad Unterscheidung in 3 Stadien
            <ul>
              <li>
                <strong>Speichereisenmangel:</strong> Negative Eisenbilanz führt
                zunächst zum Speichereisenmangel, ohne die Erythropoese zu
                beeinträchtigen.
              </li>
              <li>
                <strong>Eisendefizitäre Erythropoese:</strong> Versorgung der
                erythropoetischen Vorstufen im Knochenmark unzureichend,
                Hämoglobin normwertig.
              </li>
              <li>
                <strong>Eisenmangelanämie:</strong> Unzureichende Versorgung der
                erythropoetischen Vorstufen im Knochenmark. Zusätzlich
                Unterschreiten des Hämoglobinwertes.{" "}
                <TextInfo>absoluter Eisenmangel</TextInfo>
                <TextReference references={["ref1"]} />
              </li>
            </ul>
          </li>
        </ul>

        <Alert
          content={
            <>
              <span>CAVE:</span>
              <ul>
                <li>
                  Ferritin kann bei entzündlichen und malignen Erkrankungen
                  falsch normale oder erhöhte Werte aufweisen und damit einen
                  bestehenden Eisenmangel maskieren.
                </li>
                <li>
                  Transferrinsättigung kann trotz normaler Eisenspeicher bei
                  chronischen Erkrankungen erniedrigt sein.{" "}
                  <TextInfo>
                    Bestimmung des löslichen Transferrinrezeptors{" "}
                    <Abbreviation>stfr</Abbreviation>, hypochromer Erythrozyten{" "}
                    <Abbreviation>zpp</Abbreviation> oder des
                    Retikulozytenhämoglobins <Abbreviation>chr</Abbreviation>{" "}
                    kann hier hilfreich sein
                  </TextInfo>
                  <TextReference references={["ref1"]} />
                </li>
              </ul>
            </>
          }
          type="warning"
        />

        <h2>
          Diagnostik des funktionellen und absoluten Eisenmangels{" "}
          <TextReference references={["ref1"]} />
        </h2>

        <p>
          Per Klick oder Antippen des Stadiums des Eisenmangels öffnen sich für
          Sie die passenden, von der S3-Leitlinie „Supportive Therapie bei
          onkologischen Patient:innen“ zusammengestellten Kriterien der
          Differentialdiagnostik. <TextReference references={["ref1"]} />
        </p>

        <ToggleBox forceOpen title="Speichereisenmangel">
          <p>Ferritin bei Männern &lt; 20 µg/l; bei Frauen &lt; 15 µg/l</p>
        </ToggleBox>

        <DynamicSpacing spacing={1} />

        <ToggleBox
          forceOpen
          title={
            <>
              Eisendefizitäre Erythropoese{" "}
              <TextInfo isInDarkElement>
                entspricht funktionellem Eisenmangel
              </TextInfo>
            </>
          }
        >
          <p>Transferrinsättigung (TSAT) &lt; 20 %</p>
          <SeparatorWrapper>
            <ChipLineSeparator label="und" />
          </SeparatorWrapper>
          <p>Ferritin &gt; 30-800 ng/ml</p>
          <SeparatorWrapper>
            <ChipLineSeparator label="und" />
          </SeparatorWrapper>
          <p>
            Ggf. <Abbreviation>stfr</Abbreviation> oder{" "}
            <Abbreviation>zpp</Abbreviation> oder hypochrome Erythrozyten oder
            Retikulozytenhämoglobin
          </p>
        </ToggleBox>

        <DynamicSpacing spacing={1} />

        <ToggleBox
          forceOpen
          title={
            <>
              Eisenmangelanämie{" "}
              <TextInfo isInDarkElement>
                entspricht absolutem Eisenmangel
              </TextInfo>
            </>
          }
        >
          <p>
            <Abbreviation>hb</Abbreviation> &lt; 12 g/dl (Frauen) bzw. 13 g/dl
            (Männer)
          </p>
          <SeparatorWrapper>
            <ChipLineSeparator label="und" />
          </SeparatorWrapper>
          <p>
            <Abbreviation>tsat</Abbreviation> &lt;20 %
          </p>
          <SeparatorWrapper>
            <ChipLineSeparator label="und" />
          </SeparatorWrapper>
          <p>
            Bei Patient:innen mit Tumorerkrankung: Serum-Ferritinwert &lt;100
            ng/ml (bei sonst Gesunden: Serum-Ferritinwert &lt;30 ng/ml)
          </p>
        </ToggleBox>
      </PagePadding>
    </>
  );
}

export default CiaDiagnosticIronDeficiency;
