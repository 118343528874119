import { SvgIcon, SvgIconProps } from "@mui/material";

export function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <path
        data-name="Rechteck 596"
        style={{ fill: "none" }}
        d="M0 0h34v34H0z"
      />
      <g data-name="Gruppe 907" transform="translate(5.446 5.83)">
        <path
          data-name="Pfad 430"
          d="M36.092 33.789 25.016 14.278a.606.606 0 0 0-1.02 0L12.92 33.789a.589.589 0 0 0 0 .556.532.532 0 0 0 .51.278h22.152a.581.581 0 0 0 .51-.278.591.591 0 0 0 0-.556zM14.4 33.511l10.1-17.8 10.1 17.75H14.4z"
          transform="translate(-12.85 -14)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 431"
          d="M36.5 31.3v5.979a.7.7 0 1 0 1.39 0V31.3a.7.7 0 0 0-1.39 0z"
          transform="translate(-25.539 -22.907)"
          style={{ fill: "currentcolor" }}
        />
        <circle
          data-name="Ellipse 17"
          cx=".788"
          cy=".788"
          r=".788"
          transform="translate(10.868 16.267)"
          style={{ fill: "currentcolor" }}
        />
      </g>
    </SvgIcon>
  );
}

export default WarningIcon;
