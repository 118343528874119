import sha256 from "crypto-js/sha256";
import users from "../data/auth";
import {
  AVAILABLE_LOGIN_TYPES,
  VALID_LOGIN_TYPES,
} from "../context/authContext";
export function checkUserNamePassword(userName: string, password: string) {
  if (userName in users) {
    const passwordForUser = users[userName];
    const hashedPassword = hashPassword(password);
    return passwordForUser === hashedPassword;
  }
  return false;
}

export function hashPassword(password: string) {
  return sha256(password).toString();
}

export function checkExistingLogin(type: string, credential: string) {
  let credentialIsValid = false;
  if (type && AVAILABLE_LOGIN_TYPES.includes(type) && credential) {
    switch (type) {
      case VALID_LOGIN_TYPES.password:
        for (const value of Object.values(users)) {
          if (value === credential) {
            credentialIsValid = true;
          }
        }
        break;
    }
  }
  return credentialIsValid;
}
