import { Alert, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";
import TextReference from "../../components/TextReference";

export function CiaTherapyEC() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Transfusion von Erythrozytenkonzentraten</h1>
        <p>
          Bei akutem Blutverlust und Patient:innen mit solidem Tumor oder
          Hämoblastosen ist die Transfusionsindikation bei einem{" "}
          <Abbreviation>hb</Abbreviation> &lt; 8 g/dl{" "}
          <TextInfo>5 mmol/l</TextInfo> individuell zu prüfen:{" "}
          <TextReference
            references={["ref1", "ref5", "ref6", "ref7", "ref8"]}
          />
        </p>

        <ul>
          <li>Klinischer Zustand</li>
          <li>Ausprägung der Anämiesymptomatik</li>
          <li>
            Hb-Wert (oder <Abbreviation>hk</Abbreviation>){" "}
            <TextInfo>
              Bei Patient:innen mit längerfristig bestehender{" "}
              <Abbreviation>cia</Abbreviation> ist eine Transfusion bei einer
              Unterschreitung eines Hk von 24–21 % bzw. eines geringeren
              Hb-Werts als 8–7 g/dl (&lt; 5,0–4,3 mmol/l) unter Beachtung und
              Abwägung der Gesamtsituation zu erwägen
            </TextInfo>
          </li>
          <li>Akuität des Blutverlusts</li>
          <li>
            Kompensationsmöglichkeiten und Risikofaktoren der Patient:innen{" "}
            <TextInfo>
              Laut Querschnittsleitlinie der Bundesärztekammer stellen besonders
              kardiovaskuläre Risiken und pulmonale Erkrankungen eine für die
              klinische Entscheidung zu berücksichtigende Komorbidität dar
            </TextInfo>
          </li>
        </ul>

        <Alert
          content={
            <>
              <span>CAVE:</span>
              <br />
              <span>
                Wenig empirische Daten über Transfusionen bei onkologischen
                Patient:innen. Die Fachgesellschaften empfehlen abseits der{" "}
                <strong>schweren symptomatischen Anämie</strong> einen
                restriktiven Einsatz. <TextReference references={["ref1"]} />
              </span>
            </>
          }
          type="warning"
        />

        <h2>
          Mögliche Komplikationen einer Erythrozytentransfusion:{" "}
          <TextReference references={["ref2", "ref17"]} />
        </h2>

        <ul>
          <li>
            Zunahme <Abbreviation>te</Abbreviation>s
          </li>
          <li>
            Allergische/hämolytische Transfusionsreaktionen und
            transfusionsassoziierte <Abbreviation>gvhd</Abbreviation>
          </li>
          <li>Angst vor der Transfusion</li>
          <li>
            Transfusionsassoziierte Volumenüberladung und Kreislaufbelastung
          </li>
          <li>Hypothermie</li>
          <li>Hyperkaliämie, Hyperkalzämie</li>
          <li>Übertragung bekannter/unbekannter Pathogene</li>
          <li>Lebensqualitätsverlust durch Hospitalisierung</li>
          <li>
            Möglicherweise verringertes Überleben bei gewissen Krebsarten nach
            chirurgischem Eingriff
          </li>
          <li>Erhöhtes Risiko von Infektionen aufgrund von Immunsuppression</li>
        </ul>
      </PagePadding>
    </>
  );
}

export default CiaTherapyEC;
