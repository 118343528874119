/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { useTheme } from "@mui/material";
import { Link } from "../ts/link";
import BasicLink from "../BasicLink/BasicLink";

export interface TextLinkProps {
  link: Link;
}

export function TextLink(props: TextLinkProps) {
  const theme = useTheme();
  const styles = {
    label: css`
      text-decoration: underline;
      color: ${theme.palette.primary.main};
    `,
  };

  return (
    <BasicLink link={props.link}>
      <span css={styles.label}>{props.link.label}</span>
    </BasicLink>
  );
}

export default TextLink;
