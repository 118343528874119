/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { useTheme } from "@mui/material";
import { css } from "@emotion/react";

export interface HighlightProps {
  children: ReactNode;
  element?: "span" | "p" | "h3";
}

export function Highlight(props: HighlightProps) {
  const { element = "span" } = props;
  const ElementTag = element;
  const theme = useTheme();
  return (
    <ElementTag
      css={css`
        color: ${theme.palette.primary.main};
      `}
    >
      {props.children}
    </ElementTag>
  );
}

export default Highlight;
