import { SvgIcon, SvgIconProps } from "@mui/material";

export function LightBulbIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <path
        data-name="Rechteck 594"
        style={{ fill: "none" }}
        d="M0 0h34v34H0z"
      />
      <g data-name="Gruppe 906">
        <path
          data-name="Pfad 421"
          d="M29.143 19.2a7.47 7.47 0 0 0-7.443 7.443 7.249 7.249 0 0 0 .827 3.032l.046.092c.138.322.459.919.873 1.746a32.9 32.9 0 0 1 1.562 3.124 8.939 8.939 0 0 1 .827 3.859v2.067a1.794 1.794 0 0 0 1.792 1.792h2.986a1.794 1.794 0 0 0 1.787-1.792v-2.021a9.767 9.767 0 0 1 .965-3.859c.965-1.884 2.481-4.824 2.481-4.87a6.841 6.841 0 0 0 .735-3.17 7.47 7.47 0 0 0-7.438-7.443zM31.3 39.047v.459h-4.41v-.459zm-.689 2.205h-2.984a.677.677 0 0 1-.689-.689h4.41a.716.716 0 0 1-.735.689zm4.273-11.9c0 .046-1.516 2.986-2.481 4.87a10.273 10.273 0 0 0-1.011 3.721h-1.744v-6.614l2.159-2.021a.553.553 0 1 0-.735-.827L29.1 30.272l-1.841-1.792a.552.552 0 1 0-.781.781l2.022 2.022v6.662h-1.563a10.943 10.943 0 0 0-.919-3.767c-.322-.827-1.057-2.159-1.608-3.216a17.764 17.764 0 0 1-.827-1.654l-.046-.092A6.8 6.8 0 0 1 22.8 26.6a6.34 6.34 0 1 1 12.68 0 6.2 6.2 0 0 1-.595 2.753z"
          transform="translate(-12.216 -11.295)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 422"
          d="m16.278 22.24-2.619-1.516a.536.536 0 1 0-.551.919l2.619 1.516a.653.653 0 0 0 .276.092.505.505 0 0 0 .459-.276.563.563 0 0 0-.184-.735z"
          transform="translate(-7.437 -11.322)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 423"
          d="M24.285 14.4a.565.565 0 0 0 .459.23.852.852 0 0 0 .322-.092.527.527 0 0 0 .134-.738l-1.746-2.481a.529.529 0 1 0-.873.6z"
          transform="translate(-12.642 -5.909)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 424"
          d="M58.071 20.871a.489.489 0 0 0-.735-.184L54.671 22.2a.489.489 0 0 0-.184.735.505.505 0 0 0 .459.276.338.338 0 0 0 .276-.092l2.665-1.516a.563.563 0 0 0 .184-.732z"
          transform="translate(-29.889 -11.285)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 425"
          d="M47.344 14.631a.638.638 0 0 0 .459-.23l1.747-2.481a.529.529 0 1 0-.873-.6l-1.792 2.435a.52.52 0 0 0 .138.735.5.5 0 0 0 .321.141z"
          transform="translate(-25.778 -5.909)"
          style={{ fill: "currentcolor" }}
        />
        <path
          data-name="Pfad 426"
          d="M37.251 11.235a.559.559 0 0 0 .551-.551V7.651a.551.551 0 1 0-1.1 0v3.032a.593.593 0 0 0 .549.552z"
          transform="translate(-20.325 -3.754)"
          style={{ fill: "currentcolor" }}
        />
      </g>
    </SvgIcon>
  );
}

export default LightBulbIcon;
