/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { useTheme } from "@mui/material";

export interface VerticalConclusionArrowProps {
  color?: "primary" | "error" | "warning" | "success" | "default";
  visible?: boolean;
}

export function ArrowSeparator(props: VerticalConclusionArrowProps) {
  const theme = useTheme();
  const { color = "default", visible = true } = props;
  const lineColor = color === "default" ? "#e8f3fc" : theme.palette[color].main;

  const styles = {
    root: css`
      width: 100%;
      color: ${lineColor};
      padding-top: 8px;
      padding-bottom: 8px;
      text-align: center;
      svg {
        opacity: ${visible ? 1 : 0};
      }
    `,
  };
  return (
    <div css={styles.root}>
      <ArrowDownwardOutlinedIcon />
    </div>
  );
}

export default ArrowSeparator;
