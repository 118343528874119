/** @jsxImportSource @emotion/react */

import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { AppBar as MuiAppBar, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import AppBarBackLink from "./app-bar-back-link";
import { ReactNode } from "react";

/* eslint-disable-next-line */
export interface AppBarProps {
  onBackLinkClick?: () => void;
  onFavClick?: () => void;
  favIsActive?: boolean;
  disableFavorite?: boolean;
  disableBackLink?: boolean;
  appIcon?: ReactNode;
}

export function AppBar(props: AppBarProps) {
  const theme = useTheme();

  const styles = {
    appBarWrapper: css`
      height: 70px;
    `,
    navigationRow: css`
      color: ${theme.palette.primary.main};
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 16px;
      @media all and (display-mode: standalone) {
        padding-top: 60px;
      }
    `,
    interactiveElement: css`
      cursor: pointer;
    `,
    backlink: css`
      width: 15%;
      display: inline-flex;
      align-content: center;
    `,
    favorites: css`
      width: 15%;
      display: inline-flex;
      align-content: center;
      justify-content: flex-end;
    `,
    appIcon: css`
      display: none;
      ${theme.breakpoints.up("md")} {
        display: inline-block;
      }
    `,
  };

  const handleBackLink = () => {
    if (props.onBackLinkClick) {
      props.onBackLinkClick();
    } else {
      window.history.back();
    }
  };

  const Star = props.favIsActive ? (
    <StarIcon fontSize="medium" />
  ) : (
    <StarBorderIcon fontSize="medium" />
  );

  return (
    <div css={styles.appBarWrapper}>
      <MuiAppBar>
        <div css={styles.navigationRow}>
          {!props.disableBackLink ? (
            <div
              css={[styles.interactiveElement, styles.backlink]}
              onClick={handleBackLink}
            >
              <AppBarBackLink />
            </div>
          ) : (
            <div css={styles.backlink} />
          )}
          {props.appIcon && <div css={styles.appIcon}>{props.appIcon}</div>}
          {!props.disableFavorite ? (
            <div
              css={[styles.interactiveElement, styles.favorites]}
              onClick={props.onFavClick}
            >
              {Star}
            </div>
          ) : (
            <div css={styles.favorites} />
          )}
        </div>
      </MuiAppBar>
    </div>
  );
}

export default AppBar;
