import { FC } from "react";
import sitemapDe, { SiteMapEntry } from "../sitemapDe";
import { RouteData } from "../ts/RoutingTypes";
import { PageScopeType } from "../ts/PageScopeTypes";

export const parseSitemapEntry = (entry: SiteMapEntry, pathPrefix?: string) => {
  const entries: RouteData[] = [];
  if (entry.view !== null) {
    const currentPath = pathPrefix
      ? pathPrefix.concat(pathPrefix.endsWith("/") ? "" : "/", entry.path)
      : `/${entry.path}`;
    const newEntry: RouteData = {
      path: currentPath,
      entry,
      element: entry.view,
      protectedRoute:
        entry.config && entry.config.protectedRoute
          ? entry.config.protectedRoute
          : false,
    };
    entry.children.forEach((childEntry) => {
      const childrenEntries = parseSitemapEntry(childEntry, currentPath);
      entries.push(...childrenEntries);
      // newEntry.pageProps.childPages.push(...childrenEntries);
    });
    entries.push(newEntry);
  }
  return entries;
};

function searchTree(
  element: SiteMapEntry,
  matchingView: FC,
  currentPageTree: SiteMapEntry[]
): SiteMapEntry | null {
  currentPageTree.push(element);
  if (element.view === matchingView) {
    return element;
  }
  if (element.children != null && element.children.length > 0) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], matchingView, currentPageTree);
    }
    if (result === null) {
      currentPageTree.pop();
    }
    return result;
  }
  currentPageTree.pop();
  return null;
}

export const getCurrentPageTree = (
  currentPage: SiteMapEntry | null
): SiteMapEntry[] => {
  const currentPageTree: SiteMapEntry[] = [];

  // TODO: Implement AT support
  const sitemap = sitemapDe;
  if (currentPage && currentPage.view) {
    sitemap.forEach((entry) => {
      if (currentPage && currentPage.view) {
        searchTree(entry, currentPage.view, currentPageTree);
      }
    });
  }

  return currentPageTree;
};

const searchSiteMapTreeForPaths = (
  paths: string[],
  sitemap: SiteMapEntry[],
  currentIndex?: number
): SiteMapEntry | null => {
  let index = currentIndex || 0;
  const currentSubPath = paths[index];

  if (index >= paths.length) {
    return null;
  }

  const currentCorrespondingChild = sitemap.find(
    (entry) => entry.path === currentSubPath
  );
  if (currentCorrespondingChild) {
    if (index === paths.length - 1) {
      return currentCorrespondingChild;
    }
    index += 1;
    return searchSiteMapTreeForPaths(
      paths,
      currentCorrespondingChild.children,
      index
    );
  }
  return null;
};

export const findSiteMapEntryForPath = (
  path: string,
  sitemap: SiteMapEntry[]
): SiteMapEntry | null => {
  const normalizedPath =
    path.endsWith("/") && path.length > 1
      ? path.slice(0, path.length - 1)
      : path;
  const subPaths =
    normalizedPath !== "/" && normalizedPath.length !== 0
      ? normalizedPath.split("/")
      : [""];

  return searchSiteMapTreeForPaths(subPaths, sitemap);
};

export const getPagePrefixForPageScope = (
  pageScope: PageScopeType,
  version: "short" | "long"
) => {
  const prefixes = {
    fn: {
      short: "FN",
      long: "Febrile Neutropenie",
    },
    mds: {
      short: "MDS",
      long: "MDS-assoziierte Anämie",
    },
    op: {
      short: "OP",
      long: "Prä- und postoperatives Anämiemanagement",
    },
    cia: {
      short: "CIA",
      long: "Chemotherapie-induzierte Anämie",
    },
  };

  if (pageScope === "home") {
    return "";
  }
  return prefixes[pageScope][version];
};

const findPageInSitemap = (
  page: FC,
  sitemap: SiteMapEntry[],
  currentIndex?: number
): SiteMapEntry | null => {
  let index = currentIndex || 0;
  let result = null;

  if (index >= sitemap.length) return null;

  const currentSiteMapEntry = sitemap[index];
  // FOUND IT!
  if (currentSiteMapEntry.view === page) {
    return currentSiteMapEntry;
    // Current entry has child pages? Check them!
  }
  if (currentSiteMapEntry.children.length > 0) {
    result = findPageInSitemap(page, currentSiteMapEntry.children);
  }

  if (result === null) {
    index++;
    return findPageInSitemap(page, sitemap, index);
  }

  return result;
};

export const getUrlForPage = (page: FC, sitemap: SiteMapEntry[]) => {
  let url = null;
  const sitemapEntry = findPageInSitemap(page, sitemap);

  if (sitemapEntry !== null) {
    const pageTree = getCurrentPageTree(sitemapEntry);
    if (pageTree !== null) {
      url = "/";
      for (let i = 0; i < pageTree.length; i++) {
        url = pageTree[i].path.length > 0 ? `${url + pageTree[i].path}/` : url;
      }
    }
  }

  return url;
};

export const getInternalLinkForPage = (
  page: FC,
  locale: "de" | "at",
  label: string
) => {
  const sitemap = locale === "de" ? sitemapDe : null;
  // Home as Fallback
  let internalPath = "/";
  if (sitemap !== null) {
    const url = getUrlForPage(page, sitemap);
    internalPath = url !== null ? url : internalPath;
  }
  return {
    url: internalPath,
    isExternal: false,
    label,
  };
};
