import { ReactNode } from "react";
import {
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import RoundIconWrapper from "../Icons/RoundIconWrapper";

export interface IconListItemProps {
  icon: ReactNode;
  description: string | ReactNode;
}

export function IconListItem(props: IconListItemProps) {
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <RoundIconWrapper>{props.icon}</RoundIconWrapper>
        </ListItemIcon>
        <ListItemText>
          <Typography variant="body1">{props.description}</Typography>
        </ListItemText>
      </ListItem>
      <Divider />
    </>
  );
}

export default IconListItem;
