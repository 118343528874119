import {
  MinusIcon,
  PagePadding,
  PlusIcon,
  RoundIconWrapper,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import EsaCiaDiagnosticsDecisionTree from "../../components/DecisionTrees/EsaCiaDiagnosticsDecisionTree";

export function CiaTherapyEsaEsaCia() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Erythropoese-stimulierende Agenzien bei{" "}
          <Abbreviation>cia</Abbreviation>{" "}
          <TextReference references={["ref1"]} />
        </h1>
        <ul>
          <li>
            Zur Therapie der symptomatischen CIA bei{" "}
            <Abbreviation>hb</Abbreviation>
            -Wert ≤10 g/dl (6,2 mmol/l), um den Hb-Wert auf max. 12 g/dl (7,5
            mmol/l) zu erhöhen
          </li>
          <li>
            Sie können dazu beitragen, dass Chemotherapie-Regime nicht gekürzt
            oder verschoben werden müssen
          </li>
        </ul>

        <h2>
          Patient:innen sollten über Nutzen und Risiken des Einsatzes von ESA
          informiert werden:
        </h2>
        <RoundIconWrapper>
          <PlusIcon color="primary" />
        </RoundIconWrapper>
        <ul>
          <li>Verminderung der Transfusionsfrequenz</li>
          <li>Potenzielle Steigerung der Lebensqualität</li>
        </ul>

        <RoundIconWrapper>
          <MinusIcon color="primary" />
        </RoundIconWrapper>
        <ul>
          <li>Thromboembolische Komplikationen</li>
          <li>Bluthochdruck</li>
        </ul>

        <p>
          Studien belegen, dass der Nutzen einer ESA-Gabe bei CIA das Risiko
          übersteigt, wenn ESAs gemäß den Leitlinien eingesetzt werden.{" "}
          <TextReference references={["ref1", "ref2"]} />
        </p>

        <h2>
          Diagnostik und Korrektur{" "}
          <TextReference references={["ref2", "ref3"]} />
        </h2>
        <p>
          Algorithmus der European Organisation for Research and Treatment of
          Cancer (EORTC) zur Anämie-Therapie bei Tumorpatient:innen. Ab welchem
          Hb-Wert wird eine ESA-/Eisentherapie von der EORTC Leitlinie
          empfohlen? Durch Klicken oder Tippen erfahren Sie mehr.
        </p>

        <EsaCiaDiagnosticsDecisionTree />
      </PagePadding>
    </>
  );
}

export default CiaTherapyEsaEsaCia;
