import { useContext } from "react";
import AuthContext from "../context/authContext";

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (authContext !== null) {
    return authContext;
  }
  return {
    authed: false,
    login: (type: string) =>
      console.warn(
        "Login failed: Context not initialized; tried to login via",
        type
      ),
    logout: () => console.warn("Logout failed: Context not initialized"),
  };
};
