import {
  Accordion,
  AccordionNodeItem,
  PagePadding,
  TextLink,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import TextInfo from "../../components/TextInfo";
import { getInternalLinkForPage } from "../../util/pageHandlingHelpers";
import MdsHome from "../mds";
import CiaHome from "../cia";

export function OpCauses() {
  const linkMdsOverviewPage = getInternalLinkForPage(
    MdsHome,
    "de",
    "Mehr zum Thema MDS"
  );
  const linkCiaOverviewPage = getInternalLinkForPage(
    CiaHome,
    "de",
    "Mehr zum Thema Chemotherapie induzierte Anämie"
  );

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Bei einer präoperativ bestehenden Anämie müssen vielfältige Ursachen
          in Betracht gezogen werden. Diese lassen sich in folgende drei große
          Entitäten einteilen: <TextReference references={["ref1"]} />
        </h1>

        <Accordion
          items={[
            {
              title: "Verlust/ Blutung",
              defaultOpen: false,
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        Akute Blutverluste z. B.
                        <ul>
                          <li>Operationen, Verletzungen</li>
                          <li>
                            Akute gastrointestinale oder urogenitale Blutungen
                          </li>
                        </ul>
                      </li>
                      <li>
                        Chronische Blutverluste
                        <ul>
                          <li>
                            Chronische gastrointestinale oder urogenitale
                            Blutungen
                          </li>
                          <li>
                            Menstruationsblutungen (insbesondere bei
                            Hypermenorrhoe, z.b. im Rahmen eines{" "}
                            <i>Uterus myomatosus</i>)
                          </li>
                          <li>Respiratorische Blutungen: Hämoptysen</li>
                          <li>Tumorbedingte Blutverluste</li>
                        </ul>
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Blutbildungsstörungen",
              defaultOpen: false,
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        Mangelanämien
                        <ul>
                          <li>Eisenmangelanämie</li>
                          <li>Vitamin B12/ Folsäuremangel</li>
                        </ul>
                      </li>
                      <li>Renale Anämien/ Erythropoietinmangel</li>
                      <li>
                        Stammzelldefekte{" "}
                        <TextInfo>
                          aplastische Anämie, myelodysplastische Syndrome
                        </TextInfo>
                        <br />
                        <TextLink link={linkMdsOverviewPage} />
                      </li>
                      <li>
                        Anämie chronischer Erkrankungen
                        <ul>
                          <li>Tumorbedingte Anämie</li>
                          <li>Infektionen, Autoimmunerkrankungen</li>
                        </ul>
                      </li>
                      <li>
                        Genetische Defekte des Hämoglobins
                        <ul>
                          <li>Sichelzellanämie</li>
                          <li>Thalassämie</li>
                        </ul>
                      </li>
                      <li>
                        Medikamente
                        <ul>
                          <li>Antibiotika/Antiinfektiva</li>
                          <li>
                            Chemotherapeutika
                            <br />
                            <TextLink link={linkCiaOverviewPage} />
                          </li>
                          <li>Antikonvulsiva</li>
                        </ul>
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Abbau von Erythrozyten",
              defaultOpen: false,
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        Hämolyse
                        <ul>
                          <li>Veränderung der Erythrozyten (Kugelzellen)</li>
                          <li>
                            Autoantikörper
                            <TextInfo>Autoimmunhämolytische Anämien</TextInfo>
                          </li>
                          <li>Hypersplenismus</li>
                          <li>Malaria</li>
                        </ul>
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default OpCauses;
