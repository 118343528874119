import { ReactNode } from "react";
import { Backdrop as MuiBackdrop } from "@mui/material";

export interface BackdropProps {
  children: ReactNode;
  open: boolean;
  onClick?: () => void;
}

export function Backdrop(props: BackdropProps) {
  return (
    <MuiBackdrop open={props.open} onClick={props.onClick}>
      {props.children}
    </MuiBackdrop>
  );
}

export default Backdrop;
