import { SvgIcon, SvgIconProps } from "@mui/material";

export function BloodDropIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Icon Bluttropfen"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        data-name="Rechteck 606"
        style={{ fill: "none" }}
        d="M0 0h35v35H0z"
      />
      <path
        data-name="Pfad 442"
        d="M25.869 34.723a8.526 8.526 0 0 1-6.127-2.635 9.092 9.092 0 0 1 0-12.613l5.626-5.831a.638.638 0 0 1 .917 0l5.668 5.831a9.092 9.092 0 0 1 0 12.613 8.363 8.363 0 0 1-6.084 2.635zm0-19.7-5.21 5.356a7.755 7.755 0 0 0 0 10.755 7.189 7.189 0 0 0 10.419 0 7.755 7.755 0 0 0 0-10.755z"
        transform="translate(-8.374 -6.548)"
        style={{ fill: "currentcolor" }}
      />
    </SvgIcon>
  );
}

export default BloodDropIcon;
