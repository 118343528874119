import { SvgIcon, SvgIconProps } from "@mui/material";

export function SearchIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      data-name="Icon Lupe"
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      viewBox="0 0 35 35"
    >
      <path
        data-name="Rechteck 600"
        style={{ fill: "none" }}
        d="M0 0h35v35H0z"
      />
      <path
        data-name="Pfad 435"
        d="m34.049 32.289-5.305-5.33A8.188 8.188 0 0 0 23.062 13a8.165 8.165 0 0 0 0 16.328 7.849 7.849 0 0 0 4.679-1.523l5.389 5.457a.6.6 0 0 0 .919 0 .723.723 0 0 0 0-.973zM16.295 21.164a6.768 6.768 0 1 1 6.767 6.853 6.836 6.836 0 0 1-6.767-6.853z"
        transform="translate(-7.105 -6.158)"
        style={{ fill: "currentcolor" }}
      />
    </SvgIcon>
  );
}

export default SearchIcon;
