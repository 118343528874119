import { ReactNode, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sitemapDe from "../sitemapDe";
import GlobalContext from "../context/globalContext";
import { findSiteMapEntryForPath } from "../util/pageHandlingHelpers";

export interface GlobalContextWrapperProps {
  children: ReactNode;
}

export function GlobalContextWrapper(props: GlobalContextWrapperProps) {
  const location = useLocation();
  const { setCurrentPage, setCurrentPath } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
    setCurrentPage(findSiteMapEntryForPath(location.pathname, sitemapDe));
  }, [location]);

  return <>{props.children}</>;
}

export default GlobalContextWrapper;
