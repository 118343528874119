import { PagePadding, TextLink } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import TextReference from "../../components/TextReference";
import TextInfo from "../../components/TextInfo";
import { getInternalLinkForPage } from "../../util/pageHandlingHelpers";
import OpHome from "../op";
import Abbreviation from "../../components/Abbreviation";

export function CiaDiseaseDefinition() {
  const linkOpHome = getInternalLinkForPage(
    OpHome,
    "de",
    "Mehr zur prä- und postoperativen Anämie"
  );

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Definition</h1>
        <h2>Anämie</h2>
        <p>
          Verminderung der Zahl der Erythrozyten, die durch eine Erniedrigung
          der Hämoglobinkonzentration und/oder des Hämatokrits im peripheren
          Blut gekennzeichnet ist. <TextReference references={["ref1"]} />
        </p>

        <h2>
          Unterscheidung in <TextReference references={["ref1", "ref11"]} />
        </h2>
        <ul>
          <li>
            <strong>Tumoranämie (Anämie bei chronischer Erkrankung):</strong>{" "}
            Entstehung durch Aktivierung des Immunsystems
            <TextInfo>
              Ursachen: Tumor, Infektion, Autoimmunerkrankung
            </TextInfo>{" "}
            mit komplexen Effekten auf Hämatopoese, Eisenstoffwechsel und deren
            Regulierung
          </li>
          <li>
            <strong>Tumortherapie-induzierte Anämie:</strong> Anämie
            hervorgerufen durch eine Chemotherapie
            <TextInfo>inkl. „neue Substanzen“</TextInfo> und/oder Radiotherapie
            und/oder Radiochemotherapie
          </li>
          <li>
            <strong>Chemotherapie-induzierte Anämie (CIA):</strong>{" "}
            Ausschließlich durch Chemotherapie hervorgerufene Anämie
          </li>
          <li>
            <strong>Chronische Blutungsanämie:</strong> Anämie durch
            Blutverlust, der durch chronische Erkrankungen wie Magenulzera,
            Hypermenorrhoe, Morbus Crohn oder Hämorrhoiden hervorgerufen wird
          </li>
          <li>
            <strong>Weitere:</strong>
            <ul>
              <li>Prä-/Postoperative Anämie</li>
              <li>
                Krankenhaus-Anämie{" "}
                <TextInfo>
                  Form der Anämie, die durch wiederholte Blutentnahmen für die
                  Bestimmung von Laborwerten verursacht wird
                </TextInfo>
              </li>
              <li>
                „Krankenhaus-Anämie“{" "}
                <TextInfo>
                  Form der Anämie, die durch wiederholte Blutentnahmen für die{" "}
                  Bestimmung von Laborwerten verursacht wird
                </TextInfo>
              </li>
              <li>
                Anämie, die im Rahmen einer therapie-induzierten{" "}
                MDS/therapieinduzierten <Abbreviation>aml</Abbreviation> (
                <Abbreviation>mnpct</Abbreviation>) auftritt{" "}
                <TextInfo>
                  Mn-pCT entwickeln sich in der Regel über Monate oder Jahre{" "}
                  nach zytotoxischer Therapie. Diese Art der Anämie kann also{" "}
                  als eine Folge der Chemo- und Tumorassoziierten Anämie gesehen{" "}
                  werden.
                </TextInfo>{" "}
                <TextReference references={["ref18"]} />
              </li>
            </ul>
          </li>
        </ul>

        <TextLink link={linkOpHome} />
      </PagePadding>
    </>
  );
}

export default CiaDiseaseDefinition;
