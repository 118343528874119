import { useContext } from "react";
import {
  BloodBagIcon,
  BloodDropPlusIcon,
  MainBackground,
  PagePadding,
  PrimaryNavIconButton,
  MagnifierIcon,
  ColoredText,
} from "components/ui";
import { Grid } from "@mui/material";
import GlobalContext from "../../context/globalContext";
import HexalSeo from "../../components/HexalSeo";
import OpAnemia from "./anemia";
import OpCauses from "./causes";
import OpManagement from "./management";

export function OpHome() {
  const { currentPage } = useContext(GlobalContext);

  const anemia = currentPage?.children.find((child) => child.view === OpAnemia);
  const causes = currentPage?.children.find((child) => child.view === OpCauses);
  const management = currentPage?.children.find(
    (child) => child.view === OpManagement
  );

  return (
    <>
      <HexalSeo />
      <MainBackground />
      <PagePadding>
        <h2>
          <ColoredText color="primary">
            Prä- und postoperatives Anämiemanagement
          </ColoredText>
        </h2>
        <Grid container spacing="24px">
          {anemia && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: anemia.title,
                  isExternal: false,
                  url: anemia.path,
                  title: "Zum Kapitel Präoperative Anämie",
                }}
                icon={<BloodDropPlusIcon />}
              />
            </Grid>
          )}
          {causes && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: causes.title,
                  isExternal: false,
                  url: causes.path,
                  title: "Zum Kapitel Ursachen",
                }}
                icon={<MagnifierIcon />}
              />
            </Grid>
          )}
          {management && (
            <Grid item xs={6} md={4}>
              <PrimaryNavIconButton
                link={{
                  label: <>Anämie&shy;management</>,
                  isExternal: false,
                  url: management.path,
                  title: "Zum Kapitel Anämiemanagement",
                }}
                icon={<BloodBagIcon />}
              />
            </Grid>
          )}
        </Grid>
      </PagePadding>
    </>
  );
}

export default OpHome;
