import { Accordion, PagePadding, AccordionNodeItem } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import TextInfo from "../../components/TextInfo";

export function MdsTherapyLowRiskManagement() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Management Niedrigrisiko <Abbreviation>mds</Abbreviation>
          -assoziierte Anämie
        </h1>
        <p>Ziel der Therapie: Gewinn an Lebensqualität und Lebenszeit.</p>
        <p>Therapiegrundlage ist die supportive Therapie:</p>
        <ul>
          <li>
            <Abbreviation>esa</Abbreviation>
            -Therapie, empfohlen in Anlehnung an den Nordic Score
          </li>
          <li>
            Erythrozytenkonzentrate in Abhängigkeit vom klinischen Zustand
            (nicht <Abbreviation>hb</Abbreviation>
            -Wert!{" "}
            <TextInfo>
              Patient:innen mit schwerer koronarer Herzerkrankung und/oder
              anderen schweren Begleiterkrankungen sollten mit dem Hb-Wert über
              10 g/dl gehalten werden
            </TextInfo>
            )
          </li>
          <li>
            ggf. Eisenchelation
            <TextReference references={["ref1"]} />
          </li>
        </ul>

        <h2>
          Therapie bei Niedrigrisiko-MDS{" "}
          <TextInfo>
            <Abbreviation>ipss</Abbreviation> LOW und{" "}
            <Abbreviation>int</Abbreviation>
            -1; <Abbreviation>ipssr</Abbreviation> VERY LOW, LOW und INT
          </TextInfo>{" "}
          und symptomatischer transfusionsbedürftiger Anämie
        </h2>
        <p>
          Klicken Sie auf eine Indikation, um zur Therapieempfehlung zu
          gelangen.
        </p>

        <Accordion
          summaryAlwaysPrimary
          items={[
            {
              title:
                "Hypozelluläres MDS, Alter < 60 J., Blasten im BM < 5 %, normale Zytogenetik",
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        Ggf. Immunsuppressive Therapie mit{" "}
                        <Abbreviation>atg</Abbreviation> +{" "}
                        <Abbreviation>csa</Abbreviation>
                      </li>
                      <li>
                        <Abbreviation>ek</Abbreviation> und ggf.
                        Eisenchelationstherapie
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "MDS del(5q)",
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        Lenalidomid gemäß Indikation der Fachinformation, ggf.{" "}
                        <Abbreviation>esa</Abbreviation> bei{" "}
                        <Abbreviation>sepo</Abbreviation> &lt; 500 U/l
                        <br />
                        Lenalidomid führt bei ca. 60 % der{" "}
                        <Abbreviation>mds</Abbreviation> del(5q)-Patient:innen
                        mit transfusionspflichtiger Anämie bei{" "}
                        <Abbreviation>ipss</Abbreviation>
                        -Risiko „LOW“ bzw. „<Abbreviation>int</Abbreviation> -1“
                        zum Ansprechen mit dem Ergebnis einer
                        Transfusionsunabhängigkeit und bei 50 - 75% der
                        Patient:innen zu zytogenetischer Remission und bei 30 -
                        45% zu kompletter zytogenetischer Remission. Ähnlich
                        gutes Ansprechen bei Patient:innen mit nur einer
                        Zusatzaberration{" "}
                        <TextInfo>außer von Chromosom 7</TextInfo>
                      </li>
                      <li>
                        <Abbreviation>ek</Abbreviation> und ggf.
                        Eisenchelationstherapie
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: (
                <span>
                  MDS-RS +/- SF3B1s, EPO ≥500 U/l oder{" "}
                  <Abbreviation>epo</Abbreviation>
                  -refraktär
                </span>
              ),
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        Luspatercept
                        <TextInfo>
                          für Patient:innen mit einem{" "}
                          <Abbreviation>sepo</Abbreviation> &gt;200 U/l
                        </TextInfo>
                        <TextReference references={["ref1"]} />
                      </li>
                      <li>
                        <Abbreviation>ek</Abbreviation> und ggf.
                        Eisenchelationstherapie
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Alle weiteren MDS-Subtypen, sEPO < 500 U/l",
              body: {
                content: (
                  <AccordionNodeItem>
                    <ul>
                      <li>
                        <Abbreviation>esa</Abbreviation>{" "}
                        <TextInfo>
                          Nur Epoetin alfa ist für die Behandlung von
                          Niedrigrisiko-
                          <Abbreviation>mds</Abbreviation> gemäß Indikation der
                          Fachinformation zugelassen
                        </TextInfo>
                      </li>
                      <li>
                        Keine ausreichende und teils widersprüchliche Datenlage
                        zur Kombination von niedrigen Dosen von{" "}
                        <Abbreviation>gcsf</Abbreviation> mit ESAs mit der
                        Intention, die Wirksamkeit von ESAs zu verbessern
                      </li>
                      <li>
                        <Abbreviation>ek</Abbreviation> und ggf.
                        Eisenchelationstherapie
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRiskManagement;
