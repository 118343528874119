/** @jsxImportSource @emotion/react */

import DragHandleIcon from "@mui/icons-material/DragHandle";
import { useState, MouseEvent } from "react";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";

interface DragAndDropModeSwitchProps {
  onClick?: () => void;
}

export function DragAndDropModeSwitch(props: DragAndDropModeSwitchProps) {
  const theme = useTheme();
  const [isActive, setIsActive] = useState(false);

  const styles = {
    root: css`
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${isActive
        ? theme.palette.primary.main
        : theme.palette.text.primary};
    `,
  };

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsActive(!isActive);
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div css={styles.root} onClick={handleClick}>
      <DragHandleIcon fontSize="medium" />
    </div>
  );
}

export default DragAndDropModeSwitch;
