/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { Grid, useTheme } from "@mui/material";
import { ReactNode } from "react";

export interface VerticalLineProps {
  color?: "primary" | "error" | "warning" | "success" | "default";
  columns: 1 | 2 | 3;
  position: 0 | 1 | 2 | 3;
}

export function VerticalLine(props: VerticalLineProps) {
  const theme = useTheme();
  const { color = "default" } = props;
  const lineColor = color === "default" ? "#e8f3fc" : theme.palette[color].main;
  const background = `linear-gradient(${lineColor}, ${lineColor}) no-repeat center/1px 100%`;

  const styles = {
    root: css`
      height: 32px;
    `,
    line: css`
      height: 32px;
      background: ${background};
    `,
  };

  const columns: ReactNode[] = [];
  for (let i = 0; i < props.columns; i++) {
    columns.push(
      <Grid key={i} item xs={12 / props.columns}>
        {i + 1 === props.position && <div css={styles.line} />}
      </Grid>
    );
  }

  return (
    <Grid css={styles.root} container>
      {columns}
    </Grid>
  );
}

export default VerticalLine;
