/** @jsxImportSource @emotion/react */

import { ReactNode, MouseEvent } from "react";
import { Modal as MuiModal } from "@mui/material";
import { css } from "@emotion/react";
import ModalCloseIcon from "../Icons/ModalCloseIcon";

/* eslint-disable-next-line */
export interface ModalProps {
  children: ReactNode;
  open: boolean;
  onClose: (event?: MouseEvent) => void;
}

export function Modal(props: ModalProps) {
  const styles = {
    body: css`
      background: #fff;
      padding: 16px 16px 12px 16px;
      border-radius: 8px;
      border: solid 1px #2660a4;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 34px);
      word-break: break-word;
      p {
        margin: 0;
      }
    `,
    closeButton: css`
      position: absolute;
      top: 12px;
      right: 12px;
      width: 17px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 13px;
        height: 13px;
      }
      // display: inline-block;
      cursor: pointer;
    `,
  };
  return (
    <MuiModal open={props.open} onClose={() => props.onClose()}>
      <div css={styles.body}>
        <div onClick={(event) => props.onClose(event)} css={styles.closeButton}>
          <ModalCloseIcon />
        </div>
        {props.children}
      </div>
    </MuiModal>
  );
}

export default Modal;
