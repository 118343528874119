import { PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextInfo from "../../components/TextInfo";
import TextReference from "../../components/TextReference";

export function CiaPraxisTipps() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Praxistipps</h1>
        <h2>Do’s</h2>
        <ul>
          <li>
            Detaillierte Anämie-Abklärung auch bei Tumorpatient:innen unter{" "}
            <Abbreviation>ctx</Abbreviation>, um ursachenspezifisch behandeln zu
            können, ggf. Ausschluss <Abbreviation>mnpct</Abbreviation>
          </li>
          <li>
            Unterscheiden in Patient:innen mit chronischem Verlauf und
            postoperative Patient:innen{" "}
            <TextInfo>
              Beurteilung des <Abbreviation>hb</Abbreviation>
              -Verlaufs
            </TextInfo>
          </li>
          <li>
            Mangelernährung als Ursache prüfen
            <TextInfo>z. B. Vitamin-B12-Mangel</TextInfo>
          </li>
          <li>
            Nieren-, Leber- und Knochenmarkfunktion kritisch prüfen und ggf.
            medikamentös intervenieren
          </li>
          <li>
            Hb immer im Verlauf beurteilen und frühzeitige Einleitung der
            Therapie
          </li>
          <li>
            Eisensubstitution bei manifestem und funktionellem Eisenmangel
          </li>
          <li>
            <Abbreviation>esa</Abbreviation>
            -Therapie bei symptomatischer <Abbreviation>cia</Abbreviation> unter
            10 g/dl
          </li>
          <li>
            Leitliniengerechte ESA-Therapie mit Hb-Zielwert von ca. 12 g/dl ohne
            Bluttransfusion
          </li>
          <li>
            bei Gabe von Eisen i.v., Epoetine oder Blutkonserven schriftliche
            Aufklärung der Patientin/ des Patienten und entsprechende
            Dokumentation
          </li>
          <li>
            Therapieentscheidung immer gemeinsam treffen (Arzt/Ärztin und
            Patientin/Patient)
          </li>
        </ul>

        <h2>Dont’s</h2>
        <ul>
          <li>
            Erythropoetine bei Eisenmangelanämie ohne adäquate Eisensubstitution
          </li>
          <li>Eisengabe während eines akuten Infekts</li>
          <li>Wechsel der Erythropoetine</li>
        </ul>

        <h2>Patient Blood Management:</h2>
        <ul>
          <li>
            Im perioperativen Setting, insbesondere auf der Intensivstation,
            können Blutentnahmen zu diagnostischen Zwecken zu einer
            Krankenhaus-erworbenen Anämie führen.
            <ul>
              <li>
                Entnahmefrequenz sowie Entnahmeroutine den individuellen
                Bedürfnissen der jeweiligen Patient:innen anpassen.
              </li>
              <li>
                Blutentnahmeröhrchen mit dem kleinsten zur exakten Analyse
                benötigten Volumen nutzen.
              </li>
              <li>Verwürfe bestmöglich vermeiden.</li>
            </ul>
          </li>
          <li>
            Bei Blutverlusten &gt; 500 ml ist die maschinelle Autotransfusion{" "}
            <TextInfo>
              Auffangen, Reinigen und Wiederzuführen von Wundblut
            </TextInfo>{" "}
            eine wertvolle Möglichkeit, den Einsatz von allogenen Blutprodukten
            so gering wie möglich zu halten{" "}
            <TextReference references={["ref9"]} />
          </li>
        </ul>
      </PagePadding>
    </>
  );
}

export default CiaPraxisTipps;
