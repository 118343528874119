import {
  Accordion,
  DynamicSpacing,
  PagePadding,
  Table,
  TableBody,
  TableRow,
  AccordionNodeItem,
} from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";

export function MdsPraxisCasuisticsMdsMld() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Behandlung einer symptomatischen und transfusionsbedürftigen Anämie
          mit Erythropoetin alfa bei Subtyp <Abbreviation>mdsmld</Abbreviation>
        </h1>
        <p>
          54-jährige Patientin, 64 kg, 176 cm, <Abbreviation>ecog</Abbreviation>{" "}
          1
        </p>

        <Accordion
          items={[
            {
              title: "Symptome und Diagnostik",
              defaultOpen: true,
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>Aktuelle Anamnese 2017</h3>
                    <ul>
                      <li>
                        Vorstellung beim Hausarzt/der Hausärztin zur
                        regelmäßigen Blutbild-Kontrolle
                      </li>
                      <li>
                        Fortbestehen einer langanhaltenden leichten Anämie um 10
                        g/dl, leichte Leukopenie um 3{" "}
                        <Abbreviation>gpt</Abbreviation>
                        /l sowie eine Thrombozytopenie um 100 Gpt/l
                      </li>
                      <li>
                        Überweisung an niedergelassenen Hämatologen/Hämatologin
                        zur hämatologischen Abklärung
                      </li>
                      <li>
                        Vorerkrankungen
                        <ul>
                          <li>Seit frühem Erwachsenenalter Typ-1-Diabetes</li>
                          <li>
                            Z. n. Meningoenzephalitis Anfang der 80er Jahre
                          </li>
                        </ul>
                      </li>
                    </ul>

                    <h3>Diagnose</h3>
                    <ul>
                      <li>
                        Körperlicher Untersuchungsbefund:
                        <ul>
                          <li>Unauffällig</li>
                        </ul>
                      </li>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            Hb 10,5 g/dl, L 2,2 Gpt/l, ANC 1,1 Gpt/l, Thr 107
                            Gpt/l
                          </li>
                        </ul>
                      </li>
                      <li>Kein Substratmangel</li>
                      <li>Keine unentdeckte virale Infektion</li>
                    </ul>

                    <h3>Knochenmark-Diagnostik von 2017 sowie 07/2018</h3>
                    <ul>
                      <li>Keine Auffälligkeiten in der Zytogenetik</li>
                    </ul>

                    <h3>Abschließende Diagnose</h3>
                    <ul>
                      <li>
                        <Abbreviation>mdsmld</Abbreviation>
                      </li>
                      <li>
                        Gemäß <Abbreviation>ipss</Abbreviation>: Kategorie „low
                        risk“
                      </li>
                      <li>
                        Gemäß <Abbreviation>ipssr</Abbreviation>: Kategorie „low
                        risk“ <TextReference references={["ref13"]} />
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Medikamentöse MDS-Therapie",
              body: {
                content: (
                  <AccordionNodeItem>
                    <p>
                      Keine, da stabiler Befund, vorerst
                      Watch-and-wait-Strategie
                    </p>
                  </AccordionNodeItem>
                ),
              },
            },
            {
              title: "Weiterer Verlauf",
              body: {
                content: (
                  <AccordionNodeItem>
                    <h3>12.12.2018</h3>
                    <ul>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            <Abbreviation>hb</Abbreviation> 8,8 g/dl,{" "}
                            <Abbreviation>l</Abbreviation> 2,2{" "}
                            <Abbreviation>gpt</Abbreviation>
                            /l, <Abbreviation>anc</Abbreviation> 0,59 Gpt/l,{" "}
                            <Abbreviation>thr</Abbreviation> 66 Gpt/l
                          </li>
                        </ul>
                      </li>
                      <li>
                        Aufgrund progredienter und symptomatischer Anämie im
                        Sinne einer Belastungsdyspnoe
                      </li>
                      <li>Therapiestart Erythropoetin alfa:</li>
                    </ul>
                    <Table>
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Dosierung",
                            <>
                              40.000<Abbreviation>ie</Abbreviation>
                            </>,
                          ]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q7d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc1"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />

                    <h3>16.01.2019</h3>
                    <ul>
                      <li>kein Ansprechen</li>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            <Abbreviation>hb</Abbreviation>: 8,6 g/dl,{" "}
                            <Abbreviation>l</Abbreviation>: 2,3{" "}
                            <Abbreviation>gpt</Abbreviation>
                            /l, <Abbreviation>anc</Abbreviation>: 0,51 Gpt/l,{" "}
                            <Abbreviation>thr</Abbreviation>: 83 Gpt/l
                          </li>
                        </ul>
                      </li>
                      <li>Therapieanpassung:</li>
                    </ul>
                    <Table>
                      <TableBody>
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosierung", "60.000 IE"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={["Dosisintervall", "q7d"]}
                        />
                        <TableRow
                          dynamicCellWidth
                          entries={[
                            "Applikation",
                            <Abbreviation key={"sc2"}>sc</Abbreviation>,
                          ]}
                        />
                      </TableBody>
                    </Table>
                    <DynamicSpacing spacing={6} />

                    <h3>04.07.2019</h3>
                    <ul>
                      <li>Klinisch progrediente Belastungsdyspnoe</li>
                      <li>
                        Labor:
                        <ul>
                          <li>
                            <Abbreviation>hb</Abbreviation>: 6,2 g/dl,{" "}
                            <Abbreviation>l</Abbreviation>: 2,1{" "}
                            <Abbreviation>gpt</Abbreviation>
                            /l, <Abbreviation>anc</Abbreviation>: 0,81 Gpt/l,{" "}
                            <Abbreviation>thr</Abbreviation>: 24 Gpt/l
                          </li>
                        </ul>
                      </li>
                      <li>
                        Transfusionspflichtig für Erythrozytenkonzentrate (EKs)
                      </li>
                      <li>
                        Erneute Knochenmarkpunktion, um mögliche klonale
                        Evolution abzuklären.
                        <ul>
                          <li>
                            07/2019 <Abbreviation>kmp</Abbreviation>: Nachweis
                            einer neuen Deletion 7q sowie von Mutationen in den
                            Genen U2AF1, ETNK1 und SETBP1 mit jeweils niedriger
                            variabler Allelfrequenz
                          </li>
                          <li>
                            Gemäß <Abbreviation>ipss</Abbreviation>: Kategorie
                            „intermediate-2 risk“
                          </li>
                          <li>
                            Gemäß <Abbreviation>ipssr</Abbreviation>: Kategorie
                            „high risk“ <TextReference references={["ref13"]} />
                          </li>
                        </ul>
                      </li>
                      <li>
                        Es wurde, bei nun Vorliegen eines Hochrisiko-
                        <Abbreviation>mds</Abbreviation> und gutem{" "}
                        <Abbreviation>az</Abbreviation> der Patientin, die
                        Indikation zur allogenen hämatopoetischen
                        Stammzelltransplantation gestellt. Diese konnte
                        komplikationslos durchgeführt werden.
                      </li>
                    </ul>
                  </AccordionNodeItem>
                ),
              },
            },
          ]}
        />
      </PagePadding>
    </>
  );
}

export default MdsPraxisCasuisticsMdsMld;
