import {
  NavigationList,
  PagePadding,
  NavigationListItem,
  Link,
} from "components/ui";
import { useContext } from "react";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import HexalSeo from "../../components/HexalSeo";
import GlobalContext from "../../context/globalContext";
import MdsTherapyLowRiskManagement from "./therapyLowRiskManagement";
import MdsTherapyLowRiskEsa from "./therapyLowRiskEsa";
import MdsTherapyLowRiskImmunomodulators from "./therapyLowRiskImmunomodulators";
import MdsTherapyLowRiskLuspatercept from "./therapyLowRiskLuspatercept";

export function MdsTherapyLowRisk() {
  const navigationItems: { link: Link; description: string }[] = [];
  const { currentPage } = useContext(GlobalContext);

  const management = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskManagement
  );
  if (management) {
    navigationItems.push({
      link: {
        label: management.title,
        isExternal: false,
        url: management.path,
      },
      description: management.description,
    });
  }

  const esa = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskEsa
  );
  if (esa) {
    navigationItems.push({
      link: {
        label: esa.title,
        isExternal: false,
        url: esa.path,
      },
      description: esa.description,
    });
  }

  const immunomodulators = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskImmunomodulators
  );
  if (immunomodulators) {
    navigationItems.push({
      link: {
        label: immunomodulators.title,
        isExternal: false,
        url: immunomodulators.path,
      },
      description: immunomodulators.description,
    });
  }

  const luspatercept = currentPage?.children.find(
    (child) => child.view === MdsTherapyLowRiskLuspatercept
  );
  if (luspatercept) {
    navigationItems.push({
      link: {
        label: luspatercept.title,
        isExternal: false,
        url: luspatercept.path,
      },
      description: luspatercept.description,
    });
  }

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Management Niedrigrisiko MDS-assoziierte Anämie</h1>
        <NavigationList>
          {navigationItems.map((item, index) => (
            <NavigationListItem
              key={index}
              link={item.link}
              description={item.description}
            />
          ))}
        </NavigationList>
      </PagePadding>
    </>
  );
}

export default MdsTherapyLowRisk;
