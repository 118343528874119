import * as React from "react";
import { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkExistingLogin } from "../util/authHelpers";

const LOGIN_STORAGE_KEY = "hex_login_type";
const LOGIN_CREDENTIAL_STORAGE_KEY = "hex_login_credential";

export const VALID_LOGIN_TYPES = {
  password: "password",
  docCheck: "DocCheck",
};
export const AVAILABLE_LOGIN_TYPES = [
  VALID_LOGIN_TYPES.password,
  VALID_LOGIN_TYPES.docCheck,
];

export interface AuthContextValueProps {
  authed: boolean;
  login: (type: string, credential: string) => Promise<void>;
  logout: () => Promise<void>;
}

export const AuthContext = React.createContext<null | AuthContextValueProps>(
  null
);
export function AuthContextProvider({ children }: { children: ReactNode }) {
  const initialAuthType = localStorage.getItem(LOGIN_STORAGE_KEY);
  const initialAuthCredentials = localStorage.getItem(
    LOGIN_CREDENTIAL_STORAGE_KEY
  );
  const [authed, setAuthed] = React.useState(
    initialAuthType !== null &&
      AVAILABLE_LOGIN_TYPES.includes(initialAuthType) &&
      initialAuthCredentials !== null &&
      checkExistingLogin(initialAuthType, initialAuthCredentials)
  );
  const navigate = useNavigate();
  const location = useLocation();

  const login = (type: string, credential: string) => {
    return new Promise<void>((res) => {
      localStorage.setItem(LOGIN_STORAGE_KEY, type);
      localStorage.setItem(LOGIN_CREDENTIAL_STORAGE_KEY, credential);
      setAuthed(true);
      const origin = location.state?.from?.pathname || "/";
      navigate(origin);
      res();
    });
  };

  const logout = () => {
    return new Promise<void>((res) => {
      localStorage.removeItem(LOGIN_STORAGE_KEY);
      localStorage.removeItem(LOGIN_CREDENTIAL_STORAGE_KEY);
      setAuthed(false);
      res();
    });
  };

  return (
    <AuthContext.Provider
      value={{
        authed,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
