import { SvgIcon, SvgIconProps } from "@mui/material";

export function HexalIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      id="Ebene_1"
      data-name="Ebene 1"
      xmlns="http://www.w3.org/2000/svg"
      width="282.42"
      height="32.35"
      viewBox="0 0 282.42 32.35"
    >
      <g id="Gruppe_1939" data-name="Gruppe 1939">
        <path
          id="Pfad_2156"
          data-name="Pfad 2156"
          d="M12.77,25.57V14.23H1.99V25.56H0V1.99H1.99V12.52H12.77V1.99h1.96V25.57Z"
          fill="#788abf"
        />
        <g id="Gruppe_1936" data-name="Gruppe 1936">
          <path
            id="Pfad_2157"
            data-name="Pfad 2157"
            d="M21.58,25.57V1.99H31.9V3.67H23.58v8.89h7.55v1.68H23.58v9.65h8.43v1.68H21.59Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2158"
            data-name="Pfad 2158"
            d="M47.78,25.57l-4.76-9.06-.8-1.68h-.03l-.77,1.47-4.83,9.27H34.28l6.79-12.38L35.12,1.99h2.24l4.06,7.69.8,1.68h.04l.94-1.85,3.88-7.52h2.27L43.37,13.01l6.79,12.56H47.78Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2159"
            data-name="Pfad 2159"
            d="M65.48,25.57l-1.57-5.46H54.75l-1.54,5.46H51.18L58.14,1.99h2.55l6.93,23.57H65.49ZM59.32,4.13h-.04L55.19,18.47h8.29Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2160"
            data-name="Pfad 2160"
            d="M71.32,25.57V1.99h1.99V23.85h7.13v1.71H71.31Z"
            fill="#788abf"
          />
        </g>
        <g id="Gruppe_1937" data-name="Gruppe 1937">
          <path
            id="Pfad_2161"
            data-name="Pfad 2161"
            d="M94.51,25.57V3.71H88.49V1.99h14.03V3.7H96.47V25.57Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2162"
            data-name="Pfad 2162"
            d="M115.5,25.57V14.48c0-2.66-.94-5.14-3.15-5.14-1.33,0-2.9.84-4.9,2.73v13.5h-1.89V.28L107.45,0V10.21c2.31-1.96,3.67-2.59,5.39-2.59,2.76,0,4.55,2.55,4.55,6.3V25.57Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2163"
            data-name="Pfad 2163"
            d="M133.97,24.55a15.842,15.842,0,0,1-5.63,1.15c-4.23,0-6.33-2.38-6.33-8.92,0-5.91,2.45-9.16,6.54-9.16,3.78,0,5.91,3.11,5.91,7.49,0,.63-.03,1.5-.07,1.99H123.97c0,4.79,1.47,6.93,4.65,6.93a20.225,20.225,0,0,0,4.97-.8l.38,1.33Zm-1.43-9.37c0-3.04-1.05-5.95-4.16-5.95s-4.3,3.29-4.41,6.3h8.57Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2164"
            data-name="Pfad 2164"
            d="M139.18,25.57V7.76h1.54l.21,2.66c2.48-2.66,3.36-2.8,4.65-2.8h.8V9.44h-.87c-1.68,0-2.52.73-4.44,2.73v13.4Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2165"
            data-name="Pfad 2165"
            d="M159.12,25.57l-.25-2.59c-2.55,2.2-3.92,2.73-6.02,2.73-2.73,0-4.55-2.13-4.55-5.14,0-3.32,2.59-5.04,8.88-5.04h1.54v-.8c0-3.32-.52-5.49-4.13-5.49a15.5,15.5,0,0,0-5.07,1.08l-.38-1.47a16.413,16.413,0,0,1,5.74-1.22c4.13,0,5.74,1.99,5.74,6.47V25.57h-1.5Zm-.38-8.46h-1.75c-5.35,0-6.68,1.08-6.68,3.32,0,1.99,1.12,3.6,3.01,3.6s3.11-.66,5.42-2.76Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2166"
            data-name="Pfad 2166"
            d="M166.19,32.35V7.76h1.5l.25,2.59c2.45-2.13,3.81-2.73,5.35-2.73,3.25,0,5.21,3.71,5.21,8.99,0,5.77-2.41,9.09-5.63,9.09-1.22,0-2.66-.63-4.79-2.52v8.88l-1.89.28Zm1.89-11.09c1.99,1.85,3.46,2.76,4.58,2.76,2.24,0,3.92-3.08,3.92-7.35s-1.36-7.35-3.71-7.35c-1.22,0-2.8.84-4.79,2.73v9.2Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2167"
            data-name="Pfad 2167"
            d="M183.15,3.29V.38h1.89V3.29Zm0,22.28V7.76h1.89V25.57Z"
            fill="#788abf"
          />
          <path
            id="Pfad_2168"
            data-name="Pfad 2168"
            d="M201.62,24.55a15.842,15.842,0,0,1-5.63,1.15c-4.23,0-6.33-2.38-6.33-8.92,0-5.91,2.45-9.16,6.54-9.16,3.78,0,5.91,3.11,5.91,7.49,0,.63-.03,1.5-.07,1.99H191.62c0,4.79,1.47,6.93,4.65,6.93a20.225,20.225,0,0,0,4.97-.8l.38,1.33Zm-1.43-9.37c0-3.04-1.05-5.95-4.16-5.95s-4.3,3.29-4.41,6.3h8.57Z"
            fill="#788abf"
          />
        </g>
        <g id="Gruppe_1938" data-name="Gruppe 1938">
          <path
            id="Pfad_2169"
            data-name="Pfad 2169"
            d="M217.05,16.79h-3.11V12.84h7.83V24.1a16.669,16.669,0,0,1-7.41,1.82c-6.44,0-9.06-2.9-9.06-12.03,0-8.29,3.18-12.24,9.62-12.24a13.664,13.664,0,0,1,6.75,1.96l-1.36,3.5a11.648,11.648,0,0,0-4.72-1.36c-3.6,0-5.21,2.24-5.21,8.08,0,6.26,1.29,8.01,4.3,8.01a7.158,7.158,0,0,0,2.38-.45V16.81Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2170"
            data-name="Pfad 2170"
            d="M235.48,25.57l-.38-1.89c-2.2,1.82-3.46,2.27-5.21,2.27-2.8,0-4.72-2.03-4.72-6.23V7.55h4.58V18.5c0,1.78.59,3.25,1.96,3.25a4.761,4.761,0,0,0,2.97-1.64V7.55h4.62V25.57h-3.81Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2171"
            data-name="Pfad 2171"
            d="M242.93,4.69V.31h4.58V4.69Zm0,20.88V7.55h4.58V25.57Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2172"
            data-name="Pfad 2172"
            d="M261.23,25.57l-.38-1.89c-2.17,1.75-3.46,2.27-4.9,2.27-3.25,0-5.49-3.08-5.49-9.16,0-6.47,2.73-9.62,6.19-9.62,1.26,0,2.27.49,3.78,1.78V.84l4.58-.7V25.57h-3.78Zm-.8-12.59a4.528,4.528,0,0,0-2.8-1.57c-1.47,0-2.52,1.64-2.52,5.25,0,3.39.87,5.11,2.38,5.11a4.857,4.857,0,0,0,2.94-1.64V13Z"
            fill="#0561a8"
          />
          <path
            id="Pfad_2173"
            data-name="Pfad 2173"
            d="M282.18,24.48a18,18,0,0,1-7.03,1.43c-5.28,0-7.21-2.97-7.21-9.27s2.73-9.48,7.69-9.48c3.92,0,6.79,2.55,6.79,8.01a24.75,24.75,0,0,1-.14,2.8h-9.72c0,2.97.98,4.2,3.25,4.2a22.61,22.61,0,0,0,5.53-.87l.84,3.18Zm-4.27-10.07c0-2.06-.7-3.67-2.62-3.67s-2.73,1.89-2.76,3.92h5.39v-.24Z"
            fill="#0561a8"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default HexalIcon;
