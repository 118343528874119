import { SvgIcon, SvgIconProps } from "@mui/material";

export function DiagnosticsIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
    >
      <path
        data-name="Pfad 429"
        d="M35.493 23.659a3.487 3.487 0 1 0-4.016 3.44v1.235a5.781 5.781 0 0 1-11.563 0V27.1a6.878 6.878 0 0 0 6.355-6.837V16.69a3.1 3.1 0 0 0-3.089-3.09H23v-.573a.536.536 0 0 0-.53-.529.51.51 0 0 0-.53.529v2.161a.536.536 0 0 0 .53.529.51.51 0 0 0 .53-.529v-.529h.177a2.024 2.024 0 0 1 2.03 2.029v3.573a5.826 5.826 0 0 1-11.651 0V16.69a2.024 2.024 0 0 1 2.03-2.029h.221v.529a.536.536 0 0 0 .53.529.51.51 0 0 0 .53-.529v-2.161a.53.53 0 0 0-1.059 0v.571h-.221a3.1 3.1 0 0 0-3.087 3.09v3.573a6.878 6.878 0 0 0 6.355 6.837v1.235a6.841 6.841 0 0 0 13.681 0V27.1a3.506 3.506 0 0 0 2.957-3.441zm-3.487 2.426a2.426 2.426 0 1 1 2.427-2.426 2.448 2.448 0 0 1-2.426 2.426z"
        transform="translate(-6.743 -6.743)"
        style={{ fill: "currentcolor" }}
      />
      <path
        data-name="Rechteck 595"
        style={{ fill: "none" }}
        d="M0 0h34v34H0z"
      />
    </SvgIcon>
  );
}

export default DiagnosticsIcon;
