import { Alert, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import TextInfo from "../../components/TextInfo";

export function CiaTherapyIronSubstitution() {
  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>
          Eisensubstitution bei <Abbreviation>cia</Abbreviation>
        </h1>
        <p>
          Bei funktionellem Eisenmangel kann zusätzlich zu einer Therapie mit{" "}
          <Abbreviation>esa</Abbreviation> eine i.v. Gabe von Eisen erfolgen.
        </p>

        <p>
          Die Menge an benötigtem Eisen bei Eisenmangel lässt sich mit der{" "}
          <strong>Ganzoni-Formel</strong> berechnen:{" "}
          <TextReference references={["ref16"]} />
        </p>

        <p>
          <strong>
            Eisendefizit (mg) = Körpergewicht (kg) x
            (Ziel-Hämoglobinkonzentration (g/dl) – Ist-Hämoglobinkonzentration
            (g/dl)) x 2,4 + Reservespeichereisen (mg)
          </strong>
        </p>

        <p>
          Die Speichereisenreserve wird mit 500 mg angenommen. Bei adipösen
          Patienten sollte die geschätzte fettfreie Körpermasse als
          Körpergewicht angenommen werden.
        </p>

        <p>
          <strong>Beispiel:</strong>
        </p>
        <p>
          Bei einem 65 kg schweren Mann soll die Hämoglobinkonzentration von 9
          g/dl auf 12 g/dl angehoben werden:
        </p>
        <p>
          <strong>65kg x (12g/dl – 9g/dl) x 2,4 + 500mg = 968 mg</strong>
        </p>

        <Alert
          content={
            <>
              <span>CAVE:</span>
              <ul>
                <li>
                  Abzuwägen ist, ob der Benefit der zusätzlichen i.v. Eisengabe
                  bei funktionellem Eisenmangel das geringe Risiko{" "}
                  <TextInfo>selten: ≥ 1/10.000, &lt; 1/1.000</TextInfo> einer
                  anaphylaktischen Reaktion oder das sehr geringe Risiko einer
                  Eisenüberladung überwiegt.
                </li>
                <li>
                  Evidenz nicht ausreichend, um eine Empfehlung für oder gegen
                  eine i.v. Eisentherapie ohne begleitende ESA-Therapie
                  auszusprechen. <TextReference references={["ref1", "ref2"]} />
                </li>
              </ul>
            </>
          }
          type="warning"
        />
      </PagePadding>
    </>
  );
}

export default CiaTherapyIronSubstitution;
