/** @jsxImportSource @emotion/react */

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { ReactNode } from "react";
import { Pagination } from "swiper";
import { css } from "@emotion/react";
import { useTheme } from "@mui/material";

/* eslint-disable-next-line */
export interface FullWidthCarouselProps {
  slides: {
    body: ReactNode;
  }[];
  bordered?: boolean;
}

export function FullWidthCarousel(props: FullWidthCarouselProps) {
  const theme = useTheme();
  const styles = {
    wrapper: css`
      position: relative;
      margin-top: ${props.bordered ? "20px" : "0"};
      margin-bottom: ${props.bordered ? "40px" : "0"};
    `,
    root: css`
      border: ${props.bordered
        ? `1px solid ${theme.palette.primary.main}`
        : "none"};
      border-radius: ${props.bordered ? "13px" : "0"};
      position: ${props.bordered ? "initial" : "relative"};
      .swiper-pagination {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        position: ${props.bordered ? "absolute" : "relative"};
        bottom: ${props.bordered ? "-30px" : "0"};
      }
      .swiper-pagination-bullet {
        height: 12px;
        width: 12px;
        margin: 0 6px;
        border: solid 1px ${theme.palette.primary.main};
        border-radius: 50%;
        cursor: pointer;
      }
      .swiper-pagination-bullet-active {
        background-color: ${theme.palette.primary.main};
      }
    `,
  };

  return (
    <div css={styles.wrapper}>
      <Swiper
        modules={[Pagination]}
        grabCursor
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          750: {
            slidesPerView: 2,
          },
        }}
        css={styles.root}
      >
        {props.slides.map((slide, index) => (
          <SwiperSlide key={index}>{slide.body}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default FullWidthCarousel;
