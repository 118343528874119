/** @jsxImportSource @emotion/react */

import {
  Alert,
  ColoredText,
  CtxRegimeAccordion,
  PagePadding,
  Table,
  TableRow,
} from "components/ui";
import { css } from "@emotion/react";
import { TableBody } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import HexalSeo from "../../components/HexalSeo";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import TextInfo from "../../components/TextInfo";

export function FnProphylaxisCtRegimes() {
  const styles = {
    formula: css`
      display: flex;
      justify-content: flex-end;
    `,
  };

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>FN-Risiko verschiedener Chemotherapie-Regime</h1>
        <p>
          <Abbreviation>fn</Abbreviation>
          -Risiko häufig eingesetzter Immun-Chemotherapie- und
          Chemotherapie-Regime.
        </p>
        <p>Das Risiko wird nach dem höchsten vorliegenden Wert eingestuft:</p>
        <Table>
          <TableBody>
            <TableRow
              entries={[
                <ColoredText key={"low1"} color="success">
                  &lt; 10 %
                </ColoredText>,
                <ColoredText key={"low2"} color="success">
                  gering
                </ColoredText>,
              ]}
            />
            <TableRow
              entries={[
                <ColoredText key={"med1"} color="warning">
                  10–20 %
                </ColoredText>,
                <ColoredText key={"med2"} color="warning">
                  mittel
                </ColoredText>,
              ]}
            />
            <TableRow
              entries={[
                <ColoredText key={"hi1"} color="error">
                  &gt; 20 %
                </ColoredText>,
                <ColoredText key={"hi2"} color="error">
                  hoch
                </ColoredText>,
              ]}
            />
          </TableBody>
        </Table>
        <h2>Follikuläres Lymphom</h2>
        <CtxRegimeAccordion
          items={[
            {
              title: <Abbreviation>br</Abbreviation>,
              priority: "high",
              formula: (
                <>
                  R<sub>375</sub>
                  d1 Zyklus 1-6, B<sub>90</sub> q4w x6
                </>
              ),
              rows: [
                {
                  color: "error",
                  columnA: "27,8 %",
                  columnB: (
                    <>
                      ohne <Abbreviation>gcsf</Abbreviation>{" "}
                      <Abbreviation>pp</Abbreviation>
                    </>
                  ),
                },
                {
                  color: "success",
                  columnA: "8,2 %",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref21"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: (
                <span>
                  <Abbreviation>rchop</Abbreviation>
                  -21
                </span>
              ),
              priority: "middle",
              formula: (
                <>
                  R<sub>375</sub>
                  d1 Zyklus 1-4, C<sub>750</sub>H<sub>50</sub>O<sub>1,4</sub>P
                  <sub>100</sub> q3w x6
                </>
              ),
              rows: [
                {
                  color: "warning",
                  columnA: "17 %",
                  columnB: (
                    <>
                      k.A. G-CSF
                      <TextReference references={["ref22"]} />
                    </>
                  ),
                },
              ],
            },
          ]}
        />

        <h2>Chronische lymphatische Leukämie</h2>
        <CtxRegimeAccordion
          items={[
            {
              title: <Abbreviation>br</Abbreviation>,
              priority: "middle",
              formula: "80 % der Patient:innen q4w x6",
              rows: [
                {
                  color: "warning",
                  columnA: "10,5 %",
                  columnB: (
                    <>
                      mit <Abbreviation>gcsf</Abbreviation>{" "}
                      <Abbreviation>pp</Abbreviation> (29 % der Patient:innen)
                    </>
                  ),
                },
                {
                  color: "warning",
                  columnA: "10,5 %",
                  columnB: (
                    <>
                      mit G-CSF <Abbreviation>sp</Abbreviation> (24 % der
                      Patient:innen)
                      <TextReference references={["ref12"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: (
                <span>
                  <Abbreviation>fcr</Abbreviation>
                  -21
                </span>
              ),
              priority: "low",
              formula: (
                <>
                  R<sub>375</sub>, F<sub>20</sub>C<sub>600</sub> q4w max. x6
                </>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "8 %",
                  columnB: (
                    <>
                      k.A. G-CSF
                      <TextReference references={["ref23"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: (
                <span>
                  <Abbreviation>r</Abbreviation>
                  -Chlorambucil
                </span>
              ),
              priority: "low",
              formula: (
                <>
                  R<sub>375</sub> d1 Zyklus 1 / R<sub>500</sub> d1 Zyklen 2-6,
                  Chlorambucil
                  <sub>0,5</sub> d1,15
                </>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "2 %",
                  columnB: (
                    <>
                      k.A. G-CSF
                      <TextReference references={["ref24"]} />
                    </>
                  ),
                },
              ],
            },
          ]}
        />

        <h2>Diffuses großzelliges B-Zell-Lymphom</h2>
        <CtxRegimeAccordion
          items={[
            {
              title: (
                <span>
                  <Abbreviation>rchop</Abbreviation>
                  -14
                </span>
              ),
              priority: "high",
              formula: (
                <>
                  R<sub>375</sub>, C<sub>750</sub>H<sub>50</sub>O<sub>1,4</sub>P
                  <sub>100</sub> q2w x6
                </>
              ),
              rows: [
                {
                  color: "error",
                  columnA: "20 %",
                  columnB: (
                    <>
                      mit G-CSF PP (84 % der Patient:innen)
                      <TextReference references={["ref25"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: (
                <span>
                  <Abbreviation>rchop</Abbreviation>
                  -21
                </span>
              ),
              priority: "middle",
              formula: (
                <>
                  R<sub>375</sub>, C<sub>750</sub>H<sub>50</sub>O<sub>1,4</sub>P
                  <sub>100</sub> Q3w x6-8
                </>
              ),
              rows: [
                {
                  color: "warning",
                  columnA: "19 %",
                  columnB: (
                    <>
                      mit G-CSF PP (36 % der Patient:innen)
                      <TextReference references={["ref25"]} />
                    </>
                  ),
                },
              ],
            },
          ]}
        />

        <h2>Hodgkin-Lymphom</h2>
        <CtxRegimeAccordion
          items={[
            {
              title: (
                <span>
                  <Abbreviation>beacopp</Abbreviation>
                  <sub>eskaliert</sub>
                </span>
              ),
              priority: "high",
              formula: (
                <>
                  B<sub>10</sub>E<sub>200</sub>A<sub>35</sub>C<sub>1250</sub> O
                  <sub>1,4</sub>P<sub>100</sub>P<sub>40</sub>
                </>
              ),
              rows: [
                {
                  color: "default",
                  columnA: "",
                  columnB: <>k.A. G-CSF</>,
                },
                {
                  color: "error",
                  columnA: "35 %",
                  columnB: (
                    <>
                      mit G-CSF PP (84 % der Patient:innen)
                      <TextReference references={["ref26"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>abvd</Abbreviation>,
              priority: "low",
              formula: (
                <>
                  A<sub>25</sub>B<sub>10</sub>V<sub>6</sub>D<sub>375</sub> q4w
                  x6
                </>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "8 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref27"]} />
                    </>
                  ),
                },
              ],
            },
          ]}
        />

        <h2>Mammakarzinom</h2>
        <CtxRegimeAccordion
          items={[
            {
              title: <Abbreviation>tc</Abbreviation>,
              priority: "high",
              formula: (
                <>
                  T<sub>75</sub>C<sub>600</sub> q3w x4
                </>
              ),
              rows: [
                {
                  color: "error",
                  columnA: "20 % (27/134)",
                  columnB: "Gesamt",
                },
                {
                  color: "error",
                  columnA: "38 % (24/63)",
                  columnB: (
                    <>
                      ohne <Abbreviation>gcsf</Abbreviation>{" "}
                      <Abbreviation>pp</Abbreviation>
                    </>
                  ),
                },
                {
                  color: "success",
                  columnA: "4,2 % (3/71)",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref28"]} />
                    </>
                  ),
                },
                {
                  color: "warning",
                  columnA: "10,6 %",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref29"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>tac</Abbreviation>,
              priority: "high",
              formula: (
                <>
                  T<sub>75</sub>A<sub>50</sub>C<sub>500</sub> q3w x6
                </>
              ),
              rows: [
                {
                  color: "error",
                  columnA: "23,8 %",
                  columnB: "ohne G-CSF PP",
                },
                {
                  color: "success",
                  columnA: "3,5 %",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref30"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>t100</Abbreviation>,
              priority: "high",
              formula: (
                <>
                  T<sub>100</sub> q3w
                </>
              ),
              rows: [
                {
                  color: "error",
                  columnA: "24 %",
                  columnB: "in Beobachtungsstudien",
                },
                {
                  color: "warning",
                  columnA: "13 %",
                  columnB: (
                    <>
                      in RCTs
                      <TextReference references={["ref31"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>p175</Abbreviation>,
              priority: "high",
              formula: (
                <>
                  P<sub>175</sub> q3w
                </>
              ),
              rows: [
                {
                  color: "error",
                  columnA: "28 %",
                  columnB: "in Beobachtungsstudien",
                },
                {
                  color: "success",
                  columnA: "2 %",
                  columnB: (
                    <>
                      in RCTs
                      <TextReference references={["ref31"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>tcbh</Abbreviation>,
              priority: "high",
              rows: [
                {
                  color: "error",
                  columnA: "41 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref32"]} />
                    </>
                  ),
                },
                {
                  color: "warning",
                  columnA: "13 %",
                  columnB: <TextReference references={["ref33"]} />,
                },
                {
                  color: "error",
                  columnA: "26 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref34"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ddacp</Abbreviation>,
              priority: "high",
              formula: (
                <div css={styles.formula}>
                  <span>
                    A<sub>60</sub>C<sub>600</sub> q2w x4
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    P<sub>175</sub> q2w x4
                  </span>
                </div>
              ),
              rows: [
                {
                  color: "error",
                  columnA: ">20 %, da ddCTx",
                  columnB: "",
                },
                {
                  color: "success",
                  columnA: "2 % hospitalisiert",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref35"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ddectx4</Abbreviation>,
              priority: "high",
              formula: (
                <div css={styles.formula}>
                  <span>
                    E<sub>90</sub>C<sub>600</sub> q2w x4
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    T<sub>100</sub> q2w x4
                  </span>
                </div>
              ),
              rows: [
                {
                  color: "error",
                  columnA: ">20 %, da ddCTx",
                  columnB: "",
                },
                {
                  color: "success",
                  columnA: "5,1 %",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref36"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ddecpx12</Abbreviation>,
              priority: "high",
              formula: (
                <div css={styles.formula}>
                  <span>
                    E<sub>90</sub>C<sub>600</sub> q2w x4
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    P<sub>80</sub> q1w x12
                  </span>
                </div>
              ),
              rows: [
                {
                  color: "error",
                  columnA: ">20 %, da ddCTx",
                  columnB: "",
                },
                {
                  color: "success",
                  columnA: "1,7 %",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref19"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>iddepc</Abbreviation>,
              priority: "high",
              formula: (
                <div css={styles.formula}>
                  <span>
                    E<sub>150</sub>
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    P<sub>225</sub>
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    C<sub>2500</sub> jeweils q2w x3
                  </span>
                </div>
              ),
              rows: [
                {
                  color: "error",
                  columnA: ">20 %, da ddCTx",
                  columnB: "",
                },
                {
                  color: "success",
                  columnA: "7 %",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref13"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ddecpcb</Abbreviation>,
              priority: "high",
              formula: (
                <div css={styles.formula}>
                  <span>ddEC q2w x4</span> <ArrowForwardIcon fontSize="small" />{" "}
                  <span>P90 qw x12 + Cb AUC5/AUC6 q3w x4</span>
                </div>
              ),
              rows: [
                {
                  color: "error",
                  columnA: ">20 %, da ddCTx",
                  columnB: "",
                },
                {
                  color: "success",
                  columnA: "12 %",
                  columnB: (
                    <>
                      mit G-CSF PP
                      <TextReference references={["ref37"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ecp</Abbreviation>,
              priority: "middle",
              formula: (
                <div css={styles.formula}>
                  <span>
                    E<sub>90</sub>C<sub>600</sub> q3w x 4
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    P<sub>80</sub> q1w x12
                  </span>
                </div>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "5,8 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref17"]} />
                    </>
                  ),
                },
                {
                  color: "warning",
                  columnA: "17,5 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref18"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>acp</Abbreviation>,
              priority: "middle",
              formula: (
                <div css={styles.formula}>
                  <span>
                    A<sub>60</sub>C<sub>600</sub> q3w x4
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    P<sub>80</sub> q1w x12
                  </span>
                </div>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "5,8 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref17"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ac</Abbreviation>,
              priority: "middle",
              formula: (
                <>
                  A<sub>60</sub>C<sub>600</sub> q3w
                </>
              ),
              rows: [
                {
                  color: "warning",
                  columnA: "13 %",
                  columnB: <>in Beobachtungsstudien</>,
                },
                {
                  color: "success",
                  columnA: "7 %",
                  columnB: (
                    <>
                      in RCTs
                      <TextReference references={["ref31"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ac</Abbreviation>,
              priority: "middle",
              formula: (
                <>
                  A<sub>60</sub>C<sub>600</sub> q3w x8
                </>
              ),
              rows: [
                {
                  color: "warning",
                  columnA: "10 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref38"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ec</Abbreviation>,
              priority: "low",
              formula: (
                <>
                  E<sub>90</sub>C<sub>600</sub> q3w
                </>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "6 %",
                  columnB: <>in Beobachtungsstudien</>,
                },
                {
                  color: "success",
                  columnA: "4 %",
                  columnB: (
                    <>
                      in RCTs
                      <TextReference references={["ref31"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>ec</Abbreviation>,
              priority: "low",
              formula: (
                <>
                  E<sub>90</sub>C<sub>600</sub> q3w x6
                </>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "5,4 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref39"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>nabpec</Abbreviation>,
              priority: "low",
              formula: (
                <div css={styles.formula}>
                  <span>
                    nabP
                    <sub>125</sub> q3w day 1,8,15 x4
                  </span>{" "}
                  <ArrowForwardIcon fontSize="small" />{" "}
                  <span>
                    E<sub>90</sub>C<sub>600</sub> q3w x4
                  </span>
                </div>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "1 %",
                  columnB: (
                    <>
                      ohne G-CSF PP
                      <TextReference references={["ref10"]} />
                    </>
                  ),
                },
              ],
            },
          ]}
        />

        <h3>
          Antikörper-Drug Konjugate beim Mammakarzinom{" "}
          <TextInfo>
            Zu den häufigsten Nebenwirkungen von{" "}
            <Abbreviation>adc</Abbreviation>s zählen Neutropenien. Eine G-CSF
            Eine G-CSF Primärprophylaxe sollte je nach ADC sowie
            Patientencharakteristiken in Betracht gezogen werden.
          </TextInfo>
        </h3>
        <CtxRegimeAccordion
          items={[
            {
              title: <Abbreviation>tdxd</Abbreviation>,
              priority: "low",
              formula: (
                <>
                  T-DXd<sub>5,4</sub>
                  q3w
                </>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "≤10%",
                  columnB: (
                    <>
                      Ohne G-CSF PP <TextReference references={["ref43"]} />
                    </>
                  ),
                },
              ],
            },
            {
              title: <Abbreviation>tdm1</Abbreviation>,
              priority: "low",
              formula: (
                <>
                  T-DM1<sub>3,6</sub>
                  q3w
                </>
              ),
              rows: [
                {
                  color: "success",
                  columnA: "0",
                  columnB: (
                    <>
                      Ohne G-CSF PP{" "}
                      <TextReference references={["ref44", "ref45"]} />
                    </>
                  ),
                },
              ],
            },
          ]}
        />
        <Alert
          content={
            <>
              <span>CAVE:</span>
              <br />
              <span>
                Für das ADC Sacituzumab-Govitecan ist bisher keine Einschätzung
                des FN-Risikos möglich. Erste RWE-Daten (n=25) zeigen jedoch,
                dass schwere und lebensbedrohliche Nebenwirkungen wesentlich
                seltener auftraten als in der Zulassungsstudie. Dies wurde auf
                die primäre Gabe von G-CSF zur Prophylaxe der Neutropenie
                zurückgeführt. G-CSF Prophylaxe in Erwägung ziehen.{" "}
                <TextReference references={["ref46"]} />
              </span>
            </>
          }
          type="warning"
        />
      </PagePadding>
    </>
  );
}

export default FnProphylaxisCtRegimes;
