/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ReactNode } from "react";
import { ExpandMore } from "@mui/icons-material";
import Accordion from "../Accordion/Accordion";
import { AccordionNodeItem } from "../Accordion/AccordionNodeItem";
import { AccordionTabItem } from "../Accordion/AccordionTabItem";
import theme from "../../theme";

export interface CtxRegimeAccordionProps {
  items: {
    title: string | ReactNode;
    priority: "high" | "middle" | "low" | "none";
    formula?: string | ReactNode;
    rows: {
      color: "warning" | "error" | "success" | "default";
      columnA: string | ReactNode;
      columnB: string | ReactNode;
    }[];
  }[];
}

export function CtxRegimeAccordion(props: CtxRegimeAccordionProps) {
  const getChipData = (priority: "high" | "middle" | "low" | "none") => {
    const data = {
      label: "",
      type: "primary",
    };

    switch (priority) {
      case "high":
        data.type = "error";
        data.label = "hoch";
        break;
      case "middle":
        data.type = "warning";
        data.label = "mittel";
        break;
      case "low":
        data.type = "success";
        data.label = "gering";
        break;
      default:
        data.type = "";
        data.label = "";
    }
    return data;
  };

  const styles = {
    root: css`
      .MuiAccordionSummary-root {
        border-bottom: 1px solid ${theme.palette.primary.main};
      }
      .Mui-expanded.MuiAccordion-root {
        // border: 1px solid #2660a4;
      }
      .Mui-expanded.MuiAccordion-root:not(:first-of-type) {
        border-top: none;
      }
      .Mui-expanded div.MuiAccordionDetails-root:last-child {
        border-bottom: none;
      }
      .Mui-expanded div.MuiAccordionDetails-root {
        background-color: transparent;
      }
      .MuiAccordionSummary-content {
        margin: 6px 0;
      }
    `,
    formula: css`
      text-align: right;
    `,
    cellB: css`
      text-align: right;
    `,
  };

  return (
    <div css={styles.root}>
      <Accordion
        expandIcon={<ExpandMore />}
        items={props.items.map((item) => {
          const chipData = getChipData(item.priority);
          const body: { content: ReactNode }[] = [];

          if (item.formula) {
            body.push({
              content: (
                <AccordionNodeItem>
                  <div css={styles.formula}>{item.formula}</div>
                </AccordionNodeItem>
              ),
            });
          }
          body.push(
            ...item.rows.map((row) => ({
              content: (
                <AccordionTabItem
                  left={row.columnA}
                  right={<div css={styles.cellB}>{row.columnB}</div>}
                />
              ),
              colorScheme: row.color,
            }))
          );
          return {
            title: item.title,
            chipLabel: item.priority !== "none" ? chipData.label : undefined,
            chipType:
              item.priority !== "none"
                ? (chipData.type as "success" | "warning" | "primary" | "error")
                : undefined,
            body,
          };
        })}
      />
    </div>
  );
}

export default CtxRegimeAccordion;
