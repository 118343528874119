import { CheckList, PagePadding } from "components/ui";
import HexalSeo from "../../components/HexalSeo";
import Abbreviation from "../../components/Abbreviation";
import TextReference from "../../components/TextReference";
import BreadCrumbNavigation from "../../components/BreadCrumbNavigation";

export function FnDiagnosticFn() {
  const checkListNew = (
    <>
      <CheckList
        labelParent="Intravenöser Katheter?"
        childBoxes={[
          {
            id: "ivPort",
            label: "Kontrolle Portkatheterregion",
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent="Symptome oder Zeichen der Infektion"
        childBoxes={[
          {
            id: "smptResp",
            label: "Respirationstrakt",
            checked: false,
          },
          {
            id: "smptGastro",
            label: "Gastrointestinaltrakt",
            checked: false,
          },
          {
            id: "smptHaut",
            label: "Haut",
            checked: false,
          },
          {
            id: "smptPeri",
            label: "Perianal, urogenitale Region",
            checked: false,
          },
          {
            id: "smptOro",
            label: "Oropharynx",
            checked: false,
          },
          {
            id: "smptZns",
            label: "Zentrales Nervensystem",
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent="Vorangegangene positive antimikrobielle Befunde – med. Krankengeschichte"
        childBoxes={[]}
      />
      <CheckList
        labelParent="Routine-Diagnostik:"
        childBoxes={[
          {
            id: "diagBlut",
            label: "Blutbild, Leber-, Nierenfunktion",
            checked: false,
          },
          {
            id: "diagGer",
            label: "Gerinnung",
            checked: false,
          },
          {
            id: "diagCrp",
            label: (
              <>
                <Abbreviation>crp</Abbreviation>, ggf. Procalcitonin
              </>
            ),
            checked: false,
          },
          {
            id: "diagBlutKult",
            label: (
              <>
                Blutkulturen – mindestens 2 Paar, zentrale Zugänge
                <br />
                (bei Port: Abnahme zentraler Zugang peripher und über Port)
              </>
            ),
            checked: false,
          },
          {
            id: "diagUrin",
            label: "Urinanalyse und -kultur",
            checked: false,
          },
          {
            id: "diagSputum",
            label: "Sputummikroskopie und -kultur",
            checked: false,
          },
          {
            id: "diagStuhl",
            label: "Stuhlmikroskopie und -kultur",
            checked: false,
          },
          {
            id: "diagHaut",
            label: "Hautläsion: Aspirat/Abstrich/Biopsie",
            checked: false,
          },
          {
            id: "diagThorax",
            label: "Thoraxröntgen",
            checked: false,
          },
        ]}
      />
      <CheckList
        labelParent={
          <>
            Weiterführende Diagnostik
            <br />
            (schwere/prolongierte Neutropenie, nach Allografts)
          </>
        }
        childBoxes={[
          {
            id: "advThor",
            label: (
              <>
                High-resolution Thorax
                <Abbreviation>ct</Abbreviation> (wenn länger als 72 h febril bei
                suffizienter antimikrobieller Therapie)
              </>
            ),
            checked: false,
          },
          {
            id: "advLavage",
            label: "Bronchoalveoläre Lavage",
            checked: false,
          },
        ]}
      />
    </>
  );

  return (
    <>
      <HexalSeo />
      <PagePadding>
        <BreadCrumbNavigation />
        <h1>Diagnostik & initiales Management der febrilen Neutropenie</h1>
        <ul>
          <li>Diagnostik sollte sofort eingeleitet werden</li>
          <li>
            Signifikant erhöhte Mortalität bei Beginn der Antibiose &gt; 6 h
            nach Auftreten des Fiebers
          </li>
          <li>
            Bei fehlendem Nachweis des Ansprechens einer Therapie muss die
            Diagnostik nach 48–72 h wiederholt werden
            <TextReference references={["ref7"]} />
          </li>
        </ul>
        <p>
          Checkliste
          <TextReference references={["ref7"]} />
        </p>
        <p>
          Schauen Sie hier nach, auf welche Symptome bei der Diagnostik der FN
          besonders zu achten ist und welche unterschiedlichen Methoden der
          Diagnostik bei Verdacht auf FN empfohlen werden. Die Ankreuzfunktion
          kann dabei unterstützen, schneller zu erfassen, welche
          Routineuntersuchungen bspw. für eine/n bestimmte/n Patient:in bereits
          erfolgt sind.
        </p>
        {checkListNew}
      </PagePadding>
    </>
  );
}

export default FnDiagnosticFn;
